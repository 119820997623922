import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Advertisement from "./pages/Advertisement";
import Requisition from "./pages/Requisition";
import QuestionBank from "../psychometric/pages/QuestionBank";

import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";
// import PanelistOralExam from "./pages/PanelistOralExam";
import PrivateRoute from "../common/guard/PrivateRoute";
// import InvigilatorWrittenExam from "./pages/InvigilatorWrittenExam";
// import Appeal from "./pages/Appeal";
// import WaitingListPositions from "./pages/WaitingListPositions";
// import WaitingListRequisitions from "./pages/WaitingListRequisitions";
// import WaitingListCandidates from "./pages/WaitingListCandidates";
// import WaitingListOffers from "./pages/WaitingListOffers";
import { useRequiredAuth } from "../common/guard/requiredAuth";
// import InProgressAppeals from "./pages/InProgressAppeals";
// import ArchivedAppeals from "./pages/ArchivedAppeals";
// import AllOverdueAppeals from "./pages/AllOverdueAppeals";
import RecruitmentAdmin from "../admin/pages/Recruitment";
import PsychometricLayout from "../psychometric/Layout";
// import TempQuestions from "../psychometric/";

import { connect } from "react-redux";
// import Settings from "../psychometric/pages/Settings";

const defaultRouters = [
  {
    to: "/recruitment/requisitions",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF"],
  },
  {
    to: "/recruitment/psychometric",
    permissions: [
      "IS_QUESTION_BANK_ADMIN",
      "IS_QUESTION_BANK_CREATOR",
      "IS_QUESTION_BANK_EDITOR",
    ],
  },
];
const Layout = (props) => {
  const { hasPermissions } = useRequiredAuth();

  const renderDefaultRoute = () => {
    for (const router of defaultRouters) {
      if (hasPermissions(router.permissions)) {
        return router.to;
      }
    }

    return "/unauthorized";
  };

  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/recruitment/dashboard" component={Dashboard} />
        <PrivateRoute
          children={<Requisition />}
          permissions={["IS_HR", "IS_CBM", "IS_DAF"]}
          path="/recruitment/requisitions"
        />
        <PrivateRoute
          children={<Advertisement />}
          permissions={["IS_HR", "IS_CBM", "IS_DAF", "IS_RECRUITMENT_MEMBER"]}
          path="/recruitment/advertisements"
        />

        <PrivateRoute
          children={<RecruitmentAdmin />}
          permissions={["IS_NPSC", "IS_RECRUITMENT_ADMIN"]}
          path="/recruitment/oversight"
        />

        <PrivateRoute
          children={<PsychometricLayout />}
          permissions={[
            "IS_QUESTION_BANK_ADMIN",
            "IS_QUESTION_BANK_CREATOR",
            "IS_QUESTION_BANK_EDITOR",
          ]}
          path="/recruitment/psychometric"
        />

        {/* <PrivateRoute
          children={<TempQuestions />}
          permissions={[
            "IS_QUESTION_BANK_ADMIN",
            "IS_QUESTION_BANK_CREATOR",
            "IS_QUESTION_BANK_EDITOR",
          ]}
          path="/recruitment/psychometric/temp"
        /> */}

        {/* <PrivateRoute
          children={<Psychometric />}
          permissions={["IS_QUESTION_BANK_ADMIN"]}
          path="/recruitment/psychometric/bank"
        />

        <PrivateRoute
          children={<Settings />}
          permissions={["IS_QUESTION_BANK_ADMIN"]}
          path="/recruitment/psychometric/settings"
        /> */}

        {/* <PrivateRoute
          children={<QuestionBank />}
          permissions={["IS_NPSC", "IS_RECRUITMENT_ADMIN"]}
          path="/recruitment/question_bank"
        /> */}

        {/* <PrivateRoute
          children={<MemberApplication />}
          permissions={["IS_RECRUITMENT_MEMBER"]}
          path="/recruitment/applications"
        /> */}

        {/* <PrivateRoute
          children={<WaitingListRouter />}
          permissions={["IS_HR", "IS_CBM", "IS_DAF", "IS_RECRUITMENT_ADMIN"]}
          path="/recruitment/waiting-list"
        /> */}
        {/* <Route path="/recruitment/user-guide" component={UserGuide} /> */}

        <Route
          path="/recruitment"
          component={() => <Redirect to={renderDefaultRoute()} />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};
export default connect(mapStateToProps, {})(Layout);
