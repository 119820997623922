import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CardContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getApplicantOralExamAnswers,
  deleteOralMarkAnswer,
  publishOralExamMarks,
  onViewOralExamQuestions,
} from "../../../store/recruitment/actions";
// import { getUserDetails } from "../../../store/profile/actions";
import PreLoader from "../../common/components/PreLoader";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import ReactPaginate from "react-paginate";

import NoRecordMessage from "../../common/components/NoRecordMessage";
import SearchBox from "../../common/components/SearchBox";
import AddOralMarkDialog from "./AddOralMarkDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ApplicationCv from "../../common/components/ApplicationCv";
// import UserResume from "../../profile/UserResume";

const ApplicantOralExamAnswerCard = (props) => {
  const {
    oralExamMarkAnswer,
    exam,
    setSelectedOralExamMarkAnswer,
    setShowConfirmRemoveOralMark,
    user,
    getUserDetails,
    loading,
  } = props;
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);

  const handleOpenCurrentProfile = () => {
    setShowCurrentProfile(true);
  };
  return (
    <>
      <tr>
        <th className="text-left" style={{ backgroundColor: "#f0f2f5" }}>
          {oralExamMarkAnswer.examinerId}
        </th>

        <td className="text-left">
          {oralExamMarkAnswer.examinerFirstName}{" "}
          {oralExamMarkAnswer.examinerLastName}
        </td>
        <td className="text-left">{oralExamMarkAnswer.examinerEmail}</td>

        <td
          className="text-left"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMarkAnswer.examinerPhoneNumber}
        </td>

        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMarkAnswer.examinerScoreOnBehaviour}/
          {oralExamMarkAnswer.markOnBehaviour}
        </td>
        <td
          className="text-center"
          scope="row"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMarkAnswer.scoreOnBehaviour}/
          {oralExamMarkAnswer.markOnBehaviour}
        </td>
        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMarkAnswer.examinerScoreOnTechnicalKnowledge}/
          {oralExamMarkAnswer.markOnTechnicalKnowledge}
        </td>
        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMarkAnswer.scoreOnTechnicalKnowledge}/
          {oralExamMarkAnswer.markOnTechnicalKnowledge}
        </td>
        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMarkAnswer.examinerScoreOnGeneralKnowledge}/
          {oralExamMarkAnswer.markOnGeneralKnowledge}
        </td>
        <td
          className="text-center "
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMarkAnswer.scoreOnGeneralKnowledge}/
          {oralExamMarkAnswer.markOnGeneralKnowledge}
        </td>
        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMarkAnswer.examinerScoreOnCommunicationSkills}/
          {oralExamMarkAnswer.markOnCommunicationSkills}
        </td>
        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMarkAnswer.scoreOnCommunicationSkills}/
          {oralExamMarkAnswer.markOnCommunicationSkills}
        </td>

        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMarkAnswer.examinerScoreOnWrittenSkills}/
          {oralExamMarkAnswer.markOnWrittenSkills}
        </td>

        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMarkAnswer.scoreOnWrittenSkills}/
          {oralExamMarkAnswer.markOnWrittenSkills}
        </td>
        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          <div className="text-center">
            {oralExamMarkAnswer.examinerTotalScore}/
            {oralExamMarkAnswer.totalMarks}
          </div>
        </td>
        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          <div className="text-center">
            {oralExamMarkAnswer.totalOralScore}/{oralExamMarkAnswer.totalMarks}
          </div>
        </td>
        {!!exam.willHaveWrittenExam && (
          <td className="text-center">
            <div className="text-center">
              {oralExamMarkAnswer.totalWrittenScore}/
              {oralExamMarkAnswer.totalWrittenMarks}
            </div>
          </td>
        )}

        <td className="text-center">
          <div
            className="text-center"
            style={{
              margin: "0 auto",
              padding: "2px",
              width: "80px",
              height: "auto",
              border: `solid 3px ${
                oralExamMarkAnswer.totalScore >= 25 ? "#28a745" : "#dc3545"
              }`,
              borderRadius: "50px",
              textAlign: "center",
            }}
          >
            {oralExamMarkAnswer.totalScore}/100
          </div>
        </td>

        <td className="text-center">
          <button
            className={"btn btn-primary btn-sm"}
            onClick={() =>
              getUserDetails(
                oralExamMarkAnswer.examinerId,
                handleOpenCurrentProfile
              )
            }
          >
            {loading ? "Wait..." : "Show profile"}
          </button>
        </td>
        {/* {showCurrentProfile && (
          <UserResume
            showProfileDialog={showCurrentProfile}
            setShowProfileDialog={setShowCurrentProfile}
          />
        )} */}
        {showApplicationCv && (
          <ApplicationCv
            showApplicationCv={showApplicationCv}
            setShowApplicationCv={setShowApplicationCv}
            userId={oralExamMarkAnswer.examinerId}
          />
        )}
      </tr>
    </>
  );
};

const ApplicantOralExamAnswerDialog = (props) => {
  const {
    loading,
    showApplicantOralExamAnswers,
    setShowApplicantOralExamAnswers,
    getApplicantOralExamAnswers,
    applicantOralExamAnswers,
    applicantOralExamResult,
    deleteOralMarkAnswer,
    onViewOralExamQuestions,
    user,
    getUserDetails,
  } = props;

  const [searchTeam, setSearchTeam] = useState("");
  const [showDocument, setShowDocument] = useState(false);
  const [documentQuestions, setDocumentQuestions] = useState({});
  const [showAddOralMark, setShowAddOralMark] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOralExamMarkAnswer, setSelectedOralExamMarkAnswer] =
    useState(null);

  const [showConfirmRemoveOralMark, setShowConfirmRemoveOralMark] =
    useState(false);

  const [filterApplicantOralExamAnswers, setFilterApplicantOralExamAnswers] =
    useState([...applicantOralExamAnswers]);

  useEffect(
    () => {
      getApplicantOralExamAnswers(applicantOralExamResult.id, setIsLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicantOralExamResult]
  );

  useEffect(() => {
    const filtered = [...applicantOralExamAnswers].filter(
      ({
        examinerFirstName,
        examinerLastName,
        examinerEmail,
        examinerPhoneNumber,
      }) =>
        examinerFirstName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        examinerLastName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        examinerEmail.toLowerCase().includes(searchTeam.toLowerCase()) ||
        examinerPhoneNumber.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilterApplicantOralExamAnswers(filtered);
    paginate(filtered);
  }, [searchTeam, applicantOralExamAnswers]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(
    () => {
      paginate(filterApplicantOralExamAnswers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, filterApplicantOralExamAnswers]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filterApplicantOralExamAnswers.length;

    setItemOffset(newOffset);
  };

  const onClose = () => {
    setShowApplicantOralExamAnswers(false);
  };
  const theme = useTheme();
  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showApplicantOralExamAnswers}
        fullScreen
        maxWidth={"xl"}
      >
        <DialogTitle>
          <Typography variant="h6" noWrap component="div">
            <span style={{ color: "#007bff" }}>Oral Marking</span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {showDocument && (
            <PreviewPdfUrl
              isBase64={true}
              showDocument={showDocument}
              document={documentQuestions}
              setShowDocument={setShowDocument}
            />
          )}
          <div
            className="w-100"
            style={{
              height: "520px",
            }}
          >
            <div
              style={{
                height: "auto",
                maxHeight: "auto",
                border: "2px solid #e0f4f3",
                borderRadius: "0.5rem",
                overflow: "visible",
                boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              }}
            >
              <CardContent
                style={{
                  // padding: ".6em",
                  height: "auto",
                  minHeight: "auto",
                  overflow: "hidden",
                }}
              >
                <div className="row">
                  <div className="col-lg-4 border-right">
                    <div className="row">
                      <div className="col-2">ID</div>
                      <div className="col-10">
                        <strong>:</strong>
                        <strong className="ml-1 text-truncate">
                          {applicantOralExamResult.userId}
                        </strong>{" "}
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-2">Names</div>
                      <div className="col-10">
                        {" "}
                        <strong>:</strong>
                        <strong className="ml-1 text-truncate">
                          {applicantOralExamResult.firstName}{" "}
                          {applicantOralExamResult.lastName}
                        </strong>{" "}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-2">Email</div>
                      <div className="col-10">
                        {" "}
                        <strong>:</strong>
                        <small className="ml-1 text-truncate">
                          {applicantOralExamResult.email}
                        </small>{" "}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-2">Phone</div>
                      <div className="col-10">
                        {" "}
                        <strong>:</strong>
                        <small className="ml-1 text-truncate">
                          {applicantOralExamResult.phoneNumber}
                        </small>{" "}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-2">Created</div>
                      <div className="col-10">
                        {" "}
                        <strong className="text-truncate">
                          :
                          <span className="ml-1" style={{ color: "#007bff" }}>
                            {applicantOralExamResult.createdOn
                              ? moment(
                                  applicantOralExamResult.createdOn
                                ).format("MMMM Do YYYY HH:mm A")
                              : ""}{" "}
                          </span>
                        </strong>{" "}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-2">Panel</div>
                      <div className="col-10">
                        {" "}
                        <strong className="text-truncate">
                          :
                          <span className="ml-1" style={{ color: "#007bff" }}>
                            {applicantOralExamResult.panelName}
                          </span>
                        </strong>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 border-right">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className=" text-center mt-4"
                          style={{
                            margin: "0 auto",
                            // padding:'3px',
                            width: "120px",
                            height: "120px",
                            border: `solid 4px ${
                              applicantOralExamResult.statusId === 3
                                ? "#28a745"
                                : applicantOralExamResult.statusId === 2
                                ? "#dc3545"
                                : "#007bff"
                            }`,
                            borderRadius: "100%",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className="text-center"
                            style={{
                              position: "relative",
                              top: "38px",
                            }}
                          >
                            <strong
                              style={{ fontSize: "22px", fontFamily: "800" }}
                            >
                              {applicantOralExamResult.totalOralScore}/
                              {applicantOralExamResult.totalMarks}
                            </strong>
                            {/* <PercentIcon
                          style={{ fontSize: "20px", fontFamily: "bold" }}
                        /> */}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="mx-auto col-6 text-center">
                        <strong className="ml-2 text-truncate">
                          {applicantOralExamResult.statusId === 3 ? (
                            <span className="badge  badge-success mt-1">
                              {applicantOralExamResult.status}
                            </span>
                          ) : applicantOralExamResult.statusId === 2 ? (
                            <span className="badge  badge-danger mt-1">
                              {applicantOralExamResult.status}
                            </span>
                          ) : (
                            <span className="badge  badge-primary mt-1">
                              {applicantOralExamResult.status}
                            </span>
                          )}
                        </strong>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="row mt-2">
                      <div className="col-12">
                        <div className="input-group input-group-sm mt-2 row no-gutters">
                          <div className="input-group-prepend col-6">
                            <span
                              className="input-group-text w-100"
                              id="basic-addon1"
                            >
                              Score On Behavior
                            </span>
                          </div>
                          <div className="form-control col text-center">
                            <div className="form-check form-check-inline">
                              <div
                                style={{
                                  padding: "0px",
                                  marginTop: "-10px",
                                  width: "70px",
                                  height: "auto",

                                  textAlign: "center",
                                }}
                              >
                                {applicantOralExamResult.scoreOnBehaviour}/
                                {applicantOralExamResult.markOnBehaviour || ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="input-group input-group-sm mt-2 row no-gutters">
                          <div className="input-group-prepend col-6">
                            <span
                              className="input-group-text w-100"
                              id="basic-addon1"
                            >
                              Score On Technical Knowledge
                            </span>
                          </div>
                          <div className="form-control col text-center">
                            <div className="form-check form-check-inline">
                              <div
                                style={{
                                  padding: "0px",
                                  marginTop: "-10px",
                                  width: "70px",
                                  height: "auto",

                                  textAlign: "center",
                                }}
                              >
                                {
                                  applicantOralExamResult.scoreOnTechnicalKnowledge
                                }
                                /
                                {applicantOralExamResult.markOnTechnicalKnowledge ||
                                  ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="input-group input-group-sm mt-2 row no-gutters">
                          <div className="input-group-prepend col-6">
                            <span
                              className="input-group-text w-100"
                              id="basic-addon1"
                            >
                              Score On General Knowledge
                            </span>
                          </div>
                          <div className="form-control col text-center">
                            <div className="form-check form-check-inline">
                              <div
                                style={{
                                  padding: "0px",
                                  marginTop: "-10px",
                                  width: "70px",
                                  height: "auto",

                                  textAlign: "center",
                                }}
                              >
                                {
                                  applicantOralExamResult.scoreOnGeneralKnowledge
                                }
                                /
                                {applicantOralExamResult.markOnGeneralKnowledge ||
                                  ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="input-group input-group-sm mt-2 row no-gutters">
                          <div className="input-group-prepend col-6">
                            <span
                              className="input-group-text w-100"
                              id="basic-addon1"
                            >
                              Score On Communication Skills
                            </span>
                          </div>
                          <div className="form-control col text-center">
                            <div className="form-check form-check-inline">
                              <div
                                style={{
                                  padding: "0px",
                                  marginTop: "-10px",
                                  width: "70px",
                                  height: "auto",

                                  textAlign: "center",
                                }}
                              >
                                {
                                  applicantOralExamResult.scoreOnCommunicationSkills
                                }
                                /
                                {applicantOralExamResult.markOnCommunicationSkills ||
                                  ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="input-group input-group-sm mt-2 row no-gutters">
                          <div className="input-group-prepend col-6">
                            <span
                              className="input-group-text w-100"
                              id="basic-addon1"
                            >
                              Score On Written Skills
                            </span>
                          </div>
                          <div className="form-control col text-center">
                            <div className="form-check form-check-inline">
                              <div
                                style={{
                                  padding: "0px",
                                  marginTop: "-10px",
                                  width: "70px",
                                  height: "auto",

                                  textAlign: "center",
                                }}
                              >
                                {applicantOralExamResult.scoreOnWrittenSkills && (
                                  <>
                                    {applicantOralExamResult.scoreOnWrittenSkills ||
                                      "-"}
                                    /
                                    {applicantOralExamResult.markOnWrittenSkills ||
                                      ""}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </div>
            {!isLoading && (
              <div
                className="mt-3"
                style={{
                  padding: theme.spacing(0.4),
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <div className="row mt-3">
                    <div
                      className={
                        !!applicantOralExamAnswers.length
                          ? "col-lg-7"
                          : "col-lg-10"
                      }
                    >
                      <button
                        onClick={() =>
                          onViewOralExamQuestions(
                            applicantOralExamResult.examId,
                            setDocumentQuestions,
                            setShowDocument
                          )
                        }
                        className="btn btn-link text-primary float-left"
                      >
                        <i className="fa fa-eye"></i> View prepared oral exam
                        questions
                      </button>
                    </div>
                    <div
                      className={
                        !!applicantOralExamAnswers.length
                          ? "col-lg-5"
                          : "col-lg-2"
                      }
                    >
                      {!!applicantOralExamAnswers.length && (
                        <SearchBox onSearch={onSearch} placeholder="Search…" />
                      )}
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12">
                      <NoRecordMessage
                        isEmpty={
                          !filterApplicantOralExamAnswers.length && !isLoading
                        }
                        title="No oral marks found."
                      />
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mt-1">
                      {!!paginatedItems.length && (
                        <table className="table table-striped table-hover table-sm  fixTableHead">
                          <thead>
                            <tr style={{ backgroundColor: "#f0f2f5" }}>
                              <th scope="col">ExaminerId</th>

                              <th scope="col" className="text-left">
                                Names
                              </th>

                              <th scope="col" className="text-left">
                                Email
                              </th>
                              <th scope="col" className="text-left">
                                PhoneNumber
                              </th>

                              <th
                                scope="col"
                                colSpan={2}
                                style={{
                                  borderRight: "1px solid rgb(150, 171, 181)",
                                }}
                                className="text-center"
                              >
                                Behavior
                              </th>
                              <th
                                scope="col"
                                colSpan={2}
                                style={{
                                  borderRight: "1px solid rgb(150, 171, 181)",
                                }}
                                className="text-center text-truncate"
                              >
                                TechnicalKnowledge
                              </th>
                              <th
                                scope="col"
                                colSpan={2}
                                style={{
                                  borderRight: "1px solid rgb(150, 171, 181)",
                                }}
                                className="text-center text-truncate"
                              >
                                GeneralKnowledge
                              </th>
                              <th
                                scope="col"
                                colSpan={2}
                                style={{
                                  borderRight: "1px solid rgb(150, 171, 181)",
                                }}
                                className="text-center text-truncate"
                              >
                                CommunicationSkills
                              </th>
                              <th
                                scope="col"
                                colSpan={2}
                                style={{
                                  borderRight: "1px solid rgb(150, 171, 181)",
                                }}
                                className="text-center text-truncate"
                              >
                                WrittenSkills
                              </th>

                              <th
                                scope="col"
                                colSpan={2}
                                style={{
                                  borderRight: "1px solid rgb(150, 171, 181)",
                                }}
                                className="text-center"
                              >
                                TotalOralScore
                              </th>
                              {!!applicantOralExamResult.willHaveWrittenExam && (
                                <th scope="col" className="text-center">
                                  TotalWrittenScore
                                </th>
                              )}

                              <th scope="col" className="text-center">
                                TotalScore
                              </th>
                              {/* <th scope="col" className="text-center">
                                TopupScore
                              </th> */}
                              {/* <th scope="col" className="text-center">
                                TotalScore
                              </th> */}
                              <th scope="col" className="text-center">
                                Actions
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {paginatedItems.map((oralExamMarkAnswer, index) => (
                              <ApplicantOralExamAnswerCard
                                key={index}
                                user={user}
                                oralExamMarkAnswer={oralExamMarkAnswer}
                                exam={applicantOralExamResult}
                                setSelectedOralExamMarkAnswer={
                                  setSelectedOralExamMarkAnswer
                                }
                                setShowConfirmRemoveOralMark={
                                  setShowConfirmRemoveOralMark
                                }
                                getUserDetails={getUserDetails}
                                loading={loading}
                              />
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </CardContent>
              </div>
            )}
            {showAddOralMark && (
              <AddOralMarkDialog
                showAddOralMark={showAddOralMark}
                setShowAddOralMark={setShowAddOralMark}
                applicantOralExamResult={applicantOralExamResult}
              />
            )}
            {showConfirmRemoveOralMark && (
              <ConfirmationDialog
                confirmationDialog={showConfirmRemoveOralMark}
                message={`Are you sure you want to remove examiner ${selectedOralExamMarkAnswer.examinerFirstName} marks?`}
                setConfirmationDialog={setShowConfirmRemoveOralMark}
                onYes={() => {
                  deleteOralMarkAnswer(
                    selectedOralExamMarkAnswer,
                    setShowConfirmRemoveOralMark
                  );
                }}
              />
            )}

            <PreLoader isLoading={isLoading} />
          </div>
        </DialogContent>

        {!!paginatedItems.length &&
          applicantOralExamAnswers.length >= itemsPerPage && (
            <DialogActions className="d-flex justify-content-center py-1">
              <div
                className="justify-content-center mt-2"
                style={{ overflow: "hidden" }}
              >
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              </div>
            </DialogActions>
          )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  applicantOralExamAnswers,
  applicantOralExamResult,
}) => {
  return {
    loading,
    user,
    applicantOralExamAnswers,
    applicantOralExamResult,
  };
};
export default connect(mapStateToProps, {
  getApplicantOralExamAnswers,
  deleteOralMarkAnswer,
  publishOralExamMarks,
  onViewOralExamQuestions,
  // getUserDetails,
})(ApplicantOralExamAnswerDialog);
