import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  loadApplicationByAdvertAndExam,
  loadApplicationByAdvertAndOffer,
  loadApplicationByAdvertAndStatus,
  loadRecruitmentDashboard,
} from "../../../store/admin/actions";
import {
  DashboardDoughnutChart,
  DashboardLabel,
  DashboardPieChart,
} from "./StructureDashboard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import TableSkeleton from "../../common/components/TableSkeleton";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const RecruitmentDashboard = (props) => {
  const {
    selectedYear,

    // REDUX-------------
    loading,
    recruitmentStatistic,
    loadRecruitmentDashboard,
    loadApplicationByAdvertAndStatus,
    loadApplicationByAdvertAndExam,
    loadApplicationByAdvertAndOffer,
  } = props;
  const [loadingStatistics, setLoadingStatistics] = useState(true);
  const [viewApplications, setViewApplications] = useState(null);
  const [loadingModal, setLoadingModal] = useState(null);

  useEffect(() => {
    document.title = "IPPIS - Admin Recruitment | Dashboard";
    if (selectedYear !== null) {
      if (!recruitmentStatistic) {
        loadRecruitmentDashboard(
          selectedYear ? selectedYear.adYear : 0,
          (status) => setLoadingStatistics(status)
        );
      } else if (!loadingStatistics) {
        loadRecruitmentDashboard(selectedYear.adYear, (status) =>
          setLoadingStatistics(status)
        );
      } else setLoadingStatistics(false);
    }
    return () => {
      setLoadingStatistics(true);
    };
  }, [selectedYear]);

  if (recruitmentStatistic === null) return null;

  const {
    advertStatistic,
    appYears,
    appGender,
    bigTotal,
    offers,
    waitingList,
    exams,
  } = recruitmentStatistic;
  const REC_GENDER_PIE_CHART = {
    labels: ["Female", "Male"],
    datasets: [
      {
        label: "# of Votes",
        data: [appGender.TOTAL_REC_FEMALE, appGender.TOTAL_REC_MALE],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const SHORT_GENDER_PIE_CHART = {
    labels: ["Female", "Male"],
    datasets: [
      {
        label: "# of Votes",
        data: [appGender.TOTAL_FEMALE, appGender.TOTAL_MALE],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const REJ_GENDER_PIE_CHART = {
    labels: ["Female", "Male"],
    datasets: [
      {
        label: "# of Votes",
        data: [appGender.TOTAL_REJ_FEMALE, appGender.TOTAL_REJ_MALE],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const APPLICATION_STATUS_DOUGHNUT_CHART = {
    labels: ["Pending", "Shortlisted", "Rejected"],
    datasets: [
      {
        data: [
          bigTotal.TOTAL_APPLICATIONS -
            (bigTotal.TOTAL_SHORTLISTED + bigTotal.TOTAL_NOT_SHORTLISTED) ?? 0,
          bigTotal.TOTAL_SHORTLISTED || 0,
          bigTotal.TOTAL_NOT_SHORTLISTED || 0,
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(80, 181, 88,0.6)",
          "rgba(255, 206, 86, 0.5)",
          // "rgba(255, 206, 0, 0.5)",
          // "rgba(80, 0, 88,0.6)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(80, 181, 88, 1)",
          "rgba(255, 206, 86, 1)",
          // "rgba(255, 206, 0, 1)",
          // "rgba(80, 0, 88, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const EXAM_STATUS_DOUGHNUT_CHART = {
    labels: ["Pending", "Passed", "Failed"],
    datasets: [
      {
        data: [
          exams.TOTAL_EXAM_PENDING,
          exams.TOTAL_EXAM_PASSED,
          exams.TOTAL_EXAM_FAILED,
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(80, 181, 88,0.6)",
          "rgba(255, 206, 86, 0.5)",
          // "rgba(255, 206, 0, 0.5)",
          // "rgba(80, 0, 88,0.6)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(80, 181, 88, 1)",
          "rgba(255, 206, 86, 1)",
          // "rgba(255, 206, 0, 1)",
          // "rgba(80, 0, 88, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleViewApplicationByStatus = (advert, status) => {
    setLoadingModal({ type: status, key: advert.id, purpose: "APPLICATION" });
    loadApplicationByAdvertAndStatus(
      { advertId: advert.id, status },
      (data) => {
        if (data && data.applications.length) {
          setViewApplications({
            ...data,
            advert,
            status,
            purpose: "APPLICATION",
          });
          setLoadingModal(null);
        }
      }
    );
  };

  const handleViewApplicationByExam = (advert, status) => {
    setLoadingModal({ type: status, key: advert.id, purpose: "EXAM" });
    loadApplicationByAdvertAndExam({ advertId: advert.id, status }, (data) => {
      if (data && data.applications.length) {
        setViewApplications({
          ...data,
          advert,
          status,
          purpose: "EXAM",
        });
        setLoadingModal(null);
      }
    });
  };

  const handleViewApplicationByOffer = (advert, status) => {
    setLoadingModal({ type: status, key: advert.id, purpose: "OFFER" });
    loadApplicationByAdvertAndOffer({ advertId: advert.id, status }, (data) => {
      if (data && data.applications.length) {
        setViewApplications({
          ...data,
          advert,
          status,
          purpose: "OFFER",
        });
        setLoadingModal(null);
      }
    });
  };

  return (
    <React.Fragment>
      {/* *********************************** APPLICATION PROCESS **************************************** */}
      {/* labels */}
      <div className="d-flex flex-wrap justify-content-center w-100">
        <DashboardLabel
          labelTitle="Advertisements"
          labelValue={bigTotal.TOTAL_ADVERTS}
          loading={loadingStatistics}
        />
        <DashboardLabel
          labelTitle="Posts"
          labelValue={bigTotal.TOTAL_POST}
          loading={loadingStatistics}
        />
        <DashboardLabel
          labelTitle="Applications"
          labelValue={bigTotal.TOTAL_APPLICATIONS}
          loading={loadingStatistics}
        />
        <DashboardLabel
          labelTitle="Not Shortlisted"
          labelValue={bigTotal.TOTAL_NOT_SHORTLISTED}
          loading={loadingStatistics}
        />
        <DashboardLabel
          labelTitle="Shortlisted"
          labelValue={bigTotal.TOTAL_SHORTLISTED}
          loading={loadingStatistics}
        />
        <DashboardLabel
          labelTitle="Exams"
          labelValue={exams.TOTAL_EXAMS}
          loading={loadingStatistics}
        />
        <DashboardLabel
          labelTitle="Offers"
          labelValue={offers.TOTAL_DOFFER}
          loading={loadingStatistics}
        />
        <DashboardLabel
          labelTitle="Waiting list"
          labelValue={waitingList.TOTAL_APPLICANTS}
          loading={loadingStatistics}
        />
      </div>

      {/* pie and doughnut charts */}
      {recruitmentStatistic && (
        <div className="row justify-content-center w-100 my-sm-3 px-4">
          <div className="col-6 col-sm-2 mb-2 mb-sm-0">
            <DashboardDoughnutChart
              chartTitle="Applications status"
              chartData={APPLICATION_STATUS_DOUGHNUT_CHART}
              loading={loadingStatistics}
            />
          </div>
          <div className="col-6 col-sm-2 mb-2 mb-sm-0">
            <DashboardPieChart
              pieTitle="Pending Applications"
              pieData={REC_GENDER_PIE_CHART}
              loading={loadingStatistics}
            />
          </div>
          <div className="col-6 col-sm-2 mb-2 mb-sm-0">
            <DashboardPieChart
              pieTitle="Shortlisted Applications"
              pieData={SHORT_GENDER_PIE_CHART}
              loading={loadingStatistics}
            />
          </div>
          <div className="col-6 col-sm-2 mb-2 mb-sm-0">
            <DashboardPieChart
              pieTitle="Rejected Applications"
              pieData={REJ_GENDER_PIE_CHART}
              loading={loadingStatistics}
            />
          </div>
          <div className="col-6 col-sm-2 mb-2 mb-sm-0">
            <DashboardDoughnutChart
              chartTitle="Examination status"
              chartData={EXAM_STATUS_DOUGHNUT_CHART}
              loading={loadingStatistics}
            />
          </div>
        </div>
      )}

      <div className="container-fluid mt-5 mb-4">
        {/* table levels */}
        <div className="px-2">
          <div
            className="rounded py-2 px-2 table-responsive"
            style={{ border: "1px solid rgb(198 205 209)" }}
          >
            <table className="table table-sm">
              <caption
                style={{ backgroundColor: "#e9ecef", fontSize: "18px" }}
                className="mb-2 w-100 font-weight-bold"
              >
                Advertisements Summary
              </caption>
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                    rowSpan={2}
                  >
                    No
                  </th>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                    rowSpan={2}
                  >
                    Institutions
                  </th>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                    rowSpan={2}
                  >
                    Positions
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                    rowSpan={2}
                  >
                    Posts
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #b6ccf3",
                      background: "#b6ccf3",
                    }}
                    colSpan={3}
                  >
                    Applications
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #71b586",
                      background: "#71b586",
                    }}
                    colSpan={3}
                  >
                    Exam status
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #aed59f",
                      borderRight: "3px solid #dee2e6",
                      background: "#aed59f",
                    }}
                    colSpan={5}
                  >
                    Offers
                  </th>
                  {/* <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                      borderRight: "3px solid #dee2e6",
                    }}
                    colSpan={3}
                  >
                    Waiting list
                  </th> */}
                </tr>
                <tr>
                  {/* APPLICATION */}
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #b6ccf3",
                      minWidth: "70px",
                    }}
                  >
                    All
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Shortlisted
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Rejected
                  </th>
                  {/* EXAMINATION */}
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #71b586" }}
                  >
                    Pending
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Passed
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Failed
                  </th>
                  {/* OFFER */}
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #aed59f",
                    }}
                  >
                    Pending
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Accepted
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Rejected
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Canceled
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                      borderRight: "3px solid #dee2e6",
                    }}
                  >
                    Employed
                  </th>
                  {/* WAITING LIST */}
                  {/* <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Active
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                      borderRight: "3px solid #dee2e6",
                    }}
                  >
                    Employed
                  </th> */}
                </tr>
              </thead>
              {!loadingStatistics ? (
                <tbody style={{ fontSize: "13px" }}>
                  {advertStatistic.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-left"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.entityName}
                      </td>
                      <td
                        className={`text-left`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.positionName}
                      </td>
                      <td
                        className={`text-center`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.numberOfPosts}
                      </td>
                      {/* APPLICATIONS */}
                      <td
                        className={`text-center ${
                          item.numberOfApplication > 0 &&
                          !loading &&
                          !loadingStatistics &&
                          !loadingModal
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #b6ccf3" }}
                        onClick={() =>
                          item.numberOfApplication > 0 &&
                          !loading &&
                          !loadingStatistics &&
                          !loadingModal &&
                          handleViewApplicationByStatus(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "all"
                          )
                        }
                      >
                        {item.numberOfApplication}
                        {item.numberOfApplication > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "APPLICATION" ||
                          loadingModal.type !== "all" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view list all applicants"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      <td
                        className={`text-center ${
                          item.shortListedApplications > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                        onClick={() =>
                          item.shortListedApplications > 0 &&
                          !loading &&
                          !loadingStatistics &&
                          !loadingModal &&
                          handleViewApplicationByStatus(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "shortlisted"
                          )
                        }
                      >
                        {item.shortListedApplications}
                        {item.shortListedApplications > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "APPLICATION" ||
                          loadingModal.type !== "shortlisted" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view shortlisted applicants"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      <td
                        className={`text-center ${
                          item.notShortListedApplications > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                        onClick={() =>
                          item.notShortListedApplications > 0 &&
                          !loading &&
                          !loadingStatistics &&
                          !loadingModal &&
                          handleViewApplicationByStatus(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "rejected"
                          )
                        }
                      >
                        {item.notShortListedApplications}
                        {item.notShortListedApplications > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "APPLICATION" ||
                          loadingModal.type !== "rejected" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view not shortlisted applicants"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      {/* EXAMINATION */}
                      <td
                        className={`text-center ${
                          item.waitingForExam > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #71b586" }}
                        onClick={() =>
                          item.waitingForExam > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics &&
                          handleViewApplicationByExam(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "pending"
                          )
                        }
                      >
                        {item.waitingForExam}
                        {item.waitingForExam > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "EXAM" ||
                          loadingModal.type !== "pending" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view applicants who have at least one pending exam"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      <td
                        className={`text-center ${
                          item.passedApplicants > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                        onClick={() =>
                          item.passedApplicants > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics &&
                          handleViewApplicationByExam(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "pass"
                          )
                        }
                      >
                        {item.passedApplicants}
                        {item.passedApplicants > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "EXAM" ||
                          loadingModal.type !== "pass" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view applicants who have passed all exams"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      <td
                        className={`text-center ${
                          item.failedApplicants > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                        onClick={() =>
                          item.failedApplicants > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics &&
                          handleViewApplicationByExam(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "failed"
                          )
                        }
                      >
                        {item.failedApplicants}
                        {item.failedApplicants > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "EXAM" ||
                          loadingModal.type !== "failed" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view applicants who have failed exam"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      {/* DIRECT OFFERS */}
                      <td
                        className={`text-center ${
                          item.dirPendingOffer > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #aed59f" }}
                        onClick={() =>
                          item.dirPendingOffer > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics &&
                          handleViewApplicationByOffer(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "pending"
                          )
                        }
                      >
                        {item.dirPendingOffer}
                        {item.dirPendingOffer > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "OFFER" ||
                          loadingModal.type !== "pending" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view applicants who have pending offer request!"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      <td
                        className={`text-center ${
                          item.dirAcceptedOffer > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                        onClick={() =>
                          item.dirAcceptedOffer > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics &&
                          handleViewApplicationByOffer(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "accepted"
                          )
                        }
                      >
                        {item.dirAcceptedOffer}
                        {item.dirAcceptedOffer > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "OFFER" ||
                          loadingModal.type !== "accepted" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view applicants who have accepted the offer"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      <td
                        className={`text-center ${
                          item.dirRejectedOffer > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                        onClick={() =>
                          item.dirRejectedOffer > 0 &&
                          !loading &&
                          !loadingModal &&
                          !loadingStatistics &&
                          handleViewApplicationByOffer(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "rejected"
                          )
                        }
                      >
                        {item.dirRejectedOffer}
                        {item.dirRejectedOffer > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "OFFER" ||
                          loadingModal.type !== "rejected" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view applicants who have rejected the offer"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      <td
                        className={`text-center ${
                          item.dirCanceledOffer > 0 &&
                          !loading &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{ borderLeft: "3px solid #dee2e6" }}
                        onClick={() =>
                          item.dirCanceledOffer > 0 &&
                          !loading &&
                          !loadingStatistics &&
                          handleViewApplicationByOffer(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "canceled"
                          )
                        }
                      >
                        {item.dirCanceledOffer}
                        {item.dirCanceledOffer > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "OFFER" ||
                          loadingModal.type !== "canceled" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view applicants who have canceled the offer"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                      <td
                        className={`text-center ${
                          item.dirEmployedOffer > 0 &&
                          !loading &&
                          !loadingStatistics
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                        }}
                        onClick={() =>
                          item.dirEmployedOffer > 0 &&
                          !loading &&
                          !loadingStatistics &&
                          handleViewApplicationByOffer(
                            {
                              id: item.id,
                              entityName: item.entityName,
                              positionName: item.positionName,
                              numberOfPosts: item.numberOfPosts,
                            },
                            "employed"
                          )
                        }
                      >
                        {item.dirEmployedOffer}
                        {item.dirEmployedOffer > 0 &&
                          (!loadingModal ||
                          loadingModal.purpose !== "OFFER" ||
                          loadingModal.type !== "employed" ||
                          loadingModal.key !== item.id ? (
                            <div className={`float-right td-clickable-icon`}>
                              <IconButton
                                aria-label="view applicants who have been employed!"
                                size="small"
                              >
                                <RemoveRedEyeRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          ) : (
                            <div className={`float-right`}>
                              <CircularProgress color="info" size={15} />
                            </div>
                          ))}
                      </td>
                    </tr>
                  ))}

                  {advertStatistic.length && (
                    <tr>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light"
                        colSpan={3}
                      >
                        Total
                      </th>
                      <th
                        style={{
                          borderTop: "3px solid #dee2e6",
                          borderBottom: "3px solid #dee2e6",
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #b6ccf3",
                        }}
                        className="text-center bg-light "
                      >
                        {bigTotal.TOTAL_POST}
                      </th>
                      {/* APPLICATIONS */}
                      <th
                        style={{
                          borderTop: "3px solid #dee2e6",
                          borderBottom: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                          borderLeft: "3px solid #b6ccf3",
                        }}
                        className="text-center bg-light "
                      >
                        {bigTotal.TOTAL_APPLICATIONS}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {bigTotal.TOTAL_SHORTLISTED}
                      </th>
                      <th
                        style={{
                          borderTop: "3px solid #dee2e6",
                          borderBottom: "3px solid #dee2e6",
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #71b586",
                        }}
                        className="text-center bg-light "
                      >
                        {bigTotal.TOTAL_NOT_SHORTLISTED}
                      </th>
                      {/* EXAMINATIONS */}
                      <th
                        style={{
                          borderTop: "3px solid #dee2e6",
                          borderBottom: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                          borderLeft: "3px solid #71b586",
                        }}
                        className="text-center bg-light "
                      >
                        {exams.TOTAL_EXAM_PENDING}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {exams.TOTAL_EXAM_PASSED}
                      </th>
                      <th
                        style={{
                          borderTop: "3px solid #dee2e6",
                          borderBottom: "3px solid #dee2e6",
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #aed59f",
                        }}
                        className="text-center bg-light "
                      >
                        {exams.TOTAL_EXAM_FAILED}
                      </th>
                      {/* OFFERS */}
                      <th
                        style={{
                          borderTop: "3px solid #dee2e6",
                          borderBottom: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                          borderLeft: "3px solid #aed59f",
                        }}
                        className="text-center bg-light "
                      >
                        {offers.TOTAL_PENDING_DOFFER}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {offers.TOTAL_ACCEPTED_DOFFER}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {offers.TOTAL_REJECTED_DOFFER}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {offers.TOTAL_DOFFER -
                          (offers.TOTAL_PENDING_DOFFER +
                            offers.TOTAL_ACCEPTED_DOFFER +
                            offers.TOTAL_REJECTED_DOFFER +
                            offers.TOTAL_EMPLOYED_DOFFER)}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {offers.TOTAL_EMPLOYED_DOFFER}
                      </th>
                    </tr>
                  )}
                </tbody>
              ) : (
                <TableSkeleton cols={15} />
              )}
            </table>
          </div>
        </div>
      </div>

      {/* VIEWERS -------------------------------- */}
      {viewApplications && (
        <Dialog
          onClose={() => setViewApplications(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              <b>{viewApplications.advert.entityName}</b>
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setViewApplications(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {/* title */}
            <div className="w-100 text-center">
              <h5>{viewApplications.advert.entityName}</h5>
              <h6 className="font-weight-bold">
                {viewApplications.advert.positionName}
              </h6>
              {false && (
                <h4>
                  {viewApplications.purpose === "APPLICATION" &&
                  viewApplications.status === "shortlisted"
                    ? `List of shortlisted applicants on ${viewApplications.advert.positionName}`
                    : viewApplications.purpose === "APPLICATION" &&
                      viewApplications.status === "rejected"
                    ? `List of not shortlisted applicants on ${viewApplications.advert.positionName}`
                    : viewApplications.purpose === "APPLICATION" &&
                      viewApplications.status === "all"
                    ? `List of received applications on ${viewApplications.advert.positionName}`
                    : viewApplications.purpose === "EXAM" &&
                      viewApplications.status === "pending"
                    ? `List of applicant on ${viewApplications.advert.positionName}`
                    : viewApplications.purpose === "EXAM" &&
                      viewApplications.status === "pass"
                    ? "LIST OF SHORTLISTED APPLICANTS"
                    : viewApplications.purpose === "EXAM" &&
                      viewApplications.status === "failed"
                    ? "LIST OF SHORTLISTED APPLICANTS"
                    : viewApplications.purpose === "APPLICATION" &&
                      viewApplications.status === "shortlisted"
                    ? "LIST OF SHORTLISTED APPLICANTS"
                    : viewApplications.purpose === "APPLICATION" &&
                      viewApplications.status === "shortlisted"
                    ? "LIST OF SHORTLISTED APPLICANTS"
                    : viewApplications.purpose === "APPLICATION" &&
                      viewApplications.status === "shortlisted"
                    ? "LIST OF SHORTLISTED APPLICANTS"
                    : viewApplications.purpose === "APPLICATION" &&
                      viewApplications.status === "shortlisted"
                    ? "LIST OF SHORTLISTED APPLICANTS"
                    : viewApplications.purpose === "APPLICATION" &&
                      viewApplications.status === "shortlisted"
                    ? "LIST OF SHORTLISTED APPLICANTS"
                    : ""}
                </h4>
              )}
            </div>
            {/* pie and doughnut charts */}
            <div className="row justify-content-center w-100 my-3 px-4">
              {viewApplications.purpose === "APPLICATION" &&
                viewApplications.status === "all" && (
                  <div className="col-4 col-lg-3">
                    <DashboardDoughnutChart
                      chartTitle="Application status"
                      chartData={
                        viewApplications.applStatus
                          .APPLICATION_STATUS_DOUGHNUT_CHART
                      }
                    />
                  </div>
                )}
              <div className="col-4 col-lg-3">
                <DashboardPieChart
                  pieTitle="Applicant gender"
                  pieData={viewApplications.gender.GENDER_PIE_CHART}
                />
              </div>
              {viewApplications.purpose === "APPLICATION" && (
                <div className="col-4 col-lg-3">
                  <DashboardDoughnutChart
                    chartTitle="Examination status"
                    chartData={viewApplications.exam.EXAM_STATUS_DOUGHNUT_CHART}
                  />
                </div>
              )}
            </div>

            <div
              className="rounded pt-2 px-2  table-responsive mb-3"
              style={{ border: "1px solid rgb(198 205 209)" }}
            >
              <table className="table table-sm">
                <caption
                  style={{ backgroundColor: "#e9ecef" }}
                  className="mb-2 w-100"
                >
                  Applications Summary
                </caption>
                <thead>
                  <tr>
                    <th
                      className="text-center"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      No
                    </th>
                    <th
                      className="text-left"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Applicants
                    </th>
                    <th
                      className="text-left"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      National ID
                    </th>
                    <th
                      className="text-left"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Email
                    </th>
                    <th
                      className="text-center"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Phone number
                    </th>
                    <th
                      className="text-center"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Gender
                    </th>
                    <th
                      className="text-center"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Age
                    </th>
                    {viewApplications.status === "all" && (
                      <th
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                        }}
                      >
                        Application status
                      </th>
                    )}
                    <th
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderRight: "3px solid #dee2e6",
                      }}
                    >
                      Score
                    </th>
                    {((viewApplications.purpose === "APPLICATION" &&
                      viewApplications.status === "shortlisted") ||
                      (viewApplications.purpose === "EXAM" &&
                        viewApplications.status === "pass")) && (
                      <th
                        className="text-center"
                        style={{ borderRight: "3px solid #dee2e6" }}
                      >
                        Offer
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody style={{ fontSize: "13px" }}>
                  {viewApplications.applications.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            index + 1 ===
                              viewApplications.applications.length &&
                            "3px solid #dee2e6",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-left"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            index + 1 ===
                              viewApplications.applications.length &&
                            "3px solid #dee2e6",
                        }}
                      >
                        {item.userName}
                      </td>
                      <td
                        className={`text-left`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            index + 1 ===
                              viewApplications.applications.length &&
                            "3px solid #dee2e6",
                        }}
                      >
                        {item.idNumber}
                      </td>
                      <td
                        className={`text-left`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            index + 1 ===
                              viewApplications.applications.length &&
                            "3px solid #dee2e6",
                        }}
                      >
                        {item.email}
                      </td>
                      <td
                        className={`text-center`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            index + 1 ===
                              viewApplications.applications.length &&
                            "3px solid #dee2e6",
                        }}
                      >
                        {item.phoneNumber}
                      </td>
                      <td
                        className={`text-center`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            index + 1 ===
                              viewApplications.applications.length &&
                            "3px solid #dee2e6",
                        }}
                      >
                        {item.genderId === "M" ? "Male" : "Female"}
                      </td>
                      <td
                        className={`text-center`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            index + 1 ===
                              viewApplications.applications.length &&
                            "3px solid #dee2e6",
                        }}
                      >
                        {item.age}
                      </td>
                      {viewApplications.status === "all" && (
                        <td
                          className={`text-center`}
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderRight: "3px solid #dee2e6",
                            borderBottom:
                              index + 1 ===
                                viewApplications.applications.length &&
                              "3px solid #dee2e6",
                          }}
                        >
                          {item.statusId === 1 ? (
                            <span className="badge badge-pill badge-success">
                              Shortlisted
                            </span>
                          ) : item.statusId === 2 ? (
                            <span className="badge badge-pill badge-danger">
                              Rejected
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-secondary">
                              Pending
                            </span>
                          )}
                        </td>
                      )}
                      <td
                        className={`text-center`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                          borderBottom:
                            index + 1 ===
                              viewApplications.applications.length &&
                            "3px solid #dee2e6",
                        }}
                      >
                        {item.finalScore}
                      </td>
                      {((viewApplications.purpose === "APPLICATION" &&
                        viewApplications.status === "shortlisted") ||
                        (viewApplications.purpose === "EXAM" &&
                          viewApplications.status === "pass")) && (
                        <td
                          className={`text-center`}
                          style={{
                            borderRight: "3px solid #dee2e6",
                            borderBottom:
                              index + 1 ===
                                viewApplications.applications.length &&
                              "3px solid #dee2e6",
                          }}
                        >
                          {item.offerStatus === "Pending" ? (
                            <span className="badge badge-pill badge-secondary">
                              Pending
                            </span>
                          ) : item.offerStatus === "Accepted" ? (
                            <span className="badge badge-pill badge-success">
                              Accepted
                            </span>
                          ) : item.offerStatus === "Rejected" ? (
                            <span className="badge badge-pill badge-warning">
                              Rejected
                            </span>
                          ) : item.offerStatus === "Canceled" ? (
                            <span className="badge badge-pill badge-danger">
                              Canceled
                            </span>
                          ) : item.offerStatus === "Employed" ? (
                            <span className="badge badge-pill badge-primary">
                              Employed
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </DialogContent>

          <DialogActions></DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ loading, recruitment }) => {
  return { loading, recruitmentStatistic: recruitment.recruitmentStatistic };
};
const mapDispatchToProps = {
  loadRecruitmentDashboard,
  loadApplicationByAdvertAndStatus,
  loadApplicationByAdvertAndExam,
  loadApplicationByAdvertAndOffer,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruitmentDashboard);
