const types = {
  SET_ROLES: "SET_ROLES",
  UPDATE_ROLE: "UPDATE_ROLE",
  ADD_ROLE: "ADD_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  SET_PERMISSIONS: "SET_PERMISSIONS",
  ADD_PERMISSION: "ADD_PERMISSION",
  DELETE_PERMISSION: "DELETE_PERMISSION",
  SET_MODULES: "SET_MODULES",
  LOAD_ALL_ACTIVE_ADVERTISEMENT: "LOAD_ALL_ACTIVE_ADVERTISEMENT",
  LOAD_ADVERTISEMENT_APPLICANTS: "LOAD_ADVERTISEMENT_APPLICANTS",

  LOAD_DISABILITIES: "LOAD_DISABILITIES",
  UPDATE_DISABILITY: "UPDATE_DISABILITY",
  LOAD_DISABILITY_LEVELS: "LOAD_DISABILITY_LEVELS",
  UPDATE_DISABILITY_LEVEL: "UPDATE_DISABILITY_LEVEL",

  LOAD_CERTIFICATE_FIELD: "LOAD_CERTIFICATE_FIELD",
  UPDATE_CERTIFICATE_FIELD: "UPDATE_CERTIFICATE_FIELD",
  LOAD_CERTIFICATE: "LOAD_CERTIFICATE",
  UPDATE_CERTIFICATE: "UPDATE_CERTIFICATE",
  LOAD_COMPETENCY: "LOAD_COMPETENCY",
  UPDATE_COMPETENCY: "UPDATE_COMPETENCY",
  LOAD_DEGREES: "LOAD_DEGREES",
  UPDATE_DEGREE: "UPDATE_DEGREE",
  LOAD_QUALIFICATION: "LOAD_QUALIFICATION",
  UPDATE_QUALIFICATION: "UPDATE_QUALIFICATION",

  // STRUCTURE -------------
  SET_STRUCTURE_DASHBOARD: "SET_STRUCTURE_DASHBOARD",
  SET_STRUCTURE_DASHBOARD_POSITIONS: "SET_STRUCTURE_DASHBOARD_POSITIONS",
  SET_STRUCTURE_DASHBOARD_EMPLOYEES: "SET_STRUCTURE_DASHBOARD_EMPLOYEES",
  SET_STRUCTURE_DASHBOARD_ENTITIES: "SET_STRUCTURE_DASHBOARD_ENTITIES",

  // RECRUITMENT ----------------
  SET_ADVERTISEMENT_YEARS: "SET_ADVERTISEMENT_YEARS",
  SET_ADMIN_APPEALS: "SET_ADMIN_APPEALS",
  SET_SCHEDULED_EXAMS: "SET_SCHEDULED_EXAMS",
  SET_RECRUITMENT_DASHBOARD: "SET_RECRUITMENT_DASHBOARD",
  SET_RECRUITMENT_DASHBOARD_STATUS: "SET_RECRUITMENT_DASHBOARD_STATUS",
  // PAYROLL ----------------
  SET_ADMIN_COUNTRY: "SET_ADMIN_COUNTRY",
  // *********** ALLOWANCE
  SET_ADMIN_ALLOWANCE: "SET_ADMIN_ALLOWANCE",
  UPDATE_ADMIN_ALLOWANCE: "UPDATE_ADMIN_ALLOWANCE",
  ACTIVATE_ADMIN_ALLOWANCE: "ACTIVATE_ADMIN_ALLOWANCE",
  // *********** CREDITOR
  SET_ADMIN_CREDITOR: "SET_ADMIN_CREDITOR",
  UPDATE_ADMIN_CREDITOR: "UPDATE_ADMIN_CREDITOR",
  ACTIVATE_ADMIN_CREDITOR: "ACTIVATE_ADMIN_CREDITOR",
  // *********** BANK
  SET_ADMIN_BANK: "SET_ADMIN_BANK",
  ADD_ADMIN_BANK: "ADD_ADMIN_BANK",
  UPDATE_ADMIN_BANK: "UPDATE_ADMIN_BANK",
  ACTIVATE_ADMIN_BANK: "ACTIVATE_ADMIN_BANK",
  // *********** COST CENTER
  SET_ADMIN_COST_CENTER: "SET_ADMIN_COST_CENTER",
  ADD_ADMIN_COST_CENTER: "ADD_ADMIN_COST_CENTER",
  UPDATE_ADMIN_COST_CENTER: "UPDATE_ADMIN_COST_CENTER",
  ACTIVATE_ADMIN_COST_CENTER: "ACTIVATE_ADMIN_COST_CENTER",
  // *********** PAYROLL APPROVER
  SET_ADMIN_PAYROLL_APPROVER: "SET_ADMIN_PAYROLL_APPROVER",
  ADD_ADMIN_PAYROLL_APPROVER: "ADD_ADMIN_PAYROLL_APPROVER",
  UPDATE_ADMIN_PAYROLL_APPROVER: "UPDATE_ADMIN_PAYROLL_APPROVER",
  REMOVE_ADMIN_PAYROLL_APPROVER: "REMOVE_ADMIN_PAYROLL_APPROVER",
  SET_PAYROLL_OVERSIGHT: "SET_PAYROLL_OVERSIGHT",
  SET_PAYROLL_PERIODS: "SET_PAYROLL_PERIODS",

  // *********** SOURCE OF FUNDS
  SET_ADMIN_SOFUNDS: "SET_ADMIN_SOFUNDS",
  ADD_ADMIN_PAYROLL_SOFUNDS: "ADD_ADMIN_PAYROLL_SOFUNDS",
  UPDATE_ADMIN_SOFUNDS: "UPDATE_ADMIN_SOFUNDS",
  ACTIVATE_ADMIN_SOFUNDS: "ACTIVATE_ADMIN_SOFUNDS",
  // *********** EXIT TYPE
  SET_ADMIN_EXIT_TYPE: "SET_ADMIN_EXIT_TYPE",
  ADD_ADMIN_EXIT_TYPE: "ADD_ADMIN_EXIT_TYPE",
  UPDATE_EXIT_TYPE: "UPDATE_EXIT_TYPE",
  SET_ADMIN_EXIT_DASHBOARD: "SET_ADMIN_EXIT_DASHBOARD",
  SET_ADMIN_EXIT_YEARS_MONTHS: "SET_ADMIN_EXIT_YEARS_MONTHS",
};

export default types;
