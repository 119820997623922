import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  Typography,
  Skeleton,
  InputAdornment,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateRequisition } from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";

const UpdateRequisitionDialog = (props) => {
  const {
    loading,
    showEditRequisitionForm,
    setShowEditRequisitionForm,
    selectedRequisition,
    setSelectedRequisition,
    updateRequisition,
  } = props;

  const [requisitionForm, setRequisitionForm] = useState({
    id: selectedRequisition.id,
    positionId: selectedRequisition.positionId,
    numberOfPosts: selectedRequisition.numberOfPosts,
    isInternal: selectedRequisition.isInternal,
    reason: selectedRequisition.reason,
    willHaveWrittenExam: selectedRequisition.willHaveWrittenExam,
    willHaveOralExam: selectedRequisition.willHaveOralExam,
    statusComments: selectedRequisition.statusComments,
    statusId: selectedRequisition.statusId,
  });
  const [errors, setErrors] = useState({
    positionHasError: false,
    reasonHasError: false,
    statusHasError: false,
    statusCommentsHasError: false,
  });

  const formValidator = () => {
    const error = {
      positionHasError: false,
      reasonHasError: false,
      statusHasError: false,
      statusCommentsHasError: false,
      hasError: false,
    };

    if (!requisitionForm.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      updateRequisition(
        requisitionForm,
        setRequisitionForm,
        setShowEditRequisitionForm,
        setSelectedRequisition
      );
    }
  };

  const onClose = () => {
    setShowEditRequisitionForm(false);
    setSelectedRequisition(null);

    setRequisitionForm({
      positionId: "",
      numberOfPosts: "",
      isInternal: false,
      reason: "",
      willHaveWrittenExam: false,
      willHaveOralExam: false,
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEditRequisitionForm}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Update Requisition @{selectedRequisition.positionName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-12">
                <div className="input-group input-group-sm mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Is Internal?
                    </span>
                  </div>
                  <div className="form-control">
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="no"
                        name="isInternal"
                        value={false}
                        checked={requisitionForm.isInternal === false}
                        onChange={(event) => {
                          const isInternal = false;
                          setRequisitionForm({
                            ...requisitionForm,
                            isInternal,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="yes"
                        name="isInternal"
                        value={true}
                        checked={requisitionForm.isInternal === true}
                        onChange={(event) => {
                          const isInternal = true;
                          setRequisitionForm({
                            ...requisitionForm,
                            isInternal,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="input-group input-group-sm mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Exam
                    </span>
                  </div>
                  <div className="form-control">
                    <div
                      className="form-check form-check-inline"
                      onClick={(event) => {
                        const willHaveWrittenExam =
                          !requisitionForm.willHaveWrittenExam;
                        setRequisitionForm({
                          ...requisitionForm,
                          willHaveWrittenExam,
                        });
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="willHaveWrittenExam"
                        name="willHaveWrittenExam"
                        checked={requisitionForm.willHaveWrittenExam}
                      />
                      <label className="form-check-label">Written</label>
                    </div>

                    <div
                      className="form-check form-check-inline"
                      onClick={(event) => {
                        const willHaveOralExam =
                          !requisitionForm.willHaveOralExam;
                        setRequisitionForm({
                          ...requisitionForm,
                          willHaveOralExam,
                        });
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="willHaveOralExam"
                        name="willHaveOralExam"
                        value={true}
                        checked={requisitionForm.willHaveOralExam}
                      />
                      <label className="form-check-label">Oral</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3">
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="reason"
                  autoFocus
                  label="Type reason here .."
                  variant="outlined"
                  placeholder="Type reason here .."
                  value={requisitionForm.reason}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const reason = e.target.value;
                    setRequisitionForm({ ...requisitionForm, reason });
                    const reasonHasError = false;
                    setErrors({ ...errors, reasonHasError });
                  }}
                />

                {errors.reasonHasError && (
                  <div className="text-danger mt-2">Reason is required </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Update"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  updateRequisition,
})(UpdateRequisitionDialog);
