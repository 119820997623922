import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  Autocomplete,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import GroupIcon from "@mui/icons-material/Group";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import moment from "moment";
import {
  getAdvertisements,
  cancelAdvertisement,
  getRequisition,
} from "../../../store/recruitment/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ApplicationDialog from "./ApplicationDialog";

// import ExamSettingDialog from "./ExamSettingDialog";
import AssessmentIcon from "@mui/icons-material/Iso";
import RecruitmentMember from "../components/RecruitmentMember";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import axios from "axios";
import RequisitionDetailsDialog from "../components/RequisitionDetailsDialog";
const ITEM_HEIGHT = 60;

const Advertisement = (props) => {
  const {
    user,
    loading,
    advertisements,
    getAdvertisements,
    cancelAdvertisement,
    getRequisition,
  } = props;
  const theme = useTheme();
  const defaultIds = "";
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [filterStatus, setFilterStatus] = useState({
    id: "",
    name: "All",
  });

  useEffect(() => {
    getActiveAdvertisementStatuses();

    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };

    getAdvertisements(query, setTotalRequests, setIsLoading);
  }, []);
  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getAdvertisements(query, setTotalRequests, setIsLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      currentStatus: filterStatus?.id || defaultIds,
      page: currentPage,
      size: itemPerPage,
    };
    getAdvertisements(query, setTotalRequests, setIsLoading1);
  };

  const getNo = (idToSearch) => {
    return advertisements.findIndex(({ id }) => id === idToSearch);
  };

  const [advertisementStatuses, setAdvertisementStatuses] = useState([
    {
      id: "",
      name: "All",
    },
  ]);

  const getActiveAdvertisementStatuses = async () => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/active-advert-statuses"
      );

      setAdvertisementStatuses([
        {
          id: "",
          name: "All",
        },
        ...data,
      ]);
    } catch (error) {}
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-6">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  className="text-uppercase d-inline-flex align-items-center"
                >
                  <span>RECRUITMENT</span>
                  <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                    <span
                      className="material-icons  text-dark"
                      style={{ position: "relative", top: "4px" }}
                    >
                      chevron_right
                    </span>
                  </span>

                  <small style={{ color: "#007bff" }}>
                    {filterStatus && filterStatus.name} Advertisements (
                    {totalRequests})
                  </small>
                </Typography>
              </div>

              <div className="col-lg-6">
                <div className="d-flex justify-content-right  align-items-right">
                  <Autocomplete
                    sx={{ width: 500 }}
                    className="mr-2"
                    size="small"
                    id="filterStatus"
                    defaultValue={null}
                    value={filterStatus || null}
                    options={advertisementStatuses}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, currentStatus) => {
                      const query = {
                        searchTerm: "",
                        currentStatus: currentStatus?.id || defaultIds,
                        page: currentPage,
                        size: itemPerPage,
                      };
                      setFilterStatus(currentStatus);
                      getAdvertisements(query, setTotalRequests, setIsLoading1);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, filterStatus) => (
                      <Box component="li" {...props} key={filterStatus.id}>
                        {filterStatus.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{
                          width: "100%",
                          height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                        }}
                        {...params}
                        label="Filter By"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "filterStatus", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  <SearchBox
                    onSearch={(search) => {
                      setSearchTerm(search);

                      if (!search) {
                        const currentPage = 1;
                        setCurrentPage(currentPage);
                        const query = {
                          searchTerm: "",
                          page: currentPage,
                          currentStatus: filterStatus?.id || defaultIds,
                          size: itemPerPage,
                        };
                        getAdvertisements(
                          query,
                          setTotalRequests,
                          setIsLoading1
                        );
                      }
                    }}
                    placeholder="Search…"
                  />
                  <Button
                    disabled={!searchTerm}
                    onClick={() => onSearch()}
                    variant="contained"
                    size="small"
                    disableElevation
                    className="mr-2 "
                    style={{
                      borderRadius: "8px",
                      height: "38px",
                      border: `1px solid #007bff`,
                      color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      backgroundColor: "#007bff",
                      marginLeft: "5px",
                      "&:hover": {
                        backgroundColor: "#f0f2f5",
                      },
                    }}
                  >
                    <span className="material-icons">search</span>GO
                  </Button>
                </div>
              </div>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col" className="text-left">
                      AdvertId
                    </th>
                    <th scope="col" className="text-left">
                      PostionTitle
                    </th>
                    <th scope="col" className="text-left">
                      Unit
                    </th>

                    <th scope="col" className="text-left">
                      EmploymentGroup
                    </th>
                    <th scope="col" className="text-center">
                      RecruitmentMode
                    </th>
                    <th scope="col" className="text-left">
                      Exams
                    </th>
                    <th scope="col" className="text-right">
                      Level
                    </th>
                    <th scope="col" className="text-center">
                      IV
                    </th>

                    <th scope="col" className="text-center">
                      NumberOfPosts
                    </th>

                    <th scope="col" className="text-left">
                      PublicationDate
                    </th>
                    <th scope="col" className="text-left">
                      Deadline
                    </th>

                    <th scope="col" className="text-right">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <TableSkeleton cols={14} />
                ) : (
                  <tbody>
                    <BackdropLoader isLoading={isLoading1} />
                    {advertisements.map((advertisement, index) => (
                      <AdvertisementCard
                        advertisement={advertisement}
                        index={getNo(advertisement.id)}
                        key={advertisement.id}
                        cancelAdvertisement={cancelAdvertisement}
                        getRequisition={getRequisition}
                      />
                    ))}
                  </tbody>
                )}
              </table>
              <NoRecordMessage
                isEmpty={!advertisements.length && !isLoading}
                title={"No record found"}
              />
              {!isLoading && (
                <div
                  className="d-flex justify-content-center  align-items-center"
                  style={{ overflow: "hidden" }}
                >
                  <div className="mt-3">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      // marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(totalRequests / itemPerPage)}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                      activeLinkClassName="active"
                    />
                  </div>

                  {!!advertisements.length && (
                    <select
                      className="custom-select custom-select-sm ml-2"
                      style={{ width: "100px" }}
                      value={itemPerPage}
                      onChange={(event) => {
                        const itemPerPage = +event.target.value;
                        window.localStorage.setItem(
                          "ITEM_PER_PAGE",
                          itemPerPage
                        );
                        setItemPerPage(itemPerPage);
                        const query = {
                          searchTerm: searchTerm,
                          page: currentPage,
                          size: itemPerPage,
                          currentStatus: filterStatus?.id || defaultIds,
                        };
                        getAdvertisements(
                          query,
                          setTotalRequests,
                          setIsLoading
                        );
                      }}
                    >
                      {[10, 50, 100].map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>
                  )}
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, advertisements }) => {
  return { user, loading, advertisements };
};
export default connect(mapStateToProps, {
  getAdvertisements,
  cancelAdvertisement,
  getRequisition,
})(Advertisement);

const AdvertisementCard = (props) => {
  const {
    advertisement,
    index,
    cancelAdvertisement,
    // setConfirmRemoveAdvertisement,
    // setShowApplication,
    // setShowExamSettings,
    // setShowRecruitmentMembers,
    getRequisition,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermissions } = useRequiredAuth();
  const [filterStatus, setFilterStatus] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [showRequisitionDetails, setShowRequisitionDetails] = useState(false);
  const [selectedRequisition, setSelectedRequisition] = useState(null);

  const [confirmRemoveAdvertisement, setConfirmRemoveAdvertisement] =
    useState(false);
  const [showApplication, setShowApplication] = useState(false);
  const [showRecruitmentMembers, setShowRecruitmentMembers] = useState(false);

  const [showExamSettings, setShowExamSettings] = useState(false);
  const [reason, setReason] = useState("");

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <td className="text-left"> {advertisement.id}</td>
        <td className="text-left"> {advertisement.positionName}</td>
        <td className="text-left"> {advertisement.unitName}</td>

        <td className="text-left">{advertisement.employeeGroupName}</td>

        <td className="text-center">
          {advertisement.isInternal
            ? "Internal recruitment"
            : "Open competition"}
        </td>
        <td className="mx-auto">
          {advertisement.willHavePsychometricTest && (
            <>
              <span>1:PsychometricTest</span>
              <br />
            </>
          )}

          {advertisement.willHaveWrittenExam && (
            <>
              <span>
                {advertisement.willHavePsychometricTest ? "2" : "1"}:Written
              </span>
              <br />
            </>
          )}

          {advertisement.willHaveOralExam && (
            <span>
              {advertisement.willHavePsychometricTest &&
              advertisement.willHaveWrittenExam
                ? "3"
                : advertisement.willHaveWrittenExam
                ? "2"
                : "1"}
              :Oral
            </span>
          )}
        </td>
        <td className="text-right">
          {advertisement.levelName}.{advertisement.scaleName}
        </td>

        <td className="text-right">{advertisement.indexValue}</td>

        <td className="text-center">{advertisement.numberOfPosts}</td>

        <td className="text-center">
          {moment(advertisement.openingDate).format("DD/MM/YYYY")}
        </td>
        <td className="text-center">
          {moment(advertisement.closingDate).format("DD/MM/YYYY")}
        </td>

        <td className="text-right">{advertisement.adStatusName}</td>

        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                maxHeight: ITEM_HEIGHT * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  getRequisition(
                    advertisement?.requisitionId,
                    setShowRequisitionDetails,
                    setSelectedRequisition
                  );
                }}
                className="text-dark font-weight-light"
              >
                <WorkOutlineIcon className="mr-2 text-primary" /> Job Profile
              </MenuItem>
            </span>

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowApplication(true);
                }}
                className="text-dark font-weight-light"
              >
                <GroupIcon className="mr-2 text-primary" /> Applications
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>

      {showRequisitionDetails && (
        <RequisitionDetailsDialog
          showRequisitionDetails={showRequisitionDetails}
          setShowRequisitionDetails={setShowRequisitionDetails}
          selectedRequisition={selectedRequisition}
        />
      )}
      {showApplication && (
        <ApplicationDialog
          showApplication={showApplication}
          setShowApplication={setShowApplication}
          selectedAdvertisement={advertisement}
        />
      )}
      {showRecruitmentMembers && (
        <RecruitmentMember
          showRecruitmentMembers={showRecruitmentMembers}
          setShowRecruitmentMembers={setShowRecruitmentMembers}
          selectedAdvertisement={advertisement}
        />
      )}

      {/* {showExamSettings && (
        <ExamSettingDialog
          showExamSettings={showExamSettings}
          setShowExamSettings={setShowExamSettings}
          selectedAdvertisement={advertisement}
        />
      )} */}

      {confirmRemoveAdvertisement && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={confirmRemoveAdvertisement}
          message={
            <>
              <p>Are you sure you want to cancel this advertisement?</p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                autoFocus
                label="Reason for cancelation"
                variant="outlined"
                placeholder="Reason for cancelation"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setConfirmRemoveAdvertisement}
          noPreFormat={true}
          onYes={() => {
            cancelAdvertisement(
              { advertisementId: advertisement.id, reason },
              setConfirmRemoveAdvertisement
            );
          }}
        />
      )}
    </>
  );
};
