import axios from "axios";
import { useEffect, useState } from "react";

import { currencyFormat } from "../../common/components/Utils";

import { Line, Doughnut, Pie, Bar } from "react-chartjs-2";
import { connect } from "react-redux";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Filler,
  Legend,
} from "chart.js";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import QuestionTypes from "../components/QuestionTypes";

import {
  getDashboardChartsData,
  downloadDashboardData,
} from "../../../store/psychometric/actions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Filler,
  Legend
);

// Reusable Functions
export const commaFy = (num) => {
  var str = num.toString().split(".");
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  if (str[1] && str[1].length >= 4) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }
  return str.join(".");
};

const Dashboard = (props) => {
  const {
    questionTypes,
    dashboardChartsData,
    getDashboardChartsData,

    downloadDashboardData,
  } = props;

  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState("");

  const [period, setPeriod] = useState("D");

  const getDashboard = async () => {
    try {
      const { data } = await axios.get("/api/psy/getdashboarddata");
      if (data) {
        setDashboardData(data);
        setError("");
      }
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };
  const getTotals = () => {
    var strategicQuestions = 0;
    var operationalQuestions = 0;
    var managerialQuestions = 0;
    if (dashboardData !== null) {
      for (const item of dashboardData.domains) {
        for (const value of item.subDomains) {
          strategicQuestions += parseFloat(value.strategicQuestions);
          managerialQuestions += parseFloat(value.managerialQuestions);
          operationalQuestions += parseFloat(value.operationalQuestions);
        }
      }
    }
    return {
      strategicQuestions,
      managerialQuestions,
      operationalQuestions,
    };
  };
  useEffect(() => {
    getDashboardChartsData(period);

    if (dashboardData === null) {
      getDashboard();
    }
  }, [period]);

  const strategicLaguages = {
    labels: [
      `Eng (${currencyFormat(10916)})`,
      `Fr (${currencyFormat(10916)})`,
      `Kiny (${currencyFormat(10916)})`,
    ],
    datasets: [
      {
        data: [10916, 10916, 10916],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(75, 0, 130, 0.5)",
          "rgba(238, 130, 238, 0.8)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 0, 130, 1)",
          "rgba(238, 130, 238, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const managerialLaguages = {
    labels: [
      `Eng (${currencyFormat(10916)})`,
      `Fr (${currencyFormat(10916)})`,
      `Kiny (${currencyFormat(10916)})`,
    ],
    datasets: [
      {
        data: [10916, 10916, 10916],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(75, 0, 130, 0.5)",
          "rgba(238, 130, 238, 0.8)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 0, 130, 1)",
          "rgba(238, 130, 238, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const operationalLaguages = {
    labels: [
      `Eng (${currencyFormat(10918)})`,
      `Fr (${currencyFormat(10918)})`,
      `Kiny (${currencyFormat(10918)})`,
    ],
    datasets: [
      {
        data: [10918, 10918, 10918],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(75, 0, 130, 0.5)",
          "rgba(238, 130, 238, 0.8)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 0, 130, 1)",
          "rgba(238, 130, 238, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const strategicViews = {
    labels: [
      `Not viewed (${currencyFormat(10916)})`,
      `Viewed (${currencyFormat(10916)})`,
    ],
    datasets: [
      {
        data: [10916, 10916],
        backgroundColor: ["rgba(16,193,30, 0.5)", "rgba(255, 206, 86, 0.8)"],
        borderColor: ["rgba(16,193,30, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const managerialViews = {
    labels: [
      `Not viewed (${currencyFormat(10916)})`,
      `Viewed (${currencyFormat(10916)})`,
    ],
    datasets: [
      {
        data: [10916, 10916],
        backgroundColor: ["rgba(16,193,30, 0.5)", "rgba(255, 206, 86, 0.8)"],
        borderColor: ["rgba(16,193,30, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const operationalViews = {
    labels: [
      `Not viewed (${currencyFormat(10916)})`,
      `Viewed (${currencyFormat(10916)})`,
    ],
    datasets: [
      {
        data: [10916, 10916],
        backgroundColor: ["rgba(16,193,30, 0.5)", "rgba(255, 206, 86, 0.8)"],
        borderColor: ["rgba(16,193,30, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const pieData2 = {
    labels: [
      `A2 (${currencyFormat(10000)})`,
      `A1 (${currencyFormat(10000)})`,
      `A0 (${currencyFormat(10000)})`,
      // `Other (${currencyFormat(budgetPostsSummary[0].other)})`,
    ],
    datasets: [
      {
        data: [
          10000, 10000, 10000,
          // budgetPostsSummary[0].vacOther,
        ],
        backgroundColor: [
          "rgba(238, 130, 238, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(16,193,30, 0.5)",
          // "rgba(255,0,0, 0.5)",
        ],
        borderColor: [
          "rgba(238, 130, 238, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(16,193,30, 1)",
          // "rgba(255,0,0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const pieData3 = {
    labels: [
      `A2 (${currencyFormat(10000)})`,
      `A1 (${currencyFormat(10000)})`,
      `A0 (${currencyFormat(10000)})`,
    ],
    datasets: [
      {
        data: [10000, 10000, 10000],
        backgroundColor: [
          "rgba(238, 130, 238, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(16,193,30, 0.5)",
        ],
        borderColor: [
          "rgba(238, 130, 238, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(16,193,30, 1)",
          // "rgba(255,0,0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const pieData4 = {
    labels: [
      `Male (${currencyFormat(10000)})`,
      `Female (${currencyFormat(10000)})`,
      `Other (${currencyFormat(10000)})`,
    ],
    datasets: [
      {
        data: [100000, 100000, 100000],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(75, 0, 130, 0.5)",
          "rgba(238, 130, 238, 0.8)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 0, 130, 1)",
          "rgba(238, 130, 238, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barChartData1 = {
    labels: [
      // "Level: A",
      // "Level: B",
      // "Level: C",
      // "Level: D",
      // "Level: E",
      "Level: F",
      "Level: G/1",
      "Level: H/2",
      "Level: 3",
      "Level: 4",
      "Level: 5",
      "Level: 6",
      "Level: 7",
      "Level: 8",
      "Level: 9",
      "Level: 10",
      "Level: 11",
      "Level: 12",
      "Level: 13",
      "Level: 14",
      "Level: 0",
    ],
    datasets: [
      {
        fill: "start",
        label: "Exams",
        data: [67, 34, 56, 2, 98, 31, 15, 44, 76, 22, 58, 39, 83, 17, 64, 5],
        borderColor: "rgba(7, 142, 206, 1)",
        backgroundColor: "rgba(7, 142, 206, 1)",
      },
    ],
  };

  const barChartData2 = {
    labels: [
      // "Level: A",
      // "Level: B",
      // "Level: C",
      // "Level: D",
      // "Level: E",
      "Level: F",
      "Level: G/1",
      "Level: H/2",
      "Level: 3",
      "Level: 4",
      "Level: 5",
      "Level: 6",
      "Level: 7",
      "Level: 8",
      "Level: 9",
      "Level: 10",
      "Level: 11",
      "Level: 12",
      "Level: 13",
      "Level: 14",
      "Level: 0",
    ],
    datasets: [
      {
        // fill: "start",
        label: "Passed",
        data: [67, 34, 56, 2, 98, 31, 15, 44, 76, 22, 58, 39, 83, 17, 64, 5],
        borderColor: "rgba(16,193,30, 0.5)",
        backgroundColor: "rgba(16,193,30, 0.5)",
      },
      {
        // fill: "start",
        label: "Failed",
        data: [8, 32, 41, 14, 27, 46, 11, 9, 24, 30, 18, 12, 48, 20, 35, 13],
        borderColor: "rgba(255, 87, 34, 1)",
        backgroundColor: "rgba(255, 87, 34, 1)",
      },
    ],
  };

  const lineChartData1 = {
    labels: [
      "Level: F",
      "Level: G/1",
      "Level: H/2",
      "Level: 3",
      "Level: 4",
      "Level: 5",
      "Level: 6",
      "Level: 7",
      "Level: 8",
      "Level: 9",
      "Level: 10",
      "Level: 11",
      "Level: 12",
      "Level: 13",
      "Level: 14",
      "Level: 0",
    ],
    datasets: [
      {
        fill: "start",
        label: "All applications",
        data: [67, 34, 56, 2, 98, 31, 15, 44, 76, 22, 58, 39, 83, 17, 64, 5],
        borderColor: "rgba(75, 0, 130, 0.5)",
        backgroundColor: "rgba(75, 0, 130, 0.5)",
      },
      {
        fill: "start",
        label: "Passed PSY",
        data: [67, 34, 56, 2, 98, 31, 15, 44, 76, 22, 58, 39, 83, 17, 64, 5],
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 1)",
      },
      {
        label: "Failed PSY",
        data: [67, 34, 56, 2, 98, 31, 15, 44, 76, 22, 58, 39, 83, 17, 64, 5],
        borderColor: "rgba(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86, 1)",
      },
      {
        label: "Rejected By HR",
        data: [67, 34, 56, 2, 98, 31, 15, 44, 76, 22, 58, 39, 83, 17, 64, 5],
        borderColor: "rgba(255, 86, 86, 1)",
        backgroundColor: "rgba(255, 86, 86, 1)",
      },
      {
        label: "Shortlisted",
        data: [67, 34, 56, 2, 98, 31, 15, 44, 76, 22, 58, 39, 83, 17, 64, 5],
        borderColor: "rgba(16,193,30, 1)",
        backgroundColor: "rgba(16,193,30, 1)",
      },
    ],
  };

  const lineChartDataDay = {
    labels: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    datasets: [
      {
        fill: "start",
        label: "Candidates",
        data: [
          56, 93, 14, 27, 81, 42, 68, 39, 75, 53, 22, 8, 49, 17, 66, 85, 31, 12,
          74, 90, 63, 20, 38, 48,
        ],
        borderColor: "rgba(255, 86, 86, 1)",
        backgroundColor: "rgba(255, 86, 86, 0.2)",
      },
    ],
  };

  const lineChartDataWeek = {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    datasets: [
      {
        fill: "start",
        label: "Candidates",
        data: [56, 93, 14, 27, 81, 42, 68],
        borderColor: "rgba(255, 86, 86, 1)",
        backgroundColor: "rgba(255, 86, 86, 0.2)",
      },
    ],
  };

  const lineChartDataMonth = {
    labels: [
      "01 Aug",
      "02 Aug",
      "03 Aug",
      "04 Aug",
      "05 Aug",
      "06 Aug",
      "07 Aug",
      "08 Aug",
      "09 Aug",
      "10 Aug",
      "11 Aug",
      "12 Aug",
      "13 Aug",
      "14 Aug",
      "15 Aug",
      "16 Aug",
      "17 Aug",
      "18 Aug",
      "19 Aug",
      "20 Aug",
      "21 Aug",
      "22 Aug",
      "23 Aug",
      "24 Aug",
      "25 Aug",
      "26 Aug",
      "27 Aug",
      "28 Aug",
      "29 Aug",
      "30 Aug",
      "31 Aug",
    ],
    datasets: [
      {
        fill: "start",
        label: "Candidates",
        data: [
          74, 22, 55, 18, 91, 63, 41, 89, 12, 37, 80, 27, 44, 68, 93, 52, 16,
          71, 48, 29, 84, 11, 66, 58, 23, 87, 42, 19, 31, 75, 60,
        ],
        borderColor: "rgba(255, 86, 86, 1)",
        backgroundColor: "rgba(255, 86, 86, 0.2)",
      },
    ],
  };

  const lineChartDataYear = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        fill: "start",
        label: "Candidates",
        data: [57, 24, 86, 33, 48, 91, 12, 64, 79, 55, 29, 73],
        borderColor: "rgba(255, 86, 86, 1)",
        backgroundColor: "rgba(255, 86, 86, 0.2)",
      },
    ],
  };

  const barChartData3 = {
    labels: [
      "Level: F",
      "Level: G/1",
      "Level: H/2",
      "Level: 3",
      "Level: 4",
      "Level: 5",
      "Level: 6",
      "Level: 7",
      "Level: 8",
      "Level: 9",
      "Level: 10",
      "Level: 11",
      "Level: 12",
      "Level: 13",
      "Level: 14",
      "Level: 0",
    ],
    datasets: [
      {
        fill: "start",
        label: "English",
        data: [67, 34, 56, 2, 98, 31, 15, 44, 76, 22, 58, 39, 83, 17, 64, 5],

        borderColor: "rgba(16,193,30, 0.5)",
        backgroundColor: "rgba(16,193,30, 0.5)",
      },
      {
        fill: "start",
        label: "French",
        data: [1, 6, 37, 14, 95, 84, 76, 22, 44, 41, 65, 66, 65, 52, 15, 49],
        borderColor: "rgba(7, 142, 206, 1)",
        backgroundColor: "rgba(7, 142, 206, 1)",
      },
      {
        fill: "start",
        label: "Kinyarwanda",
        data: [27, 96, 96, 40, 59, 77, 34, 87, 96, 71, 55, 24, 26, 88, 23, 13],

        borderColor: "rgba(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86, 1)",
      },
    ],
  };

  return (
    <>
      <div className="d-flex flex-column mb-3">
        <div className="d-flex  flex-wrap justify-content-center">
          <div
            style={{ color: "#f8ff90", backgroundColor: "#3b02b5" }}
            className="min-w-80px py-3 px-5 rounded mx-2 mb-1 mt-1"
          >
            <div className="fw-semibold font-weight-bold  text-center">
              All questions
            </div>
            <div
              style={{ color: "#9cff71" }}
              className="fs-6 font-weight-bolder  text-center"
            >
              {currencyFormat(dashboardChartsData.allQuestions || 0)}
            </div>
          </div>

          <div
            style={{ color: "#fffffc" }}
            className="bg-primary   min-w-80px py-3 px-5 rounded mx-2 mb-1 mt-1"
          >
            <div className="fw-semibold font-weight-bold  text-center">
              Strategic
            </div>
            <div
              style={{ color: "#9cff71" }}
              className="fs-6 font-weight-bolder  text-center"
            >
              {currencyFormat(dashboardChartsData.strategicQuestions || 0)}
            </div>
          </div>
          <div
            style={{ color: "#fffffc" }}
            className="bg-primary   min-w-80px py-3 px-5 rounded mx-2 mb-1 mt-1"
          >
            <div className="fw-semibold font-weight-bold  text-center">
              Managerial
            </div>
            <div
              style={{ color: "#9cff71" }}
              className="fs-6 font-weight-bolder  text-center"
            >
              {currencyFormat(dashboardChartsData.managerialQuestions || 0)}
            </div>
          </div>
          <div
            style={{ color: "#fffffc" }}
            className="bg-primary   min-w-80px py-3 px-5 rounded mx-2 mb-1 mt-1"
          >
            <div className="fw-semibold font-weight-bold  text-center">
              Operational
            </div>
            <div
              style={{ color: "#9cff71" }}
              className="fs-6 font-weight-bolder  text-center"
            >
              {currencyFormat(dashboardChartsData.operationalQuestions || 0)}
            </div>
          </div>

          <div
            style={{ color: "#fffffc" }}
            className="bg-success  min-w-80px py-3 px-5 rounded mx-2 mb-1 mt-1"
          >
            <div className="fw-semibold font-weight-bold  text-center">
              Not viewed
            </div>
            <div
              style={{ color: "#9cff71" }}
              className="fs-6 font-weight-bolder  text-center"
            >
              {currencyFormat(dashboardChartsData.allNotViewed || 0)}
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-around">
        <div className="col-2">
          <div className="border rounded dashboard-card">
            <Pie
              redraw={true}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Strategic questions",
                  },
                },
              }}
              data={{
                labels: [
                  `Eng (${currencyFormat(
                    dashboardChartsData?.languages?.strategic?.en || 0
                  )})`,
                  `Fr (${currencyFormat(
                    dashboardChartsData?.languages?.strategic?.fr || 0
                  )})`,
                  `Kiny (${currencyFormat(
                    dashboardChartsData?.languages?.strategic?.kn || 0
                  )})`,
                ],
                datasets: [
                  {
                    data: [
                      dashboardChartsData?.languages?.strategic?.en || 0,
                      dashboardChartsData?.languages?.strategic?.fr || 0,
                      dashboardChartsData?.languages?.strategic?.kn || 0,
                    ],
                    backgroundColor: [
                      "rgba(54, 162, 235, 0.5)",
                      "rgba(75, 0, 130, 0.5)",
                      "rgba(238, 130, 238, 0.8)",
                    ],
                    borderColor: [
                      "rgba(54, 162, 235, 1)",
                      "rgba(75, 0, 130, 1)",
                      "rgba(238, 130, 238, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>

        <div className="col-2">
          <div className="border rounded dashboard-card">
            <Pie
              redraw={true}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Managerial questions",
                  },
                },
              }}
              data={{
                labels: [
                  `Eng (${currencyFormat(
                    dashboardChartsData?.languages?.managerial?.en || 0
                  )})`,
                  `Fr (${currencyFormat(
                    dashboardChartsData?.languages?.managerial?.fr || 0
                  )})`,
                  `Kiny (${currencyFormat(
                    dashboardChartsData?.languages?.managerial?.kn || 0
                  )})`,
                ],
                datasets: [
                  {
                    data: [
                      dashboardChartsData?.languages?.managerial?.en || 0,
                      dashboardChartsData?.languages?.managerial?.fr || 0,
                      dashboardChartsData?.languages?.managerial?.kn || 0,
                    ],
                    backgroundColor: [
                      "rgba(54, 162, 235, 0.5)",
                      "rgba(75, 0, 130, 0.5)",
                      "rgba(238, 130, 238, 0.8)",
                    ],
                    borderColor: [
                      "rgba(54, 162, 235, 1)",
                      "rgba(75, 0, 130, 1)",
                      "rgba(238, 130, 238, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>

        <div className="col-2">
          <div className="border rounded dashboard-card">
            <Pie
              redraw={true}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Operational questions",
                  },
                },
              }}
              data={{
                labels: [
                  `Eng (${currencyFormat(
                    dashboardChartsData?.languages?.operational?.en || 0
                  )})`,
                  `Fr (${currencyFormat(
                    dashboardChartsData?.languages?.operational?.fr || 0
                  )})`,
                  `Kiny (${currencyFormat(
                    dashboardChartsData?.languages?.operational?.kn || 0
                  )})`,
                ],
                datasets: [
                  {
                    data: [
                      dashboardChartsData?.languages?.operational?.en || 0,
                      dashboardChartsData?.languages?.operational?.fr || 0,
                      dashboardChartsData?.languages?.operational?.kn || 0,
                    ],
                    backgroundColor: [
                      "rgba(54, 162, 235, 0.5)",
                      "rgba(75, 0, 130, 0.5)",
                      "rgba(238, 130, 238, 0.8)",
                    ],
                    borderColor: [
                      "rgba(54, 162, 235, 1)",
                      "rgba(75, 0, 130, 1)",
                      "rgba(238, 130, 238, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>

        <div className="col-2">
          <div className="border rounded dashboard-card">
            <Doughnut
              redraw={true}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Strategic views",
                  },
                },
              }}
              data={{
                labels: [
                  `Not viewed (${currencyFormat(
                    dashboardChartsData?.views?.strategic?.notViewed || 0
                  )})`,
                  `Viewed (${currencyFormat(
                    dashboardChartsData?.views?.strategic?.viewed || 0
                  )})`,
                ],
                datasets: [
                  {
                    data: [
                      dashboardChartsData?.views?.strategic?.notViewed || 0,
                      dashboardChartsData?.views?.strategic?.viewed || 0,
                    ],
                    backgroundColor: [
                      "rgba(16,193,30, 0.5)",
                      "rgba(255, 206, 86, 0.8)",
                    ],
                    borderColor: [
                      "rgba(16,193,30, 1)",
                      "rgba(255, 206, 86, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>

        <div className="col-2">
          <div className="border rounded dashboard-card">
            <Doughnut
              redraw={true}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Managerial views",
                  },
                },
              }}
              data={{
                labels: [
                  `Not viewed (${currencyFormat(
                    dashboardChartsData?.views?.managerial?.notViewed || 0
                  )})`,
                  `Viewed (${currencyFormat(
                    dashboardChartsData?.views?.managerial?.viewed || 0
                  )})`,
                ],
                datasets: [
                  {
                    data: [
                      dashboardChartsData?.views?.managerial?.notViewed || 0,
                      dashboardChartsData?.views?.managerial?.viewed || 0,
                    ],
                    backgroundColor: [
                      "rgba(16,193,30, 0.5)",
                      "rgba(255, 206, 86, 0.8)",
                    ],
                    borderColor: [
                      "rgba(16,193,30, 1)",
                      "rgba(255, 206, 86, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>

        <div className="col-2">
          <div className="border rounded dashboard-card">
            <Doughnut
              redraw={true}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Operational views",
                  },
                },
              }}
              data={{
                labels: [
                  `Not viewed (${currencyFormat(
                    dashboardChartsData?.views?.operational?.notViewed || 0
                  )})`,
                  `Viewed (${currencyFormat(
                    dashboardChartsData?.views?.operational?.viewed || 0
                  )})`,
                ],
                datasets: [
                  {
                    data: [
                      dashboardChartsData?.views?.operational?.notViewed || 0,
                      dashboardChartsData?.views?.operational?.viewed || 0,
                    ],
                    backgroundColor: [
                      "rgba(16,193,30, 0.5)",
                      "rgba(255, 206, 86, 0.8)",
                    ],
                    borderColor: [
                      "rgba(16,193,30, 1)",
                      "rgba(255, 206, 86, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mt-4 justify-content-center tmis-shadow rounded bg-light py-3">
        <div className="col-2">
          <div
            className="border rounded dashboard-card"
            style={{ overflow: "hidden" }}
          >
            <table className="table ">
              <thead>
                <tr>
                  <th>Type</th>
                  <th className="text-right">Number</th>
                </tr>
              </thead>
              <tbody>
                {dashboardChartsData?.questionTypes?.map((questionType) => (
                  <tr key={questionType.id}>
                    <td>{questionType.name}</td>
                    <td className="text-right">
                      {questionType.numberOfQuestions}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-5">
          <div className="border rounded dashboard-card">
            <Bar
              redraw={true}
              options={{
                responsive: true,

                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Job advertisements status",
                  },
                },

                interaction: {
                  mode: "index",
                  intersect: false,
                },

                elements: {
                  line: {
                    tension: 0.4,
                  },
                },
              }}
              data={{
                labels: dashboardChartsData?.advertStatus?.labels || [],
                datasets: [
                  {
                    fill: "start",
                    label: "No PSY test",
                    data:
                      dashboardChartsData?.advertStatus?.datasets?.hasNotPSY ||
                      [],

                    borderColor: "rgba(255, 206, 86, 1)",
                    backgroundColor: "rgba(255, 206, 86, 1)",
                  },
                  {
                    fill: "start",
                    label: "Has PSY test",
                    data:
                      dashboardChartsData?.advertStatus?.datasets?.hasPSY || [],
                    borderColor: "rgba(7, 142, 206, 1)",
                    backgroundColor: "rgba(7, 142, 206, 1)",
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="col-5">
          <div className="border rounded dashboard-card">
            <Bar
              redraw={true}
              options={{
                responsive: true,

                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Psychometric test results",
                  },
                },

                interaction: {
                  mode: "index",
                  intersect: false,
                },

                elements: {
                  line: {
                    tension: 0.4,
                  },
                },
              }}
              data={{
                labels: dashboardChartsData?.psyResults?.labels || [],
                datasets: [
                  {
                    label: "Passed",
                    data:
                      dashboardChartsData?.psyResults?.datasets?.passed || [],
                    borderColor: "rgba(16,193,30, 0.5)",
                    backgroundColor: "rgba(16,193,30, 0.5)",
                  },
                  {
                    label: "Failed",
                    data:
                      dashboardChartsData?.psyResults?.datasets?.failed || [],
                    borderColor: "rgba(255, 87, 34, 1)",
                    backgroundColor: "rgba(255, 87, 34, 1)",
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mt-4 justify-content-center tmis-shadow bg-light rounded py-3">
        <div className="col-6">
          <div className="border rounded p-2 dashboard-card">
            <Bar
              redraw={true}
              options={{
                responsive: true,

                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Psychometric test assessment",
                  },
                },

                interaction: {
                  mode: "index",
                  intersect: false,
                },

                elements: {
                  line: {
                    tension: 0.4,
                  },
                },
              }}
              data={{
                labels: dashboardChartsData?.psyImpact?.labels || [],
                datasets: [
                  {
                    fill: "start",
                    label: "All applications",
                    data:
                      dashboardChartsData?.psyImpact?.datasets
                        ?.allApplications || [],
                    borderColor: "rgba(75, 0, 130, 0.5)",
                    backgroundColor: "rgba(75, 0, 130, 0.5)",
                  },
                  {
                    fill: "start",
                    label: "Passed PSY",
                    data:
                      dashboardChartsData?.psyImpact?.datasets?.passedPSY || [],
                    borderColor: "rgba(54, 162, 235, 1)",
                    backgroundColor: "rgba(54, 162, 235, 1)",
                  },
                  {
                    label: "Failed PSY",
                    data:
                      dashboardChartsData?.psyImpact?.datasets?.failedPSY || [],
                    borderColor: "rgba(255, 206, 86, 1)",
                    backgroundColor: "rgba(255, 206, 86, 1)",
                  },
                  {
                    label: "Rejected By HR",
                    data:
                      dashboardChartsData?.psyImpact?.datasets
                        ?.notShortlisted || [],
                    borderColor: "rgba(255, 86, 86, 1)",
                    backgroundColor: "rgba(255, 86, 86, 1)",
                  },
                  {
                    label: "Shortlisted",
                    data:
                      dashboardChartsData?.psyImpact?.datasets?.shortlisted ||
                      [],
                    borderColor: "rgba(16,193,30, 1)",
                    backgroundColor: "rgba(16,193,30, 1)",
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="col-6 text-right">
          <div className="border rounded p-2 dashboard-card">
            <Line
              redraw={true}
              options={{
                responsive: true,

                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Psychometric test pick time / Today",
                  },
                },

                interaction: {
                  mode: "index",
                  intersect: false,
                },

                elements: {
                  line: {
                    tension: 0.4,
                  },
                },
              }}
              data={{
                labels: dashboardChartsData?.pickTime?.labels || [],
                datasets: [
                  {
                    fill: "start",
                    label: "Candidates",
                    data: dashboardChartsData?.pickTime?.datasets || [],
                    borderColor: "rgba(255, 86, 86, 1)",
                    backgroundColor: "rgba(255, 86, 86, 0.2)",
                  },
                ],
              }}
            />
            {/* {period === "D" && (
              <Line
                redraw={true}
                options={{
                  responsive: true,

                  plugins: {
                    legend: {
                      position: "bottom",
                    },
                    title: {
                      display: true,
                      text: "Psychometric test pick time / Today",
                    },
                  },

                  interaction: {
                    mode: "index",
                    intersect: false,
                  },

                  elements: {
                    line: {
                      tension: 0.4,
                    },
                  },
                }}
                data={{
                  labels: dashboardChartsData?.pickTime?.labels || [],
                  datasets: [
                    {
                      fill: "start",
                      label: "Candidates",
                      data: dashboardChartsData?.pickTime?.datasets || [],
                      borderColor: "rgba(255, 86, 86, 1)",
                      backgroundColor: "rgba(255, 86, 86, 0.2)",
                    },
                  ],
                }}
              />
            )}

            {period === "W" && (
              <Line
                redraw={true}
                options={{
                  responsive: true,

                  plugins: {
                    legend: {
                      position: "bottom",
                    },
                    title: {
                      display: true,
                      text: "Psychometric test pick time / This Week",
                    },
                  },

                  interaction: {
                    mode: "index",
                    intersect: false,
                  },

                  elements: {
                    line: {
                      tension: 0.4,
                    },
                  },
                }}
                data={{
                  labels: dashboardChartsData?.pickTime?.labels || [],
                  datasets: [
                    {
                      fill: "start",
                      label: "Candidates",
                      data: dashboardChartsData?.pickTime?.datasets || [],
                      borderColor: "rgba(255, 86, 86, 1)",
                      backgroundColor: "rgba(255, 86, 86, 0.2)",
                    },
                  ],
                }}
              />
            )}

            {period === "M" && (
              <Line
                redraw={true}
                options={{
                  responsive: true,

                  plugins: {
                    legend: {
                      position: "bottom",
                    },
                    title: {
                      display: true,
                      text: "Psychometric test pick time / August 2024",
                    },
                  },

                  interaction: {
                    mode: "index",
                    intersect: false,
                  },

                  elements: {
                    line: {
                      tension: 0.4,
                    },
                  },
                }}
                data={{
                  labels: dashboardChartsData?.pickTime?.labels || [],
                  datasets: [
                    {
                      fill: "start",
                      label: "Candidates",
                      data: dashboardChartsData?.pickTime?.datasets || [],
                      borderColor: "rgba(255, 86, 86, 1)",
                      backgroundColor: "rgba(255, 86, 86, 0.2)",
                    },
                  ],
                }}
              />
            )}

            {period === "Y" && (
              <Line
                redraw={true}
                options={{
                  responsive: true,

                  plugins: {
                    legend: {
                      position: "bottom",
                    },
                    title: {
                      display: true,
                      text: "Psychometric test pick time / 2024",
                    },
                  },

                  interaction: {
                    mode: "index",
                    intersect: false,
                  },

                  elements: {
                    line: {
                      tension: 0.4,
                    },
                  },
                }}
                data={{
                  labels: dashboardChartsData?.pickTime?.labels || [],
                  datasets: [
                    {
                      fill: "start",
                      label: "Candidates",
                      data: dashboardChartsData?.pickTime?.datasets || [],
                      borderColor: "rgba(255, 86, 86, 1)",
                      backgroundColor: "rgba(255, 86, 86, 0.2)",
                    },
                  ],
                }}
              />
            )} */}
          </div>

          <FormControl size="small" style={{ width: "100px" }} className="mt-2">
            <InputLabel>Period</InputLabel>
            <Select
              size="small"
              value={period}
              label="Age"
              onChange={(e) => setPeriod(e.target.value)}
            >
              <MenuItem value={"D"}>Day</MenuItem>
              <MenuItem value={"W"}>Week</MenuItem>
              <MenuItem value={"M"}>Month</MenuItem>
              <MenuItem value={"Y"}>Year</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div
        className="bg-light border mt-3 rounded"
        style={{ overflow: "hidden" }}
      >
        <div className="pb-2" style={{ backgroundColor: "#f2f2f2" }}>
          <div className="row pl-5 pr-5 ml-3 mr-3 mb-0">
            <div className="col text-right p-4">Knowledge Level</div>
            <div className="col">
              <div className="row">
                {dashboardData === null ? (
                  <div>Loading...</div>
                ) : (
                  [
                    {
                      id: "1",
                      name: "Strategic",
                    },
                    {
                      id: "2",
                      name: "Managerial",
                    },
                    {
                      id: "3",
                      name: "Operational",
                    },
                  ].map((item, i) => (
                    <div
                      className="col fw-bold pt-4 pb-4 text-center text-primary"
                      key={i + 1}
                    >
                      {item.name}
                    </div>
                  ))
                )}
                <div className="col p-4 fw-bold text-center text-primary">
                  Total
                  <Tooltip title="Download data">
                    <span className="ml-2">
                      <IconButton
                        size="small"
                        className=" mr-1"
                        color="primary"
                        onClick={() =>
                          downloadDashboardData(dashboardChartsData)
                        }
                      >
                        <span className="material-icons">file_download</span>
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          {dashboardData === null ? (
            <div>Loading...</div>
          ) : (
            dashboardData.domains.map((domain, d) => (
              <div
                key={d + 1}
                className="row bg-white pl-5 pr-5 pt-2 ml-3 mr-3 mb-3 border rounded pb-2"
              >
                <div className="col">
                  <div className="row">
                    <div
                      className="col text-right font-weight-bold p-2"
                      style={{ fontSize: "20px" }}
                    >
                      {domain.name}
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div
                          className="col d-flex align-items-center justify-content-center flex-column mb-5"
                          style={{ fontSize: "24px", color: "#e5e5e5" }}
                        >
                          Subdomain Level
                        </div>
                        <div className="col text-right">
                          {domain.subDomains.length === 0 ? (
                            <div className="row">Not Found!</div>
                          ) : (
                            domain.subDomains.map((item, i) => (
                              <div key={i + 1} className="row">
                                <div className="col p-1">
                                  <div className="p-2">{item.name}</div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      {domain.subDomains.length === 0 ? (
                        <div className="row">Not Found!</div>
                      ) : (
                        domain.subDomains.map((value, v) => (
                          <div key={v + 1} className="row">
                            <div className="col p-1">
                              <div className="p-2 text-center bg-light rounded-lg">
                                {commaFy(value.strategicQuestions)}
                              </div>
                            </div>
                            <div className="col p-1">
                              <div className="p-2 text-center bg-light rounded-lg">
                                {commaFy(value.managerialQuestions)}
                              </div>
                            </div>
                            <div className="col p-1">
                              <div className="p-2 text-center bg-light rounded-lg">
                                {commaFy(value.operationalQuestions)}
                              </div>
                            </div>
                            <div className="col p-1">
                              <div className="p-2 text-center bg-light rounded-lg">
                                {commaFy(
                                  parseFloat(value.strategicQuestions) +
                                    parseFloat(value.managerialQuestions) +
                                    parseFloat(value.operationalQuestions)
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
          <div className="row bg-white m-3 border rounded pl-5 pr-5 ml-3 mr-3 pt-3 pb-3">
            <div className="col">
              <div className="row fw-bold">
                <div
                  className="col text-primary pl-3 pr-3 pt-2 pb-2 text-right"
                  style={{ fontSize: "20px" }}
                >
                  TOTAL PER KNOWLEDGE LEVEL
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col p-1">
                      <div className="p-2 text-center bg-primary rounded-lg text-white">
                        {commaFy(getTotals().strategicQuestions)}
                      </div>
                    </div>
                    <div className="col p-1">
                      <div className="p-2 text-center bg-primary rounded-lg text-white">
                        {commaFy(getTotals().managerialQuestions)}
                      </div>
                    </div>
                    <div className="col p-1">
                      <div className="p-2 text-center bg-primary rounded-lg text-white">
                        {commaFy(getTotals().operationalQuestions)}
                      </div>
                    </div>
                    <div className="col p-1">
                      <div className="p-2 text-center bg-primary rounded-lg text-white">
                        {commaFy(
                          getTotals().strategicQuestions +
                            getTotals().managerialQuestions +
                            getTotals().operationalQuestions
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ questionTypes, dashboardChartsData }) => {
  return {
    questionTypes,
    dashboardChartsData,
  };
};

export default connect(mapStateToProps, {
  getDashboardChartsData,
  downloadDashboardData,
})(Dashboard);
