
import loadingGif from "../../assets/loading.gif";
const PreLoader = (props) => {
  const { isLoading } = props;

  return (
    <>
    {isLoading && ( <div className="text-center">
    <img src={loadingGif} height="128" alt="loading" />
  </div>)}
    </>
   
  );
};

export default PreLoader;
