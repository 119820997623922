const types = {
  SET_ANALYTICS: "SET_ANALYTICS",
  SET_JOB_PUBLICATIONS: "SET_JOB_PUBLICATIONS",
  SET_APPLICANT_JOB_PUBLICATIONS: "SET_APPLICANT_JOB_PUBLICATIONS",
  SET_APPLICANTS: "SET_APPLICANTS",
  SET_APPLICANT_EXAM_MARKING: "SET_APPLICANT_EXAM_MARKING",
  SET_APPLICANT_ORAL_EXAM_MARKING: "SET_APPLICANT_ORAL_EXAM_MARKING",
  SET_APPLICANT_OVERALL_RESULTS: "SET_APPLICANT_OVERALL_RESULTS",
  UPDATE_APPLICANT_OVERALL_RESULT: "UPDATE_APPLICANT_OVERALL_RESULT",
  SET_APPEALS: "SET_APPEALS",
  SET_APPEAL: "SET_APPEAL",
  UPDATE_APPEAL: "UPDATE_APPEAL",
  SET_UNCONFIRMED_APPEALS: "SET_UNCONFIRMED_APPEALS",
  SET_APPEAL_DETAILS: "SET_APPEAL_DETAILS",
  SET_QUESTIONS: "SET_QUESTIONS",
  SET_TESTS: "SET_TESTS",
  UPLOADED_EXAMS: "UPLOADED_EXAMS",
  ADD_REQUISITION: "ADD_REQUISITION",
  SET_REQUISITONS: "SET_REQUISITONS",
  UPDATE_REQUISITION: "UPDATE_REQUISITION",
  DELETE_REQUISITION: "DELETE_REQUISITION",
  SET_REQUISITON_STATUS: "SET_REQUISITON_STATUS",
  SET_REQUISITION_CERTIFICATES: "SET_REQUISITION_CERTIFICATES",
  SET_REQUISITION_COMPETENCIES: "SET_REQUISITION_COMPETENCIES",
  SET_REQUISITION_QUALIFICATIONS: "SET_REQUISITION_QUALIFICATIONS",
  SET_REQUISITION_EXPERIENCES: "SET_REQUISITION_EXPERIENCES",
  SET_ADVERTISEMENTS: "SET_ADVERTISEMENTS",
  ADD_ADVERTISEMENT: "ADD_ADVERTISEMENT",
  UPDATE_ADVERTISEMENT: "UPDATE_ADVERTISEMENT",
  SET_ADVERTISEMENT_APPLICATIONS: "SET_ADVERTISEMENT_APPLICATIONS",
  UPDATE_ADVERTISEMENT_APPLICATION: "UPDATE_ADVERTISEMENT_APPLICATION",
  SET_ADVERTISEMENT_STATUS: "SET_ADVERTISEMENT_STATUS",
  SET_APPLICATION_STATUS: "SET_APPLICATION_STATUS",
  SET_USER_PROFILE: "SET_USER_PROFILE",
  SET_CONVERTED_FILE_TO_BASE64: "SET_CONVERTED_FILE_TO_BASE64",
  SET_WRITTEN_EXAMS: "SET_WRITTEN_EXAMS",
  ADD_WRITTEN_EXAM: "ADD_WRITTEN_EXAM",
  UPDATE_WRITTEN_EXAM: "UPDATE_WRITTEN_EXAM",
  DELETE_WRITTEN_EXAM: "DELETE_WRITTEN_EXAM",
  DELETE_ORAL_EXAM: "DELETE_ORAL_EXAM",
  SET_ORAL_EXAMS: "SET_ORAL_EXAMS",
  ADD_ORAL_EXAM: "ADD_ORAL_EXAM",
  UPDATE_ORAL_EXAM: "UPDATE_ORAL_EXAM",
  SET_TEMP_QUESTIONS: "SET_TEMP_QUESTIONS",
  DELETE_TEMP_QUESTION: "DELETE_TEMP_QUESTION",
  UPDATE_TEMP_QUESTION: "UPDATE_TEMP_QUESTION",
  HAS_EXAM_QUESTIONS: "HAS_EXAM_QUESTIONS",
  SET_EXAM_QUESTIONS: "SET_EXAM_QUESTIONS",
  UPDATE_EXAM_QUESTION: "UPDATE_EXAM_QUESTION",
  SET_APPLICANT_EXAM_SESSIONS: "SET_APPLICANT_EXAM_SESSIONS",
  UPDATE_APPLICANT_EXAM_SESSIONS: "UPDATE_APPLICANT_EXAM_SESSIONS",
  START_APPLICANT_EXAM_SESSIONS: "START_APPLICANT_EXAM_SESSIONS",
  SET_APPLICANT_EXAM_SESSION_LOGS: "SET_APPLICANT_EXAM_SESSION_LOGS",
  SET_APPLICANT_SESSION_ADDITIONALMINUTES:
    "SET_APPLICANT_SESSION_ADDITIONALMINUTES",
  SET_SESSION_UNLOCKED_OVER_3_TIMES: "SET_SESSION_UNLOCKED_OVER_3_TIMES",
  SET_APPLICANTS_NOT_ATTENDED: "SET_APPLICANTS_NOT_ATTENDED",
  SET_APPLICANTS_ATTENDED: "SET_APPLICANTS_ATTENDED",
  DELETE_APPLICANT_NOT_ATTENDED: "DELETE_APPLICANT_NOT_ATTENDED",
  DELETE_APPLICANT_ATTENDED: "DELETE_APPLICANT_ATTENDED",
  SET_APPLICANT_WRITTEN_EXAM_RESULTS: "SET_APPLICANT_WRITTEN_EXAM_RESULTS",
  SET_APPLICANT_WRITTEN_EXAM_ANSWERS: "SET_APPLICANT_WRITTEN_EXAM_ANSWERS",
  UPDATE_APPLICANT_WRITTEN_EXAM_SESSION:
    "UPDATE_APPLICANT_WRITTEN_EXAM_SESSION",
  UPDATE_APPLICANT_WRITTEN_EXAM_ANSWER: "UPDATE_APPLICANT_WRITTEN_EXAM_ANSWER",
  SET_APPLICANT_WRITTEN_EXAM_RESULT: "SET_APPLICANT_WRITTEN_EXAM_RESULT",
  SET_APPLICANT_ORAL_EXAM_RESULTS: "SET_APPLICANT_ORAL_EXAM_RESULTS",
  UPDATE_APPLICANT_ORAL_EXAM_SESSION: "UPDATE_APPLICANT_ORAL_EXAM_SESSION",
  SET_APPLICANT_ORAL_EXAM_RESULT: "SET_APPLICANT_ORAL_EXAM_RESULT",
  SET_APPLICANT_ORAL_EXAM_ANSWERS: "SET_APPLICANT_ORAL_EXAM_ANSWERS",
  UPDATE_APPLICANT_ORAL_EXAM_ANSWER: "UPDATE_APPLICANT_ORAL_EXAM_ANSWER",
  ADD_APPLICANT_ORAL_EXAM_ANSWER: "ADD_APPLICANT_ORAL_EXAM_ANSWER",
  SET_SEARCHED_PANELISTS: "SET_SEARCHED_PANELISTS",
  SET_SEARCHED_INVIGILATORS: "SET_SEARCHED_INVIGILATORS",
  SET_SEARCHED_RECRUITMENT_MEMBER: "SET_SEARCHED_RECRUITMENT_MEMBER",
  SET_INVIGILATORS: "SET_INVIGILATORS",
  SET_PANELISTS: "SET_PANELISTS",
  SET_RECRUITMENT_MEMBERS: "SET_RECRUITMENT_MEMBERS",
  ADD_INVIGILATOR: "ADD_INVIGILATOR",
  ADD_RECRUITMENT_MEMBER: "ADD_RECRUITMENT_MEMBER",
  DELETE_INVIGILATOR: "DELETE_INVIGILATOR",
  DELETE_RECRUITMENT_MEMBER: "DELETE_RECRUITMENT_MEMBER",
  ADD_PANELIST: "ADD_PANELIST",
  DELETE_PANELIST: "DELETE_PANELIST",
  SET_PANEL_ORAL_EXAMS: "SET_PANEL_ORAL_EXAMS",
  SET_EXAMINER_ORAL_EXAM_RESULTS: "SET_EXAMINER_ORAL_EXAM_RESULTS",
  UPDATE_EXAMINER_ORAL_EXAM_RESULT: "UPDATE_EXAMINER_ORAL_EXAM_RESULT",
  UPDATE_PANEL_ORAL_EXAM: "UPDATE_PANEL_ORAL_EXAM",
  SET_INVIGILATOR_WRITTEN_EXAMS: "SET_INVIGILATOR_WRITTEN_EXAMS",
  SET_APPEAL_ADVERTISEMENTS: "SET_APPEAL_ADVERTISEMENTS",
  SET_SHOW_ADD_ORAL_MARK_DIALOG: "SET_SHOW_ADD_ORAL_MARK_DIALOG",
  SET_SHOW_ADD_ORAL_SCHEMA_DIALOG: "SET_SHOW_ADD_ORAL_SCHEMA_DIALOG",
  SET_APPLICANT_ORAL_EXAM_SCHEMA: "SET_APPLICANT_ORAL_EXAM_SCHEMA",
  SET_WAITING_LIST_POSITIONS: "SET_WAITING_LIST_POSITIONS",
  SET_WAITINGLIST_REQUISITIONS: "SET_WAITINGLIST_REQUISITIONS",
  SET_WAITINGLIST_JOB_OFFERS: "SET_WAITINGLIST_JOB_OFFERS",
  UPDATE_WAITINGLIST_JOB_OFFER: "UPDATE_WAITINGLIST_JOB_OFFER",
  UPDATE_WAITINGLIST_REQUISITION: "UPDATE_WAITINGLIST_REQUISITION",
  ADD_WAITINGLIST_REQUISITION: "ADD_WAITINGLIST_REQUISITION",
  DELETE_WAITINGLIST_REQUISITION: "DELETE_WAITINGLIST_REQUISITION",
  SET_WAITINGLIST_CANDIDATES: "SET_WAITINGLIST_CANDIDATES",
  SET_WAITINGLIST_OFFERS: "SET_WAITINGLIST_OFFERS",
  SET_ENTITY_SECTORS_WAITINGLIST_POSITIONS:
    "SET_ENTITY_SECTORS_WAITINGLIST_POSITIONS",
  ADD_APPLICANT_ATTENDED: "ADD_APPLICANT_ATTENDED",
  SET_INPROGRESS_APPEALS: "SET_INPROGRESS_APPEALS",
  SET_ARCHIVED_APPEALS: "SET_ARCHIVED_APPEALS",
  SET_ALL_OVERDUE_APPEALS: "SET_ALL_OVERDUE_APPEALS",
  SET_ACTIVE_CENTERS: "SET_ACTIVE_CENTERS",
  SET_EXAM_CENTERS: "SET_EXAM_CENTERS",
  ADD_EXAM_CENTER: "ADD_EXAM_CENTER",
  DELETE_EXAM_CENTER: "DELETE_EXAM_CENTER",
  SET_EXAM_CENTER_NONE_SELECTED: "SET_EXAM_CENTER_NONE_SELECTED",
  DELETE_EXAM_CENTER_NONE_SELECTED: "DELETE_EXAM_CENTER_NONE_SELECTED",
  SET_PANELS: "SET_PANELS",
  DELETE_PANEL: "DELETE_PANEL",
  ADD_PANEL: "ADD_PANEL",
  UPDATE_PANEL: "UPDATE_PANEL",

  SET_QUESTION_TYPES: "SET_QUESTION_TYPES",
  UPDATE_QUESTION_TYPE: "UPDATE_QUESTION_TYPE",
};

export default types;
