import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Box,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { a11yProps, TabPanel } from "../../common/components/TabPanel";
import RecruitmentOversight from "../components/RecruitmentOversight";
import RecruitmentDashboard from "../components/RecruitmentDashboard";
import ScheduledExam from "../components/ScheduledExam";
import { getAdvertisementYear } from "../../../store/admin/actions";

const Recruitment = (props) => {
  const { loading, advertisementYears, getAdvertisementYear } = props;
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [loadingYears, setLoadingYears] = useState(true);
  const [selectedYear, setSelectedYear] = useState(null);
  const [filterYearStatus, setFilterYearStatus] = useState(false);

  useEffect(() => {
    if (advertisementYears === null || advertisementYears.length === 0) {
      getAdvertisementYear((status, data) => {
        setLoadingYears(status);
        setSelectedYear(data);
      });
    } else {
      setSelectedYear(advertisementYears[0] || null);
      setLoadingYears(false);
    }
    return () => {
      setLoadingYears(true);
      setSelectedYear(null);
      setFilterYearStatus(false);
      setValue(0);
    };
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const DATA_YearOption =
    value === 0 && advertisementYears
      ? [{ adYear: "ALL" }, ...advertisementYears]
      : advertisementYears
      ? advertisementYears
      : [];

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            className="app-bar w-100 mb-0 pb-0"
          >
            <Toolbar className="mb-0 pb-0">
              <Tabs
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                className="mb-0 pb-0"
                value={value}
                onChange={handleChangeTab}
              >
                <Tab label="Oversight" {...a11yProps(0)} />
                <Tab label="Exams" {...a11yProps(1)} />
                <Tab label="Dashboard" {...a11yProps(2)} />
              </Tabs>

              <Box sx={{ flexGrow: 1 }} />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { sm: "none", md: "block" } }}
              >
                <span className="lead text-uppercase">
                  Recruitment Administration
                </span>
              </Typography>
              {filterYearStatus && value !== 1 && (
                <Autocomplete
                  className="ml-2"
                  disabled={loadingYears || loading}
                  disablePortal
                  getOptionLabel={(option) => option.adYear}
                  renderOption={(props, field) => (
                    <Box component="li" {...props}>
                      {field?.adYear}
                    </Box>
                  )}
                  options={DATA_YearOption}
                  sx={{ width: 120 }}
                  size="small"
                  value={selectedYear || null}
                  isOptionEqualToValue={(option, value) =>
                    option.adYear === value.adYear
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose year"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "year",
                      }}
                    />
                  )}
                  onChange={(event, year) => {
                    setSelectedYear(year || null);
                    setFilterYearStatus(false);
                  }}
                />
              )}
              {!filterYearStatus && value !== 1 && (
                <div className="d-flex flex-row align-items-center rounded border px-1 ml-2">
                  {selectedYear && (
                    <span className="font-weight-normal mr-1">
                      {selectedYear.adYear}
                    </span>
                  )}
                  <IconButton
                    color="primary"
                    aria-label="filter by advertisement year"
                    component="label"
                    size="small"
                    onClick={() => setFilterYearStatus(true)}
                  >
                    <span className="material-icons-outlined">filter_list</span>
                  </IconButton>
                </div>
              )}
            </Toolbar>
          </AppBar>

          <div>
            <TabPanel value={value} index={0}>
              <React.Fragment>
                <RecruitmentOversight selectedYear={selectedYear} />
              </React.Fragment>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <React.Fragment>
                <ScheduledExam />
              </React.Fragment>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <React.Fragment>
                <RecruitmentDashboard selectedYear={selectedYear} />
              </React.Fragment>
            </TabPanel>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ loading, recruitment }) => {
  return { loading, advertisementYears: recruitment.advertisementYears };
};

export default connect(mapStateToProps, { getAdvertisementYear })(Recruitment);
