import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import QuestionBank from "./pages/QuestionBank";

import PrivateRoute from "../common/guard/PrivateRoute";
import { useRequiredAuth } from "../common/guard/requiredAuth";
import TempQuestions from "./pages/TempQuestions";

import {
  getQuestionBankKnowledgeDomains,
  getKnowledgeDomains,
  getKnowledgeSubDomains,
  getKnowledgeLevels,
} from "../../store/psychometric/actions";

import { getQuestionTypes } from "../../store/recruitment/actions";
import { getLevels } from "../../store/structure/actions";

import { connect } from "react-redux";
import Settings from "./pages/Settings";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import LiveCandidatesPage from "./pages/LiveCandidatesPage";

const defaultRouters = [
  {
    to: "/recruitment/requisitions",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF"],
  },
  {
    to: "/recruitment/psychometric/temp",
    permissions: ["IS_QUESTION_BANK_CREATOR", "IS_QUESTION_BANK_EDITOR"],
  },
  {
    to: "/recruitment/psychometric/dashboard",
    permissions: ["IS_QUESTION_BANK_ADMIN"],
  },
];

const Layout = (props) => {
  const {
    getQuestionBankKnowledgeDomains,
    getKnowledgeDomains,
    getKnowledgeSubDomains,
    getKnowledgeLevels,
    getQuestionTypes,
    getLevels,
    dashboardChartsData,
  } = props;

  const { hasPermissions } = useRequiredAuth();

  const history = useHistory();
  const location = useLocation();

  const renderDefaultRoute = () => {
    for (const router of defaultRouters) {
      if (hasPermissions(router.permissions)) {
        return router.to;
      }
    }
    return "/unauthorized";
  };

  useEffect(() => {
    getQuestionBankKnowledgeDomains();
    getKnowledgeDomains();

    getKnowledgeSubDomains();

    getKnowledgeLevels();
    getQuestionTypes();
    getLevels();
  }, []);

  const btnVariant = (pathname) =>
    location.pathname.includes(pathname) ? "contained" : "outlined";

  return (
    <div className="container-fluid">
      <AppBar position="static" elevation={0} className="app-bar">
        <Toolbar className="px-0">
          {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
            <>
              <ButtonGroup variant="outlined">
                <Button
                  size="small"
                  variant={btnVariant("/recruitment/psychometric/dashboard")}
                  onClick={() =>
                    history.push("/recruitment/psychometric/dashboard")
                  }
                >
                  <span className="material-icons">dashboard</span>
                  Dashboard
                </Button>
                <Button
                  size="small"
                  variant={btnVariant("/recruitment/psychometric/bank")}
                  onClick={() => history.push("/recruitment/psychometric/bank")}
                >
                  <span className="material-icons mr-1">layers</span>
                  Question Bank
                </Button>
                <Button
                  // color="error"
                  size="small"
                  variant={btnVariant(
                    "/recruitment/psychometric/live-candidates"
                  )}
                  onClick={() =>
                    history.push("/recruitment/psychometric/live-candidates")
                  }
                >
                  <span className="material-icons mr-1">live_tv</span>
                  Live candidates
                </Button>
              </ButtonGroup>

              <Box sx={{ flexGrow: 1 }} />
            </>
          )}

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { sm: "none", md: "block" } }}
          >
            <span className="lead text-uppercase">
              Psychometric Test Question Bank
            </span>
          </Typography>

          {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
            <Button
              onClick={() => history.push("/recruitment/psychometric/settings")}
              variant={btnVariant("/recruitment/psychometric/settings")}
              size="small"
              disableElevation
              className="ml-2"
            >
              <span className="material-icons">settings</span>
              <span>Settings</span>
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <div>
        <React.Fragment>
          <Switch>
            <PrivateRoute
              children={<Dashboard />}
              permissions={["IS_QUESTION_BANK_ADMIN"]}
              path="/recruitment/psychometric/dashboard"
            />

            <PrivateRoute
              children={<QuestionBank />}
              permissions={["IS_QUESTION_BANK_ADMIN"]}
              path="/recruitment/psychometric/bank"
            />

            <PrivateRoute
              children={<LiveCandidatesPage />}
              permissions={["IS_QUESTION_BANK_ADMIN"]}
              path="/recruitment/psychometric/live-candidates"
            />

            <PrivateRoute
              children={<TempQuestions />}
              permissions={[
                "IS_QUESTION_BANK_ADMIN",
                "IS_QUESTION_BANK_CREATOR",
                "IS_QUESTION_BANK_EDITOR",
              ]}
              path="/recruitment/psychometric/temp"
            />

            <PrivateRoute
              children={<Settings />}
              permissions={["IS_QUESTION_BANK_ADMIN"]}
              path="/recruitment/psychometric/settings"
            />

            <Route
              path="/recruitment/psychometric"
              component={() => <Redirect to={renderDefaultRoute()} />}
            />
          </Switch>
        </React.Fragment>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading, dashboardChartsData }) => {
  return {
    user,
    loading,
    dashboardChartsData,
  };
};
export default connect(mapStateToProps, {
  getQuestionBankKnowledgeDomains,
  getKnowledgeDomains,
  getKnowledgeSubDomains,
  getKnowledgeLevels,
  getQuestionTypes,
  getLevels,
})(Layout);
