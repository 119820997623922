import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import MenuBar from "./components/MenuBar";
import Proflile from "../common/pages/Profile";
import MyAcount from "../common/pages/MyAcount";
// import Oversight from "./pages/Oversight";
import UserGuide from "./pages/UserGuide";
import Positions from "./pages/Positions";
import Units from "./pages/Units";
// import SalaryIndexGrid from "../payroll/pages/SalaryIndexGrid";
import JobClassification from "./pages/JobClassification";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import Organigram from "./pages/Organigram";
import RRADepartments from "./pages/RRADepartments";

import Oversight from "../admin/pages/Structure";
import { useRequiredAuth } from "../common/guard/requiredAuth";

const defaultRouters = [
  {
    to: "/structure/oversight",
    permissions: ["IS_STRUCTURE_OVERSIGHT"],
  },
];

const Layout = (props) => {
  const { user } = props;
  const history = useHistory();
  useEffect(() => {
    if (isEmpty(user)) return history.push("/");
  }, []);

  const { hasPermissions } = useRequiredAuth();

  const renderDefaultRoute = () => {
    for (const router of defaultRouters) {
      if (
        hasPermissions(router.permissions) &&
        !hasPermissions(["IS_ADMIN", "IS_HR", "IS_CBM", "IS_DAF"])
      ) {
        return router.to;
      }
    }

    return "/structure/units";
  };
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/structure/dashboard" component={Dashboard} />
        <Route path="/structure/units" component={Units} />
        <Route path="/structure/positions" component={Positions} />
        <Route path="/structure/organigram" component={Organigram} />
        <Route path="/structure/user-guide" component={UserGuide} />
        <Route path="/structure/profile" component={Proflile} />
        <Route path="/structure/oversight" component={Oversight} />
        <Route path="/structure/my-account" component={MyAcount} />
        <Route path="/structure/rra-departments" component={RRADepartments} />

        <Route
          path="/structure"
          component={() => <Redirect to={renderDefaultRoute()} />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps)(Layout);

//export  const StructureDrawer=connect(mapStateToProps)(Layout);
