import { defaultState } from "./state";
import structure from "./action-types";
import commonTypes from "../common/action-types";
import { arrangingPostions } from "./actions";

const types = { ...commonTypes, ...structure };

const units = (unitsState = defaultState.units, action) => {
  switch (action.type) {
    case types.SET_UNITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.units;

    default:
      return unitsState;
  }
};

const treeUnits = (treeUnitsState = defaultState.treeUnits, action) => {
  switch (action.type) {
    case types.SET_TREE_UNITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.treeUnits;

    default:
      return treeUnitsState;
  }
};

const selectedUnit = (
  selectedUnitState = defaultState.selectedUnit,
  action
) => {
  switch (action.type) {
    case types.SELECTED_UNIT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedUnit;

    default:
      return selectedUnitState;
  }
};

const searchUnit = (searchUnitState = defaultState.searchUnit, action) => {
  switch (action.type) {
    case types.SEARCH_UNIT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchUnit;

    default:
      return searchUnitState;
  }
};

const searchPosition = (
  searchPositionState = defaultState.searchPosition,
  action
) => {
  switch (action.type) {
    case types.SEARCH_POSITION:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchPosition;

    default:
      return searchPositionState;
  }
};
const searchCurrentPositions = (
  searchCurrentPositionsState = defaultState.searchCurrentPositions,
  action
) => {
  switch (action.type) {
    case types.SEARCH_CURRENT_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchCurrentPositions;

    default:
      return searchCurrentPositionsState;
  }
};

const unitTypes = (unitTypesState = defaultState.unitTypes, action) => {
  switch (action.type) {
    case types.SET_UNIT_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.unitTypes;

    default:
      return unitTypesState;
  }
};
const entitySectorPositions = (
  entitySectorPositionsState = defaultState.entitySectorPositions,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.entitySectorPositions;

    default:
      return entitySectorPositionsState;
  }
};

const positions = (positionsState = defaultState.positions, action) => {
  switch (action.type) {
    case types.SET_POSITIONS:
      return action.data;

    case types.ADD_POSITION: {
      const tmpPositionsStateState = [...positionsState];
      tmpPositionsStateState.push(action.data);

      const arrangedPostions = arrangingPostions(
        tmpPositionsStateState.sort((a, b) => a.levelId - b.levelId)
      );

      return arrangedPostions;
    }

    case types.DELETE_POSITION: {
      const tmpRemovePositions = [...positionsState];
      const index = tmpRemovePositions.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemovePositions.splice(index, 1);

      return tmpRemovePositions;
    }

    case types.UPDATE_POSITION: {
      const tmpPositionsStateState = [...positionsState];
      const index = tmpPositionsStateState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpPositionsStateState[index] = action.data;

      return tmpPositionsStateState;
    }

    case types.SET_POSITION_PLANNER || types.SET_POSITION_HEAD_UNITY: {
      const tmpPositionsStateState = [...positionsState];

      const index = tmpPositionsStateState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpPositionsStateState[index] = action.data;

      return tmpPositionsStateState;
    }

    case types.CLEAN_STATE:
      return defaultState.positions;

    default:
      return positionsState;
  }
};

const salaryStructures = (
  salaryStructuresState = defaultState.salaryStructures,
  action
) => {
  switch (action.type) {
    case types.SET_SALARY_STRUCTURES:
      return action.data;

    case types.ADD_SALARY_STRUCTURE: {
      const tmpSalaryStructuresState = [...salaryStructuresState];
      tmpSalaryStructuresState.unshift(action.data);
      return tmpSalaryStructuresState;
    }

    case types.UPDATE_SALARY_STRUCTURE: {
      const tmpSalaryStructuresState = [...salaryStructuresState];

      const index = tmpSalaryStructuresState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpSalaryStructuresState[index] = action.data;

      return tmpSalaryStructuresState;
    }

    case types.SET_SALARY_STRUCTURE_AS_ADDED: {
      const tmpSalaryStructuresState = [...salaryStructuresState];
      const index = tmpSalaryStructuresState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0)
        tmpSalaryStructuresState[index] = {
          ...tmpSalaryStructuresState[index],
          wasAdded: action.data.added,
        };

      return tmpSalaryStructuresState;
    }

    case types.DELETE_SALARY_STRUCTURE: {
      const tmpSalaryStructuresState = [...salaryStructuresState];
      const index = tmpSalaryStructuresState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpSalaryStructuresState.splice(index, 1);

      return tmpSalaryStructuresState;
    }

    case types.CLEAN_STATE:
      return defaultState.salaryStructures;

    default:
      return salaryStructuresState;
  }
};

const employeeGroups = (
  employeeGroupsState = defaultState.employeeGroups,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_GROUPS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employeeGroups;

    default:
      return employeeGroupsState;
  }
};

const allowanceTypes = (
  allowanceTypesState = defaultState.allowanceTypes,
  action
) => {
  switch (action.type) {
    case types.SET_ALLOWANCE_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.allowanceTypes;

    default:
      return allowanceTypesState;
  }
};

const vJobClassifications = (
  vJobClassificationsState = defaultState.vJobClassifications,
  action
) => {
  switch (action.type) {
    case types.SET_VJOB_CLASSIFICATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.vJobClassifications;

    default:
      return vJobClassificationsState;
  }
};

const hiringModes = (hiringModesState = defaultState.hiringModes, action) => {
  switch (action.type) {
    case types.SET_HIRING_MODES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.hiringModes;

    default:
      return hiringModesState;
  }
};

const echelons = (echelonsState = defaultState.echelons, action) => {
  switch (action.type) {
    case types.SET_ECHELONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.echelons;

    default:
      return echelonsState;
  }
};

const employeePromotions = (
  employeePromotionsState = defaultState.employeePromotions,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_PROMOTIONS:
      return action.data;

    // case types.UPDATE_EMPLOYEE_PROMOTION: {
    //   const tmpEmployeePromotions = [...employeePromotions];

    //   const index = tmpEmployeePromotions.findIndex(
    //     ({ id }) => id === action.data.id
    //   );

    //   tmpEmployeePromotions[index] = action.data;

    //   return tmpEmployeePromotions;
    // }

    case types.CLEAN_STATE:
      return defaultState.employeePromotions;

    default:
      return employeePromotionsState;
  }
};

const banks = (banksState = defaultState.banks, action) => {
  switch (action.type) {
    case types.SET_BANKS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.banks;

    default:
      return banksState;
  }
};

const medicalInsuranceTypes = (
  medicalInsuranceTypesState = defaultState.medicalInsuranceTypes,
  action
) => {
  switch (action.type) {
    case types.SET_MEDICAL_INSURANCE_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.medicalInsuranceTypes;

    default:
      return medicalInsuranceTypesState;
  }
};

const levels = (levelsState = defaultState.levels, action) => {
  switch (action.type) {
    case types.SET_LEVELS:
      return action.data;

    case types.UPDATE_LEVEL: {
      const tmpState = [...levelsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = { ...action.data };

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.levels;

    default:
      return levelsState;
  }
};

const positionEmployees = (
  positionEmployeesState = defaultState.positionEmployees,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_EMPLOYEES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positionEmployees;

    default:
      return positionEmployeesState;
  }
};

const supervisors = (supervisorsState = defaultState.supervisors, action) => {
  switch (action.type) {
    case types.SET_SUPERVISORS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.supervisors;

    default:
      return supervisorsState;
  }
};

const professionalCertificates = (
  professionalCertificatesState = defaultState.professionalCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_PROFESSIONAL_CERTIFICATES:
      return action.data;

    case types.ADD_PROFESSIONAL_CERTIFICATE:
      const tmpProfessionalCertificatesState = [
        ...professionalCertificatesState,
      ];

      if (
        !tmpProfessionalCertificatesState.find(
          ({ professionalCertificateId }) =>
            professionalCertificateId === action.data.professionalCertificateId
        )
      ) {
        tmpProfessionalCertificatesState.unshift(action.data);
      }

      return tmpProfessionalCertificatesState;

    case types.DELETE_PROFESSIONAL_CERTIFICATE:
      const tmpRemoveCertificate = [...professionalCertificatesState];
      const index = tmpRemoveCertificate.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemoveCertificate.splice(index, 1);

      return tmpRemoveCertificate;

    case types.CLEAN_STATE:
      return defaultState.professionalCertificates;

    default:
      return professionalCertificatesState;
  }
};

const profileDegrees = (
  profileDegreesState = defaultState.profileDegrees,
  action
) => {
  switch (action.type) {
    case types.SET_PROFILE_DEREES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.profileDegrees;

    default:
      return profileDegreesState;
  }
};
const qualifications = (
  qualificationsState = defaultState.qualifications,
  action
) => {
  switch (action.type) {
    case types.SET_QUALIFICATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.qualifications;

    default:
      return qualificationsState;
  }
};

const competencies = (
  competenciesState = defaultState.competencies,
  action
) => {
  switch (action.type) {
    case types.SET_COMPETENCIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.competencies;

    default:
      return competenciesState;
  }
};

const certificateTypes = (
  certificateTypesState = defaultState.certificateTypes,
  action
) => {
  switch (action.type) {
    case types.SET_CERTIFICATE_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.certificateTypes;

    default:
      return certificateTypesState;
  }
};

const professionalCertificateTypes = (
  professionalCertificateTypesState = defaultState.professionalCertificateTypes,
  action
) => {
  switch (action.type) {
    case types.SET_PROFESSIONAL_CERTIFICATE_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.professionalCertificateTypes;

    default:
      return professionalCertificateTypesState;
  }
};

const positionKnowledges = (
  positionKnowledgesState = defaultState.positionKnowledges,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_KNOWLEDGES:
      return action.data;

    case types.ADD_POSITION_KNOWLEDGE:
      const tmpPositionKnowledgesState = [...positionKnowledgesState];

      if (
        !tmpPositionKnowledgesState.find(
          ({ knowledgeId }) => knowledgeId === action.data.knowledgeId
        )
      ) {
        tmpPositionKnowledgesState.unshift(action.data);
      }

      return tmpPositionKnowledgesState;

    case types.DELETE_POSITION_KNOWLEDGE:
      const tmpRemovePositionKnowledges = [...positionKnowledgesState];
      const index = tmpRemovePositionKnowledges.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemovePositionKnowledges.splice(index, 1);

      return tmpRemovePositionKnowledges;

    case types.CLEAN_STATE:
      return defaultState.positionKnowledges;

    default:
      return positionKnowledgesState;
  }
};

const searchEmployee = (
  searchEmployeeState = defaultState.searchEmployee,
  action
) => {
  switch (action.type) {
    case types.SEARCH_EMPLOYEES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchEmployee;

    default:
      return searchEmployeeState;
  }
};
const searchCurrentEmployees = (
  searchCurrentEmployeesState = defaultState.searchCurrentEmployees,
  action
) => {
  switch (action.type) {
    case types.SEARCH_CURRENT_EMPLOYEES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchCurrentEmployees;

    default:
      return searchCurrentEmployeesState;
  }
};

const employees = (employeesState = defaultState.employees, action) => {
  switch (action.type) {
    case types.SET_EMPLOYEES:
      return action.data;

    case types.ADD_EMPLOYEE:
      const tmpEmployeesState = [...employeesState];
      tmpEmployeesState.unshift(action.data);
      return tmpEmployeesState;

    case types.UPDATE_EMPLOYEE: {
      const tmpEmployeesState = [...employeesState];
      const index = tmpEmployeesState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpEmployeesState[index] = action.data;

      return tmpEmployeesState;
    }

    case types.DELETE_EMPLOYEE: {
      const tmpRemoveEmployee = [...employeesState];
      const index = tmpRemoveEmployee.findIndex(
        ({ employeePositionId }) =>
          employeePositionId === action.employeePositionId
      );

      if (index < 0) return tmpRemoveEmployee;

      tmpRemoveEmployee.splice(index, 1);

      return tmpRemoveEmployee;
    }

    case types.CLEAN_STATE:
      return defaultState.employees;

    default:
      return employeesState;
  }
};

const searchedEmployees = (
  searchedEmployeesState = defaultState.searchedEmployees,
  action
) => {
  switch (action.type) {
    case types.SET_SEARCHED_EMPLOYEES:
      return action.data;

    case types.UPDATE_EMPLOYEE: {
      const tmpsearchedEmployeesState = [...searchedEmployeesState];
      const index = tmpsearchedEmployeesState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index < 0) return tmpsearchedEmployeesState;

      tmpsearchedEmployeesState[index] = action.data;

      return tmpsearchedEmployeesState;
    }

    case types.DELETE_EMPLOYEE: {
      const tmpRemoveEmployee = [...searchedEmployeesState];
      const index = tmpRemoveEmployee.findIndex(
        ({ employeePositionId }) =>
          employeePositionId === action.employeePositionId
      );

      if (index < 0) return tmpRemoveEmployee;

      tmpRemoveEmployee.splice(index, 1);

      return tmpRemoveEmployee;
    }

    case types.CLEAN_STATE:
      return defaultState.searchedEmployees;

    default:
      return searchedEmployeesState;
  }
};

const nationalIdDetail = (
  nationalIdDetailState = defaultState.nationalIdDetail,
  action
) => {
  switch (action.type) {
    case types.SET_NATIONAL_ID_DETAIL:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.nationalIdDetail;

    default:
      return nationalIdDetailState;
  }
};
const searchedUserDetails = (
  searchedUserDetailsState = defaultState.searchedUserDetails,
  action
) => {
  switch (action.type) {
    case types.SET_SEARCHED_USER_DETAILS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchedUserDetails;

    default:
      return searchedUserDetailsState;
  }
};

const employee = (employeeState = defaultState.employee, action) => {
  switch (action.type) {
    case types.SET_EMPLOYEE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employee;

    default:
      return employeeState;
  }
};
const salaryIndexGrid = (
  salaryIndexGridState = defaultState.salaryIndexGrid,
  action
) => {
  switch (action.type) {
    case types.SET_SALARAY_INDEX_GRID:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.salaryIndexGrid;

    default:
      return salaryIndexGridState;
  }
};

const jobClassifications = (
  jobClassificationsState = defaultState.jobClassifications,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATIONS:
      return action.data;

    case types.UPDATE_JOB_CLASSIFICATION: {
      const tmpJobClassificationsState = [...jobClassificationsState];
      const index = tmpJobClassificationsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpJobClassificationsState[index] = action.data;

      return tmpJobClassificationsState;
    }

    case types.CLEAN_STATE:
      return defaultState.jobClassifications;

    default:
      return jobClassificationsState;
  }
};

const jobClassificationAllowances = (
  jobClassificationAllowancesState = defaultState.jobClassificationAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_ALLOWANCES:
      return action.data;
    case types.ADD_JOB_CLASSIFICATION_ALLOWANCE: {
      const tmpJobClassificationAllowancesState = [
        ...jobClassificationAllowancesState,
      ];
      tmpJobClassificationAllowancesState.unshift(action.data);
      return tmpJobClassificationAllowancesState;
    }

    case types.UPDATE_JOB_CLASSIFICATION_ALLOWANCE: {
      const tmpJobClassificationAllowancesState = [
        ...jobClassificationAllowancesState,
      ];
      const index = tmpJobClassificationAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpJobClassificationAllowancesState[index] = action.data;

      return tmpJobClassificationAllowancesState;
    }

    case types.DELETE_JOB_CLASSIFICATION_ALLOWANCE:
      const tmpJobClassificationAllowancesState = [
        ...jobClassificationAllowancesState,
      ];
      const index = tmpJobClassificationAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpJobClassificationAllowancesState.splice(index, 1);

      return tmpJobClassificationAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationAllowances;

    default:
      return jobClassificationAllowancesState;
  }
};

const unitAllowances = (
  unitAllowancesState = defaultState.unitAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_UNIT_ALLOWANCES:
      return action.data;
    case types.ADD_UNIT_ALLOWANCE: {
      const tmpUnitAllowancesState = [...unitAllowancesState];
      tmpUnitAllowancesState.unshift(action.data);
      return tmpUnitAllowancesState;
    }

    case types.UPDATE_UNIT_ALLOWANCE: {
      const tmpUnitAllowancesState = [...unitAllowancesState];
      const index = tmpUnitAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpUnitAllowancesState[index] = action.data;

      return tmpUnitAllowancesState;
    }

    case types.DELETE_UNIT_ALLOWANCE:
      const tmpUnitAllowancesState = [...unitAllowancesState];
      const index = tmpUnitAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpUnitAllowancesState.splice(index, 1);

      return tmpUnitAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.unitAllowances;

    default:
      return unitAllowancesState;
  }
};
//entitySectorAllowances

const entitySectorAllowances = (
  entitySectorAllowancesState = defaultState.entitySectorAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_ALLOWANCES:
      return action.data;
    case types.ADD_ENTITY_SECTOR_ALLOWANCE: {
      const tmpEntitySectorAllowancesState = [...entitySectorAllowancesState];
      tmpEntitySectorAllowancesState.unshift(action.data);
      return tmpEntitySectorAllowancesState;
    }

    case types.DELETE_ENTITY_SECTOR_ALLOWANCE:
      const tmpEntitySectorAllowancesState = [...entitySectorAllowancesState];
      const index = tmpEntitySectorAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpEntitySectorAllowancesState.splice(index, 1);

      return tmpEntitySectorAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.unitAllowances;

    default:
      return entitySectorAllowancesState;
  }
};
//positionAllowances

const positionAllowances = (
  positionAllowancesState = defaultState.positionAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_ALLOWANCES:
      return action.data;
    case types.ADD_POSITION_ALLOWANCE: {
      const tmpPositionAllowancesState = [...positionAllowancesState];
      tmpPositionAllowancesState.unshift(action.data);
      return tmpPositionAllowancesState;
    }

    case types.DELETE_POSITION_ALLOWANCE:
      const tmpPositionAllowancesState = [...positionAllowancesState];
      const index = tmpPositionAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpPositionAllowancesState.splice(index, 1);

      return tmpPositionAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.positionAllowances;

    default:
      return positionAllowancesState;
  }
};
//jobFields

const sharedPositionAllowances = (
  sharedPositionAllowancesState = defaultState.sharedPositionAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_SHARED_POSITION_ALLOWANCES:
      return action.data;
    case types.ADD_SHARED_POSITION_ALLOWANCE: {
      const tmpSharedPositionAllowancesState = [
        ...sharedPositionAllowancesState,
      ];
      tmpSharedPositionAllowancesState.unshift(action.data);
      return tmpSharedPositionAllowancesState;
    }

    case types.DELETE_SHARED_POSITION_ALLOWANCE: {
      const tmpSharedPositionAllowancesState = [
        ...sharedPositionAllowancesState,
      ];
      const index = tmpSharedPositionAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpSharedPositionAllowancesState.splice(index, 1);

      return tmpSharedPositionAllowancesState;
    }

    case types.CLEAN_STATE:
      return defaultState.sharedPositionAllowances;

    default:
      return sharedPositionAllowancesState;
  }
};

const entitySectors = (
  entitySectorsState = defaultState.entitySectors,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTORS:
      return action.data;

    case types.UPDATE_ENTITY_SECTOR: {
      const tmpEntitySectorsState = [...entitySectorsState];
      const index = tmpEntitySectorsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpEntitySectorsState[index] = action.data;

      return tmpEntitySectorsState;
    }

    case types.CLEAN_STATE:
      return defaultState.entitySectors;

    default:
      return entitySectorsState;
  }
};
//position
const position = (positionState = defaultState.position, action) => {
  switch (action.type) {
    case types.SET_POSITION:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.position;

    default:
      return positionState;
  }
};

const unitWithholds = (
  unitWithholdsState = defaultState.unitWithholds,
  action
) => {
  switch (action.type) {
    case types.SET_UNIT_WITHHOLDS:
      return action.data;
    case types.ADD_UNIT_WITHHOLD: {
      const tmpUnitCreditorsState = [...unitWithholdsState];
      tmpUnitCreditorsState.unshift(action.data);
      return tmpUnitCreditorsState;
    }

    case types.UPDATE_UNIT_WITHHOLD: {
      const tmpUnitCreditorsState = [...unitWithholdsState];
      const index = tmpUnitCreditorsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpUnitCreditorsState[index] = action.data;

      return tmpUnitCreditorsState;
    }

    case types.DELETE_UNIT_WITHHOLD:
      const tmpUnitCreditorsState = [...unitWithholdsState];
      const index = tmpUnitCreditorsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpUnitCreditorsState.splice(index, 1);

      return tmpUnitCreditorsState;

    case types.CLEAN_STATE:
      return defaultState.unitWithholds;

    default:
      return unitWithholdsState;
  }
};

const employeeAllowances = (
  employeeAllowancesState = defaultState.employeeAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_ALLOWANCES:
      return action.data;
    case types.ADD_EMPLOYEE_ALLOWANCE: {
      const tmpEmployeeAllowancesState = [...employeeAllowancesState];
      tmpEmployeeAllowancesState.unshift(action.data);
      return tmpEmployeeAllowancesState;
    }

    case types.DELETE_EMPLOYEE_ALLOWANCE:
      const tmpEmployeeAllowancesState = [...employeeAllowancesState];
      const index = tmpEmployeeAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpEmployeeAllowancesState.splice(index, 1);

      return tmpEmployeeAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.employeeAllowances;

    default:
      return employeeAllowancesState;
  }
};

const employeeWithholds = (
  employeeWithholdsState = defaultState.employeeWithholds,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_WITHHOLDS:
      return action.data;
    case types.ADD_EMPLOYEE_WITHHOLD: {
      const tmpEmployeeWithholdsState = [...employeeWithholdsState];
      tmpEmployeeWithholdsState.unshift(action.data);
      return tmpEmployeeWithholdsState;
    }

    case types.UPDATE_EMPLOYEE_WITHHOLD: {
      const tmpEmployeeWithholdsState = [...employeeWithholdsState];
      const index = tmpEmployeeWithholdsState.findIndex(
        ({ id, creditorId, employeePositionId }) =>
          employeePositionId === action.data.employeePositionId &&
          creditorId === action.data.creditorId
      );
      tmpEmployeeWithholdsState[index] = action.data;

      return tmpEmployeeWithholdsState;
    }

    case types.DELETE_EMPLOYEE_WITHHOLD:
      const tmpEmployeeWithholdsState = [...employeeWithholdsState];

      const index = tmpEmployeeWithholdsState.findIndex(
        ({ id }) => id === action.id
      );

      console.log(index);

      if (index >= 0) tmpEmployeeWithholdsState.splice(index, 1);

      return tmpEmployeeWithholdsState;

    case types.CLEAN_STATE:
      return defaultState.employeeWithholds;

    default:
      return employeeWithholdsState;
  }
};

//jobClassificationEmployees

const jobClassificationEmployees = (
  jobClassificationEmployeesState = defaultState.jobClassificationEmployees,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_EMPOYEES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationEmployees;

    default:
      return jobClassificationEmployeesState;
  }
};

//jobClassificationQualifications

const jobClassificationQualifications = (
  jobClassificationQualificationsState = defaultState.jobClassificationQualifications,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_QUALIFICATIONS:
      return action.data;

    case types.ADD_JOB_CLASSIFICATION_QUALIFICATION:
      const tmpJobClassificationQualificationsState = [
        ...jobClassificationQualificationsState,
      ];
      tmpJobClassificationQualificationsState.unshift(action.data);
      return tmpJobClassificationQualificationsState;

    case types.DELETE_JOB_CLASSIFICATION_QUALIFICATION:
      const tmprmJobClassificationQualificationsState = [
        ...jobClassificationQualificationsState,
      ];
      const index = tmprmJobClassificationQualificationsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmprmJobClassificationQualificationsState.splice(index, 1);

      return tmprmJobClassificationQualificationsState;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationQualifications;

    default:
      return jobClassificationQualificationsState;
  }
};

//jobClassificationCompetencies
const jobClassificationCompetencies = (
  jobClassificationCompetenciesState = defaultState.jobClassificationCompetencies,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_COMPETENCIES:
      return action.data;

    case types.UPDATE_JOB_CLASSIFICATION_COMPETENCY: {
      const tmpJobClassificationCompetencies = [
        ...jobClassificationCompetenciesState,
      ];

      const index = tmpJobClassificationCompetencies.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpJobClassificationCompetencies[index] = action.data;

      return tmpJobClassificationCompetencies;
    }

    case types.DELETE_JOB_CLASSIFICATION_COMPETENCY: {
      const tmpJobClassificationCompetencies = [
        ...jobClassificationCompetenciesState,
      ];
      const index = tmpJobClassificationCompetencies.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpJobClassificationCompetencies.splice(index, 1);

      return tmpJobClassificationCompetencies;
    }

    case types.CLEAN_STATE:
      return defaultState.jobClassificationCompetencies;

    default:
      return jobClassificationCompetenciesState;
  }
};
//jobClassificationCertificates

const jobClassificationCertificates = (
  jobClassificationCertificatesStates = defaultState.jobClassificationCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_CERTIFICATES:
      return action.data;

    case types.UPDATE_JOB_CLASSIFICATION_CERTIFICATE: {
      const tmpJobClassificationCertificatesStates = [
        ...jobClassificationCertificatesStates,
      ];

      const index = tmpJobClassificationCertificatesStates.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpJobClassificationCertificatesStates[index] = action.data;

      return tmpJobClassificationCertificatesStates;
    }

    case types.DELETE_JOB_CLASSIFICATION_CERTIFICATE: {
      const tmpJobClassificationCertificatesStates = [
        ...jobClassificationCertificatesStates,
      ];
      const index = tmpJobClassificationCertificatesStates.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpJobClassificationCertificatesStates.splice(index, 1);

      return tmpJobClassificationCertificatesStates;
    }

    case types.CLEAN_STATE:
      return defaultState.jobClassificationCertificates;

    default:
      return jobClassificationCertificatesStates;
  }
};
const certificateFields = (
  certificateFieldsState = defaultState.certificateFields,
  action
) => {
  switch (action.type) {
    case types.SET_CERTIFICATE_FIELDS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.certificateFields;

    default:
      return certificateFieldsState;
  }
};

const certificates = (
  certificatesState = defaultState.certificates,
  action
) => {
  switch (action.type) {
    case types.SET_CERTIFICATES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.certificates;

    default:
      return certificatesState;
  }
};

const positionCompetencies = (
  positionCompetenciesState = defaultState.positionCompetencies,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_COMPETENCIES:
      return action.data;

    case types.ADD_POSITION_COMPETENCY: {
      const tmpPositionCompetenciesState = [...positionCompetenciesState];

      tmpPositionCompetenciesState.unshift(action.data);
      return tmpPositionCompetenciesState;
    }
    case types.REMOVE_POSITION_COMPETENCY: {
      const tmpPositionCompetenciesState = [...positionCompetenciesState];

      const index = tmpPositionCompetenciesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpPositionCompetenciesState.splice(index, 1);

      return tmpPositionCompetenciesState;
    }

    case types.CLEAN_STATE:
      return defaultState.positionCompetencies;

    default:
      return positionCompetenciesState;
  }
};

const positionQualifications = (
  positionQualificationsState = defaultState.positionQualifications,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_QUALIFICATIONS:
      return action.data;

    case types.ADD_POSITION_QUALIFICATION: {
      const tmpPositionQualificationsState = [...positionQualificationsState];

      tmpPositionQualificationsState.unshift(action.data);
      return tmpPositionQualificationsState;
    }

    case types.DELETE_POSITION_QUALIFICATION: {
      const tmpPositionQualificationsState = [...positionQualificationsState];

      const index = tmpPositionQualificationsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpPositionQualificationsState.splice(index, 1);

      return tmpPositionQualificationsState;
    }

    case types.CLEAN_STATE:
      return defaultState.positionQualifications;

    default:
      return positionQualificationsState;
  }
};

const positionCertificates = (
  positionCertificatesState = defaultState.positionCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_CERTIFICATES:
      return action.data;

    case types.ADD_POSITION_CERTIFICATE: {
      const tmpPositionCertificatesStates = [...positionCertificatesState];

      tmpPositionCertificatesStates.unshift(action.data);
      return tmpPositionCertificatesStates;
    }

    case types.REMOVE_POSITION_CERTIFICATE: {
      const tmpPositionCertificatesStates = [...positionCertificatesState];

      const index = tmpPositionCertificatesStates.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index < 0) return tmpPositionCertificatesStates;

      tmpPositionCertificatesStates.splice(index, 1);

      return tmpPositionCertificatesStates;
    }

    case types.CLEAN_STATE:
      return defaultState.positionCertificates;

    default:
      return positionCertificatesState;
  }
};

const nonAddedPositionCertificates = (
  nonAddedPositionCertificatesState = defaultState.nonAddedPositionCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_NON_ADDED_POSITION_CERTIFICATES:
      return action.data;

    case types.ADD_NON_ADDED_POSITION_CERTIFICATE: {
      const _tmpPositionCertificatesStates = [
        ...nonAddedPositionCertificatesState,
      ];
      _tmpPositionCertificatesStates.push(action.data);
      return _tmpPositionCertificatesStates;
    }
    case types.REMOVE_NON_ADDED_POSITION_CERTIFICATE: {
      const tmpPositionCertificatesStates = [
        ...nonAddedPositionCertificatesState,
      ];
      const index = tmpPositionCertificatesStates.findIndex(
        ({ id }) => id === action.data.id
      );
      if (index < 0) return tmpPositionCertificatesStates;
      tmpPositionCertificatesStates.splice(index, 1);

      return tmpPositionCertificatesStates;
    }

    case types.CLEAN_STATE:
      return defaultState.nonAddedPositionCertificates;

    default:
      return nonAddedPositionCertificatesState;
  }
};
const nonAddedPositionCompetencies = (
  nonAddedPositionCompetenciesState = defaultState.nonAddedPositionCompetencies,
  action
) => {
  switch (action.type) {
    case types.SET_NON_ADDED_POSITION_COMPETENCIES:
      return action.data;

    case types.ADD_NON_ADDED_POSITION_COMPETENCY: {
      const _tmpPositionCompetenciesStates = [
        ...nonAddedPositionCompetenciesState,
      ];
      _tmpPositionCompetenciesStates.push(action.data);
      return _tmpPositionCompetenciesStates;
    }
    case types.REMOVE_NON_ADDED_POSITION_COMPETENCY: {
      const tmpPositionCompetenciesStates = [
        ...nonAddedPositionCompetenciesState,
      ];
      const index = tmpPositionCompetenciesStates.findIndex(
        ({ id }) => id === action.data.id
      );
      if (index < 0) return tmpPositionCompetenciesStates;
      tmpPositionCompetenciesStates.splice(index, 1);

      return tmpPositionCompetenciesStates;
    }

    case types.CLEAN_STATE:
      return defaultState.nonAddedPositionCompetencies;

    default:
      return nonAddedPositionCompetenciesState;
  }
};
const sharedScopes = (
  sharedScopesState = defaultState.sharedScopes,
  action
) => {
  switch (action.type) {
    case types.SET_SHARED_SCOPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.sharedScopes;

    default:
      return sharedScopesState;
  }
};

const verifyRssbNumber = (
  verifyRssbNumberState = defaultState.verifyRssbNumber,
  action
) => {
  switch (action.type) {
    case types.SET_VERIFY_RSSB_NUMBER:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.verifyRssbNumber;

    default:
      return verifyRssbNumberState;
  }
};

const previewEmployeePaySlip = (
  previewEmployeePaySlipState = defaultState.previewEmployeePaySlip,
  action
) => {
  switch (action.type) {
    case types.PREVIEW_EMPLOYEE_PAYSLIP:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.previewEmployeePaySlip;

    default:
      return previewEmployeePaySlipState;
  }
};

const jobs = (jobsState = defaultState.jobs, action) => {
  switch (action.type) {
    case types.SET_JOBS:
      return action.data;

    case types.ADD_JOB: {
      const tmpJobsState = [...jobsState];
      tmpJobsState.push(action.data);
      return tmpJobsState;
    }

    case types.UPDATE_JOB: {
      const tmpJobsState = [...jobsState];
      const index = tmpJobsState.findIndex(({ id }) => id === action.data.id);

      if (index < 0) return jobsState;

      tmpJobsState[index] = action.data;

      return tmpJobsState;
    }

    case types.DELETE_JOB: {
      const tmpJobsState = [...jobsState];
      const index = tmpJobsState.findIndex(({ id }) => id === action.id);

      if (index < 0) return jobsState;

      tmpJobsState.splice(index, 1);

      return tmpJobsState;
    }

    case types.CLEAN_STATE:
      return defaultState.jobs;

    default:
      return jobsState;
  }
};

const jobFields = (jobFieldsState = defaultState.jobFields, action) => {
  switch (action.type) {
    case types.SET_JOB_FIELDS:
      return action.data;

    case types.ADD_JOB_FIELD: {
      const tmpjobFieldsState = [...jobFieldsState];
      tmpjobFieldsState.unshift(action.data);
      return tmpjobFieldsState;
    }

    case types.UPDATE_JOB_FIELD: {
      const tmpjobFieldsState = [...jobFieldsState];
      const index = tmpjobFieldsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index < 0) return jobFieldsState;

      tmpjobFieldsState[index] = action.data;

      return tmpjobFieldsState;
    }

    case types.DELETE_JOB_FIELD: {
      const tmpjobFieldsState = [...jobFieldsState];
      const index = tmpjobFieldsState.findIndex(({ id }) => id === action.id);

      if (index < 0) return jobFieldsState;

      tmpjobFieldsState.splice(index, 1);

      return tmpjobFieldsState;
    }

    case types.CLEAN_STATE:
      return defaultState.jobFields;

    default:
      return jobFieldsState;
  }
};

const entityClasses = (
  entityClassesState = defaultState.entityClasses,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_CLASSES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.entityClasses;

    default:
      return entityClassesState;
  }
};

const jobCategories = (
  jobCategoriesState = defaultState.jobCategories,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CATEGORIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.jobCategories;

    default:
      return jobCategoriesState;
  }
};

const employeeBankAccounts = (
  employeeBankAccountsState = defaultState.employeeBankAccounts,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_BANK_ACCOUNTS:
      return action.data;

    case types.DELETE_EMPLOYEE_BANK_ACCOUNT: {
      const tmpEmployeeBankAccountsState = [...employeeBankAccountsState];
      const index = tmpEmployeeBankAccountsState.findIndex(
        ({ id }) => id === action.id
      );

      if (index < 0) return employeeBankAccountsState;

      tmpEmployeeBankAccountsState.splice(index, 1);

      return tmpEmployeeBankAccountsState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeBankAccounts;

    default:
      return employeeBankAccountsState;
  }
};

const employeeArrears = (
  employeeArrearsState = defaultState.employeeArrears,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_ARREARS:
      return action.data;

    case types.ADD_EMPLOYEE_ARREAR: {
      const tmpEmployeeArrearsState = [...employeeArrearsState];
      tmpEmployeeArrearsState.unshift(action.data);

      return tmpEmployeeArrearsState;
    }

    case types.DELETE_EMPLOYEE_ARREAR: {
      const tmpEmployeeArrearsState = [...employeeArrearsState];
      const index = tmpEmployeeArrearsState.findIndex(
        ({ id }) => id === action.id
      );

      if (index < 0) return employeeArrearsState;

      tmpEmployeeArrearsState.splice(index, 1);

      return tmpEmployeeArrearsState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeArrears;

    default:
      return employeeArrearsState;
  }
};

const periods = (periodsState = defaultState.periods, action) => {
  switch (action.type) {
    case types.SET_PERIODS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.periods;

    default:
      return periodsState;
  }
};

const employeeProfile = (
  employeeProfileState = defaultState.employeeProfile,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_PROFILE:
      return action.data;

    case types.SET_EMPLOYEE_NEXT_OF_KIN: {
      const tmpState = { ...employeeProfileState };
      tmpState.nextOfKin = action.data;
      return tmpState;
    }

    case types.ADD_EMPLOYEE_EMPLOYMENT: {
      const tmpState = { ...employeeProfileState };

      let tmpEmployments = [];

      if (!!tmpState.employments) {
        tmpEmployments = [...tmpState.employments];
      }

      tmpEmployments.push(action.data);

      tmpState.employments = tmpEmployments;

      return tmpState;
    }

    case types.REMOVE_EMPLOYEE_EMPLOYMENT: {
      const tmpState = { ...employeeProfileState };

      const tmpEmployments = [...tmpState.employments];

      const index = tmpEmployments.findIndex(({ id }) => id === action.id);

      tmpEmployments.splice(index, 1);

      tmpState.employments = tmpEmployments;

      return tmpState;
    }

    case types.SET_EMPLOYEE_QUALIFICATIONS: {
      const tmpState = { ...employeeProfileState };
      tmpState.qualifications = action.data;
      return tmpState;
    }

    case types.ADD_EMPLOYEE_QUALIFICATION: {
      const tmpState = { ...employeeProfileState };

      let tmpQualifications = [];

      if (!!tmpState.qualifications) {
        tmpQualifications = [...tmpState.qualifications];
      }

      tmpQualifications.push(action.data);

      tmpState.qualifications = tmpQualifications;

      return tmpState;
    }

    case types.REMOVE_EMPLOYEE_QUALIFICATION: {
      const tmpState = { ...employeeProfileState };

      const tmpQualifications = [...tmpState.qualifications];

      const index = tmpQualifications.findIndex(({ id }) => id === action.id);

      tmpQualifications.splice(index, 1);

      tmpState.qualifications = tmpQualifications;

      return tmpState;
    }

    case types.SET_EMPLOYEE_CERTIFICATES: {
      const tmpState = { ...employeeProfileState };
      tmpState.certificates = action.data;
      return tmpState;
    }

    case types.ADD_EMPLOYEE_CERTIFICATE: {
      const tmpState = { ...employeeProfileState };

      let tmpCertificates = [];

      if (!!tmpState.certificates) {
        tmpCertificates = [...tmpState.certificates];
      }

      tmpCertificates.push(action.data);

      tmpState.certificates = tmpCertificates;

      return tmpState;
    }

    case types.REMOVE_EMPLOYEE_CERTIFICATE: {
      const tmpState = { ...employeeProfileState };

      const tmpCertificates = [...tmpState.certificates];

      const index = tmpCertificates.findIndex(({ id }) => id === action.id);

      tmpCertificates.splice(index, 1);

      tmpState.certificates = tmpCertificates;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeProfile;

    default:
      return employeeProfileState;
  }
};

const employeeQualifications = (
  employeeQualificationState = defaultState.employeeQualifications,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_QUALIFICATIONS:
      return action.data;

    case types.ADD_EMPLOYEE_QUALIFICATION: {
      const tmpQualifications = [...employeeQualificationState];

      tmpQualifications.push(action.data);
      return tmpQualifications;
    }
    case types.UPDATE_EMPLOYEE_QUALIFICATION: {
      const tmpQualifications = [...employeeQualificationState];
      const index = tmpQualifications.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpQualifications[index] = action.data;

      return tmpQualifications;
    }
    case types.REMOVE_EMPLOYEE_QUALIFICATION: {
      const tmpQualifications = [...employeeQualificationState];

      const index = tmpQualifications.findIndex(({ id }) => id === action.id);

      tmpQualifications.splice(index, 1);

      return tmpQualifications;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeQualifications;

    default:
      return employeeQualificationState;
  }
};

const employeeCertificates = (
  employeeCertificatesState = defaultState.employeeCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_CERTIFICATES:
      return action.data;

    case types.ADD_EMPLOYEE_CERTIFICATE: {
      const tmpCertificates = [...employeeCertificatesState];

      tmpCertificates.push(action.data);
      return tmpCertificates;
    }
    case types.UPDATE_EMPLOYEE_CERTIFICATE: {
      const tmpCertificates = [...employeeCertificatesState];
      const index = tmpCertificates.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpCertificates[index] = action.data;

      return tmpCertificates;
    }
    case types.REMOVE_EMPLOYEE_CERTIFICATE: {
      const tmpCertificates = [...employeeCertificatesState];

      const index = tmpCertificates.findIndex(({ id }) => id === action.id);

      tmpCertificates.splice(index, 1);

      return tmpCertificates;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeCertificates;

    default:
      return employeeCertificatesState;
  }
};

const relationships = (
  relationshipsState = defaultState.relationships,
  action
) => {
  switch (action.type) {
    case types.SET_RELATIONSHIPS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.relationships;

    default:
      return relationshipsState;
  }
};

const countries = (countriesState = defaultState.countries, action) => {
  switch (action.type) {
    case types.SET_COUNTRIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.countries;

    default:
      return countriesState;
  }
};

const schools = (schoolsState = defaultState.schools, action) => {
  switch (action.type) {
    case types.SET_SCHOOLS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.schools;

    default:
      return schoolsState;
  }
};

const entitiesOversights = (
  entitiesOversightsState = defaultState.entitiesOversights,
  action
) => {
  let tmpEntitiesOversightsState = [],
    index = -1;
  switch (action.type) {
    case types.SET_ENTITIES_OVERSIGHTS:
      return action.data;

    case types.UPDATE_ENTITIES_OVERSIGHTS: {
      tmpEntitiesOversightsState = [...entitiesOversightsState];
      index = tmpEntitiesOversightsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpEntitiesOversightsState[index] = action.data;

      return tmpEntitiesOversightsState;
    }

    case types.CHANGE_ENTITY_STATUS_OVERSIGHT: {
      tmpEntitiesOversightsState = [...entitiesOversightsState];
      index = tmpEntitiesOversightsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpEntitiesOversightsState[index] = {
        ...tmpEntitiesOversightsState[index],
        endedOn: action.data.action === "open" ? null : new Date(),
        endedBy: null,
      };
      return tmpEntitiesOversightsState;
    }

    case types.CLEAN_STATE:
      return defaultState.entitiesOversights;

    default:
      return entitiesOversightsState;
  }
};

const organigramData = (
  organigramDataState = defaultState.organigramData,
  action
) => {
  switch (action.type) {
    case types.SET_ORGANIGRAM_DATA:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.organigramData;

    default:
      return organigramDataState;
  }
};

const paymentStatuses = (
  paymentStatusesState = defaultState.paymentStatuses,
  action
) => {
  switch (action.type) {
    case types.SET_PAYMENT_STATUSES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.paymentStatuses;

    default:
      return paymentStatusesState;
  }
};

const entities = (entitiesState = defaultState.entities, action) => {
  switch (action.type) {
    case types.SET_ENTITIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.entities;

    default:
      return entitiesState;
  }
};

const employeesPullRequests = (
  employeesPullRequestsState = defaultState.employeesPullRequests,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEES_PULL_REQUESTS:
      return action.data;

    case types.UPDATE_EMPLOYEE: {
      const tmpState = [...employeesPullRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0)
        tmpState[index] = {
          ...tmpState[index],
          email: action.data.email,
          phoneNumber: action.data.phoneNumber,
          professionalEmail: action.data.professionalEmail,
        };

      return tmpState;
    }

    case types.REMOVE_EMPLOYEE_PULL_REQUEST: {
      const tmpState = [...employeesPullRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeesPullRequests;

    default:
      return employeesPullRequestsState;
  }
};

const employments = (employmentsState = defaultState.employments, action) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_EMPLOYMENTS:
      return action.data;

    case types.ADD_EMPLOYEE_EMPLOYMENT: {
      const tmpEmployments = [...employmentsState];

      tmpEmployments.push(action.data);
      return tmpEmployments;
    }

    case types.UPDATE_EMPLOYEE_EMPLOYMENT: {
      const tmpEmployments = [...employmentsState];
      const index = tmpEmployments.findIndex(
        ({ employmentId }) => employmentId === action.data.employmentId
      );
      tmpEmployments[index] = action.data;

      return tmpEmployments;
    }

    case types.REMOVE_EMPLOYEE_EMPLOYMENT: {
      const tmpEmployments = [...employmentsState];

      const index = tmpEmployments.findIndex(
        ({ employmentId }) => employmentId === action.id
      );

      tmpEmployments.splice(index, 1);

      return tmpEmployments;
    }

    case types.CLEAN_STATE:
      return defaultState.employments;

    default:
      return employmentsState;
  }
};

const employeeDisabilities = (
  employeeDisabilitiesState = defaultState.employeeDisabilities,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_DISABILITIES:
      return action.data;

    case types.ADD_EMPLOYEE_DISABILITY: {
      const tmpState = [...employeeDisabilitiesState];

      tmpState.push(action.data);

      return tmpState;
    }

    case types.UPDATE_EMPLOYEE_DISABILITY: {
      const tmpState = [...employeeDisabilitiesState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      tmpState[index] = action.data;

      return tmpState;
    }

    case types.REMOVE_EMPLOYEE_DISABILITY: {
      const tmpState = [...employeeDisabilitiesState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeDisabilities;

    default:
      return employeeDisabilitiesState;
  }
};

const disabilityLevels = (
  disabilityLevelsState = defaultState.disabilityLevels,
  action
) => {
  switch (action.type) {
    case types.SET_DISABILITY_LEVELS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.disabilityLevels;

    default:
      return disabilityLevelsState;
  }
};
const disabilities = (
  disabilitiesState = defaultState.disabilities,
  action
) => {
  switch (action.type) {
    case types.SET_DISABILITY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.disabilities;

    default:
      return disabilitiesState;
  }
};
const languages = (languagesState = defaultState.languages, action) => {
  switch (action.type) {
    case types.SET_LANGUAGES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.languages;

    default:
      return languagesState;
  }
};

const employeeReferences = (
  employeeReferencesState = defaultState.employeeReferences,
  action
) => {
  switch (action.type) {
    case types.SET_REFERENCES:
      return action.data;

    case types.ADD_REFERENCE: {
      const tmpState = [...employeeReferencesState];
      //

      tmpState.push(action.data);

      return tmpState;
    }

    case types.UPDATE_REFERENCE: {
      const tmpState = [...employeeReferencesState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      tmpState[index] = action.data;

      return tmpState;
    }

    case types.REMOVE_REFERENCE: {
      const tmpState = [...employeeReferencesState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeReferences;

    default:
      return employeeReferencesState;
  }
};
//employeeLanguages

const employeeLanguages = (
  employeeLanguagesState = defaultState.employeeLanguages,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_LANGUAGES:
      return action.data;

    case types.ADD_EMPLOYEE_LANGUAGE: {
      const tmpState = [...employeeLanguagesState];
      //

      tmpState.push(action.data);

      return tmpState;
    }
    case types.UPDATE_EMPLOYEE_LANGUAGE: {
      const tmpState = [...employeeLanguagesState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      tmpState[index] = action.data;

      return tmpState;
    }

    case types.REMOVE_EMPLOYEE_LANGUAGE: {
      const tmpState = [...employeeLanguagesState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeLanguages;

    default:
      return employeeLanguagesState;
  }
};

const userProfile = (userProfileState = defaultState.userProfile, action) => {
  switch (action.type) {
    case types.SET_USER_PROFILE:
      return action.data;

    case types.UPDATE_EMPLOYEE: {
      const tmpUserProfileState = {
        ...userProfileState,
        professionalEmail: action.data.professionalEmail,
      };

      return tmpUserProfileState;
    }

    case types.CLEAN_STATE:
      return defaultState.userProfile;

    default:
      return userProfileState;
  }
};
const employeeNexKin = (
  employeeNexKinState = defaultState.employeeNexKin,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_NEXT_KEN:
      return action.data;

    case types.SET_EMPLOYEE_NEXT_OF_KIN: {
      const tmpState = { ...employeeNexKinState };
      tmpState.nextOfKin = action.data;
      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeNexKin;

    default:
      return employeeNexKinState;
  }
};

const headOfInstitution = (
  headOfInstitutionState = defaultState.headOfInstitution,
  action
) => {
  switch (action.type) {
    case types.SET_HEAD_OF_INSTITUTION:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.headOfInstitution;

    default:
      return headOfInstitutionState;
  }
};
const missingEmployeeDataSummary = (
  missingEmployeeDataSummaryState = defaultState.missingEmployeeDataSummary,
  action
) => {
  switch (action.type) {
    case types.SET_MISSING_EMPLOYEE_DATA_SUMMARY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.missingEmployeeDataSummary;

    default:
      return missingEmployeeDataSummaryState;
  }
};
const missingEmployeeData = (
  missingEmployeeDataState = defaultState.missingEmployeeData,
  action
) => {
  switch (action.type) {
    case types.SET_MISSING_EMPLOYEE_DATA:
      return action.data;

    case types.UPDATE_EMPLOYEE: {
      const tmpState = [...missingEmployeeDataState];

      const index = tmpState.findIndex(
        ({ id, employeeId }) =>
          id === action.id ||
          employeeId === action.id ||
          employeeId === action.employeeId
      );

      tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.missingEmployeeData;

    default:
      return missingEmployeeDataState;
  }
};

const rraGrades = (rraGradesState = defaultState.rraGrades, action) => {
  switch (action.type) {
    case types.SET_RRA_GRADES:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.rraGrades;
    default:
      return rraGradesState;
  }
};

const rraDepartments = (
  rraDepartmentsState = defaultState.rraDepartments,
  action
) => {
  switch (action.type) {
    case types.SET_RRA_DEPARTMENTS:
      return action.data;

    case types.ADD_OR_UPDATE_RRA_DEPARTMENT: {
      const tmpRRADepartments = [...rraDepartmentsState];

      const index = tmpRRADepartments.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpRRADepartments[index] = action.data;
      else if (!!action?.data?.id) tmpRRADepartments.push(action.data);

      return tmpRRADepartments;
    }

    case types.DELETE_RRA_DEPARTMENT: {
      const tmpRRADepartments = [...rraDepartmentsState];
      const index = rraDepartmentsState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpRRADepartments.splice(index, 1);

      return tmpRRADepartments;
    }

    case types.CLEAN_STATE:
      return defaultState.rraDepartments;
    default:
      return rraDepartmentsState;
  }
};

const noOfEmployeeRequests = (
  noOfEmployeeRequestsState = defaultState.noOfEmployeeRequests,
  action
) => {
  switch (action.type) {
    case types.SET_NO_OF_EMPLOYEE_REQUESTS:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.noOfEmployeeRequests;

    default:
      return noOfEmployeeRequestsState;
  }
};
const employeeRequests = (
  employeeRequestsState = defaultState.employeeRequests,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_REQUESTS:
      return action.data;

    case types.UPDATE_EMPLOYEE_REQUEST: {
      const tmpState = [...employeeRequestsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      tmpState[index] = action.data;

      return tmpState;
    }
    case types.CLEAN_STATE:
      return defaultState.employeeRequests;

    default:
      return employeeRequestsState;
  }
};

export default {
  headOfInstitution,
  employeesPullRequests,
  entities,
  entitiesOversights,
  schools,
  countries,
  units,
  treeUnits,
  unitTypes,
  positions,
  selectedUnit,
  searchUnit,
  searchPosition,
  searchCurrentPositions,
  salaryStructures,
  employeeGroups,
  hiringModes,
  echelons,
  levels,
  positionEmployees,
  supervisors,
  positionQualifications,
  profileDegrees,
  qualifications,
  professionalCertificates,
  competencies,
  certificateTypes,
  professionalCertificateTypes,
  positionKnowledges,
  banks,
  medicalInsuranceTypes,
  searchEmployee,
  searchCurrentEmployees,
  employees,
  nationalIdDetail,
  employee,
  salaryIndexGrid,
  jobClassifications,
  jobClassificationAllowances,
  unitAllowances,
  unitWithholds,
  jobFields,
  entitySectors,
  vJobClassifications,
  position,
  positionAllowances,
  employeeAllowances,
  employeeWithholds,
  jobClassificationEmployees,
  jobClassificationQualifications,
  jobClassificationCompetencies,
  certificateFields,
  certificates,
  jobClassificationCertificates,
  positionCompetencies,
  positionCertificates,
  entitySectorAllowances,
  sharedScopes,
  entitySectorPositions,
  sharedPositionAllowances,
  organigramData,
  verifyRssbNumber,
  previewEmployeePaySlip,
  allowanceTypes,
  jobs,
  entityClasses,
  jobCategories,
  employeeBankAccounts,
  employeeArrears,
  periods,
  employeeProfile,
  relationships,
  employeePromotions,
  searchedEmployees,
  paymentStatuses,
  employments,
  employeeQualifications,
  employeeCertificates,
  employeeDisabilities,
  disabilityLevels,
  disabilities,
  languages,
  employeeReferences,
  employeeLanguages,
  userProfile,
  employeeNexKin,
  searchedUserDetails,
  missingEmployeeDataSummary,
  missingEmployeeData,
  nonAddedPositionCertificates,
  nonAddedPositionCompetencies,
  rraGrades,
  rraDepartments,
  noOfEmployeeRequests,
  employeeRequests,
};
