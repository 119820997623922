import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import { IconButton, Tooltip } from "@mui/material";

import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";

import { getApplicantSessionLogs } from "../../../store/psychometric/actions";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import AlertDialog from "../../common/components/AlertDialog";
import NoRecordMessage from "../../common/components/NoRecordMessage";

function LiveCandidateLogs({
  psychometricTestSessionId,
  getApplicantSessionLogs,
  applicantSessionLogs,
}) {
  useEffect(() => {
    getApplicantSessionLogs(psychometricTestSessionId);
  }, [psychometricTestSessionId]);

  useEffect(() => {
    const timer = setInterval(() => {
      getApplicantSessionLogs(psychometricTestSessionId);
    }, 5000);

    return () => clearInterval(timer);
  }, [psychometricTestSessionId]);

  return (
    <div className="text-center ">
      <Typography className="font-weight-bold text-primary mb-1">
        Logs
      </Typography>

      {!!applicantSessionLogs?.length && (
        <Timeline>
          {applicantSessionLogs.map((log) => (
            <LogItem key={log.id} log={log} />
          ))}
        </Timeline>
      )}
      <NoRecordMessage
        isEmpty={!applicantSessionLogs?.length}
        title="No record found. "
      />
    </div>
  );
}

const mapStateToProps = ({ user, loading, applicantSessionLogs }) => {
  return {
    user,
    loading,
    applicantSessionLogs,
  };
};
export default connect(mapStateToProps, {
  getApplicantSessionLogs,
})(LiveCandidateLogs);

const LogItem = ({ log }) => {
  const [logImage, setLogImage] = useState("");
  const [showlogImage, setShowLogImage] = useState(false);

  const dispatch = useDispatch();

  const getlogImage = async (logId) => {
    try {
      dispatch({ type: "START_LOADING" });
      const { data } = await axios.get("/api/psy/log-image/" + logId);
      dispatch({ type: "END_LOADING" });

      setLogImage(data.applicantImage);
      setShowLogImage(true);
    } catch (error) {}
  };

  return (
    <>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          <small>{moment(log.createdOn).format("lll")}</small>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot
            color={
              ["LOCKED"].includes(log.action)
                ? "error"
                : ["WARNING"].includes(log.action)
                ? "warning"
                : "info"
            }
          >
            <span className="material-icons" style={{ fontSize: "12px" }}>
              adjust
            </span>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography
            component="p"
            variant="body2"
            className="btn-link cursor-pointer"
          >
            {log.action}
            {!!log.hasImage && (
              <Tooltip title="View log image" className="ml-1">
                <IconButton onClick={() => getlogImage(log.id)}>
                  <PhotoSizeSelectActualIcon />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          <Typography
            component="div"
            variant="body2"
            className="lead"
            sx={{ color: "text.secondary" }}
          >
            <small>{log.comment}</small>
          </Typography>
        </TimelineContent>
      </TimelineItem>

      {showlogImage && (
        <AlertDialog
          showAlert={showlogImage}
          setShowAlert={setShowLogImage}
          maxWidth="sm"
          children={
            <div className="text-center">
              <div>{log.action}</div>
              {!!logImage ? (
                <img
                  src={`data:image/png;base64,${logImage}`}
                  className="img-fluid border rounded"
                  alt="default Image"
                  style={{
                    height: "250px",
                  }}
                />
              ) : (
                <span>No image found</span>
              )}
            </div>
          }
        />
      )}
    </>
  );
};
