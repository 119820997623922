import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useRequiredAuth } from "./requiredAuth";

function PrivateRoute(props) {
  const { isAuth, hasPermissions } = useRequiredAuth();
  if (!isAuth()) return <Redirect to="/" />;
  if (!hasPermissions(props.permissions))
    return <Redirect to="/unauthorized" />;
  return <Route {...props} />;
}

export default PrivateRoute;
