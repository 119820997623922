import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { editPositionRoles } from "../../../store/structure/actions";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
const PositionRolesDialog = (props) => {
  const {
    loading,
    isEntitySectorLocked,
    selectedPosition,
    showPositionRolesModel,
    setShowPositionRolesModel,
    editPositionRoles,
    user,
  } = props;
  //  console.log(selectedPosition);

  const { hasPermissions } = useRequiredAuth();

  return (
    <Dialog
      onClose={() => setShowPositionRolesModel(false)}
      aria-labelledby="customized-dialog-title"
      open={showPositionRolesModel}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="text-dark">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          Position Roles
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setShowPositionRolesModel(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>

        <Divider className="my-1" />
      </DialogTitle>

      <DialogContent>
        <div
          className="elevated rounded p-3 "
          style={{
            minHeight: "50vh",
            backgroundColor: "#fafafa",
            border: "1px solid #17a2b8",
          }}
        >
          <h6 className="mb-2">Roles</h6>

          <FormGroup row>
            {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
              <FormControlLabel
                disabled={
                  isEntitySectorLocked ||
                  loading ||
                  selectedPosition.isHeadOfInstitution ||
                  selectedPosition.isShared
                }
                control={
                  <Checkbox
                    checked={selectedPosition.isHeadOfInstitution}
                    onChange={(e) => {
                      editPositionRoles(
                        {
                          positionId: selectedPosition.id,
                          role: "isHeadOfInstitution",
                          isShared: selectedPosition.isShared,
                          active: e.target.checked,
                        },
                        setShowPositionRolesModel
                      );
                    }}
                  />
                }
                label="Head Of Institution"
              />
            )}
            {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
              <FormControlLabel
                disabled={
                  isEntitySectorLocked || loading || selectedPosition.isShared

                  // || selectedPosition.isTechnicalHead
                }
                control={
                  <Checkbox
                    checked={selectedPosition.isTechnicalHead}
                    onChange={(e) => {
                      editPositionRoles(
                        {
                          positionId: selectedPosition.id,
                          role: "isTechnicalHead",
                          isShared: selectedPosition.isShared,
                          active: e.target.checked,
                        },
                        setShowPositionRolesModel
                      );
                    }}
                  />
                }
                label="Technical Head"
              />
            )}

            <FormControlLabel
              disabled={isEntitySectorLocked || loading}
              control={
                <Checkbox
                  checked={selectedPosition.isHeadOfUnit}
                  onChange={(e) => {
                    editPositionRoles(
                      {
                        positionId: selectedPosition.id,
                        role: "isHeadOfUnit",
                        isShared: selectedPosition.isShared,
                        active: e.target.checked,
                      },
                      setShowPositionRolesModel
                    );
                  }}
                />
              }
              label="Head Of Unit"
            />

            {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
              <FormControlLabel
                disabled={
                  isEntitySectorLocked || loading
                  //  || selectedPosition.isCBM
                }
                control={
                  <Checkbox
                    checked={selectedPosition.isCBM}
                    onChange={(e) => {
                      editPositionRoles(
                        {
                          positionId: selectedPosition.id,
                          role: "isCBM",
                          isShared: selectedPosition.isShared,
                          active: e.target.checked,
                        },
                        setShowPositionRolesModel
                      );
                    }}
                  />
                }
                label="CBM"
              />
            )}

            {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
              <FormControlLabel
                disabled={
                  isEntitySectorLocked || loading
                  //  || selectedPosition.isDAF
                }
                control={
                  <Checkbox
                    checked={selectedPosition.isDAF}
                    onChange={(e) => {
                      editPositionRoles(
                        {
                          positionId: selectedPosition.id,
                          role: "isDAF",
                          isShared: selectedPosition.isShared,
                          active: e.target.checked,
                        },
                        setShowPositionRolesModel
                      );
                    }}
                  />
                }
                label="DAF"
              />
            )}

            {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
              <FormControlLabel
                disabled={isEntitySectorLocked || loading}
                control={
                  <Checkbox
                    checked={selectedPosition.isHR}
                    onChange={(e) => {
                      editPositionRoles(
                        {
                          positionId: selectedPosition.id,
                          role: "isHR",
                          isShared: selectedPosition.isShared,
                          active: e.target.checked,
                        },
                        setShowPositionRolesModel
                      );
                    }}
                  />
                }
                label="HR"
              />
            )}

            {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
              <FormControlLabel
                disabled={isEntitySectorLocked || loading}
                control={
                  <Checkbox
                    checked={selectedPosition.isPlanner}
                    onChange={(e) => {
                      editPositionRoles(
                        {
                          positionId: selectedPosition.id,
                          role: "isPlanner",
                          isShared: selectedPosition.isShared,
                          active: e.target.checked,
                        },
                        setShowPositionRolesModel
                      );
                    }}
                  />
                }
                label="Planner"
              />
            )}
            {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
              <FormControlLabel
                disabled={isEntitySectorLocked || loading}
                control={
                  <Checkbox
                    checked={selectedPosition.isRBMContractInitiator}
                    onChange={(e) => {
                      editPositionRoles(
                        {
                          positionId: selectedPosition.id,
                          role: "isRBMContractInitiator",
                          isShared: selectedPosition.isShared,
                          active: e.target.checked,
                        },
                        setShowPositionRolesModel
                      );
                    }}
                  />
                }
                label="RBM Contract Initiator"
              />
            )}
            {!!user.selectedEntitySector.canUseTrainingModule &&
              hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedPosition.isTrainingInitiator}
                      onChange={(e) => {
                        editPositionRoles(
                          {
                            positionId: selectedPosition.id,
                            role: "isTrainingInitiator",
                            isShared: selectedPosition.isShared,
                            active: e.target.checked,
                          },
                          setShowPositionRolesModel
                        );
                      }}
                    />
                  }
                  label="Training Initiator"
                />
              )}
            {!!user.selectedEntitySector.canUseTrainingModule &&
              hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedPosition.isTrainingMinistryReviewer}
                      onChange={(e) => {
                        editPositionRoles(
                          {
                            positionId: selectedPosition.id,
                            role: "isTrainingMinistryReviewer",
                            isShared: selectedPosition.isShared,
                            active: e.target.checked,
                          },
                          setShowPositionRolesModel
                        );
                      }}
                    />
                  }
                  label="Institution - Training reviewer"
                />
              )}

            {!!user.selectedEntitySector.canUseTrainingModule &&
              hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedPosition.isTrainingMinistryVerifier}
                      onChange={(e) => {
                        editPositionRoles(
                          {
                            positionId: selectedPosition.id,
                            role: "isTrainingMinistryVerifier",
                            isShared: selectedPosition.isShared,
                            active: e.target.checked,
                          },
                          setShowPositionRolesModel
                        );
                      }}
                    />
                  }
                  label="Institution - Training verifier"
                />
              )}

            {!!user.selectedEntitySector.canUseTrainingModule &&
              hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      disabled
                      checked={
                        selectedPosition.isTrainingMinistryDelegatedApprover
                      }
                      onChange={(e) => {
                        editPositionRoles(
                          {
                            positionId: selectedPosition.id,
                            role: "isTrainingMinistryDelegatedApprover",
                            isShared: selectedPosition.isShared,
                            active: e.target.checked,
                          },
                          setShowPositionRolesModel
                        );
                      }}
                    />
                  }
                  label="Institution - Training delegated approver"
                />
              )}

            {!!user.selectedEntitySector.canUseTrainingModule &&
              user.selectedEntitySector.id ===
                "2000000000-010200000000000000-02" && (
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      disabled
                      checked={
                        selectedPosition.isTrainingMifotraDelegatedApprover
                      }
                      onChange={(e) => {
                        editPositionRoles(
                          {
                            positionId: selectedPosition.id,
                            role: "isTrainingMifotraDelegatedApprover",
                            isShared: selectedPosition.isShared,
                            active: e.target.checked,
                          },
                          setShowPositionRolesModel
                        );
                      }}
                    />
                  }
                  label="Mifotra - Training delegated approver"
                />
              )}
          </FormGroup>
        </div>
      </DialogContent>

      {/* <DialogActions className="py-4"></DialogActions> */}
    </Dialog>
  );
};

const mapStateToProps = ({ loading, user, onWait }) => {
  return {
    user,
    loading,
    onWait,
  };
};
export default connect(mapStateToProps, { editPositionRoles })(
  PositionRolesDialog
);
