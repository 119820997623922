import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Switch,
  ListItemAvatar,
  Avatar,
} from "@mui/material";

import ReactPaginate from "react-paginate";
import SearchBox from "../../common/components/SearchBox";
import { saveJcCertificate } from "../../../store/structure/actions";
const JobClassificationCertificates = (props) => {
  const {
    loading,
    onWait,
    jobClassificationId,
    jobClassificationCertificates,
    getJobClassificationCertificates,
    saveJcCertificate,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [
    filteredJobClassificationCertificates,
    setFilteredJobClassificationCertificates,
  ] = useState([...jobClassificationCertificates]);

  useEffect(() => {
    const filtered = jobClassificationCertificates.filter(({ name }) =>
      name
        .toLowerCase()
        .replace(/ /g, "")
        .includes(searchTerm.toLowerCase().replace(/ /g, ""))
    );

    setFilteredJobClassificationCertificates(filtered);

    paginate(filtered);
  }, [searchTerm, jobClassificationCertificates]);

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredJobClassificationCertificates);
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
        filteredJobClassificationCertificates.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (idToSearch) =>
    jobClassificationCertificates.findIndex(({ id }) => id === idToSearch);

  return (
    <>
      <SearchBox disabled={false} onSearch={onSearch} placeholder="Search…" />
      <List
        className="mt-2"
        sx={{
          borderRadius: "8px",
          border: `1px solid #c8c6c6`,
          backgroundColor: "#f9f9f9",
        }}
        dense
      >
        {!!paginatedItems &&
          paginatedItems.map((certificate, index) => (
            <React.Fragment key={certificate.id}>
              <ListItem dense>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      fontSize: "16px",
                      border: "1px solid #c8c6c6",
                    }}
                    className="bg-white text-dark "
                  >
                    {getNo(certificate.id) + 1}
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <span
                      className={`${
                        !certificate.added
                          ? "font-weight-light"
                          : "font-weight-bold"
                      }`}
                    >
                      {certificate.name}
                    </span>
                  }
                  secondary={
                    <small
                      className={`${
                        !certificate.added
                          ? "font-weight-light"
                          : "font-weight-bold"
                      }`}
                    >
                      {certificate.certificateFieldName}
                    </small>
                  }
                />
                <Switch
                  disabled={loading}
                  edge="end"
                  onChange={(e) => {
                    saveJcCertificate({
                      jobClassificationId,
                      certificateId: certificate.id,
                      added: e.target.checked,
                    });
                  }}
                  checked={certificate.added}
                />
              </ListItem>

              {paginatedItems.length > index + 1 && <Divider />}
            </React.Fragment>
          ))}
      </List>

      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <span>
            <span className="pr-1 d-none d-md-inline">Next</span>
            <i className="fas fa-angle-double-right"></i>
          </span>
        }
        previousLabel={
          <span>
            <i className="fas fa-angle-double-left"></i>
            <span className="pl-1 d-none d-md-inline">Previous</span>
          </span>
        }
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination mt-2"
        pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
        previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
        nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
        activeLinkClassName="active"
      />
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  jobClassificationCertificates,
  // certificates,
}) => {
  return {
    loading,
    onWait,
    jobClassificationCertificates,
    // certificates,
  };
};
export default connect(mapStateToProps, {
  saveJcCertificate,
})(JobClassificationCertificates);
