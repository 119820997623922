import React from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import UserResume from "../../recruitment/components/profile/UserResume";

const ApplicationCv = (props) => {
  const {
    showApplicationCv,
    setShowApplicationCv,
    application,
    dialogActionContents,
  } = props;

  const onClose = () => {
    setShowApplicationCv(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showApplicationCv}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <BadgeIcon className="mb-2 mr-2 text-dark" />
            <span className="text-dark">
              {application?.firstName || ""}'s <strong className="text-primary">Profile</strong>
            </span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ height: "650px" }}>
          <UserResume application={application} />
        </DialogContent>
        {dialogActionContents ? (
          <DialogActions className="d-flex justify-content-center py-2">
            {dialogActionContents}
          </DialogActions>
        ) : (
          <DialogActions></DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ApplicationCv;
