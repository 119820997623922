import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  Autocomplete,
  TextField,
  DialogActions,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
// import { setAppeal } from "../../../store/e-appeal/actions";

import {
  getInProgressAppeals,
  getArchivedAppeals,
  getOverdueAppeals,
  confirmFLAppealDecision,
  confirmSLAppealDecision,
  confirmOverdueFLAppealDecision,
  onForwardBackAppeal,
} from "../../../store/recruitment/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import ReactPaginate from "react-paginate";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import ViewAppealDialog from "../components/ViewAppealDialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import TableSkeleton from "../../common/components/TableSkeleton";

const Appeals = (props) => {
  const {
    appealCategory,
    getInProgressAppeals,
    getArchivedAppeals,
    getOverdueAppeals,
    appeals,
    setAppeal,
    selectedAdvertisement,
    confirmFLAppealDecision,
    confirmSLAppealDecision,
    confirmOverdueFLAppealDecision,
    onClose,
    onForwardBackAppeal,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [viewAppeal, setViewAppeal] = useState(false);
  const { hasPermissions } = useRequiredAuth();
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  const theme = useTheme();
  const [filteredAppeals, setFilteredAppeals] = useState([...appeals]);
  const [filterTypes, setFilterTypes] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [confirmDecision, setConfirmDecision] = useState(false);
  const [showForwardBackAppeals, setShowForwardBackAppeals] = useState(false);
  const [reason, setReason] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  useEffect(
    () => {
      if (appealCategory === "InProgressAppeals")
        getInProgressAppeals(selectedAdvertisement.id, setShowLoader);
      if (appealCategory === "ArchivedAppeals")
        getArchivedAppeals(selectedAdvertisement.id, setShowLoader);

      if (appealCategory === "OverdueAppeals")
        getOverdueAppeals(selectedAdvertisement.id, setShowLoader);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appealCategory, selectedAdvertisement]
  );

  useEffect(() => {
    const filtered = [...appeals].filter(
      ({
        message,
        subject,
        appealTypeName,
        statusName,
        applicantName,
        applicationId,
      }) =>
        (message && message.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (subject && subject.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (applicantName &&
          applicantName.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (applicationId &&
          applicationId.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (appealTypeName &&
          appealTypeName.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (statusName &&
          statusName.toLowerCase().includes(searchTeam.toLowerCase()))
    );

    setFilteredAppeals(filtered);
  }, [searchTeam, appeals]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredAppeals);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, appeals, filteredAppeals]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredAppeals.length || 0;

    setItemOffset(newOffset);
  };

  const filterAppealTypes = () => {
    let tempFilteredAppealTypes = [];
    appeals.forEach((appeal) => {
      if (
        !tempFilteredAppealTypes.find(
          ({ name }) => name === appeal.appealTypeName
        )
      )
        tempFilteredAppealTypes.push({
          id: appeal.appealTypeId,
          name: appeal.appealTypeName,
        });
    });

    return tempFilteredAppealTypes;
  };
  //filterAppealStatus

  const filterAppealStatus = () => {
    let tempFilteredAppealStatus = [];
    appeals.forEach((appeal) => {
      if (
        !tempFilteredAppealStatus.find(({ name }) => name === appeal.statusName)
      )
        tempFilteredAppealStatus.push({
          id: appeal.statusId,
          name: appeal.statusName,
        });
    });

    return tempFilteredAppealStatus;
  };
  // console.log(selectedAdvertisement);
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100 mt-4">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-12">
                <div className="d-flex flex-column mb-3 w-100">
                  <div className="d-flex  flex-wrap justify-content-center w-100">
                    <div
                      style={{ color: " " }}
                      className="bg-warning border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                    >
                      <div className="fw-semibold font-weight-bold  text-center">
                        Pending
                      </div>
                      <div
                        style={{ color: "#ffff" }}
                        className="fs-6 font-weight-bolder  text-center"
                      >
                        {!showLoader && !!appeals.length
                          ? appeals.filter(({ statusId }) => statusId === 1)
                              ?.length
                          : 0}
                      </div>
                    </div>

                    <div
                      style={{ color: "#ffe6aa" }}
                      className="bg-danger border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                    >
                      <div className="fw-semibold font-weight-bold  text-center">
                        Not Valid
                      </div>
                      <div
                        style={{ color: "#ffff" }}
                        className="fs-6 font-weight-bolder  text-center"
                      >
                        {!showLoader && !!appeals.length
                          ? appeals.filter(
                              ({ statusId, isValid }) =>
                                (statusId === 2 && !isValid) ||
                                (statusId === 4 && !isValid) ||
                                (statusId === 3 && !isValid)
                            )?.length
                          : 0}
                      </div>
                    </div>
                    <div
                      style={{ color: "#ffe6aa" }}
                      className="bg-success border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                    >
                      <div className="fw-semibold font-weight-bold  text-center">
                        Valid
                      </div>
                      <div
                        style={{ color: "#ffff" }}
                        className="fs-6 font-weight-bolder  text-center"
                      >
                        {!showLoader && !!appeals.length
                          ? appeals.filter(
                              ({ statusId, isValid }) =>
                                (statusId === 2 && !!isValid) ||
                                (statusId === 4 && !!isValid) ||
                                (statusId === 3 && !!isValid)
                            )?.length
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Toolbar className="row">
              <div className="col-lg-3">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  // sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span style={{ color: "#007bff" }}>
                    <span style={{ color: "#007bff" }}>
                      {appealCategory === "InProgressAppeals" && (
                        <>In Progress</>
                      )}
                      {appealCategory === "ArchivedAppeals" && <>Archives</>}
                      {appealCategory === "OverdueAppeals" && <>Overdue</>}
                    </span>
                    ({appeals.length})
                  </span>
                </Typography>
              </div>

              <div className="col-lg-9">
                <div className="d-flex justify-content-end  align-items-right">
                  {appealCategory === "InProgressAppeals" && (
                    <Autocomplete
                      size="small"
                      className="mx-2"
                      id="filterStatus"
                      disabled={!appeals.length}
                      defaultValue={null}
                      value={filterStatus}
                      options={filterAppealStatus()}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, filterStatus) => {
                        setFilterStatus(filterStatus || null);
                        setSearchTeam("");
                        if (filterStatus) setSearchTeam(filterStatus.name);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, filterStatus) => (
                        <Box component="li" {...props} key={filterStatus.id}>
                          {filterStatus.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "35px!important",
                            width: "300px",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px!important",
                          }}
                          {...params}
                          label="Filter By Appeal Status"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "filterStatus", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}

                  <Autocomplete
                    size="small"
                    id="filterTypes"
                    className="mx-2"
                    disabled={!appeals.length}
                    defaultValue={null}
                    value={filterTypes}
                    options={filterAppealTypes()}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, filterTypes) => {
                      setFilterTypes(filterTypes || null);
                      setSearchTeam("");
                      if (filterTypes) setSearchTeam(filterTypes.name);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, filterTypes) => (
                      <Box component="li" {...props} key={filterTypes.id}>
                        {filterTypes.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{
                          height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                          width: "300px",
                        }}
                        {...params}
                        label="Filter By Appeal Type"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "filterTypes", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  <div className="mx-2">
                    {" "}
                    <SearchBox
                      disabled={!appeals.length}
                      onSearch={onSearch}
                      placeholder="Search…"
                    />
                  </div>

                  {hasPermissions(["IS_HR", "IS_NPSC_APPROVER", "IS_NPSC"]) &&
                    appealCategory !== "ArchivedAppeals" && (
                      <button
                        onClick={() => {
                          setConfirmDecision(true);
                        }}
                        type="button"
                        className="btn btn-success  px-2 mx-2 float-right"
                        // disabled={isShotlisting}
                      >
                        Approve
                      </button>
                    )}

                  {appealCategory === "OverdueAppeals" &&
                    hasPermissions(["IS_NPSC"]) && (
                      <button
                        onClick={() => {
                          setReason("");
                          setShowForwardBackAppeals(true);
                        }}
                        className="btn  btn-secondary btn-sm mr-3"
                        type="button"
                      >
                        Send to 1<sup>st</sup> Level
                      </button>
                    )}
                </div>
              </div>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              {!!paginatedItems.length && (
                <table className="table-striped table-hover table-sm  fixTableHead">
                  {
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col" className="text-left">
                          #
                        </th>

                        <th scope="col" className="text-left">
                          Appealer
                        </th>
                        {/* <th scope="col" className="text-left">
                          Message
                        </th> */}
                        <th scope="col" className="text-left">
                          Appeal Type
                        </th>
                        <th scope="col" className="text-right">
                          Appealed at
                        </th>
                        <th scope="col" className="text-right">
                          Level
                        </th>
                        <th scope="col" className="text-right">
                          Status
                        </th>

                        <th scope="col" className="text-right">
                          <span className="mr-3">Actions</span>
                        </th>
                      </tr>
                    </thead>
                  }

                  {showLoader ? (
                    <TableSkeleton cols={7} />
                  ) : (
                    <tbody>
                      {paginatedItems.map((appeal, index) => (
                        <AppealCard
                          appeal={appeal}
                          index={index}
                          setViewAppeal={setViewAppeal}
                          setSelectedAppeal={setAppeal}
                          key={index}
                        />
                      ))}
                    </tbody>
                  )}
                </table>
              )}

              <NoRecordMessage
                isEmpty={!filteredAppeals.length && !showLoader}
                title="No Results found"
              />

              {viewAppeal && (
                <ViewAppealDialog
                  entitySectorName={selectedAdvertisement.entityName}
                  setViewAppeal={setViewAppeal}
                  viewAppeal={viewAppeal}
                  appealCategory={appealCategory}
                />
              )}

              {!!paginatedItems.length && appeals.length >= itemsPerPage && (
                <div
                  className="justify-content-center mt-2"
                  style={{ overflow: "hidden" }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                    activeLinkClassName="active"
                  />
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>
      <DialogActions className="d-flex justify-content-center py-2">
        {showForwardBackAppeals && (
          <ConfirmationDialog
            disabled={!reason}
            confirmationDialog={showForwardBackAppeals}
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong className="text-primary">
                    Send to 1<sup>st</sup> Level
                  </strong>{" "}
                  appeals?
                </p>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="Reason"
                  autoFocus
                  label="Type your reason .."
                  variant="outlined"
                  placeholder="Type your reason  .."
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value || "");
                  }}
                />
              </>
            }
            setConfirmationDialog={setShowForwardBackAppeals}
            onYes={() => {
              onForwardBackAppeal(
                { advertisementId: selectedAdvertisement.id, reason },
                onClose,
                setReason,
                setShowForwardBackAppeals
              );
            }}
          />
        )}

        {confirmDecision && (
          <ConfirmationDialog
            confirmationDialog={confirmDecision}
            message={`Are you sure you want to Approve appeals?`}
            setConfirmationDialog={setConfirmDecision}
            noPreFormat={true}
            onYes={() => {
              if (hasPermissions(["IS_HR"])) {
                confirmFLAppealDecision(
                  { advertisementId: selectedAdvertisement.id },
                  onClose,
                  setConfirmDecision
                );
              } else if (hasPermissions(["IS_NPSC_APPROVER", "IS_NPSC"])) {
                //confirmOverdueFLAppealDecision
                if (appealCategory === "OverdueAppeals")
                  confirmOverdueFLAppealDecision(
                    { advertisementId: selectedAdvertisement.id },
                    onClose,
                    setConfirmDecision
                  );
                else
                  confirmSLAppealDecision(
                    { advertisementId: selectedAdvertisement.id },
                    onClose,
                    setConfirmDecision
                  );
              }
            }}
          />
        )}
      </DialogActions>
    </>
  );
};

const mapStateToProps = ({ user, loading, appeals }) => {
  return { user, loading, appeals };
};
export default connect(mapStateToProps, {
  getInProgressAppeals,
  getArchivedAppeals,
  getOverdueAppeals,
  // setAppeal,
  confirmFLAppealDecision,
  confirmSLAppealDecision,
  confirmOverdueFLAppealDecision,
  onForwardBackAppeal,
})(Appeals);

export const AppealCard = (props) => {
  const { appeal, setViewAppeal, setSelectedAppeal } = props;

  return (
    <>
      <tr
        className="appeal-hovered"
        onClick={() => {
          setSelectedAppeal(appeal);
          setViewAppeal(true);
        }}
      >
        <td className="text-left">
          <strong> {appeal.applicationId} </strong>
        </td>

        <td className="text-left">{appeal.applicantName}</td>

        <td className="text-left text-truncate">{appeal.subject}</td>

        <td className="text-right">
          {moment(
            appeal.decisionOn ? appeal.decisionOn : appeal.createdOn
          ).format("lll")}
        </td>

        <td className="text-right">
          <span className="badge rounded-pill badge-info ml-3 px-2 fw-bold">
            {appeal.levelName}
          </span>
          {/* {appeal.level === 1 && !!appeal.appealedToLevel2 && (
            <>
              {" "}
              <span className="badge rounded-pill badge-success ml-3 px-2 fw-bold">
                Second level
              </span>
            </>
          )} */}
        </td>

        <td className="text-right">
          {appeal.statusId === 2 && !appeal.isStatusConfirmed && (
            <span className="badge rounded-pill ap-badge-info mr-3 px-2 fw-bold">
              Waiting for confirmation..
            </span>
          )}
          {appeal.statusId === 1 && !!appeal.isOverdueAppealforwardedBack && (
            <span className="badge rounded-pill ap-badge-secondary mr-3 px-2 fw-bold">
              Sent to 1<sup>st</sup> Level
            </span>
          )}

          {/* {appeal.statusId === 2 && !!appeal.isAppealWaitingToResolved && (
            <span className="badge rounded-pill badge-warning mr-3 px-2 fw-bold">
              Waiting for resolution..
            </span>
          )} */}
          {/* {appeal.statusId === 2 && !!appeal.isAppealResolved && (
            <span className="badge rounded-pill badge-warning mr-3 px-2 fw-bold">
              Waiting for NPSC to close an appeal
            </span>
          )} */}

          {appeal.statusId === 2 &&
            !!appeal.isStatusConfirmed &&
            appeal.level === 1 && (
              <span className="badge rounded-pill badge-warning mr-3 px-2 fw-bold">
                Waiting for Appealer to close an appeal
              </span>
            )}

          {(appeal.statusId === 2 ||
            appeal.statusId === 4 ||
            appeal.statusId === 3) &&
            !!appeal.isValid && (
              <span className="badge  badge-success  px-2 fw-bold mr-2">
                Valid
              </span>
            )}
          {(appeal.statusId === 2 ||
            appeal.statusId === 4 ||
            appeal.statusId === 3) &&
            !appeal.isValid && (
              <span className="badge  badge-danger  px-2 fw-bold mr-2">
                Not valid
              </span>
            )}

          <span
            className={`badge px-2 fw-bold  ${
              appeal.statusId === 1
                ? "badge-warning"
                : appeal.statusId === 2
                ? "badge-primary"
                : "badge-danger"
            }`}
          >
            {appeal.statusId === 3 ? "Closed" : appeal.statusName}
          </span>
        </td>

        <td className="text-right">
          <button type="button" className="btn btn-success m-2">
            <OpenInNewIcon />
          </button>
        </td>
      </tr>
    </>
  );
};
