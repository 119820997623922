import React, { useEffect } from "react";
import { connect } from "react-redux";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Link } from "react-router-dom";
// import { getApplicationProfileStatus } from "../../../../store/profile/actions";
import CVUpload from "./CVUpload";
// import CVUpload from "./CVUpload";

const ProfileStatus = (props) => {
  const { applicationProfileStatus, getApplicationProfileStatus, application } =
    props;
  useEffect(() => {
    getApplicationProfileStatus(application.id);
  }, []);

  const profilePercentage = Math.round(
    (((applicationProfileStatus.verified ? 1 : 0) +
      (applicationProfileStatus.education ? 1 : 0) +
      (applicationProfileStatus.language ? 1 : 0) +
      (applicationProfileStatus.referees ? 1 : 0) +
      (applicationProfileStatus.experience ? 1 : 0) +
      (applicationProfileStatus.disability ? 1 : 0) +
      (applicationProfileStatus.certificate ? 1 : 0) +
      (applicationProfileStatus.publication ? 1 : 0) +
      (applicationProfileStatus.cv ? 1 : 0)) *
      100.0) /
      9.0
  );

  return (
    <div className="elevated rounded border px-3 py-1">
      <span className="text-primary font-weight-bold">
        Profile status
        <small className="badge-pill text-danger">* is required</small>
      </span>

      <div className="d-flex flex-row align-items-center mt-1 mb-2">
        <div>
          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.verified ? "text-success" : "text-danger"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.verified ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">Identity*</span>
          </Link>
          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.education
                ? "text-success"
                : "text-danger"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.education ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">Education*</span>
          </Link>
          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.language ? "text-success" : "text-danger"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.language ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">Languages*</span>
          </Link>
          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.disability
                ? "text-success"
                : "text-danger"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.disability ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">Disability*</span>
          </Link>
          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.referees ? "text-success" : "text-danger"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.referees ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">3 Referees*</span>
          </Link>
          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.cv ? "text-success" : "text-danger"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.cv ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">Upload CV*</span>
          </Link>
          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.experience
                ? "text-success"
                : "text-secondary"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.experience ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">Experience</span>
          </Link>

          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.certificate
                ? "text-success"
                : "text-secondary"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.certificate ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">Certificates</span>
          </Link>

          <Link
            to="/applicant/profile"
            className={`d-flex flex-row align-items-center ${
              applicationProfileStatus.publication
                ? "text-success"
                : "text-secondary"
            }`}
            style={{ textDecoration: "none" }}
          >
            {applicationProfileStatus.publication ? (
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            ) : (
              <CircleOutlinedIcon
                style={{ fontSize: "16px", marginTop: "3px" }}
              />
            )}
            <span className="ml-1">Publications</span>
          </Link>
        </div>
        <div className="w-100 d-block d-sm-none d-md-none d-lg-block">
          <div
            className="mx-auto"
            style={{
              width: "60px",
              height: "60px",
              lineHeight: "60px",
              borderRadius: "50%",
              fontSize: "20px",
              color: +profilePercentage >= 100 ? "#28a745" : "#545454",
              textAlign: "center",
              border: `solid 1px ${
                +profilePercentage >= 100 ? "#28a745" : "#545454"
              }`,
            }}
          >
            {profilePercentage}%
          </div>
        </div>
      </div>
      <CVUpload applicationId={application?.id || ""} />
    </div>
  );
};

const mapStateToProps = ({ loading, applicationProfileStatus }) => ({
  loading,
  applicationProfileStatus,
});

export default connect(mapStateToProps, {
  // getApplicationProfileStatus
})(ProfileStatus);
