import React from "react";

function NoResults() {
  return (
    <div className="jumbotron d-flex flex-column justify-content-center align-items-center">
      <span className="lead" style={{ fontSize: "18px" }}>
        --No record found--
      </span>
    </div>
  );
}

export default NoResults;
