import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  InputAdornment,
  DialogActions,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { saveOpenQuestionScore } from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";
import PaperDraggableComponent from "../../common/components/PaperDraggableComponent";
import ErrorMessage from "../../common/components/ErrorMessage";

const AddOpenQuestionMarkDialog = (props) => {
  const {
    loading,
    showScore,
    setShowScore,
    writtenExamMarkAnswer,
    saveOpenQuestionScore,
  } = props;

  const [formData, setFormData] = useState({
    writtenExamMarkAnswerId: writtenExamMarkAnswer.id,
    score: writtenExamMarkAnswer.score === 0 ? 0 : writtenExamMarkAnswer.score,
    comment: writtenExamMarkAnswer.comment,
  });

  const [errors, setErrors] = useState({
    scoreHasError: false,
    score2HasError: false,
    score3HasError: false,
    commentHasError: false,
  });

  const formValidator = () => {
    const error = {
      scoreHasError: false,
      score2HasError: false,
      commentHasError: false,
      hasError: false,
      score3HasError: false,
    };

    if (!formData.score) {
      error.scoreHasError = true;
      error.hasError = true;
    }

    if (formData.score && formData.score < 0) {
      error.score3HasError = true;
      error.hasError = true;
    }

    if (
      formData.score &&
      formData.score >
        (writtenExamMarkAnswer.examQuestion &&
          writtenExamMarkAnswer.examQuestion.marks)
    ) {
      error.score2HasError = true;
      error.hasError = true;
    }

    // if (!formData.comment) {
    //   error.commentHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveOpenQuestionScore(formData, onClose);
    }
  };

  const onClose = () => {
    setShowScore(false);
  };

  return (
    <>
      <Dialog
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperDraggableComponent}
        onClose={onClose}
        open={showScore}
        // fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          className="text-primary"
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          <Typography variant="overline" display="block">
            Add Mark
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div className="row">
            <div className="col-lg-12 mt-1">
              <p>
                <strong className="mr-1">
                  {" "}
                  Q.
                  {writtenExamMarkAnswer.examQuestion &&
                    writtenExamMarkAnswer.examQuestion.questionNo}{" "}
                </strong>

                {writtenExamMarkAnswer.examQuestion &&
                  writtenExamMarkAnswer.examQuestion.question &&
                  writtenExamMarkAnswer.examQuestion.question.description}
              </p>
              <p>
                <strong>Answer:</strong>
              </p>
              <p
                className="w-100 mr-4 border px-2 py-2 mt-n3"
                dangerouslySetInnerHTML={{
                  __html: writtenExamMarkAnswer.answer,
                }}
              ></p>
            </div>
            <div className="col-lg-12 mt-2">
              <TextField
                fullWidth
                size="small"
                name="score"
                autoFocus
                label="Score"
                variant="outlined"
                placeholder="Score"
                type="number"
                value={formData.score}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const score = e.target.value;
                  const s = score ? parseInt(score) : 0;
                  setFormData({
                    ...formData,
                    score,
                  });
                  let score2HasError = false;
                  let score3HasError = false;
                  if (s < 0) {
                    //score2HasError
                    score3HasError = true;
                  }
                  if (
                    s >
                    (writtenExamMarkAnswer.examQuestion &&
                      writtenExamMarkAnswer.examQuestion.marks)
                  ) {
                    //score2HasError
                    score2HasError = true;
                  }
                  const scoreHasError = false;
                  setErrors({
                    ...errors,
                    scoreHasError,
                    score2HasError,
                    score3HasError,
                  });
                }}
              />
              <ErrorMessage
                hasError={errors.scoreHasError}
                message="Score is required"
              />
              <ErrorMessage
                hasError={errors.score3HasError}
                message="Score must be great or equal to 0 mark"
              />
              <ErrorMessage
                hasError={errors.score2HasError}
                message={`Score must be less or equal to ${
                  writtenExamMarkAnswer.examQuestion &&
                  writtenExamMarkAnswer.examQuestion.marks
                } mark(s)`}
              />
            </div>
            <div className="col-lg-12 mt-2">
              <TextField
                fullWidth
                multiline
                rows={2}
                size="small"
                name="comment"
                autoFocus
                label="Comment"
                variant="outlined"
                placeholder="Comment"
                value={formData.comment}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const comment = e.target.value;
                  setFormData({
                    ...formData,
                    comment,
                  });
                  const commentHasError = false;
                  setErrors({ ...errors, commentHasError });
                }}
              />

              {/* <ErrorMessage
                hasError={errors.commentHasError}
                message="Comment is required"
              /> */}
            </div>

            <div className="col-12">
              <div className="input-group input-group-sm mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Score
                  </span>
                </div>
                <div className="form-control">
                  <div className="form-check form-check-inline">
                    <div
                      style={{
                        padding: "0px",
                        marginTop: "-10px",
                        width: "70px",
                        height: "auto",
                        border: `solid 3px #344457`,
                        borderRadius: "50px",
                        textAlign: "center",
                      }}
                    >
                      {formData.score} /{" "}
                      {writtenExamMarkAnswer.examQuestion &&
                        writtenExamMarkAnswer.examQuestion.marks}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase float-right"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  saveOpenQuestionScore,
})(AddOpenQuestionMarkDialog);
