import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { getApplicationExperiences }  from "../../../../store/profile/actions";

import NoResults from "../../../common/components/NoResults";

import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import moment from "moment";
import PreviewPdfUrl from "../../../common/components/PreviewPdfUrl";
import AdvertSkeleton from "../../../common/components/AdvertSkeleton";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import { showError } from "../../../toastify";

export const Experiences = (props) => {
  const {
    env,
    loading,
    onWait,
    applicationExperiences,
    getApplicationExperiences,
    applicationId,
  } = props;

  useEffect(() => {
    getApplicationExperiences(applicationId);
  }, []);

  const [loadingDocument, setLoadingDocument] = useState(false);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="experiences">
        <CardContent>
          {loading && !onWait && !applicationExperiences.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <Typography variant="h6" style={{ color: "#007bff" }}>
                  Work experience
                </Typography>
              </div>

              {applicationExperiences.length === 0 ? (
                <NoResults />
              ) : (
                applicationExperiences.map((experience, index) => (
                  <ExperienceItemCard
                    key={experience.id}
                    experience={experience}
                    index={index}
                    loading={loading}
                    setLoadingDocument={setLoadingDocument}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "#007bff",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ env, loading, onWait, applicationExperiences }) => ({
  env,
  loading,
  onWait,
  applicationExperiences,
});

const mapDispatchToProps = {
  // getApplicationExperiences,
};

export default connect(mapStateToProps, mapDispatchToProps)(Experiences);

const ExperienceItemCard = (props) => {
  const { loading, env, experience, setLoadingDocument } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          // avatar={<span className="font-weight-bold text-primary"></span>}
          title={
            <Typography>
              <strong>{experience.positionName} </strong> at{" "}
              <span>{experience.institutionName}</span>
            </Typography>
          }
          subheader={
            <span>
              <small className="btn-link">
                {experience.fromDate ? (
                  <>
                    {moment(experience.fromDate).format("MMM-YYYY")} -{" "}
                    {experience.toDate !== null && experience.toDate.length > 0
                      ? moment(experience.toDate).format("MMM-YYYY")
                      : "Present"}
                  </>
                ) : (
                  experience.oldPeriod ?? "None"
                )}
              </small>

              <div
                className="d-flex flex-column flex-md-row align-items-center-center"
                style={{ fontSize: "12px" }}
              >
                <div className="d-flex flex-row mr-3">
                  <PhoneRoundedIcon
                    className="text-secondary"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <span className="font-weight-bold ml-1">
                    {experience.institutionPhone}
                  </span>
                </div>
                <div className="d-flex flex-row">
                  <MailRoundedIcon
                    className="text-secondary"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <a
                    href={`mailto:${experience.institutionEmail}`}
                    className="font-weight-bold ml-1 text-dark"
                  >
                    {experience.institutionEmail}
                  </a>
                </div>
              </div>
            </span>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center "
                style={{ borderRadius: "4px" }}
              >
                <IconButton
                  // disableRipple
                  // className="ml-n2"
                  size="small"
                  onClick={() => {
                    if (experience.docReferenceId) {
                      setOnViewDocument(true);
                    } else {
                      showError("Document is not available");
                    }
                  }}
                >
                  <FilePresentIcon
                    // fontSize="large"
                    style={{
                      color: !!experience.docReferenceId
                        ? "#fe4066"
                        : "#c57c7cb8",
                    }}
                  />
                </IconButton>
              </div>
            </>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `Experience document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              experience.docReferenceId,
          }}
        />
      )}
    </>
  );
};
