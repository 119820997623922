import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";

import { getQuestionLogs } from "../../../store/psychometric/actions";
import { useEffect, useState } from "react";
import moment from "moment";
import NoRecordMessage from "../../common/components/NoRecordMessage";

const QuestionLogsDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    dispatch,
    question,
    questionLogs,
    getQuestionLogs,
  } = props;

  useEffect(() => {
    getQuestionLogs(question.id);
  }, [question]);

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle className="text-primary">
        <Typography variant="h6" noWrap component="div">
          <span className="text-dark">Question Logs</span>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!!questionLogs?.length && (
          <table className="table table-striped table-sm  mt-2">
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th scope="col" className="text-left">
                  Action
                </th>
                <th scope="col" className="text-left">
                  Description
                </th>

                <th scope="col" className="text-left">
                  Question Type
                </th>

                <th scope="col" className="text-left">
                  Assertions
                </th>

                <th scope="col" className="text-left">
                  Knowledge Level
                </th>

                <th scope="col" className="text-left">
                  Knowledge Domain
                </th>

                <th scope="col" className="text-center">
                  Seconds
                </th>

                <th scope="col" className="text-center">
                  Score
                </th>
              </tr>
            </thead>

            <tbody>
              {questionLogs?.map((log, index) => (
                <LogCard
                  loading={loading}
                  question={question}
                  index={index}
                  key={log.id}
                  log={log}
                />
              ))}
            </tbody>
          </table>
        )}
        <div className="mt-2">
          <NoRecordMessage
            isEmpty={!questionLogs?.length}
            title="No record found. "
          />
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-3"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading, questionLogs }) => {
  return {
    user,
    loading,
    questionLogs,
  };
};
export default connect(mapStateToProps, {
  getQuestionLogs,
})(QuestionLogsDialog);

const LogCard = (props) => {
  const { loading, question, index, log } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr>
        <th
          scope="row"
          style={{
            backgroundColor: ["UPDATE", "EDIT"].includes(log.logAction)
              ? "RoyalBlue"
              : ["DISABLE"].includes(log.logAction)
              ? "Tomato"
              : ["ENABLE"].includes(log.logAction)
              ? "SeaGreen"
              : "LightSlateGray",

            color: "white",
          }}
        >
          <span className="badge px-0">
            {moment(log.createdOn).format("lll")}
          </span>
          <br />
          {log.logAction} <br />
          By: {log.user?.lastName || "User"} {log.user?.firstName}
        </th>

        <td
          className="text-left"
          style={{
            width: "40%",
          }}
        >
          <strong>EN:</strong> {log.en} <br />
          <strong>FR:</strong> {log.fr} <br />
          <strong>KN:</strong> {log.kn}
        </td>

        <td className="text-left">{log.questionType?.name}</td>

        <td
          className="text-left"
          style={{
            width: "30%",
          }}
        >
          <table>
            <tbody>
              {log.optionLogs
                .sort((a, b) => (a.optionNumber > b.optionNumber ? 1 : -1))
                .map((optionLog, index) => (
                  <tr key={index}>
                    <td>{optionLog.optionNumber}:</td>
                    <td>
                      <p
                        className={`font-weight-${
                          !!optionLog.isCorrect ? "bold" : "normal"
                        }`}
                      >
                        <strong>EN:</strong> {optionLog.en} <br />
                        <strong>FR:</strong> {optionLog.fr} <br />
                        <strong>KN:</strong> {optionLog.kn}
                        {!!optionLog.isCorrect && (
                          <span className="badge badge-success ml-1">
                            Correct
                          </span>
                        )}
                      </p>
                    </td>

                    {question.questionTypeId === 5 && (
                      <td>
                        <p>
                          <strong>EN:</strong> {optionLog.matchEn} <br />
                          <strong>FR:</strong> {optionLog.matchFr} <br />
                          <strong>KN:</strong> {optionLog.matchKn}
                        </p>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </td>

        <td className="text-left">{log.knowledgeLevel?.name}</td>

        <td className="text-left">{log.knowledgeSubDomain?.name}</td>

        <td className="text-center">{log.score} Marks</td>

        <td className="text-center">{log.questionTimeInSec} Sec</td>
      </tr>
    </>
  );
};
