import { Button } from "@mui/material";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import SearchBox from "../../common/components/SearchBox";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  getSearchedRecruitmentMember,
  addRecruitmentMember,
} from "../../../store/recruitment/actions";
import PreLoader from "../../common/components/PreLoader";
import AddIcon from "@mui/icons-material/Add";
import RECRUITMENT_ACTION_TYPE from "../../../store/recruitment/action-types";
import NoRecordMessage from "../../common/components/NoRecordMessage";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid #007bff`,
  display: "flex",
  color: "#007bff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "#007bff!important",
  },
};
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid #007bff`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "#007bff",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const AddRecruitmentMember = (props) => {
  const {
    setShowRecruitmentMember,
    selectedAdvertisement,
    searchedRecruitmentMember,
    getSearchedRecruitmentMember,
    addRecruitmentMember,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isAddingRecruitmentMember, setIsAddingRecruitmentMember] =
    useState(false);
  const dispatch = useDispatch();

  const onSearch = (term) => {
    setSearchTeam(term);
  };
  const onSubmit = () => {
    if (searchTeam)
      getSearchedRecruitmentMember(
        { searchTerm: searchTeam, advertisementId: selectedAdvertisement.id },
        setIsSearching
      );
  };
  return (
    <>
      <div className="d-flex justify-content-end mt-3 mb-2">
        <SearchBox
          disabled={isSearching || isAddingRecruitmentMember}
          onSearch={onSearch}
          placeholder="Enter user email or phone number…"
        />
        <Button
          className="ml-2 px-1"
          disabled={isSearching || isAddingRecruitmentMember}
          onClick={() => onSubmit()}
          style={{
            ...ActiveButton,
            float: "right",
          }}
          size="md"
        >
          Search{isSearching && "ing..."}
        </Button>

        <Button
          className="ml-2"
          disabled={isSearching || isAddingRecruitmentMember}
          onClick={() => {
            setSearchTeam("");
            setShowRecruitmentMember(false);
            dispatch({
              type: RECRUITMENT_ACTION_TYPE.SET_SEARCHED_RECRUITMENT_MEMBER,
              data: null,
            });
          }}
          style={{
            ...InactiveButton,
            // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
            float: "right",
          }}
          size="md"
        >
          <TrendingFlatIcon />
        </Button>
      </div>

      <PreLoader className="mt-3" isLoading={isSearching} />

      <NoRecordMessage
        isEmpty={!searchedRecruitmentMember && !isSearching}
        title={"Please search by email or phone number for existing user!"}
      />

      {!isSearching && searchedRecruitmentMember && (
        <div className="mt-3">
          <table className="table table-striped table-hover table-sm  fixTableHead">
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th scope="col" className="text-left">
                  Names
                </th>
                <th scope="col" className="text-left">
                  Email
                </th>
                <th scope="col" className="text-left">
                  PhoneNumber
                </th>
                <th scope="col" className="text-left">
                  IDNumber
                </th>

                <th scope="col" className="text-left">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="text-left">
                  <span style={{ fontSize: "10px" }}>
                    {searchedRecruitmentMember.lastName}{" "}
                    {searchedRecruitmentMember.firstName}
                  </span>
                </td>
                <td className="text-left">
                  <span style={{ fontSize: "10px" }}>
                    {searchedRecruitmentMember.email}
                  </span>
                </td>
                <td className="text-left">
                  <span style={{ fontSize: "10px" }}>
                    {searchedRecruitmentMember.phoneNumber}
                  </span>
                </td>
                <td className="text-left">
                  <span style={{ fontSize: "10px" }}>
                    {searchedRecruitmentMember.idNumber}
                  </span>
                </td>

                <td className="text-left">
                  {" "}
                  <button
                    disabled={isAddingRecruitmentMember}
                    onClick={() => {
                      addRecruitmentMember(
                        {
                          userId: searchedRecruitmentMember.id,
                          advertisementId: selectedAdvertisement.id,
                        },
                        setIsAddingRecruitmentMember,
                        setShowRecruitmentMember
                      );
                    }}
                    className="btn btn-primary"
                  >
                    {isAddingRecruitmentMember ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      <AddIcon />
                    )}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, searchedRecruitmentMember }) => {
  return {
    loading,
    searchedRecruitmentMember,
  };
};
export default connect(mapStateToProps, {
  getSearchedRecruitmentMember,
  addRecruitmentMember,
})(AddRecruitmentMember);

// export default AddRecruitmentMember;
