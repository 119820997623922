import React, { useEffect } from "react";

const CustomCountdown = ({ remainingSeconds, setRemainingSeconds }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secondsLeft
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <span className="text-gray-600 text-sm">
      {formatTime(remainingSeconds)}
    </span>
  );
};

export default CustomCountdown;
