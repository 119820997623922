import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  InputAdornment,
  DialogActions,
} from "@mui/material";

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { saveOralMarks } from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";
import ErrorMessage from "../../common/components/ErrorMessage";
import recruitment from "../../../store/recruitment/action-types";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
const AddOralMarkDialog = (props) => {
  const {
    loading,
    showAddOralMarkDialog,
    applicantOralExamResult,
    applicantOralExamSchema,
    saveOralMarks,
  } = props;
  const dispatch = useDispatch();
  const [showDocument, setShowDocument] = useState(false);
  const [formData, setFormData] = useState({
    panelId: applicantOralExamResult.panelId,
    applicantExamSessionId: applicantOralExamResult.id,
    scoreOnBehaviour: applicantOralExamResult.examinerScoreOnBehaviour,
    scoreOnCommunicationSkills:
      applicantOralExamResult.examinerScoreOnCommunicationSkills,
    scoreOnGeneralKnowledge:
      applicantOralExamResult.examinerScoreOnGeneralKnowledge,
    scoreOnTechnicalKnowledge:
      applicantOralExamResult.examinerScoreOnTechnicalKnowledge,
      scoreOnWrittenSkills:
      applicantOralExamResult.examinerScoreOnWrittenSkills || 0,

    behaviour: applicantOralExamSchema.markOnBehaviour,
    communicationSkills: applicantOralExamSchema.markOnCommunicationSkills,
    generalKnowledge: applicantOralExamSchema.markOnGeneralKnowledge,
    technicalKnowledge: applicantOralExamSchema.markOnTechnicalKnowledge,
    writtenSkills: applicantOralExamSchema.markOnWrittenSkills,
    totalMarks: 50,
    examinerComment: applicantOralExamResult.examinerComment,
  });

  const [errors, setErrors] = useState({
    scoreOnBehaviour: { hasError: false, message: "" },
    scoreOnGeneralKnowledge: { hasError: false, message: "" },
    scoreOnTechnicalKnowledge: { hasError: false, message: "" },
    scoreOnCommunicationSkills: { hasError: false, message: "" },
    scoreOnWrittenSkills: { hasError: false, message: "" },
  });

  const formValidator = () => {
    const errors = {
      scoreOnBehaviour: { hasError: false, message: "" },
      scoreOnGeneralKnowledge: { hasError: false, message: "" },
      scoreOnTechnicalKnowledge: { hasError: false, message: "" },
      scoreOnCommunicationSkills: { hasError: false, message: "" },
      scoreOnWrittenSkills: { hasError: false, message: "" },
      hasError: false,
    };

    if (!formData.scoreOnBehaviour) {
      errors.scoreOnBehaviour = {
        hasError: true,
        message: "Score On Behaviour is required",
      };
      errors.hasError = true;
    } else {
      if (formData.scoreOnBehaviour < 0) {
        errors.scoreOnBehaviour = {
          hasError: true,
          message: "Score On Behaviour is less than zero",
        };
        errors.hasError = true;
      }

      if (formData.scoreOnBehaviour > formData.behaviour) {
        errors.scoreOnBehaviour = {
          hasError: true,
          message: `Score On Behaviour is greater than ${formData.behaviour}`,
        };
        errors.hasError = true;
      }
    }

    if (!formData.scoreOnGeneralKnowledge) {
      errors.scoreOnGeneralKnowledge = {
        hasError: true,
        message: "Score On General Knowledge is required",
      };
      errors.hasError = true;
    } else {
      if (formData.scoreOnGeneralKnowledge < 0) {
        errors.scoreOnBehaviour = {
          hasError: true,
          message: "Score On General Knowledge is less than zero",
        };
        errors.hasError = true;
      }

      if (formData.scoreOnGeneralKnowledge > formData.generalKnowledge) {
        errors.scoreOnBehaviour = {
          hasError: true,
          message: `Score On General Knowledge is greater than ${formData.generalKnowledge}`,
        };
        errors.hasError = true;
      }
    }

    if (!formData.scoreOnTechnicalKnowledge) {
      errors.scoreOnTechnicalKnowledge = {
        hasError: true,
        message: "Score On Technical Knowledge is required",
      };
      errors.hasError = true;
    } else {
      if (formData.scoreOnTechnicalKnowledge < 0) {
        errors.scoreOnTechnicalKnowledge = {
          hasError: true,
          message: "Score On Technical Knowledge is less than zero",
        };
        errors.hasError = true;
      }

      if (formData.scoreOnTechnicalKnowledge > formData.technicalKnowledge) {
        errors.scoreOnTechnicalKnowledge = {
          hasError: true,
          message: `Score On Technical Knowledge is greater than ${formData.technicalKnowledge}`,
        };
        errors.hasError = true;
      }
    }
    //scoreOnCommunicationSkills

    if (!formData.scoreOnCommunicationSkills) {
      errors.scoreOnCommunicationSkills = {
        hasError: true,
        message: "Score On Communication Skills is required",
      };
      errors.hasError = true;
    } else {
      if (formData.scoreOnCommunicationSkills < 0) {
        errors.scoreOnCommunicationSkills = {
          hasError: true,
          message: "Score On Communication Skills is less than zero",
        };
        errors.hasError = true;
      }

      if (formData.scoreOnCommunicationSkills > formData.communicationSkills) {
        errors.scoreOnCommunicationSkills = {
          hasError: true,
          message: `Score On Communication Skills is greater than ${formData.communicationSkills}`,
        };
        errors.hasError = true;
      }
    }

  if(applicantOralExamSchema.markOnWrittenSkills > 0){
    if (!formData.scoreOnWrittenSkills) {
      errors.scoreOnWrittenSkills = {
        hasError: true,
        message: "Score On Written Skills is required",
      };
      errors.hasError = true;
    } else {
      if (formData.scoreOnWrittenSkills < 0) {
        errors.scoreOnWrittenSkills = {
          hasError: true,
          message: "Score On Written Skills is less than zero",
        };
        errors.hasError = true;
      }

      if (formData.scoreOnWrittenSkills > formData.writtenSkills) {
        errors.scoreOnWrittenSkills = {
          hasError: true,
          message: `Score On Written Skills is greater than ${formData.writtenSkills}`,
        };
        errors.hasError = true;
      }
    }
  }
   

    setErrors(errors);

    if (errors.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const totalMarks = () => {
    return (
      isNull(formData.scoreOnBehaviour) +
      isNull(formData.scoreOnCommunicationSkills) +
      isNull(formData.scoreOnGeneralKnowledge) +
      isNull(formData.scoreOnTechnicalKnowledge) +
      isNull(formData.scoreOnWrittenSkills)
    );
  };
  const onSave = () => {
    if (!formValidator()) {
      saveOralMarks(formData, onClose);
    }
  };

  const onClose = () => {
    dispatch({
      type: recruitment.SET_SHOW_ADD_ORAL_MARK_DIALOG,
      data: false,
    });
  };

  const isNull = (value) => {
    return value ? parseFloat(value) : 0;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        open={showAddOralMarkDialog}
        // fullWidth
        maxWidth={applicantOralExamResult.testForWrittenSkills ? "lg" : "sm"}
      >
        <DialogTitle
          className="text-primary"
        >
          <Typography variant="overline" display="block">
            Add Marks  | {applicantOralExamResult.firstName}{" "}
            {applicantOralExamResult.lastName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {showDocument && (
            <PreviewPdfUrl
            isBase64={true}
              showDocument={showDocument}
              document={{
                url: applicantOralExamSchema?.questionsAttachment,
                name: "Oral exam questions",
              }}
              setShowDocument={setShowDocument}
            />
          )}

          <div
            className="row"
            style={{
              height: "500px",
            }}
          >
            <div
              className={
                applicantOralExamResult.testForWrittenSkills
                  ? "col-lg-6"
                  : "col-lg-12"
              }
            >
              <div className="row">
                <div className="col-lg-12 mt-0">
                {applicantOralExamResult.panelName && (
              <>
                <span className="badge badge-primary">{applicantOralExamResult.panelName} Panel</span>
               
              </>
            )}
                  <a
                    href="#"
                    onClick={() => setShowDocument(true)}
                    className="text-primary float-right"
                  >
                    <i className="fa fa-eye"></i> View questions
                  </a>
                </div>
                <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend col-6">
                      <span className="input-group-text w-100">
                        Score On Behaviour
                      </span>
                    </div>
                    <input
                      readOnly={applicantOralExamResult.isPublished}
                      type="number"
                      onChange={(e) => {
                        const scoreOnBehaviour = e.target.value;

                        const s = scoreOnBehaviour
                          ? parseInt(scoreOnBehaviour)
                          : 0;
                        setFormData({
                          ...formData,
                          scoreOnBehaviour,
                        });

                        const errorScoreOnBehaviour = {
                          hasError: false,
                          message: "",
                        };

                        if (s < 0) {
                          errorScoreOnBehaviour.hasError = true;
                          errorScoreOnBehaviour.message =
                            "Score On Behaviour is less than zero";
                        }

                        if (s > formData.behaviour) {
                          errorScoreOnBehaviour.hasError = true;
                          errorScoreOnBehaviour.message = `Score On Behaviour is greater than ${formData.behaviour}`;
                        }

                        setErrors({
                          ...errors,
                          scoreOnBehaviour: errorScoreOnBehaviour,
                        });
                      }}
                      value={formData.scoreOnBehaviour}
                      className="form-control col text-center"
                      aria-label="Score On Behaviour"
                    />

                    <div className="input-group-append ">
                      <span className="input-group-text">
                        {isNull(formData.scoreOnBehaviour)}/{formData.behaviour}
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    hasError={errors.scoreOnBehaviour.hasError}
                    message={errors.scoreOnBehaviour.message}
                  />
                </div>

                <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend  col-6">
                      <span className="input-group-text w-100">
                        Score On General Knowledge
                      </span>
                    </div>
                    <input
                      readOnly={applicantOralExamResult.isPublished}
                      type="number"
                      onChange={(e) => {
                        const scoreOnGeneralKnowledge = e.target.value;

                        const s = scoreOnGeneralKnowledge
                          ? parseInt(scoreOnGeneralKnowledge)
                          : 0;
                        setFormData({
                          ...formData,
                          scoreOnGeneralKnowledge,
                        });

                        const errorScoreOnGeneralKnowledge = {
                          hasError: false,
                          message: "",
                        };

                        if (s < 0) {
                          errorScoreOnGeneralKnowledge.hasError = true;
                          errorScoreOnGeneralKnowledge.message =
                            "Score On General Knowledge is less than zero";
                        }

                        if (s > formData.generalKnowledge) {
                          errorScoreOnGeneralKnowledge.hasError = true;
                          errorScoreOnGeneralKnowledge.message = `Score On General Knowledge is greater than ${formData.generalKnowledge}`;
                        }

                        setErrors({
                          ...errors,
                          scoreOnGeneralKnowledge: errorScoreOnGeneralKnowledge,
                        });
                      }}
                      value={formData.scoreOnGeneralKnowledge}
                      className="form-control col text-center"
                      aria-label="Score On General Knowledge"
                    />

                    <div className="input-group-append">
                      <span className="input-group-text">
                        {isNull(formData.scoreOnGeneralKnowledge)}/
                        {formData.generalKnowledge}
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    hasError={errors.scoreOnGeneralKnowledge.hasError}
                    message={errors.scoreOnGeneralKnowledge.message}
                  />
                </div>

                <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend col-6">
                      <span className="input-group-text w-100">
                        Score On Technical Knowledge
                      </span>
                    </div>
                    <input
                      readOnly={applicantOralExamResult.isPublished}
                      type="number"
                      onChange={(e) => {
                        const scoreOnTechnicalKnowledge = e.target.value;

                        const s = scoreOnTechnicalKnowledge
                          ? parseInt(scoreOnTechnicalKnowledge)
                          : 0;
                        setFormData({
                          ...formData,
                          scoreOnTechnicalKnowledge,
                        });

                        const errorScoreOnTechnicalKnowledge = {
                          hasError: false,
                          message: "",
                        };

                        if (s < 0) {
                          errorScoreOnTechnicalKnowledge.hasError = true;
                          errorScoreOnTechnicalKnowledge.message =
                            "Score On Technical Knowledge is less than zero";
                        }

                        if (s > formData.technicalKnowledge) {
                          errorScoreOnTechnicalKnowledge.hasError = true;
                          errorScoreOnTechnicalKnowledge.message = `Score On Technical Knowledge is greater than ${formData.technicalKnowledge}`;
                        }

                        setErrors({
                          ...errors,
                          scoreOnTechnicalKnowledge:
                            errorScoreOnTechnicalKnowledge,
                        });
                      }}
                      value={formData.scoreOnTechnicalKnowledge}
                      className="form-control col text-center"
                      aria-label="Score On Technical Knowledge"
                    />

                    <div className="input-group-append">
                      <span className="input-group-text">
                        {isNull(formData.scoreOnTechnicalKnowledge)}/
                        {formData.technicalKnowledge}
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    hasError={errors.scoreOnTechnicalKnowledge.hasError}
                    message={errors.scoreOnTechnicalKnowledge.message}
                  />
                </div>

                <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend col-6">
                      <span className="input-group-text w-100">
                        Score On Communication Skills
                      </span>
                    </div>
                    <input
                      readOnly={applicantOralExamResult.isPublished}
                      type="number"
                      onChange={(e) => {
                        const scoreOnCommunicationSkills = e.target.value;

                        const s = scoreOnCommunicationSkills
                          ? parseInt(scoreOnCommunicationSkills)
                          : 0;
                        setFormData({
                          ...formData,
                          scoreOnCommunicationSkills,
                        });

                        const errorScoreOnCommunicationSkills = {
                          hasError: false,
                          message: "",
                        };

                        if (s < 0) {
                          errorScoreOnCommunicationSkills.hasError = true;
                          errorScoreOnCommunicationSkills.message =
                            "Score On Communication Skills is less than zero";
                        }

                        if (s > formData.communicationSkills) {
                          errorScoreOnCommunicationSkills.hasError = true;
                          errorScoreOnCommunicationSkills.message = `Score On Communication Skills is greater than ${formData.communicationSkills}`;
                        }

                        setErrors({
                          ...errors,
                          scoreOnCommunicationSkills:
                            errorScoreOnCommunicationSkills,
                        });
                      }}
                      value={formData.scoreOnCommunicationSkills}
                      className="form-control col text-center"
                      aria-label="Score On Communication Skills"
                    />

                    <div className="input-group-append">
                      <span className="input-group-text">
                        {isNull(formData.scoreOnCommunicationSkills)}/
                        {formData.communicationSkills}
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    hasError={errors.scoreOnCommunicationSkills.hasError}
                    message={errors.scoreOnCommunicationSkills.message}
                  />
                </div>
{applicantOralExamSchema.markOnWrittenSkills > 0 &&    <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend col-6">
                      <span className="input-group-text w-100">
                        Score On Written Skills
                      </span>
                    </div>
                    <input
                      readOnly={applicantOralExamResult.isPublished}
                      type="number"
                      onChange={(e) => {
                        const scoreOnWrittenSkills = e.target.value;

                        const s = scoreOnWrittenSkills
                          ? parseInt(scoreOnWrittenSkills)
                          : 0;
                        setFormData({
                          ...formData,
                          scoreOnWrittenSkills,
                        });

                        const errorScoreOnWrittenSkills = {
                          hasError: false,
                          message: "",
                        };

                        if (s < 0) {
                          errorScoreOnWrittenSkills.hasError = true;
                          errorScoreOnWrittenSkills.message =
                            "Score On Written Skills is less than zero";
                        }

                        if (s > formData.writtenSkills) {
                          errorScoreOnWrittenSkills.hasError = true;
                          errorScoreOnWrittenSkills.message = `Score On Written Skills is greater than ${formData.writtenSkills}`;
                        }

                        setErrors({
                          ...errors,
                          scoreOnWrittenSkills:
                          errorScoreOnWrittenSkills,
                        });
                      }}
                      value={formData.scoreOnWrittenSkills}
                      className="form-control col text-center"
                      aria-label="Score On Written Skills"
                    />

                    <div className="input-group-append">
                      <span className="input-group-text">
                        {isNull(formData.scoreOnWrittenSkills)}/
                        {formData.writtenSkills}
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    hasError={errors.scoreOnWrittenSkills.hasError}
                    message={errors.scoreOnWrittenSkills.message}
                  />
                </div>}
             

                <div className="col-lg-12 mt-2">
                  <TextField
                    disabled={applicantOralExamResult.isPublished}
                    fullWidth
                    multiline
                    rows={2}
                    size="small"
                    name="examinerComment"
                    label="Comment"
                    variant="outlined"
                    placeholder="Comment"
                    value={formData.examinerComment}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const comment = e.target.value;
                      setFormData({
                        ...formData,
                        examinerComment: comment,
                      });
                    }}
                  />
                </div>

                <div className="col-12">
                  <div className="input-group input-group-sm mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Total Score
                      </span>
                    </div>
                    <div className="form-control">
                      <div
                        className="form-check form-check-inline"
                        style={{
                          float: "right",
                        }}
                      >
                        <div
                          style={{
                            padding: "0px",
                            marginTop: "-2px",

                            width: "70px",
                            height: "auto",
                            border: `solid 3px ${
                              totalMarks() >= 25
                                ? "#28a745"
                                : applicantOralExamResult.isMarked
                                ? "#dc3545"
                                : ""
                            }`,
                            borderRadius: "50px",
                            textAlign: "center",
                          }}
                        >
                          {totalMarks()} / {formData.totalMarks}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {applicantOralExamResult.testForWrittenSkills && (
              <div
                className={
                  applicantOralExamResult.testForWrittenSkills
                    ? "col-lg-6"
                    : "col-lg-12"
                }
              >
                <div className="card border-primary w-100">
                  <div className="card-header  bg-transparent border-primary">
                    <strong>Test For Written Skills</strong>
                  </div>
                  <div className="card-body">
                    <nav>
                      <ol className="breadcrumb">
                        <div
                          className="breadcrumb-item"
                          dangerouslySetInnerHTML={{
                            __html:
                              applicantOralExamResult.testForWrittenSkills,
                          }}
                        ></div>
                      </ol>
                    </nav>
                    <p className="card-text">
                      <div
                        style={{ maxHeight: "210px", overflow: "auto" }}
                        className="ml-4"
                        dangerouslySetInnerHTML={{
                          __html: applicantOralExamResult.writtenSkillsAnswer,
                        }}
                      ></div>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        {!applicantOralExamResult.isPublished && (
          <DialogActions className="d-flex justify-content-center py-1">
            <button
              onClick={onSave}
              type="button"
              className={
                applicantOralExamResult.isMarked
                  ? "btn btn-success text-uppercase"
                  : "btn btn-primary text-uppercase"
              }
              disabled={loading}
            >
              {loading
                ? "Wait..."
                : applicantOralExamResult.isMarked
                ? "Update"
                : "Save"}
            </button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  showAddOralMarkDialog,
  applicantOralExamResult,
  applicantOralExamSchema,
}) => {
  return {
    loading,
    showAddOralMarkDialog,
    applicantOralExamResult,
    applicantOralExamSchema,
  };
};
export default connect(mapStateToProps, {
  saveOralMarks,
})(AddOralMarkDialog);
