export const defaultState = {
  analytics: [],
  jobPublications: [],
  applicantJobPublications: [],
  applicants: [],
  applicantExamMarking: [],
  applicantOralExamMarking: [],
  applicantOverallResults: [],
  appeals: [],
  questions: [],
  tests: [],
  tempQuestions: [],
  requisitions: [],
  requisitionStatus: [],
  applicationsStatus: [],
  advertisementStatus: [],
  requisitionCertificates: [],
  requisitionCompetencies: [],
  requisitionQualifications: [],
  requisitionExperiences: [],
  advertisements: [],
  advertisementApplications: [],
  userProfile: null,
  convertedFileToBase64: null,
  writtenExams: [],
  oralExams: [],
  questionTypes: [],
  hasExamQuestion: false,
  examQuestions: [],
  applicantExamSessions: [],
  applicantSessionLogs: [],
  applicantsNotAttended: [],
  applicantsAttended: [],
  haslockedOver3Times: false,
  sessionAdditionalMinutes: [],
  applicantWrittenExamResults: [],
  applicantWrittenExamAnswers: [],
  applicantWrittenExamResult: null,
  applicantOralExamResults: [],
  applicantOralExamResult: null,
  applicantOralExamAnswers: [],
  searchedPanelist: null,
  searchedInvigilator: null,
  searchedRecruitmentMember: null,
  panelists: [],
  invigilators: [],
  panelOralExams: [],
  examinerOralExamResults: [],
  invigilatorWrittenExams: [],
  recruitmentMembers: [],
  appealAdvertisements: [],
  unConfirmedAppeals: [],
  showAddOralMarkDialog: false,
  showAddOralSchemaDialog: false,
  applicantOralExamSchema: null,
  waitingListPositions: [],
  waitingListRequisitions: [],
  waitingListJobOffers: [],
  waitingListCandidates: [],
  waitingListOffers: [],
  waitingListPositionByEntitySector: [],
  inprogressAppeals: [],
  archivedAppeals: [],
  allOverdueAppeals: [],
  activeCenters: [],
  examCenters: [],
  examCenterNoneSelected: [],
  panels: [],
};
