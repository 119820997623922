import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import QuestionTypes from "../components/QuestionTypes";
import KnowledgeLevels from "../components/KnowledgeLevels";
import JobLevels from "../components/JobLevels";
import KnowledgeDomains from "../components/KnowledgeDomains";
import KnowledgeSubDomains from "../components/KnowledgeSubDomains";

const Settings = () => {
  return (
    <div className="row bg-light border py-3 mx-0">
      <div className="col">
        <JobLevels />
      </div>
      <div className="col">
        <KnowledgeLevels />
      </div>
      <div className="col">
        <KnowledgeDomains />
      </div>
      <div className="col">
        <KnowledgeSubDomains />
      </div>
      <div className="col">
        <QuestionTypes />
      </div>
    </div>
  );
};

export default Settings;
