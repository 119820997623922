export const defaultState = {
  loading: false,
  onWait: false,
  fetchingUser: true,
  strongPassword: true,
  user: {},
  ippisModules: [],
  selectedModule: {},
  env: null,
  selectedEmployeeProfilePicture: null,
  userProfilePicture: null,
  requestDocumentDetail:null,
  requireAuth: false,
  hrMessage: "hrMessage",
  userProfile:null
};
