import {
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

const ConnectToRisaPKIDialog = (props) => {
  const {
    user,
    showDialog,
    setShowDialog,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth={`md`}
      >
        <DialogTitle className="text-primary">
        
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        
        </DialogTitle>
        <DialogContent
       style={{height:"400px"}}
        >
          <div className="mt-3" style={{width:"100%",height:"100%"}}>
          <iframe rel="noreferrer"  style={{width:"100%",height:"100%"}} src={`http://localhost:5500/risa-dn/registration/${user.id}`} frameBorder={0}></iframe>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
      </Dialog>
    </>
  );
};
;
export default ConnectToRisaPKIDialog;
