import { toast } from "react-toastify";

export const showSuccess = (message) => {
  return toast.success(message || "Succeed");
};

export const showError = async (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data === "Unauthenticated"
  )
    return;

  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data === "redirect"
  )
    return window.location.replace("https://selfservice.ippis.rw");

  if (
    (error &&
      error.response &&
      error.response.data &&
      error.response.data === "SESSION_EXPIRED") ||
    error === "SESSION_EXPIRED"
  ) {
    return;
  }

  if (error && error.response && error.response.data) {
    if (error.response.data.toString().includes("<html"))
      return toast.error("Error occurred. Try again later.");
    else return toast.error(error.response.data);
  } else if (error.message) return toast.error(error.message);
  else return toast.error(error || "Error occurred. Try again");
};
