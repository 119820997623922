const types = {
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  SET_USER_PROFILE_PICTURE: "SET_USER_PROFILE_PICTURE",
  SET_SELECTED_EMPLOYEE_PROFILE_PICTURE:
    "SET_SELECTED_EMPLOYEE_PROFILE_PICTURE",
  CLEAN_STATE: "CLEAN_STATE",
  SET_STRONG_PASSWORD: "SET_STRONG_PASSWORD",
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",
  SET_SELECTED_MODULE: "SET_SELECTED_MODULE",
  SET_SELECTED_ENTITY_SECTOR: "SET_SELECTED_ENTITY_SECTOR",
  SET_IPPIS_MODULES: "SET_IPPIS_MODULES",

  START_WAIT: "START_WAIT",
  END_WAIT: "END_WAIT",
  SET_ENV: "SET_ENV",
  SET_REQUEST_DOCUMENT_DETAIL:"SET_REQUEST_DOCUMENT_DETAIL",

  SET_REQUIRE_AUTHENTICATION: "SET_REQUIRE_AUTHENTICATION",
  SET_REQUIRE_AUTHENTICATION_OFF: "SET_REQUIRE_AUTHENTICATION_OFF",

  SET_HR_MESSAGE: "SET_HR_MESSAGE",
  SET_OTP_SETTING: "SET_OTP_SETTING",
  SET_USER_PROFILE:"SET_USER_PROFILE"
};

export default types;
