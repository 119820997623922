import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { getJobClassificationCompetencies } from "../../../store/structure/actions";
import JobClassificationCompetencyCard from "./JobClassificationCompetencyCard";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Switch,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import SearchBox from "../../common/components/SearchBox";
import { saveJcCompetency } from "../../../store/structure/actions";
const JobClassificationCompetencies = (props) => {
  const {
    loading,
    jobClassificationId,
    jobClassificationCompetencies,
    // competencies,
    saveJcCompetency,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [
    filteredJobClassificationCompetencies,
    setFilteredJobClassificationCompetencies,
  ] = useState([...jobClassificationCompetencies]);

  useEffect(() => {
    const filtered = jobClassificationCompetencies.filter(({ name }) =>
      name
        .toLowerCase()
        .replace(/ /g, "")
        .includes(searchTerm.toLowerCase().replace(/ /g, ""))
    );

    setFilteredJobClassificationCompetencies(filtered);

    paginate(filtered);
  }, [searchTerm, jobClassificationCompetencies]);

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 7;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredJobClassificationCompetencies);
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
        filteredJobClassificationCompetencies.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (idToSearch) =>
    jobClassificationCompetencies.findIndex(({ id }) => id === idToSearch);

  return (
    <>
      <SearchBox disabled={false} onSearch={onSearch} placeholder="Search…" />
      <List
        className="mt-2"
        sx={{
          borderRadius: "8px",
          border: `1px solid #c8c6c6`,
          backgroundColor: "#f9f9f9",
        }}
        dense
      >
        {!!paginatedItems &&
          paginatedItems.map((competency, index) => (
            <React.Fragment key={competency.id}>
              <ListItem dense>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      fontSize: "16px",
                      border: "1px solid #c8c6c6",
                    }}
                    className="bg-white text-dark "
                  >
                    {getNo(competency.id) + 1}
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <span
                      className={`${
                        !competency.added
                          ? "font-weight-light"
                          : "font-weight-bold"
                      }`}
                    >
                      {competency.name}
                    </span>
                  }
                />
                <Switch
                  disabled={loading}
                  edge="end"
                  onChange={(e) => {
                    saveJcCompetency({
                      jobClassificationId,
                      competencyId: competency.id,
                      added: e.target.checked,
                    });
                  }}
                  checked={competency.added}
                />
              </ListItem>

              {paginatedItems.length > index + 1 && <Divider />}
            </React.Fragment>
          ))}
      </List>

      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <span>
            <span className="pr-1 d-none d-md-inline">Next</span>
            <i className="fas fa-angle-double-right"></i>
          </span>
        }
        previousLabel={
          <span>
            <i className="fas fa-angle-double-left"></i>
            <span className="pl-1 d-none d-md-inline">Previous</span>
          </span>
        }
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination mt-2"
        pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
        previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
        nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
        activeLinkClassName="active"
      />
    </>
  );
};

const mapStateToProps = ({
  loading,
  jobClassificationCompetencies,
  // competencies,
}) => {
  return {
    loading,
    jobClassificationCompetencies,
    // competencies,
  };
};
export default connect(mapStateToProps, {
  saveJcCompetency,
})(JobClassificationCompetencies);
