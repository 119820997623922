import { connect } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

const TopProgressBar = (props) => {
  const { loading } = props;
  TopBarProgress.config({
    barColors: {
      0: "#f00",
      0.5: "#f00",
      1.0: "#f00",
    },
    shadowBlur: 5,
  });
  return <>{loading && <TopBarProgress />}</>;
};

const mapStateToProps = ({ loading }) => {
  return { loading };
};
export default connect(mapStateToProps)(TopProgressBar);
