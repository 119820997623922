import { Link } from "react-router-dom";

const Footer = (props) => {
  const { recruitmentUrl, selfServiceUrl } = props;
  return (
    <footer
      id="footer"
      className="text-center  pt-2"
      style={{
        backgroundColor: "#f6f8fa",
        borderTop: "1px solid rgba(0, 0, 0, 0.125)",
      }}
    >
      <span className="text-uppercase" style={{ fontSize: "12px" }}>
        &copy;{new Date().getFullYear()}{" "}
        <a
          href="https://www.gov.rw"
          target="_blank"
          rel="noreferrer"
          className="text-dark"
        >
          Government of Rwanda
        </a>
      </span>

      <div className="" style={{ fontSize: "10px" }}>
        <a
          className=" my-sm-0  text-uppercase"
          href={recruitmentUrl}
          target="_blank"
        >
          E-Recruitment
        </a>{" "}
        |{" "}
        <a
          className=" my-sm-0  text-uppercase"
          href={selfServiceUrl}
          target="_blank"
        >
          Self-service
        </a>{" "}
        |{" "}
        <Link className=" my-sm-0  text-uppercase" to="#">
          About IPPIS
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
