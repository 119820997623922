import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  DialogActions,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ErrorMessage from "../../common/components/ErrorMessage";
import ActionDialog from "../../common/components/ActionDialog";
import { showError } from "../../toastify";

import { submitSecondLevelAppealDecision } from "../../../store/recruitment/actions";
const SecondLevelAppealDecisionDialog = (props) => {
  const {
    user,
    loading,
    makeAppealSLDecision,
    setMakeAppealSLDecision,
    secondLevelAppeal,
    submitSecondLevelAppealDecision,
  } = props;

  const onClose = () => {
    setMakeAppealSLDecision(false);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const maxLength = 250;

  const [formData, setFormData] = useState({
    appealId: secondLevelAppeal ? secondLevelAppeal.id : 0,
    message:
      secondLevelAppeal && secondLevelAppeal.decision
        ? secondLevelAppeal.decision
        : "",
    isSecondLevelValid:
      secondLevelAppeal && secondLevelAppeal.isAppealValid
        ? secondLevelAppeal.isAppealValid
        : false,
    secondLevelValid:
      secondLevelAppeal && secondLevelAppeal.isAppealValid === true
        ? "yes"
        : secondLevelAppeal && secondLevelAppeal.isAppealValid === false
        ? "no"
        : "",
  });

  const [errors, setErrors] = useState({
    fileHasError: false,
    messageHasError: false,
    secondLevelValid: false,
    hasError: false,
  });

  //console.log(entitySectors);
  const formValidator = () => {
    const error = {
      messageHasError: false,
      secondLevelValid: false,
      hasError: false,
    };

    if (!formData.message) {
      error.messageHasError = true;
      error.hasError = true;
    }
    if (!formData.secondLevelValid) {
      error.secondLevelValid = true;
      error.hasError = true;
    }
    // if (!formData.attachedFiles.length) {
    //   error.fileHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!errors.fileHasError) {
      if (!formValidator()) {
        submitSecondLevelAppealDecision(
          {
            appealId: formData.appealId,
            message: formData.message,
            isValid: formData.isSecondLevelValid,
          },
          setFormData,
          setIsSubmitting,
          onClose
        );
      }
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={makeAppealSLDecision}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-11">
              <h5
                style={{
                  // background: "rgba(0,99,207,.08)",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  margin: "0 25px 32px 0",
                  width: "-moz-fit-content",
                  width: "fit-content",
                  color: "#0063cf",
                }}
              >
                {" "}
                Second Level Decision.
              </h5>
            </div>
            <div className="col-1">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <span className="material-icons">close</span>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mt-0">
              <div className="input-group input-group-sm mt-0">
                <div
                  className="input-group-prepend"
                  style={{
                    borderColor:
                      formData.secondLevelValid === "yes"
                        ? "#139647"
                        : formData.secondLevelValid === "no"
                        ? "#d62e4a"
                        : "",
                  }}
                >
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{
                      background:
                        formData.secondLevelValid === "yes"
                          ? "#139647"
                          : formData.secondLevelValid === "no"
                          ? "#d62e4a"
                          : "",
                      color:
                        formData.secondLevelValid === "yes"
                          ? "#fff"
                          : formData.secondLevelValid === "no"
                          ? "#fff"
                          : "",
                    }}
                  >
                    Is Appeal Valid?
                  </span>
                </div>
                <div
                  className="form-control"
                  style={{
                    borderColor:
                      formData.secondLevelValid === "yes"
                        ? "#139647"
                        : formData.secondLevelValid === "no"
                        ? "#d62e4a"
                        : "",
                  }}
                >
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="secondLevelValid"
                      name="secondLevelValid"
                      checked={formData.secondLevelValid === "yes"}
                      onChange={(event) => {
                        const isSecondLevelValid = true;
                        const secondLevelValid = "yes";
                        setFormData({
                          ...formData,
                          isSecondLevelValid,
                          secondLevelValid,
                        });
                        setErrors({
                          ...errors,
                          secondLevelValid: false,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="Yes">
                      Yes
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="secondLevelValid"
                      name="secondLevelValid"
                      checked={formData.secondLevelValid === "no"}
                      onChange={(event) => {
                        const isSecondLevelValid = false;
                        const secondLevelValid = "no";
                        setFormData({
                          ...formData,
                          isSecondLevelValid,
                          secondLevelValid,
                        });
                        setErrors({
                          ...errors,
                          secondLevelValid: false,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="No">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <ErrorMessage
                hasError={errors.secondLevelValid}
                message="Please confirm if the appeal is valid or not!"
              />
            </div>
            <div className="col-lg-12 mt-4">
              <TextField
                fullWidth
                multiline
                rows={4}
                size="small"
                name="message"
                label={`Please type your decision in ${maxLength} words`}
                variant="outlined"
                placeholder={`Please type your decision in ${maxLength} words`}
                value={formData.message}
                inputProps={{
                  maxLength: maxLength,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const message = e.target.value;
                  setFormData({
                    ...formData,
                    message,
                  });

                  setErrors({
                    ...errors,
                    messageHasError: false,
                  });
                }}
              />
              {!!formData.message.length && (
                <div className="float-right text-primary">
                  {formData.message.length}/{maxLength}
                </div>
              )}

              <ErrorMessage
                hasError={errors.messageHasError}
                message="Appeal decision is required"
              />
            </div>
          </div>

          {isSubmitting && (
            <ActionDialog showAction={isSubmitting} action="Submitting" />
          )}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary  px-4"
            disabled={loading || isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  submitSecondLevelAppealDecision,
})(SecondLevelAppealDecisionDialog);
