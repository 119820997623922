import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";

import { addNewField, deleteJobField } from "../../../store/structure/actions";
import SearchBox from "../../common/components/SearchBox";
import ReactPaginate from "react-paginate";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const ActiveButton = {
  borderRadius: "8px",
  border: `1px solid #007bff`,
  height: "25px",
  color: "#fff",
  alignItems: "center",
  fontSize: ".70rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "#007bff",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const AllJobFieldsDialog = (props) => {
  const {
    showAllJobFieldsDialog,
    setShowAllJobFieldsDialog,
    jobFields,
    addNewField,
    deleteJobField,
    loading,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobFields, setFilteredJobFields] = useState([...jobFields]);

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  // const getFilteredJobFields = () => {
  //   if (jobFields.length && !!searchTerm)
  //     return jobFields.filter(({ name }) =>
  //       name
  //         .toLowerCase()
  //         .replace(/ /g, "")
  //         .includes(searchTerm.toLowerCase().replace(/ /g, ""))
  //     );

  //   else return jobFields;
  // };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredJobFields);
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredJobFields.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (idToSearch) =>
    jobFields.findIndex(({ id }) => id === idToSearch);

  const [showAdd, setShowAdd] = useState(false);
  const [confirmRemoveJobField, setConfirmRemoveJobField] = useState(false);

  const [jobField, setJobField] = useState({
    id: null,
    name: "",
  });

  useEffect(() => {
    const filtered = jobFields.filter(({ name }) =>
      name
        .toLowerCase()
        .replace(/ /g, "")
        .includes(searchTerm.toLowerCase().replace(/ /g, ""))
    );

    setFilteredJobFields(filtered);

    paginate(filtered);
  }, [searchTerm, jobFields]);

  return (
    <>
      <Dialog
        onClose={() => setShowAllJobFieldsDialog(false)}
        open={showAllJobFieldsDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            <span className="badge badge-secondary p-2 mr-1">
              {filteredJobFields.length}
            </span>
            JobFields available
            <Button
              onClick={() => setShowAdd(true)}
              style={{
                ...ActiveButton,
              }}
              size="small"
              className="ml-2"
            >
              <span className="material-icons">add</span> New
            </Button>
          </Typography>
          <Tooltip title="Download">
            <span>
              <IconButton
                disabled
                className="p-0"
                color="primary"
                sx={{
                  position: "absolute",
                  right: 54,
                  top: 12,
                }}
              >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              </IconButton>
            </span>
          </Tooltip>

          <IconButton
            aria-label="close"
            onClick={() => setShowAllJobFieldsDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className="pt-2 pb-0">
          <div
            className=" rounded "
            style={{
              // backgroundColor: "#f9f9f9",
              // border: "1px solid #17a2b8",
              minHeight: "60vh",
            }}
          >
            <SearchBox
              disabled={false}
              onSearch={onSearch}
              placeholder="Search…"
            />
            {!!paginatedItems && (
              <List
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                  border: `1px solid #c8c6c6`,
                }}
                dense
                className="bg-light mt-2"
              >
                {paginatedItems.map((jobField, index) => (
                  <React.Fragment key={jobField.id}>
                    <ListItem
                      secondaryAction={
                        <span
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #c8c6c6",
                          }}
                          className="d-inline-flex p-1 rounded"
                        >
                          <IconButton
                            size="small"
                            edge="end"
                            color="primary"
                            onClick={() => {
                              setJobField(jobField);
                              setShowAdd(true);
                            }}
                          >
                            <span className="material-icons">edit</span>
                          </IconButton>
                          <IconButton
                            size="small"
                            edge="end"
                            color="error"
                            onClick={() => {
                              setJobField(jobField);
                              setConfirmRemoveJobField(true);
                            }}
                          >
                            <span className="material-icons">
                              delete_outline
                            </span>
                          </IconButton>
                        </span>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            fontSize: "16px",
                            border: "1px solid #c8c6c6",
                          }}
                          className="bg-white text-dark "
                        >
                          {getNo(jobField.id) + 1}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<span>{jobField.name.toUpperCase()}</span>}
                      />
                    </ListItem>
                    {paginatedItems.length > index + 1 && <Divider />}
                  </React.Fragment>
                ))}

                {!paginatedItems.length && (
                  <div className="text-center">No jobFields found</div>
                )}
              </List>
            )}
          </div>

          {showAdd && (
            <AddNewField
              showAdd={showAdd}
              setShowAdd={setShowAdd}
              addNewField={addNewField}
              loading={loading}
              jobField={jobField}
              setJobField={setJobField}
            />
          )}

          {confirmRemoveJobField && !!jobField.id && (
            <ConfirmationDialog
              confirmationDialog={confirmRemoveJobField}
              message={`REMOVE "${jobField.name.toUpperCase()}"?`}
              setConfirmationDialog={setConfirmRemoveJobField}
              onYes={() => {
                deleteJobField(
                  jobField.id,
                  setConfirmRemoveJobField,
                  setJobField
                );
              }}
            />
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <span>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </span>
            }
            previousLabel={
              <span>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1 d-none d-md-inline">Previous</span>
              </span>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
            activeLinkClassName="active"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, jobFields }) => {
  return {
    loading,
    jobFields,
  };
};

export default connect(mapStateToProps, {
  addNewField,
  deleteJobField,
})(AllJobFieldsDialog);

const AddNewField = (props) => {
  const { showAdd, setShowAdd, loading, addNewField, jobField, setJobField } =
    props;

  const onSave = () => {
    addNewField(jobField, setShowAdd, setJobField);
  };

  const onClose = () => {
    setShowAdd(false);

    setJobField({ id: null, name: "" });
  };

  return (
    <Dialog onClose={onClose} open={showAdd} fullWidth maxWidth="xs">
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          {!!jobField.id ? "Edit" : "Add new"} field
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="row no-gutters mt-2">
          <div className="col-9">
            <TextField
              size="small"
              name="fieldName"
              fullWidth
              label="Type fields name"
              variant="outlined"
              value={jobField.name.toUpperCase()}
              onChange={(e) => {
                setJobField({ ...jobField, name: e.target.value || "" });
              }}
            />
          </div>
          <div className="col-3">
            <button
              type="button"
              className="btn btn-primary text-uppercase ml-2"
              disabled={loading}
              onClick={onSave}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="py-3"></DialogActions>
    </Dialog>
  );
};
