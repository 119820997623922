import types from "./action-types";
import { defaultState } from "./state";

const env = (envState = defaultState.env, action) => {
  switch (action.type) {
    case types.SET_ENV:
      return action.data;

    default:
      return envState;
  }
};

const hrMessage = (hrMessageState = defaultState.hrMessage, action) => {
  switch (action.type) {
    case types.SET_HR_MESSAGE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.hrMessage;

    default:
      return hrMessageState;
  }
};

const selectedEmployeeProfilePicture = (
  selectedEmployeeProfilePictureState = defaultState.selectedEmployeeProfilePicture,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_EMPLOYEE_PROFILE_PICTURE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedEmployeeProfilePicture;

    default:
      return selectedEmployeeProfilePictureState;
  }
};

const userProfilePicture = (
  userProfilePictureState = defaultState.userProfilePicture,
  action
) => {
  switch (action.type) {
    case types.SET_USER_PROFILE_PICTURE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.userProfilePicture;

    default:
      return userProfilePictureState;
  }
};

const loading = (loadingState = defaultState.loading, action) => {
  switch (action.type) {
    case types.START_LOADING:
      return true;

    case types.END_LOADING:
      return false;

    case types.CLEAN_STATE:
      return defaultState.loading;

    default:
      return loadingState;
  }
};

const onWait = (waitState = defaultState.onWait, action) => {
  switch (action.type) {
    case types.START_WAIT:
      return true;

    case types.END_WAIT:
      return false;

    case types.CLEAN_STATE:
      return defaultState.onWait;

    default:
      return waitState;
  }
};

const isFetchingUser = (state = defaultState.fetchingUser, action) => {
  switch (action.type) {
    case types.END_LOADING:
      return false;

    default:
      return state;
  }
};

const strongPassword = (
  strongPasswordState = defaultState.strongPassword,
  action
) => {
  switch (action.type) {
    case types.SET_STRONG_PASSWORD:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.strongPassword;

    default:
      return strongPasswordState;
  }
};

const user = (userState = defaultState.user, action) => {
  switch (action.type) {
    case types.SET_USER:
      return action.data;

    case types.SET_SELECTED_ENTITY_SECTOR: {
      const tmpUserState = { ...userState };
      const selectedEntitySector = tmpUserState.entitySectors.find(
        ({ id }) => id === action.entitySectorId
      );

      tmpUserState.selectedEntitySector = selectedEntitySector;

      return tmpUserState;
    }

    case types.SET_OTP_SETTING: {
      const tmpUserState = { ...userState };

      return {
        ...tmpUserState,
        disableTwoFactorAuth: !userState.disableTwoFactorAuth,
      };
    }

    case types.LOGOUT:
      return defaultState.user;

    default:
      return userState;
  }
};

const ippisModules = (state = defaultState.ippisModules, action) => {
  switch (action.type) {
    case types.SET_IPPIS_MODULES:
      return action.data;

    case types.LOGOUT:
      return defaultState.ippisModules;

    default:
      return state;
  }
};

const selectedModule = (state = defaultState.selectedModule, action) => {
  switch (action.type) {
    case types.SET_SELECTED_MODULE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedModule;

    default:
      return state;
  }
};

const requestDocumentDetail = (requestDocumentDetailState = defaultState.requestDocumentDetail, action) => {
  switch (action.type) {
    case types.SET_REQUEST_DOCUMENT_DETAIL:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requestDocumentDetail;

    default:
      return requestDocumentDetailState;
  }
};
const userProfile = (userProfileState = defaultState.userProfile, action) => {
  switch (action.type) {
    case types.SET_USER_PROFILE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.userProfile;

    default:
      return userProfileState;
  }
};

//
const requireAuth = (state = defaultState.requireAuth, action) => {
  switch (action.type) {
    case types.SET_REQUIRE_AUTHENTICATION:
      return true;

    case types.LOGOUT:
    case types.SET_USER:
    case types.SET_REQUIRE_AUTHENTICATION_OFF:
      return defaultState.requireAuth;

    default:
      return state;
  }
};

export default {
  hrMessage,
  loading,
  onWait,
  isFetchingUser,
  strongPassword,
  user,
  ippisModules,
  selectedModule,
  env,
  selectedEmployeeProfilePicture,
  userProfilePicture,
  requestDocumentDetail,
  requireAuth,
  userProfile
};
