import Alert from '@mui/material/Alert';

const ErrorMessage = (props) => {
  const {hasError,  message } = props;

  return (
    <>
      {hasError && (
         <Alert severity="error" className='mt-2 mb-2'>
         {message && <span>{message}</span>} 
       </Alert>
      )}
    </>
  );
};

export default ErrorMessage;
