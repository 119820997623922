import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import defaultProfile from "../../assets/default-profile.jpg";
import logoSmall from "../../assets/logo-small.png";

import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

import { connect } from "react-redux";

import {
  logout,
  selectModule,
  getUserProfilePicture,
} from "../../../store/common/actions";
import EntitySwitcher from "./EntitySwitcher";
import { isEmpty } from "lodash";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CableIcon from "@mui/icons-material/Cable";
import ConnectToRisaPKIDialog from "./ConnectToRisaPKIDialog";
import { openCenteredPopup } from "./Utils";
import UserSignatureDialog from "./UserSignatureDialog";
import DigitalCertificateDialog from "./DigitalCertificateDialog";
const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    drawerState,
    setDrawerState,
    user,
    logout,
    ippisModules,
    selectedModule,
    selectModule,
    env,
    recruitmentUrl,
    selfServiceUrl,
    userProfilePicture,
    getUserProfilePicture,
  } = props;

  const [anchorElUser, setAnchorElUser] = useState(null);
  const userMenuOpen = Boolean(anchorElUser);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [userSignature, setUserSignature] = useState(null);
  const [anchorElModules, setAnchorElModules] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showDigitalCertificateDialog, setShowDigitalCertificateDialog] =
    useState(false);
  const modulesMenuOpen = Boolean(anchorElModules);
  const [showUserSignatureDialog, setShowUserSignatureDialog] = useState(false);

  const handleOpenModulesMenu = (event) => {
    setAnchorElModules(event.currentTarget);
  };

  const handleCloseModulesMenu = () => {
    setAnchorElModules(null);
  };

  useEffect(() => {
    if (!isEmpty(user) && !userProfilePicture)
      getUserProfilePicture(user.idNumber);
  }, [user]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} className="app-bar">
        <Toolbar className="pr-2 tool-bar">
          {!isEmpty(user) && location.pathname !== "/" && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              className="mr-2"
              onClick={() => {
                setDrawerState(!drawerState);
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography component="div" sx={{ flexGrow: 1 }} className="left">
            <img
              src={logoSmall}
              className="brand_logo mr-2 d-none d-md-inline"
              alt="Logo"
            />
            <strong className="">
              <span style={{ color: "#007bff", fontWeight: "bolder" }}>
                IPPIS
              </span>
            </strong>

            {isEmpty(selectedModule) && (
              <span className="ml-1 d-none d-md-inline text-dark text-uppercase ">
                {" - "} Integrated Payroll and Personnel Information System
              </span>
            )}
            {!isEmpty(selectedModule) && (
              <span className=" d-none d-md-inline text-dark text-uppercase ">
                {" - "} {selectedModule.fullName}
              </span>
            )}
            {!isEmpty(selectedModule) && (
              <strong className="d-inline d-md-none text-uppercase">
                {" - "} <span> {selectedModule.shortName}</span>
              </strong>
            )}
          </Typography>

          {!isEmpty(user.selectedEntitySector) && (
            <span className="d-none d-md-inline">
              <EntitySwitcher />
            </span>
          )}

          {isEmpty(user.selectedEntitySector) && (
            <>
              <div className="mr-2 d-block d-md-none">
                <IconButton onClick={handleOpenModulesMenu} className="p-0 ">
                  <span className="material-icons-outlined">more_vert</span>
                </IconButton>
                <Menu
                  anchorEl={anchorElModules}
                  id="other-modules"
                  open={modulesMenuOpen}
                  onClose={handleCloseModulesMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseModulesMenu();
                    }}
                  >
                    <a
                      className=" my-sm-0  text-uppercase text-primary "
                      href={recruitmentUrl}
                      target="_blank"
                    >
                      E-Recruitment
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseModulesMenu();
                    }}
                  >
                    <a
                      className=" my-sm-0  text-uppercase text-primary "
                      href={selfServiceUrl}
                      target="_blank"
                    >
                      Self-service
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseModulesMenu();
                      history.push("#");
                    }}
                  >
                    <Link
                      className=" my-sm-0  text-uppercase text-primary "
                      to="#"
                    >
                      About IPPIS
                    </Link>
                  </MenuItem>
                </Menu>
              </div>

              <div className="mr-2 d-none d-md-block">
                <a
                  className=" my-sm-0  text-uppercase text-primary "
                  href={recruitmentUrl}
                  target="_blank"
                >
                  E-Recruitment
                </a>{" "}
                |{" "}
                <a
                  className=" my-sm-0  text-uppercase text-primary "
                  href={selfServiceUrl}
                  target="_blank"
                >
                  Self-service
                </a>{" "}
                {isEmpty(user) && (
                  <>
                    |{" "}
                    <Link
                      className=" my-sm-0  text-uppercase text-primary "
                      to="#"
                    >
                      About IPPIS
                    </Link>
                  </>
                )}
              </div>
            </>
          )}

          {!isEmpty(user) &&
            !isEmpty(user.selectedEntitySector) &&
            !isEmpty(selectedModule) && (
              <div className="roles text-primary  d-flex">
                {user.selectedEntitySector.roles[selectedModule.id]?.length >
                  0 && (
                  <>
                    {user.selectedEntitySector.roles[selectedModule.id]?.map(
                      (role, index) => (
                        <strong className="d-flex " key={role.id}>
                          <span className="role text-truncate">
                            {role.name}
                          </span>
                          {index + 1 <
                            user.selectedEntitySector.roles[selectedModule.id]
                              .length && (
                            <DragIndicatorIcon style={{ color: "#cecece" }} />
                          )}
                        </strong>
                      )
                    )}
                  </>
                )}
                {user.selectedEntitySector.roles[selectedModule.id]?.length ===
                  0 && (
                  <strong className=" d-flex ">
                    <span className="role text-truncate">Employees</span>
                  </strong>
                )}
                <ArrowLeftIcon />
              </div>
            )}

          {!isEmpty(user) && (
            <>
              {showDialog && (
                <ConnectToRisaPKIDialog
                  user={user}
                  showDialog={showDialog}
                  setShowDialog={setShowDialog}
                />
              )}
              {showUserSignatureDialog && (
                <UserSignatureDialog
                  userSignature={userSignature}
                  setUserSignature={setUserSignature}
                  showUserSignatureDialog={showUserSignatureDialog}
                  setShowUserSignatureDialog={setShowUserSignatureDialog}
                />
              )}
              {showDigitalCertificateDialog && (
                <DigitalCertificateDialog
                  user={user}
                  showDialog={showDigitalCertificateDialog}
                  setShowDialog={setShowDigitalCertificateDialog}
                />
              )}

              <span className="mr-1 badge badge-secondary">
                {" "}
                {user.firstName}
              </span>
              <IconButton onClick={handleOpenUserMenu} className="p-0 ">
                {!userProfilePicture && (
                  <Avatar alt="Profile picture" src={defaultProfile} />
                )}
                {userProfilePicture && (
                  <Avatar
                    style={{ border: "1px solid #c3c4c6" }}
                    alt="Profile picture"
                    src={"data:image/jpg;base64," + userProfilePicture}
                  />
                )}
              </IconButton>
              <Menu
                anchorEl={anchorElUser}
                id="account-menu"
                open={userMenuOpen}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem disabled className="text-uppercase">
                  {user.lastName} {user.firstName}
                </MenuItem>
                <Divider />

                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    history.push("/user/profile");
                  }}
                >
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                {/* <MenuItem
                  disabled={isWaiting}
                  onClick={() => {
                    getUserSignature(
                      setShowUserSignatureDialog,
                      handleCloseUserMenu,
                      setIsWaiting
                    );
                  }}
                >
                  <ListItemIcon>
                    <span className="material-icons mr-1">draw</span>
                  </ListItemIcon>
                  My signature
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    setShowDigitalCertificateDialog(true);
                  }}
                >
                  <ListItemIcon>
                    <span className="material-icons mr-1">card_membership</span>
                  </ListItemIcon>
                  My digital certificate
                </MenuItem>

                {/* {!user.dn && (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      openCenteredPopup(
                        `${process.env.REACT_APP_FILES_URL}/risa-dn/registration/${user.id}`
                      );
                    }}
                  >
                    <ListItemIcon>
                      <CableIcon />
                    </ListItemIcon>
                    Connect to GOVCA - PKI
                  </MenuItem>
                )} */}

                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    history.push("/user/account");
                  }}
                >
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  My account
                </MenuItem>
                <Divider />

                <MenuItem
                  className="text-danger"
                  onClick={() => {
                    handleCloseUserMenu();
                    logout(history);
                  }}
                >
                  <ListItemIcon className="text-danger">
                    <LogoutIcon />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
              {/* <span className="d-inline d-lg-none">
                <IconButton
                  edge="start"
                  size="small"
                  color="inherit"
                  aria-label="menu"
                  className="ml-2"
                  onClick={handleOpenModulesMenu}
                >
                  <span className="material-icons">more_vert</span>
                </IconButton>
                <Menu
                  anchorEl={anchorElModules}
                  open={modulesMenuOpen}
                  onClose={handleCloseModulesMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflowY: "scroll",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem disabled>IPPIS MODULES</MenuItem>
                  <Divider />

                  {ippisModules.map((mod) => (
                    <MenuItem
                      key={mod.shortName}
                      onClick={() => {
                        handleCloseModulesMenu();
                        history.push(mod.to);
                      }}
                    >
                      <ListItemIcon>
                        <img src={mod.icon} width="20" alt="" />
                      </ListItemIcon>
                      {mod.shortName}
                    </MenuItem>
                  ))}
                </Menu>
              </span> */}
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const mapStateToProps = ({
  user,
  loading,
  ippisModules,
  selectedModule,
  env,
  userProfilePicture,
}) => {
  return {
    user,
    loading,
    ippisModules,
    selectedModule,
    env,
    userProfilePicture,
  };
};
export default connect(mapStateToProps, {
  logout,
  selectModule,
  getUserProfilePicture,
})(Header);
