import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { deletePositionCompetency } from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ListSkeleton from "../../common/components/ListSkeleton";

const PositionCompetencies = (props) => {
  const { loading, onWait, positionCompetencies, deletePositionCompetency } =
    props;

  // useEffect(() => {
  //   getpositionCompetencies(position.id);
  // }, [position]);

  //PAGINATION
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [confirmRemoveCompetency, setConfirmRemoveCompetency] = useState(false);

  const { hasPermissions } = useRequiredAuth();
  const getNo = (idToSearch) =>
    positionCompetencies.findIndex(({ id }) => id === idToSearch);
  return (
    <>
      {confirmRemoveCompetency && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveCompetency}
          isLoading={onWait}
          message={`Are you sure you want to remove this Competency(${selectedCompetency.competencyName})?`}
          setConfirmationDialog={setConfirmRemoveCompetency}
          onYes={() => {
            deletePositionCompetency(
              selectedCompetency,
              setConfirmRemoveCompetency,
              setSelectedCompetency
            );
          }}
        />
      )}
      {(!loading || onWait) && !!positionCompetencies.length && (
        <div className="card service-card valid mb-2 mt-2">
          <div
            className="card-header float-left text-left"
            style={{ padding: "0.25rem 1.2rem" }}
          >
            Competencies
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <List
                  className="mt-2"
                  sx={{
                    borderRadius: "8px",
                    border: `1px solid #c8c6c6`,
                    backgroundColor: "#f9f9f9",
                  }}
                  dense
                >
                  {positionCompetencies.map((competency, index) => (
                    <React.Fragment key={competency.id}>
                      <ListItem
                        dense
                        secondaryAction={
                          <>
                            {!!competency.positionId &&
                              hasPermissions([
                                "IS_HR",
                                "IS_STRUCTURE_OVERSIGHT",
                              ]) && (
                                <span>
                                  <IconButton
                                    style={{ float: "right" }}
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                      setSelectedCompetency(competency);
                                      setConfirmRemoveCompetency(true);
                                    }}
                                    aria-label="settings"
                                  >
                                    <span className="material-icons">
                                      delete_outline
                                    </span>
                                  </IconButton>
                                </span>
                              )}
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              fontSize: "16px",
                              border: "1px solid #c8c6c6",
                            }}
                            className="bg-white text-dark "
                          >
                            {getNo(competency.id) + 1}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            <span className={`${"font-weight-light"}`}>
                              {competency.competencyName}
                            </span>
                          }
                        />
                      </ListItem>

                      {positionCompetencies.length > index + 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <ListSkeleton />}
      {(!loading || onWait) && !positionCompetencies.length && (
        <div
          className="jumbotron jumbotron-fluid text-center mt-2"
          style={{ width: "100%" }}
        >
          <div className="container">
            <p className="lead">No competencies found</p>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  positionCompetencies,
  position,
}) => {
  return {
    loading,
    onWait,
    positionCompetencies,
    position,
  };
};
export default connect(mapStateToProps, {
  deletePositionCompetency,
})(PositionCompetencies);
