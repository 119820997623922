import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  saveJobClassification,
  getJobClassifications,
  getLevels,
  getJobFields,
} from "../../../store/structure/actions";
import { showError } from "../../../app/toastify";

const AddJobClassificationDialog = (props) => {
  const {
    loading,
    showAddJobForm,
    setShowAddJobForm,
    jobClassifications,
    getJobClassifications,
    saveJobClassification,
    levels,
    getLevels,
    jobFields,
    getJobFields,
    jobs,
    entityClasses,
    jobCategories,
  } = props;

  //
  const [entityClass, setEntityClass] = useState(null);
  const [jobCategory, setJobCategory] = useState(null);
  const [level, setLevel] = useState(null);
  const [jobField, setJobField] = useState(null);
  //const [showAddJobFieldForm, setAddJobFieldForm] = useState(false);
  const [job, setJob] = useState(null);

  const [formData, setFormData] = useState({
    jobId: "",
    entityClassId: "",
    jobCategoryId: "",
    levelId: "",
  });

  const [errors, setErrors] = useState({
    jobIdHasError: false,
    entityClassIdHasError: false,
    jobCategoryIdHasError: false,
    levelIdHasError: false,
  });

  const formValidator = () => {
    const error = {
      jobIdHasError: false,
      entityClassIdHasError: false,
      jobCategoryIdHasError: false,
      levelIdHasError: false,
      hasError: false,
    };

    if (!formData.jobId) {
      error.jobIdHasError = true;
      error.hasError = true;
    }

    if (!formData.entityClassId) {
      error.entityClassIdHasError = true;
      error.hasError = true;
    }

    if (!formData.jobCategoryId) {
      error.jobCategoryIdHasError = true;
      error.hasError = true;
    }

    if (!formData.levelId) {
      error.levelIdHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveJobClassification(formData, setFormData, setShowAddJobForm);
    }
  };

  const onClose = () => {
    setShowAddJobForm(false);
    setFormData({
      name: "",
      entityClassId: "",
      jobCategoryId: "",
      jobCategoryName: "",
      levelId: "",
      levelName: "",
      jobFieldId: "",
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAddJobForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Add New Job Classification
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="rounded p-3"
            style={{
              backgroundColor: "#f9f9f9",
              border: "1px solid #17a2b8",
              minHeight: "45vh",
            }}
          >
            <div className="row mt-3 ">
              <div className="col-12">
                <Autocomplete
                  size="small"
                  name="entityClass"
                  fullWidth
                  className="mb-3"
                  placeholder="Entity class"
                  defaultValue={null}
                  value={entityClass}
                  options={entityClasses}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, entityClass) => {
                    setEntityClass(entityClass);
                    setJobCategory(null);
                    setLevel(null);
                    setFormData({
                      ...formData,
                      entityClassId: entityClass?.id || "",
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, entityClass) => (
                    <Box component="li" {...props}>
                      {entityClass.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Entity class"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "entityClass", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.entityClassIdHasError && (
                  <small className="text-danger mb-2">
                    {" "}
                    Entity class is required
                  </small>
                )}
              </div>
              <div className="col-lg-12 ">
                <Autocomplete
                  size="small"
                  fullWidth
                  name="jobId"
                  className="mb-3"
                  defaultValue={null}
                  placeholder="Job title"
                  value={job}
                  options={jobs}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, job) => {
                    setJob(job || null);
                    const jobId = job ? job.id : "";
                    setFormData({ ...formData, jobId });
                    const jobHasError = false;
                    setErrors({ ...errors, jobHasError });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, job) => (
                    <Box component="li" {...props}>
                      {job.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Job title"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "job",
                      }}
                    />
                  )}
                />

                {errors.jobIdHasError && (
                  <div className="text-danger mb-2">
                    {" "}
                    Job title is required{" "}
                  </div>
                )}
              </div>
              <div className="col-12 ">
                <Autocomplete
                  size="small"
                  name="jobCategory"
                  fullWidth
                  className="mb-3"
                  placeholder="Job category"
                  defaultValue={null}
                  value={jobCategory}
                  options={jobCategories}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, jobCategory) => {
                    setJobCategory(jobCategory || null);
                    setLevel(null);

                    setFormData({
                      ...formData,
                      jobCategoryId: jobCategory?.id,
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, jobCategory) => (
                    <Box component="li" {...props}>
                      {jobCategory.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Job category"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "jobCategory", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.jobCategoryIdHasError && (
                  <div className="text-danger mb-2">
                    Job category is required
                  </div>
                )}
              </div>
              <div className="col-12 ">
                <Autocomplete
                  size="small"
                  name="levelId"
                  fullWidth
                  className="mb-3"
                  defaultValue={null}
                  placeholder="Level"
                  value={level}
                  options={levels}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, level) => {
                    setLevel(level || null);
                    setFormData({ ...formData, levelId: level?.id || "" });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, level) => (
                    <Box component="li" {...props}>
                      {level.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Level"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "level", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.levelIdHasError && (
                  <div className="text-danger mb-2">Level is required</div>
                )}
              </div>
            </div>
            <div className="text-center">
              <button
                onClick={onSave}
                type="button"
                className="btn btn-primary text-uppercase  px-4"
                disabled={loading}
              >
                {loading ? "Wait..." : "Save"}
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="py-3"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  jobClassifications,
  levels,
  jobFields,
  jobs,
  entityClasses,
  jobCategories,
}) => {
  return {
    loading,
    jobClassifications,
    levels,
    jobFields,
    jobs,
    entityClasses,
    jobCategories,
  };
};
export default connect(mapStateToProps, {
  saveJobClassification,
  getJobClassifications,
  getLevels,
  getJobFields,
})(AddJobClassificationDialog);
