// automatically generated by the FlatBuffers compiler, do not modify
/* eslint-disable */

import {flatbuffers} from 'flatbuffers';

/**
 * @enum {number}
 */
export namespace onnxruntime.experimental.fbs {
  export enum AttributeType {
    UNDEFINED = 0,
    FLOAT = 1,
    INT = 2,
    STRING = 3,
    TENSOR = 4,
    GRAPH = 5,
    FLOATS = 6,
    INTS = 7,
    STRINGS = 8,
    TENSORS = 9,
    GRAPHS = 10,
    SPARSE_TENSOR = 11,
    SPARSE_TENSORS = 12
  }
}

/**
 * @enum {number}
 */
export namespace onnxruntime.experimental.fbs {
  export enum DimensionValueType {UNKNOWN = 0, VALUE = 1, PARAM = 2}
}

/**
 * @enum {number}
 */
export namespace onnxruntime.experimental.fbs {
  export enum TensorDataType {
    UNDEFINED = 0,
    FLOAT = 1,
    UINT8 = 2,
    INT8 = 3,
    UINT16 = 4,
    INT16 = 5,
    INT32 = 6,
    INT64 = 7,
    STRING = 8,
    BOOL = 9,
    FLOAT16 = 10,
    DOUBLE = 11,
    UINT32 = 12,
    UINT64 = 13,
    COMPLEX64 = 14,
    COMPLEX128 = 15,
    BFLOAT16 = 16,
    FLOAT8E4M3FN = 17,
    FLOAT8E4M3FNUZ = 18,
    FLOAT8E5M2 = 19,
    FLOAT8E5M2FNUZ = 20,
  }
}

/**
 * @enum {number}
 */
export namespace onnxruntime.experimental.fbs {
  export enum NodeType {Primitive = 0, Fused = 1}
}

/**
 * @enum {number}
 */
export namespace onnxruntime.experimental.fbs {
  export enum TypeInfoValue {NONE = 0, tensor_type = 1, sequence_type = 2, map_type = 3}
}

/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class Shape {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns Shape
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): Shape {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Shape= obj
     * @returns Shape
     */
    static getRootAsShape(bb: flatbuffers.ByteBuffer, obj?: Shape): Shape {
      return (obj || new Shape()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Shape= obj
     * @returns Shape
     */
    static getSizePrefixedRootAsShape(bb: flatbuffers.ByteBuffer, obj?: Shape): Shape {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new Shape()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.Dimension= obj
     * @returns onnxruntime.experimental.fbs.Dimension
     */
    dim(index: number, obj?: onnxruntime.experimental.fbs.Dimension): onnxruntime.experimental.fbs.Dimension|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? (obj || new onnxruntime.experimental.fbs.Dimension())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    dimLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startShape(builder: flatbuffers.Builder) {
      builder.startObject(1);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset dimOffset
     */
    static addDim(builder: flatbuffers.Builder, dimOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, dimOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createDimVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startDimVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endShape(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createShape(builder: flatbuffers.Builder, dimOffset: flatbuffers.Offset): flatbuffers.Offset {
      Shape.startShape(builder);
      Shape.addDim(builder, dimOffset);
      return Shape.endShape(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class Dimension {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns Dimension
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): Dimension {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Dimension= obj
     * @returns Dimension
     */
    static getRootAsDimension(bb: flatbuffers.ByteBuffer, obj?: Dimension): Dimension {
      return (obj || new Dimension()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Dimension= obj
     * @returns Dimension
     */
    static getSizePrefixedRootAsDimension(bb: flatbuffers.ByteBuffer, obj?: Dimension): Dimension {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new Dimension()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param onnxruntime.experimental.fbs.DimensionValue= obj
     * @returns onnxruntime.experimental.fbs.DimensionValue|null
     */
    value(obj?: onnxruntime.experimental.fbs.DimensionValue): onnxruntime.experimental.fbs.DimensionValue|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? (obj || new onnxruntime.experimental.fbs.DimensionValue())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    denotation(): string|null;
    denotation(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    denotation(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startDimension(builder: flatbuffers.Builder) {
      builder.startObject(2);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset valueOffset
     */
    static addValue(builder: flatbuffers.Builder, valueOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, valueOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset denotationOffset
     */
    static addDenotation(builder: flatbuffers.Builder, denotationOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, denotationOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endDimension(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createDimension(
        builder: flatbuffers.Builder, valueOffset: flatbuffers.Offset,
        denotationOffset: flatbuffers.Offset): flatbuffers.Offset {
      Dimension.startDimension(builder);
      Dimension.addValue(builder, valueOffset);
      Dimension.addDenotation(builder, denotationOffset);
      return Dimension.endDimension(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class DimensionValue {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns DimensionValue
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): DimensionValue {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param DimensionValue= obj
     * @returns DimensionValue
     */
    static getRootAsDimensionValue(bb: flatbuffers.ByteBuffer, obj?: DimensionValue): DimensionValue {
      return (obj || new DimensionValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param DimensionValue= obj
     * @returns DimensionValue
     */
    static getSizePrefixedRootAsDimensionValue(bb: flatbuffers.ByteBuffer, obj?: DimensionValue): DimensionValue {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new DimensionValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @returns onnxruntime.experimental.fbs.DimensionValueType
     */
    dimType(): onnxruntime.experimental.fbs.DimensionValueType {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? /**  */ (this.bb!.readInt8(this.bb_pos + offset)) :
                      onnxruntime.experimental.fbs.DimensionValueType.UNKNOWN;
    }

    /**
     * @returns flatbuffers.Long
     */
    dimValue(): flatbuffers.Long {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.readInt64(this.bb_pos + offset) : this.bb!.createLong(0, 0);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    dimParam(): string|null;
    dimParam(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    dimParam(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startDimensionValue(builder: flatbuffers.Builder) {
      builder.startObject(3);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param onnxruntime.experimental.fbs.DimensionValueType dimType
     */
    static addDimType(builder: flatbuffers.Builder, dimType: onnxruntime.experimental.fbs.DimensionValueType) {
      builder.addFieldInt8(0, dimType, onnxruntime.experimental.fbs.DimensionValueType.UNKNOWN);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Long dimValue
     */
    static addDimValue(builder: flatbuffers.Builder, dimValue: flatbuffers.Long) {
      builder.addFieldInt64(1, dimValue, builder.createLong(0, 0));
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset dimParamOffset
     */
    static addDimParam(builder: flatbuffers.Builder, dimParamOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, dimParamOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endDimensionValue(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createDimensionValue(
        builder: flatbuffers.Builder, dimType: onnxruntime.experimental.fbs.DimensionValueType,
        dimValue: flatbuffers.Long, dimParamOffset: flatbuffers.Offset): flatbuffers.Offset {
      DimensionValue.startDimensionValue(builder);
      DimensionValue.addDimType(builder, dimType);
      DimensionValue.addDimValue(builder, dimValue);
      DimensionValue.addDimParam(builder, dimParamOffset);
      return DimensionValue.endDimensionValue(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class TensorTypeAndShape {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns TensorTypeAndShape
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): TensorTypeAndShape {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param TensorTypeAndShape= obj
     * @returns TensorTypeAndShape
     */
    static getRootAsTensorTypeAndShape(bb: flatbuffers.ByteBuffer, obj?: TensorTypeAndShape): TensorTypeAndShape {
      return (obj || new TensorTypeAndShape()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param TensorTypeAndShape= obj
     * @returns TensorTypeAndShape
     */
    static getSizePrefixedRootAsTensorTypeAndShape(bb: flatbuffers.ByteBuffer, obj?: TensorTypeAndShape):
        TensorTypeAndShape {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new TensorTypeAndShape()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @returns onnxruntime.experimental.fbs.TensorDataType
     */
    elemType(): onnxruntime.experimental.fbs.TensorDataType {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? /**  */ (this.bb!.readInt32(this.bb_pos + offset)) :
                      onnxruntime.experimental.fbs.TensorDataType.UNDEFINED;
    }

    /**
     * @param onnxruntime.experimental.fbs.Shape= obj
     * @returns onnxruntime.experimental.fbs.Shape|null
     */
    shape(obj?: onnxruntime.experimental.fbs.Shape): onnxruntime.experimental.fbs.Shape|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.Shape())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startTensorTypeAndShape(builder: flatbuffers.Builder) {
      builder.startObject(2);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param onnxruntime.experimental.fbs.TensorDataType elemType
     */
    static addElemType(builder: flatbuffers.Builder, elemType: onnxruntime.experimental.fbs.TensorDataType) {
      builder.addFieldInt32(0, elemType, onnxruntime.experimental.fbs.TensorDataType.UNDEFINED);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset shapeOffset
     */
    static addShape(builder: flatbuffers.Builder, shapeOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, shapeOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endTensorTypeAndShape(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createTensorTypeAndShape(
        builder: flatbuffers.Builder, elemType: onnxruntime.experimental.fbs.TensorDataType,
        shapeOffset: flatbuffers.Offset): flatbuffers.Offset {
      TensorTypeAndShape.startTensorTypeAndShape(builder);
      TensorTypeAndShape.addElemType(builder, elemType);
      TensorTypeAndShape.addShape(builder, shapeOffset);
      return TensorTypeAndShape.endTensorTypeAndShape(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class MapType {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns MapType
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): MapType {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param MapType= obj
     * @returns MapType
     */
    static getRootAsMapType(bb: flatbuffers.ByteBuffer, obj?: MapType): MapType {
      return (obj || new MapType()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param MapType= obj
     * @returns MapType
     */
    static getSizePrefixedRootAsMapType(bb: flatbuffers.ByteBuffer, obj?: MapType): MapType {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new MapType()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @returns onnxruntime.experimental.fbs.TensorDataType
     */
    keyType(): onnxruntime.experimental.fbs.TensorDataType {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? /**  */ (this.bb!.readInt32(this.bb_pos + offset)) :
                      onnxruntime.experimental.fbs.TensorDataType.UNDEFINED;
    }

    /**
     * @param onnxruntime.experimental.fbs.TypeInfo= obj
     * @returns onnxruntime.experimental.fbs.TypeInfo|null
     */
    valueType(obj?: onnxruntime.experimental.fbs.TypeInfo): onnxruntime.experimental.fbs.TypeInfo|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.TypeInfo())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startMapType(builder: flatbuffers.Builder) {
      builder.startObject(2);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param onnxruntime.experimental.fbs.TensorDataType keyType
     */
    static addKeyType(builder: flatbuffers.Builder, keyType: onnxruntime.experimental.fbs.TensorDataType) {
      builder.addFieldInt32(0, keyType, onnxruntime.experimental.fbs.TensorDataType.UNDEFINED);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset valueTypeOffset
     */
    static addValueType(builder: flatbuffers.Builder, valueTypeOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, valueTypeOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endMapType(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createMapType(
        builder: flatbuffers.Builder, keyType: onnxruntime.experimental.fbs.TensorDataType,
        valueTypeOffset: flatbuffers.Offset): flatbuffers.Offset {
      MapType.startMapType(builder);
      MapType.addKeyType(builder, keyType);
      MapType.addValueType(builder, valueTypeOffset);
      return MapType.endMapType(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class SequenceType {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns SequenceType
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): SequenceType {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param SequenceType= obj
     * @returns SequenceType
     */
    static getRootAsSequenceType(bb: flatbuffers.ByteBuffer, obj?: SequenceType): SequenceType {
      return (obj || new SequenceType()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param SequenceType= obj
     * @returns SequenceType
     */
    static getSizePrefixedRootAsSequenceType(bb: flatbuffers.ByteBuffer, obj?: SequenceType): SequenceType {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new SequenceType()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param onnxruntime.experimental.fbs.TypeInfo= obj
     * @returns onnxruntime.experimental.fbs.TypeInfo|null
     */
    elemType(obj?: onnxruntime.experimental.fbs.TypeInfo): onnxruntime.experimental.fbs.TypeInfo|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? (obj || new onnxruntime.experimental.fbs.TypeInfo())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startSequenceType(builder: flatbuffers.Builder) {
      builder.startObject(1);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset elemTypeOffset
     */
    static addElemType(builder: flatbuffers.Builder, elemTypeOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, elemTypeOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endSequenceType(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createSequenceType(builder: flatbuffers.Builder, elemTypeOffset: flatbuffers.Offset): flatbuffers.Offset {
      SequenceType.startSequenceType(builder);
      SequenceType.addElemType(builder, elemTypeOffset);
      return SequenceType.endSequenceType(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class EdgeEnd {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns EdgeEnd
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): EdgeEnd {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @returns number
     */
    nodeIndex(): number {
      return this.bb!.readUint32(this.bb_pos);
    }

    /**
     * @returns number
     */
    srcArgIndex(): number {
      return this.bb!.readInt32(this.bb_pos + 4);
    }

    /**
     * @returns number
     */
    dstArgIndex(): number {
      return this.bb!.readInt32(this.bb_pos + 8);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number node_index
     * @param number src_arg_index
     * @param number dst_arg_index
     * @returns flatbuffers.Offset
     */
    static createEdgeEnd(
        builder: flatbuffers.Builder, node_index: number, src_arg_index: number,
        dst_arg_index: number): flatbuffers.Offset {
      builder.prep(4, 12);
      builder.writeInt32(dst_arg_index);
      builder.writeInt32(src_arg_index);
      builder.writeInt32(node_index);
      return builder.offset();
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class NodeEdge {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns NodeEdge
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): NodeEdge {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param NodeEdge= obj
     * @returns NodeEdge
     */
    static getRootAsNodeEdge(bb: flatbuffers.ByteBuffer, obj?: NodeEdge): NodeEdge {
      return (obj || new NodeEdge()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param NodeEdge= obj
     * @returns NodeEdge
     */
    static getSizePrefixedRootAsNodeEdge(bb: flatbuffers.ByteBuffer, obj?: NodeEdge): NodeEdge {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new NodeEdge()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @returns number
     */
    nodeIndex(): number {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.EdgeEnd= obj
     * @returns onnxruntime.experimental.fbs.EdgeEnd
     */
    inputEdges(index: number, obj?: onnxruntime.experimental.fbs.EdgeEnd): onnxruntime.experimental.fbs.EdgeEnd|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.EdgeEnd())
                          .__init(this.bb!.__vector(this.bb_pos + offset) + index * 12, this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    inputEdgesLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.EdgeEnd= obj
     * @returns onnxruntime.experimental.fbs.EdgeEnd
     */
    outputEdges(index: number, obj?: onnxruntime.experimental.fbs.EdgeEnd): onnxruntime.experimental.fbs.EdgeEnd|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? (obj || new onnxruntime.experimental.fbs.EdgeEnd())
                          .__init(this.bb!.__vector(this.bb_pos + offset) + index * 12, this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    outputEdgesLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startNodeEdge(builder: flatbuffers.Builder) {
      builder.startObject(3);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number nodeIndex
     */
    static addNodeIndex(builder: flatbuffers.Builder, nodeIndex: number) {
      builder.addFieldInt32(0, nodeIndex, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset inputEdgesOffset
     */
    static addInputEdges(builder: flatbuffers.Builder, inputEdgesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, inputEdgesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startInputEdgesVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(12, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset outputEdgesOffset
     */
    static addOutputEdges(builder: flatbuffers.Builder, outputEdgesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, outputEdgesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startOutputEdgesVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(12, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endNodeEdge(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createNodeEdge(
        builder: flatbuffers.Builder, nodeIndex: number, inputEdgesOffset: flatbuffers.Offset,
        outputEdgesOffset: flatbuffers.Offset): flatbuffers.Offset {
      NodeEdge.startNodeEdge(builder);
      NodeEdge.addNodeIndex(builder, nodeIndex);
      NodeEdge.addInputEdges(builder, inputEdgesOffset);
      NodeEdge.addOutputEdges(builder, outputEdgesOffset);
      return NodeEdge.endNodeEdge(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class Node {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns Node
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): Node {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Node= obj
     * @returns Node
     */
    static getRootAsNode(bb: flatbuffers.ByteBuffer, obj?: Node): Node {
      return (obj || new Node()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Node= obj
     * @returns Node
     */
    static getSizePrefixedRootAsNode(bb: flatbuffers.ByteBuffer, obj?: Node): Node {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new Node()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    name(): string|null;
    name(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    name(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    docString(): string|null;
    docString(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    docString(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    domain(): string|null;
    domain(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    domain(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @returns number
     */
    sinceVersion(): number {
      let offset = this.bb!.__offset(this.bb_pos, 10);
      return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
    }

    /**
     * @returns number
     */
    index(): number {
      let offset = this.bb!.__offset(this.bb_pos, 12);
      return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    opType(): string|null;
    opType(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    opType(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 14);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @returns onnxruntime.experimental.fbs.NodeType
     */
    type(): onnxruntime.experimental.fbs.NodeType {
      let offset = this.bb!.__offset(this.bb_pos, 16);
      return offset ? /**  */ (this.bb!.readInt32(this.bb_pos + offset)) :
                      onnxruntime.experimental.fbs.NodeType.Primitive;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    executionProviderType(): string|null;
    executionProviderType(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    executionProviderType(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 18);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param number index
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array
     */
    inputs(index: number): string;
    inputs(index: number, optionalEncoding: flatbuffers.Encoding): string|Uint8Array;
    inputs(index: number, optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 20);
      return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
    }

    /**
     * @returns number
     */
    inputsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 20);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array
     */
    outputs(index: number): string;
    outputs(index: number, optionalEncoding: flatbuffers.Encoding): string|Uint8Array;
    outputs(index: number, optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 22);
      return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
    }

    /**
     * @returns number
     */
    outputsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 22);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.Attribute= obj
     * @returns onnxruntime.experimental.fbs.Attribute
     */
    attributes(index: number, obj?: onnxruntime.experimental.fbs.Attribute): onnxruntime.experimental.fbs.Attribute
        |null {
      let offset = this.bb!.__offset(this.bb_pos, 24);
      return offset ? (obj || new onnxruntime.experimental.fbs.Attribute())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    attributesLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 24);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @returns number
     */
    inputArgCounts(index: number): number|null {
      let offset = this.bb!.__offset(this.bb_pos, 26);
      return offset ? this.bb!.readInt32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
    }

    /**
     * @returns number
     */
    inputArgCountsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 26);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @returns Int32Array
     */
    inputArgCountsArray(): Int32Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 26);
      return offset ?
          new Int32Array(
              this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
              this.bb!.__vector_len(this.bb_pos + offset)) :
          null;
    }

    /**
     * @param number index
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array
     */
    implicitInputs(index: number): string;
    implicitInputs(index: number, optionalEncoding: flatbuffers.Encoding): string|Uint8Array;
    implicitInputs(index: number, optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 28);
      return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
    }

    /**
     * @returns number
     */
    implicitInputsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 28);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startNode(builder: flatbuffers.Builder) {
      builder.startObject(13);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset nameOffset
     */
    static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, nameOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset docStringOffset
     */
    static addDocString(builder: flatbuffers.Builder, docStringOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, docStringOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset domainOffset
     */
    static addDomain(builder: flatbuffers.Builder, domainOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, domainOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number sinceVersion
     */
    static addSinceVersion(builder: flatbuffers.Builder, sinceVersion: number) {
      builder.addFieldInt32(3, sinceVersion, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number index
     */
    static addIndex(builder: flatbuffers.Builder, index: number) {
      builder.addFieldInt32(4, index, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset opTypeOffset
     */
    static addOpType(builder: flatbuffers.Builder, opTypeOffset: flatbuffers.Offset) {
      builder.addFieldOffset(5, opTypeOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param onnxruntime.experimental.fbs.NodeType type
     */
    static addType(builder: flatbuffers.Builder, type: onnxruntime.experimental.fbs.NodeType) {
      builder.addFieldInt32(6, type, onnxruntime.experimental.fbs.NodeType.Primitive);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset executionProviderTypeOffset
     */
    static addExecutionProviderType(builder: flatbuffers.Builder, executionProviderTypeOffset: flatbuffers.Offset) {
      builder.addFieldOffset(7, executionProviderTypeOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset inputsOffset
     */
    static addInputs(builder: flatbuffers.Builder, inputsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(8, inputsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createInputsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startInputsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset outputsOffset
     */
    static addOutputs(builder: flatbuffers.Builder, outputsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(9, outputsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createOutputsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startOutputsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset attributesOffset
     */
    static addAttributes(builder: flatbuffers.Builder, attributesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(10, attributesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createAttributesVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startAttributesVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset inputArgCountsOffset
     */
    static addInputArgCounts(builder: flatbuffers.Builder, inputArgCountsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(11, inputArgCountsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<number> data
     * @returns flatbuffers.Offset
     */
    static createInputArgCountsVector(builder: flatbuffers.Builder, data: number[]|Uint8Array): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addInt32(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startInputArgCountsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset implicitInputsOffset
     */
    static addImplicitInputs(builder: flatbuffers.Builder, implicitInputsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(12, implicitInputsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createImplicitInputsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startImplicitInputsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endNode(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createNode(
        builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset, docStringOffset: flatbuffers.Offset,
        domainOffset: flatbuffers.Offset, sinceVersion: number, index: number, opTypeOffset: flatbuffers.Offset,
        type: onnxruntime.experimental.fbs.NodeType, executionProviderTypeOffset: flatbuffers.Offset,
        inputsOffset: flatbuffers.Offset, outputsOffset: flatbuffers.Offset, attributesOffset: flatbuffers.Offset,
        inputArgCountsOffset: flatbuffers.Offset, implicitInputsOffset: flatbuffers.Offset): flatbuffers.Offset {
      Node.startNode(builder);
      Node.addName(builder, nameOffset);
      Node.addDocString(builder, docStringOffset);
      Node.addDomain(builder, domainOffset);
      Node.addSinceVersion(builder, sinceVersion);
      Node.addIndex(builder, index);
      Node.addOpType(builder, opTypeOffset);
      Node.addType(builder, type);
      Node.addExecutionProviderType(builder, executionProviderTypeOffset);
      Node.addInputs(builder, inputsOffset);
      Node.addOutputs(builder, outputsOffset);
      Node.addAttributes(builder, attributesOffset);
      Node.addInputArgCounts(builder, inputArgCountsOffset);
      Node.addImplicitInputs(builder, implicitInputsOffset);
      return Node.endNode(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class ValueInfo {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns ValueInfo
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): ValueInfo {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param ValueInfo= obj
     * @returns ValueInfo
     */
    static getRootAsValueInfo(bb: flatbuffers.ByteBuffer, obj?: ValueInfo): ValueInfo {
      return (obj || new ValueInfo()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param ValueInfo= obj
     * @returns ValueInfo
     */
    static getSizePrefixedRootAsValueInfo(bb: flatbuffers.ByteBuffer, obj?: ValueInfo): ValueInfo {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new ValueInfo()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    name(): string|null;
    name(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    name(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    docString(): string|null;
    docString(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    docString(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param onnxruntime.experimental.fbs.TypeInfo= obj
     * @returns onnxruntime.experimental.fbs.TypeInfo|null
     */
    type(obj?: onnxruntime.experimental.fbs.TypeInfo): onnxruntime.experimental.fbs.TypeInfo|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? (obj || new onnxruntime.experimental.fbs.TypeInfo())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startValueInfo(builder: flatbuffers.Builder) {
      builder.startObject(3);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset nameOffset
     */
    static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, nameOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset docStringOffset
     */
    static addDocString(builder: flatbuffers.Builder, docStringOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, docStringOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset typeOffset
     */
    static addType(builder: flatbuffers.Builder, typeOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, typeOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endValueInfo(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createValueInfo(
        builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset, docStringOffset: flatbuffers.Offset,
        typeOffset: flatbuffers.Offset): flatbuffers.Offset {
      ValueInfo.startValueInfo(builder);
      ValueInfo.addName(builder, nameOffset);
      ValueInfo.addDocString(builder, docStringOffset);
      ValueInfo.addType(builder, typeOffset);
      return ValueInfo.endValueInfo(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class TypeInfo {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns TypeInfo
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): TypeInfo {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param TypeInfo= obj
     * @returns TypeInfo
     */
    static getRootAsTypeInfo(bb: flatbuffers.ByteBuffer, obj?: TypeInfo): TypeInfo {
      return (obj || new TypeInfo()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param TypeInfo= obj
     * @returns TypeInfo
     */
    static getSizePrefixedRootAsTypeInfo(bb: flatbuffers.ByteBuffer, obj?: TypeInfo): TypeInfo {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new TypeInfo()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    denotation(): string|null;
    denotation(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    denotation(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @returns onnxruntime.experimental.fbs.TypeInfoValue
     */
    valueType(): onnxruntime.experimental.fbs.TypeInfoValue {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? /**  */ (this.bb!.readUint8(this.bb_pos + offset)) :
                      onnxruntime.experimental.fbs.TypeInfoValue.NONE;
    }

    /**
     * @param flatbuffers.Table obj
     * @returns ?flatbuffers.Table
     */
    value<T extends flatbuffers.Table>(obj: T): T|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startTypeInfo(builder: flatbuffers.Builder) {
      builder.startObject(3);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset denotationOffset
     */
    static addDenotation(builder: flatbuffers.Builder, denotationOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, denotationOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param onnxruntime.experimental.fbs.TypeInfoValue valueType
     */
    static addValueType(builder: flatbuffers.Builder, valueType: onnxruntime.experimental.fbs.TypeInfoValue) {
      builder.addFieldInt8(1, valueType, onnxruntime.experimental.fbs.TypeInfoValue.NONE);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset valueOffset
     */
    static addValue(builder: flatbuffers.Builder, valueOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, valueOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endTypeInfo(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createTypeInfo(
        builder: flatbuffers.Builder, denotationOffset: flatbuffers.Offset,
        valueType: onnxruntime.experimental.fbs.TypeInfoValue, valueOffset: flatbuffers.Offset): flatbuffers.Offset {
      TypeInfo.startTypeInfo(builder);
      TypeInfo.addDenotation(builder, denotationOffset);
      TypeInfo.addValueType(builder, valueType);
      TypeInfo.addValue(builder, valueOffset);
      return TypeInfo.endTypeInfo(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class OperatorSetId {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns OperatorSetId
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): OperatorSetId {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param OperatorSetId= obj
     * @returns OperatorSetId
     */
    static getRootAsOperatorSetId(bb: flatbuffers.ByteBuffer, obj?: OperatorSetId): OperatorSetId {
      return (obj || new OperatorSetId()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param OperatorSetId= obj
     * @returns OperatorSetId
     */
    static getSizePrefixedRootAsOperatorSetId(bb: flatbuffers.ByteBuffer, obj?: OperatorSetId): OperatorSetId {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new OperatorSetId()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    domain(): string|null;
    domain(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    domain(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @returns flatbuffers.Long
     */
    version(): flatbuffers.Long {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.readInt64(this.bb_pos + offset) : this.bb!.createLong(0, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startOperatorSetId(builder: flatbuffers.Builder) {
      builder.startObject(2);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset domainOffset
     */
    static addDomain(builder: flatbuffers.Builder, domainOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, domainOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Long version
     */
    static addVersion(builder: flatbuffers.Builder, version: flatbuffers.Long) {
      builder.addFieldInt64(1, version, builder.createLong(0, 0));
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endOperatorSetId(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createOperatorSetId(
        builder: flatbuffers.Builder, domainOffset: flatbuffers.Offset, version: flatbuffers.Long): flatbuffers.Offset {
      OperatorSetId.startOperatorSetId(builder);
      OperatorSetId.addDomain(builder, domainOffset);
      OperatorSetId.addVersion(builder, version);
      return OperatorSetId.endOperatorSetId(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class Tensor {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns Tensor
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): Tensor {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Tensor= obj
     * @returns Tensor
     */
    static getRootAsTensor(bb: flatbuffers.ByteBuffer, obj?: Tensor): Tensor {
      return (obj || new Tensor()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Tensor= obj
     * @returns Tensor
     */
    static getSizePrefixedRootAsTensor(bb: flatbuffers.ByteBuffer, obj?: Tensor): Tensor {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new Tensor()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    name(): string|null;
    name(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    name(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    docString(): string|null;
    docString(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    docString(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param number index
     * @returns flatbuffers.Long
     */
    dims(index: number): flatbuffers.Long|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.readInt64(this.bb!.__vector(this.bb_pos + offset) + index * 8) :
                      this.bb!.createLong(0, 0);
    }

    /**
     * @returns number
     */
    dimsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @returns onnxruntime.experimental.fbs.TensorDataType
     */
    dataType(): onnxruntime.experimental.fbs.TensorDataType {
      let offset = this.bb!.__offset(this.bb_pos, 10);
      return offset ? /**  */ (this.bb!.readInt32(this.bb_pos + offset)) :
                      onnxruntime.experimental.fbs.TensorDataType.UNDEFINED;
    }

    /**
     * @param number index
     * @returns number
     */
    rawData(index: number): number|null {
      let offset = this.bb!.__offset(this.bb_pos, 12);
      return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
    }

    /**
     * @returns number
     */
    rawDataLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 12);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @returns Uint8Array
     */
    rawDataArray(): Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 12);
      return offset ?
          new Uint8Array(
              this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
              this.bb!.__vector_len(this.bb_pos + offset)) :
          null;
    }

    /**
     * @param number index
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array
     */
    stringData(index: number): string;
    stringData(index: number, optionalEncoding: flatbuffers.Encoding): string|Uint8Array;
    stringData(index: number, optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 14);
      return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
    }

    /**
     * @returns number
     */
    stringDataLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 14);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startTensor(builder: flatbuffers.Builder) {
      builder.startObject(6);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset nameOffset
     */
    static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, nameOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset docStringOffset
     */
    static addDocString(builder: flatbuffers.Builder, docStringOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, docStringOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset dimsOffset
     */
    static addDims(builder: flatbuffers.Builder, dimsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, dimsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Long> data
     * @returns flatbuffers.Offset
     */
    static createDimsVector(builder: flatbuffers.Builder, data: flatbuffers.Long[]): flatbuffers.Offset {
      builder.startVector(8, data.length, 8);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addInt64(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startDimsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(8, numElems, 8);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param onnxruntime.experimental.fbs.TensorDataType dataType
     */
    static addDataType(builder: flatbuffers.Builder, dataType: onnxruntime.experimental.fbs.TensorDataType) {
      builder.addFieldInt32(3, dataType, onnxruntime.experimental.fbs.TensorDataType.UNDEFINED);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset rawDataOffset
     */
    static addRawData(builder: flatbuffers.Builder, rawDataOffset: flatbuffers.Offset) {
      builder.addFieldOffset(4, rawDataOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<number> data
     * @returns flatbuffers.Offset
     */
    static createRawDataVector(builder: flatbuffers.Builder, data: number[]|Uint8Array): flatbuffers.Offset {
      builder.startVector(1, data.length, 1);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addInt8(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startRawDataVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(1, numElems, 1);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset stringDataOffset
     */
    static addStringData(builder: flatbuffers.Builder, stringDataOffset: flatbuffers.Offset) {
      builder.addFieldOffset(5, stringDataOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createStringDataVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startStringDataVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endTensor(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createTensor(
        builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset, docStringOffset: flatbuffers.Offset,
        dimsOffset: flatbuffers.Offset, dataType: onnxruntime.experimental.fbs.TensorDataType,
        rawDataOffset: flatbuffers.Offset, stringDataOffset: flatbuffers.Offset): flatbuffers.Offset {
      Tensor.startTensor(builder);
      Tensor.addName(builder, nameOffset);
      Tensor.addDocString(builder, docStringOffset);
      Tensor.addDims(builder, dimsOffset);
      Tensor.addDataType(builder, dataType);
      Tensor.addRawData(builder, rawDataOffset);
      Tensor.addStringData(builder, stringDataOffset);
      return Tensor.endTensor(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class SparseTensor {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns SparseTensor
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): SparseTensor {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param SparseTensor= obj
     * @returns SparseTensor
     */
    static getRootAsSparseTensor(bb: flatbuffers.ByteBuffer, obj?: SparseTensor): SparseTensor {
      return (obj || new SparseTensor()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param SparseTensor= obj
     * @returns SparseTensor
     */
    static getSizePrefixedRootAsSparseTensor(bb: flatbuffers.ByteBuffer, obj?: SparseTensor): SparseTensor {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new SparseTensor()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param onnxruntime.experimental.fbs.Tensor= obj
     * @returns onnxruntime.experimental.fbs.Tensor|null
     */
    values(obj?: onnxruntime.experimental.fbs.Tensor): onnxruntime.experimental.fbs.Tensor|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? (obj || new onnxruntime.experimental.fbs.Tensor())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param onnxruntime.experimental.fbs.Tensor= obj
     * @returns onnxruntime.experimental.fbs.Tensor|null
     */
    indices(obj?: onnxruntime.experimental.fbs.Tensor): onnxruntime.experimental.fbs.Tensor|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.Tensor())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param number index
     * @returns flatbuffers.Long
     */
    dims(index: number): flatbuffers.Long|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.readInt64(this.bb!.__vector(this.bb_pos + offset) + index * 8) :
                      this.bb!.createLong(0, 0);
    }

    /**
     * @returns number
     */
    dimsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startSparseTensor(builder: flatbuffers.Builder) {
      builder.startObject(3);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset valuesOffset
     */
    static addValues(builder: flatbuffers.Builder, valuesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, valuesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset indicesOffset
     */
    static addIndices(builder: flatbuffers.Builder, indicesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, indicesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset dimsOffset
     */
    static addDims(builder: flatbuffers.Builder, dimsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, dimsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Long> data
     * @returns flatbuffers.Offset
     */
    static createDimsVector(builder: flatbuffers.Builder, data: flatbuffers.Long[]): flatbuffers.Offset {
      builder.startVector(8, data.length, 8);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addInt64(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startDimsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(8, numElems, 8);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endSparseTensor(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createSparseTensor(
        builder: flatbuffers.Builder, valuesOffset: flatbuffers.Offset, indicesOffset: flatbuffers.Offset,
        dimsOffset: flatbuffers.Offset): flatbuffers.Offset {
      SparseTensor.startSparseTensor(builder);
      SparseTensor.addValues(builder, valuesOffset);
      SparseTensor.addIndices(builder, indicesOffset);
      SparseTensor.addDims(builder, dimsOffset);
      return SparseTensor.endSparseTensor(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class Attribute {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns Attribute
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): Attribute {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Attribute= obj
     * @returns Attribute
     */
    static getRootAsAttribute(bb: flatbuffers.ByteBuffer, obj?: Attribute): Attribute {
      return (obj || new Attribute()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Attribute= obj
     * @returns Attribute
     */
    static getSizePrefixedRootAsAttribute(bb: flatbuffers.ByteBuffer, obj?: Attribute): Attribute {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new Attribute()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    name(): string|null;
    name(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    name(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    docString(): string|null;
    docString(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    docString(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @returns onnxruntime.experimental.fbs.AttributeType
     */
    type(): onnxruntime.experimental.fbs.AttributeType {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? /**  */ (this.bb!.readInt32(this.bb_pos + offset)) :
                      onnxruntime.experimental.fbs.AttributeType.UNDEFINED;
    }

    /**
     * @returns number
     */
    f(): number {
      let offset = this.bb!.__offset(this.bb_pos, 10);
      return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
    }

    /**
     * @returns flatbuffers.Long
     */
    i(): flatbuffers.Long {
      let offset = this.bb!.__offset(this.bb_pos, 12);
      return offset ? this.bb!.readInt64(this.bb_pos + offset) : this.bb!.createLong(0, 0);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    s(): string|null;
    s(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    s(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 14);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param onnxruntime.experimental.fbs.Tensor= obj
     * @returns onnxruntime.experimental.fbs.Tensor|null
     */
    t(obj?: onnxruntime.experimental.fbs.Tensor): onnxruntime.experimental.fbs.Tensor|null {
      let offset = this.bb!.__offset(this.bb_pos, 16);
      return offset ? (obj || new onnxruntime.experimental.fbs.Tensor())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param onnxruntime.experimental.fbs.Graph= obj
     * @returns onnxruntime.experimental.fbs.Graph|null
     */
    g(obj?: onnxruntime.experimental.fbs.Graph): onnxruntime.experimental.fbs.Graph|null {
      let offset = this.bb!.__offset(this.bb_pos, 18);
      return offset ? (obj || new onnxruntime.experimental.fbs.Graph())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param number index
     * @returns number
     */
    floats(index: number): number|null {
      let offset = this.bb!.__offset(this.bb_pos, 20);
      return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
    }

    /**
     * @returns number
     */
    floatsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 20);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @returns Float32Array
     */
    floatsArray(): Float32Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 20);
      return offset ?
          new Float32Array(
              this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
              this.bb!.__vector_len(this.bb_pos + offset)) :
          null;
    }

    /**
     * @param number index
     * @returns flatbuffers.Long
     */
    ints(index: number): flatbuffers.Long|null {
      let offset = this.bb!.__offset(this.bb_pos, 22);
      return offset ? this.bb!.readInt64(this.bb!.__vector(this.bb_pos + offset) + index * 8) :
                      this.bb!.createLong(0, 0);
    }

    /**
     * @returns number
     */
    intsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 22);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array
     */
    strings(index: number): string;
    strings(index: number, optionalEncoding: flatbuffers.Encoding): string|Uint8Array;
    strings(index: number, optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 24);
      return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
    }

    /**
     * @returns number
     */
    stringsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 24);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.Tensor= obj
     * @returns onnxruntime.experimental.fbs.Tensor
     */
    tensors(index: number, obj?: onnxruntime.experimental.fbs.Tensor): onnxruntime.experimental.fbs.Tensor|null {
      let offset = this.bb!.__offset(this.bb_pos, 26);
      return offset ? (obj || new onnxruntime.experimental.fbs.Tensor())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    tensorsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 26);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.Graph= obj
     * @returns onnxruntime.experimental.fbs.Graph
     */
    graphs(index: number, obj?: onnxruntime.experimental.fbs.Graph): onnxruntime.experimental.fbs.Graph|null {
      let offset = this.bb!.__offset(this.bb_pos, 28);
      return offset ? (obj || new onnxruntime.experimental.fbs.Graph())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    graphsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 28);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startAttribute(builder: flatbuffers.Builder) {
      builder.startObject(13);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset nameOffset
     */
    static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, nameOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset docStringOffset
     */
    static addDocString(builder: flatbuffers.Builder, docStringOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, docStringOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param onnxruntime.experimental.fbs.AttributeType type
     */
    static addType(builder: flatbuffers.Builder, type: onnxruntime.experimental.fbs.AttributeType) {
      builder.addFieldInt32(2, type, onnxruntime.experimental.fbs.AttributeType.UNDEFINED);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number f
     */
    static addF(builder: flatbuffers.Builder, f: number) {
      builder.addFieldFloat32(3, f, 0.0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Long i
     */
    static addI(builder: flatbuffers.Builder, i: flatbuffers.Long) {
      builder.addFieldInt64(4, i, builder.createLong(0, 0));
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset sOffset
     */
    static addS(builder: flatbuffers.Builder, sOffset: flatbuffers.Offset) {
      builder.addFieldOffset(5, sOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset tOffset
     */
    static addT(builder: flatbuffers.Builder, tOffset: flatbuffers.Offset) {
      builder.addFieldOffset(6, tOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset gOffset
     */
    static addG(builder: flatbuffers.Builder, gOffset: flatbuffers.Offset) {
      builder.addFieldOffset(7, gOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset floatsOffset
     */
    static addFloats(builder: flatbuffers.Builder, floatsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(8, floatsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<number> data
     * @returns flatbuffers.Offset
     */
    static createFloatsVector(builder: flatbuffers.Builder, data: number[]|Uint8Array): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addFloat32(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startFloatsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset intsOffset
     */
    static addInts(builder: flatbuffers.Builder, intsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(9, intsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Long> data
     * @returns flatbuffers.Offset
     */
    static createIntsVector(builder: flatbuffers.Builder, data: flatbuffers.Long[]): flatbuffers.Offset {
      builder.startVector(8, data.length, 8);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addInt64(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startIntsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(8, numElems, 8);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset stringsOffset
     */
    static addStrings(builder: flatbuffers.Builder, stringsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(10, stringsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createStringsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startStringsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset tensorsOffset
     */
    static addTensors(builder: flatbuffers.Builder, tensorsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(11, tensorsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createTensorsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startTensorsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset graphsOffset
     */
    static addGraphs(builder: flatbuffers.Builder, graphsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(12, graphsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createGraphsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startGraphsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endAttribute(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createAttribute(
        builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset, docStringOffset: flatbuffers.Offset,
        type: onnxruntime.experimental.fbs.AttributeType, f: number, i: flatbuffers.Long, sOffset: flatbuffers.Offset,
        tOffset: flatbuffers.Offset, gOffset: flatbuffers.Offset, floatsOffset: flatbuffers.Offset,
        intsOffset: flatbuffers.Offset, stringsOffset: flatbuffers.Offset, tensorsOffset: flatbuffers.Offset,
        graphsOffset: flatbuffers.Offset): flatbuffers.Offset {
      Attribute.startAttribute(builder);
      Attribute.addName(builder, nameOffset);
      Attribute.addDocString(builder, docStringOffset);
      Attribute.addType(builder, type);
      Attribute.addF(builder, f);
      Attribute.addI(builder, i);
      Attribute.addS(builder, sOffset);
      Attribute.addT(builder, tOffset);
      Attribute.addG(builder, gOffset);
      Attribute.addFloats(builder, floatsOffset);
      Attribute.addInts(builder, intsOffset);
      Attribute.addStrings(builder, stringsOffset);
      Attribute.addTensors(builder, tensorsOffset);
      Attribute.addGraphs(builder, graphsOffset);
      return Attribute.endAttribute(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class Graph {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns Graph
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): Graph {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Graph= obj
     * @returns Graph
     */
    static getRootAsGraph(bb: flatbuffers.ByteBuffer, obj?: Graph): Graph {
      return (obj || new Graph()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Graph= obj
     * @returns Graph
     */
    static getSizePrefixedRootAsGraph(bb: flatbuffers.ByteBuffer, obj?: Graph): Graph {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new Graph()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.Tensor= obj
     * @returns onnxruntime.experimental.fbs.Tensor
     */
    initializers(index: number, obj?: onnxruntime.experimental.fbs.Tensor): onnxruntime.experimental.fbs.Tensor|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? (obj || new onnxruntime.experimental.fbs.Tensor())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    initializersLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.ValueInfo= obj
     * @returns onnxruntime.experimental.fbs.ValueInfo
     */
    nodeArgs(index: number, obj?: onnxruntime.experimental.fbs.ValueInfo): onnxruntime.experimental.fbs.ValueInfo|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.ValueInfo())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    nodeArgsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.Node= obj
     * @returns onnxruntime.experimental.fbs.Node
     */
    nodes(index: number, obj?: onnxruntime.experimental.fbs.Node): onnxruntime.experimental.fbs.Node|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? (obj || new onnxruntime.experimental.fbs.Node())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    nodesLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @returns number
     */
    maxNodeIndex(): number {
      let offset = this.bb!.__offset(this.bb_pos, 10);
      return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.NodeEdge= obj
     * @returns onnxruntime.experimental.fbs.NodeEdge
     */
    nodeEdges(index: number, obj?: onnxruntime.experimental.fbs.NodeEdge): onnxruntime.experimental.fbs.NodeEdge|null {
      let offset = this.bb!.__offset(this.bb_pos, 12);
      return offset ? (obj || new onnxruntime.experimental.fbs.NodeEdge())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    nodeEdgesLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 12);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array
     */
    inputs(index: number): string;
    inputs(index: number, optionalEncoding: flatbuffers.Encoding): string|Uint8Array;
    inputs(index: number, optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 14);
      return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
    }

    /**
     * @returns number
     */
    inputsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 14);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array
     */
    outputs(index: number): string;
    outputs(index: number, optionalEncoding: flatbuffers.Encoding): string|Uint8Array;
    outputs(index: number, optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 16);
      return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
    }

    /**
     * @returns number
     */
    outputsLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 16);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.SparseTensor= obj
     * @returns onnxruntime.experimental.fbs.SparseTensor
     */
    sparseInitializers(index: number, obj?: onnxruntime.experimental.fbs.SparseTensor):
        onnxruntime.experimental.fbs.SparseTensor|null {
      let offset = this.bb!.__offset(this.bb_pos, 18);
      return offset ? (obj || new onnxruntime.experimental.fbs.SparseTensor())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    sparseInitializersLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 18);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startGraph(builder: flatbuffers.Builder) {
      builder.startObject(8);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset initializersOffset
     */
    static addInitializers(builder: flatbuffers.Builder, initializersOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, initializersOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createInitializersVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startInitializersVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset nodeArgsOffset
     */
    static addNodeArgs(builder: flatbuffers.Builder, nodeArgsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, nodeArgsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createNodeArgsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startNodeArgsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset nodesOffset
     */
    static addNodes(builder: flatbuffers.Builder, nodesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, nodesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createNodesVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startNodesVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number maxNodeIndex
     */
    static addMaxNodeIndex(builder: flatbuffers.Builder, maxNodeIndex: number) {
      builder.addFieldInt32(3, maxNodeIndex, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset nodeEdgesOffset
     */
    static addNodeEdges(builder: flatbuffers.Builder, nodeEdgesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(4, nodeEdgesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createNodeEdgesVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startNodeEdgesVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset inputsOffset
     */
    static addInputs(builder: flatbuffers.Builder, inputsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(5, inputsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createInputsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startInputsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset outputsOffset
     */
    static addOutputs(builder: flatbuffers.Builder, outputsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(6, outputsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createOutputsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startOutputsVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset sparseInitializersOffset
     */
    static addSparseInitializers(builder: flatbuffers.Builder, sparseInitializersOffset: flatbuffers.Offset) {
      builder.addFieldOffset(7, sparseInitializersOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createSparseInitializersVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]):
        flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startSparseInitializersVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endGraph(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createGraph(
        builder: flatbuffers.Builder, initializersOffset: flatbuffers.Offset, nodeArgsOffset: flatbuffers.Offset,
        nodesOffset: flatbuffers.Offset, maxNodeIndex: number, nodeEdgesOffset: flatbuffers.Offset,
        inputsOffset: flatbuffers.Offset, outputsOffset: flatbuffers.Offset,
        sparseInitializersOffset: flatbuffers.Offset): flatbuffers.Offset {
      Graph.startGraph(builder);
      Graph.addInitializers(builder, initializersOffset);
      Graph.addNodeArgs(builder, nodeArgsOffset);
      Graph.addNodes(builder, nodesOffset);
      Graph.addMaxNodeIndex(builder, maxNodeIndex);
      Graph.addNodeEdges(builder, nodeEdgesOffset);
      Graph.addInputs(builder, inputsOffset);
      Graph.addOutputs(builder, outputsOffset);
      Graph.addSparseInitializers(builder, sparseInitializersOffset);
      return Graph.endGraph(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class Model {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns Model
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): Model {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Model= obj
     * @returns Model
     */
    static getRootAsModel(bb: flatbuffers.ByteBuffer, obj?: Model): Model {
      return (obj || new Model()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param Model= obj
     * @returns Model
     */
    static getSizePrefixedRootAsModel(bb: flatbuffers.ByteBuffer, obj?: Model): Model {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new Model()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @returns flatbuffers.Long
     */
    irVersion(): flatbuffers.Long {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.readInt64(this.bb_pos + offset) : this.bb!.createLong(0, 0);
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.OperatorSetId= obj
     * @returns onnxruntime.experimental.fbs.OperatorSetId
     */
    opsetImport(index: number, obj?: onnxruntime.experimental.fbs.OperatorSetId):
        onnxruntime.experimental.fbs.OperatorSetId|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.OperatorSetId())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    opsetImportLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    producerName(): string|null;
    producerName(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    producerName(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    producerVersion(): string|null;
    producerVersion(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    producerVersion(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 10);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    domain(): string|null;
    domain(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    domain(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 12);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @returns flatbuffers.Long
     */
    modelVersion(): flatbuffers.Long {
      let offset = this.bb!.__offset(this.bb_pos, 14);
      return offset ? this.bb!.readInt64(this.bb_pos + offset) : this.bb!.createLong(0, 0);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    docString(): string|null;
    docString(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    docString(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 16);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param onnxruntime.experimental.fbs.Graph= obj
     * @returns onnxruntime.experimental.fbs.Graph|null
     */
    graph(obj?: onnxruntime.experimental.fbs.Graph): onnxruntime.experimental.fbs.Graph|null {
      let offset = this.bb!.__offset(this.bb_pos, 18);
      return offset ? (obj || new onnxruntime.experimental.fbs.Graph())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    graphDocString(): string|null;
    graphDocString(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    graphDocString(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 20);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startModel(builder: flatbuffers.Builder) {
      builder.startObject(9);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Long irVersion
     */
    static addIrVersion(builder: flatbuffers.Builder, irVersion: flatbuffers.Long) {
      builder.addFieldInt64(0, irVersion, builder.createLong(0, 0));
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset opsetImportOffset
     */
    static addOpsetImport(builder: flatbuffers.Builder, opsetImportOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, opsetImportOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createOpsetImportVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startOpsetImportVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset producerNameOffset
     */
    static addProducerName(builder: flatbuffers.Builder, producerNameOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, producerNameOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset producerVersionOffset
     */
    static addProducerVersion(builder: flatbuffers.Builder, producerVersionOffset: flatbuffers.Offset) {
      builder.addFieldOffset(3, producerVersionOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset domainOffset
     */
    static addDomain(builder: flatbuffers.Builder, domainOffset: flatbuffers.Offset) {
      builder.addFieldOffset(4, domainOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Long modelVersion
     */
    static addModelVersion(builder: flatbuffers.Builder, modelVersion: flatbuffers.Long) {
      builder.addFieldInt64(5, modelVersion, builder.createLong(0, 0));
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset docStringOffset
     */
    static addDocString(builder: flatbuffers.Builder, docStringOffset: flatbuffers.Offset) {
      builder.addFieldOffset(6, docStringOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset graphOffset
     */
    static addGraph(builder: flatbuffers.Builder, graphOffset: flatbuffers.Offset) {
      builder.addFieldOffset(7, graphOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset graphDocStringOffset
     */
    static addGraphDocString(builder: flatbuffers.Builder, graphDocStringOffset: flatbuffers.Offset) {
      builder.addFieldOffset(8, graphDocStringOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endModel(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createModel(
        builder: flatbuffers.Builder, irVersion: flatbuffers.Long, opsetImportOffset: flatbuffers.Offset,
        producerNameOffset: flatbuffers.Offset, producerVersionOffset: flatbuffers.Offset,
        domainOffset: flatbuffers.Offset, modelVersion: flatbuffers.Long, docStringOffset: flatbuffers.Offset,
        graphOffset: flatbuffers.Offset, graphDocStringOffset: flatbuffers.Offset): flatbuffers.Offset {
      Model.startModel(builder);
      Model.addIrVersion(builder, irVersion);
      Model.addOpsetImport(builder, opsetImportOffset);
      Model.addProducerName(builder, producerNameOffset);
      Model.addProducerVersion(builder, producerVersionOffset);
      Model.addDomain(builder, domainOffset);
      Model.addModelVersion(builder, modelVersion);
      Model.addDocString(builder, docStringOffset);
      Model.addGraph(builder, graphOffset);
      Model.addGraphDocString(builder, graphDocStringOffset);
      return Model.endModel(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class KernelCreateInfos {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns KernelCreateInfos
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): KernelCreateInfos {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param KernelCreateInfos= obj
     * @returns KernelCreateInfos
     */
    static getRootAsKernelCreateInfos(bb: flatbuffers.ByteBuffer, obj?: KernelCreateInfos): KernelCreateInfos {
      return (obj || new KernelCreateInfos()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param KernelCreateInfos= obj
     * @returns KernelCreateInfos
     */
    static getSizePrefixedRootAsKernelCreateInfos(bb: flatbuffers.ByteBuffer, obj?: KernelCreateInfos):
        KernelCreateInfos {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new KernelCreateInfos()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param number index
     * @returns number
     */
    nodeIndices(index: number): number|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
    }

    /**
     * @returns number
     */
    nodeIndicesLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @returns Uint32Array
     */
    nodeIndicesArray(): Uint32Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ?
          new Uint32Array(
              this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
              this.bb!.__vector_len(this.bb_pos + offset)) :
          null;
    }

    /**
     * @param number index
     * @returns flatbuffers.Long
     */
    kernelDefHashes(index: number): flatbuffers.Long|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.readUint64(this.bb!.__vector(this.bb_pos + offset) + index * 8) :
                      this.bb!.createLong(0, 0);
    }

    /**
     * @returns number
     */
    kernelDefHashesLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startKernelCreateInfos(builder: flatbuffers.Builder) {
      builder.startObject(2);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset nodeIndicesOffset
     */
    static addNodeIndices(builder: flatbuffers.Builder, nodeIndicesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, nodeIndicesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<number> data
     * @returns flatbuffers.Offset
     */
    static createNodeIndicesVector(builder: flatbuffers.Builder, data: number[]|Uint8Array): flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addInt32(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startNodeIndicesVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset kernelDefHashesOffset
     */
    static addKernelDefHashes(builder: flatbuffers.Builder, kernelDefHashesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, kernelDefHashesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Long> data
     * @returns flatbuffers.Offset
     */
    static createKernelDefHashesVector(builder: flatbuffers.Builder, data: flatbuffers.Long[]): flatbuffers.Offset {
      builder.startVector(8, data.length, 8);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addInt64(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startKernelDefHashesVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(8, numElems, 8);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endKernelCreateInfos(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createKernelCreateInfos(
        builder: flatbuffers.Builder, nodeIndicesOffset: flatbuffers.Offset,
        kernelDefHashesOffset: flatbuffers.Offset): flatbuffers.Offset {
      KernelCreateInfos.startKernelCreateInfos(builder);
      KernelCreateInfos.addNodeIndices(builder, nodeIndicesOffset);
      KernelCreateInfos.addKernelDefHashes(builder, kernelDefHashesOffset);
      return KernelCreateInfos.endKernelCreateInfos(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class SubGraphSessionState {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns SubGraphSessionState
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): SubGraphSessionState {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param SubGraphSessionState= obj
     * @returns SubGraphSessionState
     */
    static getRootAsSubGraphSessionState(bb: flatbuffers.ByteBuffer, obj?: SubGraphSessionState): SubGraphSessionState {
      return (obj || new SubGraphSessionState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param SubGraphSessionState= obj
     * @returns SubGraphSessionState
     */
    static getSizePrefixedRootAsSubGraphSessionState(bb: flatbuffers.ByteBuffer, obj?: SubGraphSessionState):
        SubGraphSessionState {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new SubGraphSessionState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    graphId(): string|null;
    graphId(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    graphId(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param onnxruntime.experimental.fbs.SessionState= obj
     * @returns onnxruntime.experimental.fbs.SessionState|null
     */
    sessionState(obj?: onnxruntime.experimental.fbs.SessionState): onnxruntime.experimental.fbs.SessionState|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.SessionState())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startSubGraphSessionState(builder: flatbuffers.Builder) {
      builder.startObject(2);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset graphIdOffset
     */
    static addGraphId(builder: flatbuffers.Builder, graphIdOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, graphIdOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset sessionStateOffset
     */
    static addSessionState(builder: flatbuffers.Builder, sessionStateOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, sessionStateOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endSubGraphSessionState(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      builder.requiredField(offset, 4);  // graph_id
      return offset;
    }

    static createSubGraphSessionState(
        builder: flatbuffers.Builder, graphIdOffset: flatbuffers.Offset,
        sessionStateOffset: flatbuffers.Offset): flatbuffers.Offset {
      SubGraphSessionState.startSubGraphSessionState(builder);
      SubGraphSessionState.addGraphId(builder, graphIdOffset);
      SubGraphSessionState.addSessionState(builder, sessionStateOffset);
      return SubGraphSessionState.endSubGraphSessionState(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class SessionState {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns SessionState
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): SessionState {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param SessionState= obj
     * @returns SessionState
     */
    static getRootAsSessionState(bb: flatbuffers.ByteBuffer, obj?: SessionState): SessionState {
      return (obj || new SessionState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param SessionState= obj
     * @returns SessionState
     */
    static getSizePrefixedRootAsSessionState(bb: flatbuffers.ByteBuffer, obj?: SessionState): SessionState {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new SessionState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param onnxruntime.experimental.fbs.KernelCreateInfos= obj
     * @returns onnxruntime.experimental.fbs.KernelCreateInfos|null
     */
    kernels(obj?: onnxruntime.experimental.fbs.KernelCreateInfos): onnxruntime.experimental.fbs.KernelCreateInfos|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? (obj || new onnxruntime.experimental.fbs.KernelCreateInfos())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param number index
     * @param onnxruntime.experimental.fbs.SubGraphSessionState= obj
     * @returns onnxruntime.experimental.fbs.SubGraphSessionState
     */
    subGraphSessionStates(index: number, obj?: onnxruntime.experimental.fbs.SubGraphSessionState):
        onnxruntime.experimental.fbs.SubGraphSessionState|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.SubGraphSessionState())
                          .__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) :
                      null;
    }

    /**
     * @returns number
     */
    subGraphSessionStatesLength(): number {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startSessionState(builder: flatbuffers.Builder) {
      builder.startObject(2);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset kernelsOffset
     */
    static addKernels(builder: flatbuffers.Builder, kernelsOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, kernelsOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset subGraphSessionStatesOffset
     */
    static addSubGraphSessionStates(builder: flatbuffers.Builder, subGraphSessionStatesOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, subGraphSessionStatesOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param Array.<flatbuffers.Offset> data
     * @returns flatbuffers.Offset
     */
    static createSubGraphSessionStatesVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]):
        flatbuffers.Offset {
      builder.startVector(4, data.length, 4);
      for (let i = data.length - 1; i >= 0; i--) {
        builder.addOffset(data[i]);
      }
      return builder.endVector();
    }

    /**
     * @param flatbuffers.Builder builder
     * @param number numElems
     */
    static startSubGraphSessionStatesVector(builder: flatbuffers.Builder, numElems: number) {
      builder.startVector(4, numElems, 4);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endSessionState(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    static createSessionState(
        builder: flatbuffers.Builder, kernelsOffset: flatbuffers.Offset,
        subGraphSessionStatesOffset: flatbuffers.Offset): flatbuffers.Offset {
      SessionState.startSessionState(builder);
      SessionState.addKernels(builder, kernelsOffset);
      SessionState.addSubGraphSessionStates(builder, subGraphSessionStatesOffset);
      return SessionState.endSessionState(builder);
    }
  }
}
/**
 * @constructor
 */
export namespace onnxruntime.experimental.fbs {
  export class InferenceSession {
    bb: flatbuffers.ByteBuffer|null = null;

    bb_pos = 0;
    /**
     * @param number i
     * @param flatbuffers.ByteBuffer bb
     * @returns InferenceSession
     */
    __init(i: number, bb: flatbuffers.ByteBuffer): InferenceSession {
      this.bb_pos = i;
      this.bb = bb;
      return this;
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param InferenceSession= obj
     * @returns InferenceSession
     */
    static getRootAsInferenceSession(bb: flatbuffers.ByteBuffer, obj?: InferenceSession): InferenceSession {
      return (obj || new InferenceSession()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @param InferenceSession= obj
     * @returns InferenceSession
     */
    static getSizePrefixedRootAsInferenceSession(bb: flatbuffers.ByteBuffer, obj?: InferenceSession): InferenceSession {
      bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
      return (obj || new InferenceSession()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
    }

    /**
     * @param flatbuffers.ByteBuffer bb
     * @returns boolean
     */
    static bufferHasIdentifier(bb: flatbuffers.ByteBuffer): boolean {
      return bb.__has_identifier('ORTM');
    }

    /**
     * @param flatbuffers.Encoding= optionalEncoding
     * @returns string|Uint8Array|null
     */
    ortVersion(): string|null;
    ortVersion(optionalEncoding: flatbuffers.Encoding): string|Uint8Array|null;
    ortVersion(optionalEncoding?: any): string|Uint8Array|null {
      let offset = this.bb!.__offset(this.bb_pos, 4);
      return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
    }

    /**
     * @param onnxruntime.experimental.fbs.Model= obj
     * @returns onnxruntime.experimental.fbs.Model|null
     */
    model(obj?: onnxruntime.experimental.fbs.Model): onnxruntime.experimental.fbs.Model|null {
      let offset = this.bb!.__offset(this.bb_pos, 6);
      return offset ? (obj || new onnxruntime.experimental.fbs.Model())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param onnxruntime.experimental.fbs.SessionState= obj
     * @returns onnxruntime.experimental.fbs.SessionState|null
     */
    sessionState(obj?: onnxruntime.experimental.fbs.SessionState): onnxruntime.experimental.fbs.SessionState|null {
      let offset = this.bb!.__offset(this.bb_pos, 8);
      return offset ? (obj || new onnxruntime.experimental.fbs.SessionState())
                          .__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) :
                      null;
    }

    /**
     * @param flatbuffers.Builder builder
     */
    static startInferenceSession(builder: flatbuffers.Builder) {
      builder.startObject(3);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset ortVersionOffset
     */
    static addOrtVersion(builder: flatbuffers.Builder, ortVersionOffset: flatbuffers.Offset) {
      builder.addFieldOffset(0, ortVersionOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset modelOffset
     */
    static addModel(builder: flatbuffers.Builder, modelOffset: flatbuffers.Offset) {
      builder.addFieldOffset(1, modelOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset sessionStateOffset
     */
    static addSessionState(builder: flatbuffers.Builder, sessionStateOffset: flatbuffers.Offset) {
      builder.addFieldOffset(2, sessionStateOffset, 0);
    }

    /**
     * @param flatbuffers.Builder builder
     * @returns flatbuffers.Offset
     */
    static endInferenceSession(builder: flatbuffers.Builder): flatbuffers.Offset {
      let offset = builder.endObject();
      return offset;
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset offset
     */
    static finishInferenceSessionBuffer(builder: flatbuffers.Builder, offset: flatbuffers.Offset) {
      builder.finish(offset, 'ORTM');
    }

    /**
     * @param flatbuffers.Builder builder
     * @param flatbuffers.Offset offset
     */
    static finishSizePrefixedInferenceSessionBuffer(builder: flatbuffers.Builder, offset: flatbuffers.Offset) {
      builder.finish(offset, 'ORTM', true);
    }

    static createInferenceSession(
        builder: flatbuffers.Builder, ortVersionOffset: flatbuffers.Offset, modelOffset: flatbuffers.Offset,
        sessionStateOffset: flatbuffers.Offset): flatbuffers.Offset {
      InferenceSession.startInferenceSession(builder);
      InferenceSession.addOrtVersion(builder, ortVersionOffset);
      InferenceSession.addModel(builder, modelOffset);
      InferenceSession.addSessionState(builder, sessionStateOffset);
      return InferenceSession.endInferenceSession(builder);
    }
  }
}
