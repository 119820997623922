import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  addNewQualification,
  editQualification,
  loadQualification,
  loadDegrees,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import { getJobFields } from "../../../store/structure/actions";

const Qualifications = (props) => {
  const {
    createQualification,
    setCreateQualification,
    // redux -----------------
    loading,
    jobFields,
    qualifications,
    degrees,
    loadQualification,
    addNewQualification,
    editQualification,
    loadDegrees,
    getJobFields,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [loadingDegree, setLoadingDegree] = useState(false);
  const [qualificationId, setQualificationId] = useState(-1);
  const [qualificationName, setQualificationName] = useState("");
  const [thisDegree, setThisDegree] = useState(null);
  const [jobField, setJobField] = useState(null);
  const [formError, setFormError] = useState(null);
  const [degreeFilter, setDegreeFilter] = useState(null);
  const [jobFieldFilter, setJobFieldFilter] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //PAGINATION
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (qualifications === null || qualifications.length === 0) {
      loadQualification(setLoadingData);
    } else {
      setLoadingData(false);
    }

    if (degrees === null || degrees.length === 0) {
      loadDegrees(setLoadingDegree);
    }

    if (jobFields === null || jobFields.length === 0) {
      getJobFields();
    }

    return () => {
      setLoadingData(true);
      setLoadingDegree(false);
      setQualificationId(-1);
      setQualificationName("");
      setThisDegree(null);
      setJobField(null);
      setFormError(null);
      setSearchInput("");
      setItemOffset(0);
    };
  }, []);

  const onCloseForm = () => {
    setCreateQualification(false);
    setQualificationId(-1);
    setQualificationName("");
    setThisDegree(null);
    setJobField(null);
  };

  const onSaveForm = () => {
    if (qualificationId === 0 && !createQualification) {
      setFormError({
        element: "GENERAL",
        msg: "Please select qualification you want to update.",
      });
      return;
    }
    if (qualificationName.length === 0) {
      setFormError({ element: "NAME", msg: "Qualification name is required." });
      return;
    }
    if (thisDegree === null) {
      setFormError({
        element: "DEGREE",
        msg: "Degree is required.",
      });
      return;
    }
    if (jobField === null) {
      setFormError({
        element: "JOB_FIELD",
        msg: "Career  field is required.",
      });
      return;
    }
    if (qualificationId >= 0 && !createQualification) {
      console.log({
        id: qualificationId,
        name: qualificationName,
        degree: thisDegree,
        jobField: jobField,
      });
      editQualification(
        {
          id: qualificationId,
          name: qualificationName,
          degree: thisDegree,
          jobField: jobField,
        },
        (status) => status && onCloseForm()
      );
    } else {
      console.log("create: ", {
        newName: qualificationName,
        degreeId: thisDegree.id,
        jobFieldId: jobField.id,
      });
      addNewQualification(
        {
          newName: qualificationName,
          degreeId: thisDegree.id,
          jobFieldId: jobField.id,
        },
        (status) => status && onCloseForm()
      );
    }
  };

  const IS_EDITING = Boolean(qualificationId >= 0);
  let SEARCHED_DATA = !loadingData
    ? searchInput.length === 0 && qualifications
      ? qualifications
      : qualifications.filter((item) =>
          item.name
            .toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase())
        )
    : [];
  SEARCHED_DATA = degreeFilter
    ? SEARCHED_DATA.filter((item) => item.degreeId === degreeFilter?.id)
    : SEARCHED_DATA;
  SEARCHED_DATA = jobFieldFilter
    ? SEARCHED_DATA.filter((item) => item.jobFieldId === jobFieldFilter?.id)
    : SEARCHED_DATA;
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };
  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center">
        <div className="col-12 col-sm">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={
              loadingData ||
              qualifications === null ||
              qualifications.length === 0
            }
            placeholder="Search certificate..."
          />
        </div>

        <div className="col-12 col-sm mt-3 mt-md-0">
          <Autocomplete
            disabled={
              loading ||
              loadingData ||
              loadingDegree ||
              degrees === null ||
              degrees.length === 0
            }
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, degree) => (
              <Box component="li" {...props}>
                {degree?.name}
              </Box>
            )}
            options={degrees || []}
            size="small"
            value={degreeFilter || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose degree"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "degree",
                }}
              />
            )}
            onChange={(event, degree) => {
              setDegreeFilter(degree || null);
              setItemOffset(0);
            }}
          />
        </div>

        <div className="col-12 col-sm mt-3 mt-md-0">
          <Autocomplete
            disabled={
              loading || loadingData || loadingDegree || jobFields.length === 0
            }
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, jobFiled) => (
              <Box component="li" {...props}>
                {jobFiled?.name}
              </Box>
            )}
            options={jobFields || []}
            size="small"
            value={jobFieldFilter || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Career  field"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "jobField",
                }}
              />
            )}
            onChange={(event, jobField) => {
              setJobFieldFilter(jobField || null);
              setItemOffset(0);
            }}
          />
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData || qualifications === null ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Qualification</th>
            <th scope="col">Degree</th>
            <th scope="col">Career field</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        {loadingData || qualifications === null ? (
          <TableSkeleton cols={5} />
        ) : qualifications.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={5}>
                <div className="text-center font-weight-bold">
                  No Career field found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((item, index) => (
              <tr key={"qualification-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {index + 1 + itemOffset}
                </th>
                <td>{item && item.name}</td>
                <td>{item && item.degree && item.degree.name}</td>
                <td>{item && item.jobField && item.jobField.name}</td>
                <td align="center">
                  <IconButton
                    aria-label="edit qualification"
                    size="small"
                    onClick={() => {
                      setQualificationId(item.id);
                      setQualificationName(item.name);
                      setThisDegree(item.degree);
                      setJobField(item.jobField);
                    }}
                  >
                    <ModeRoundedIcon fontSize="small" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createQualification || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Qualification" : "Add New Qualification"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            <TextField
              id="outlined-basic"
              label="Disability name"
              variant="outlined"
              size="small"
              className="w-100"
              disabled={loading || loadingData}
              value={qualificationName}
              onChange={(e) => setQualificationName(e.target.value)}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <Autocomplete
              disabled={loading || loadingData || loadingDegree}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, degree) => (
                <Box component="li" {...props}>
                  {degree?.name}
                </Box>
              )}
              options={degrees || []}
              size="small"
              value={thisDegree || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Degree"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "degree",
                  }}
                />
              )}
              onChange={(event, degree) => {
                setThisDegree(degree || null);
                setFormError(null);
              }}
              className="mt-4"
            />

            <Autocomplete
              disabled={loading || loadingData || loadingDegree}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, field) => (
                <Box component="li" {...props}>
                  {field?.name}
                </Box>
              )}
              options={jobFields || []}
              size="small"
              value={jobField || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Career  field"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "field",
                  }}
                />
              )}
              onChange={(event, field) => {
                setJobField(field || null);
                setFormError(null);
              }}
              className="mt-4"
            />
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase  px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

Qualifications.propTypes = {
  createQualification: PropTypes.bool,
  setCreateQualification: PropTypes.func,
};

const mapStateToProps = ({ loading, degrees, jobFields }) => {
  return {
    loading,
    qualifications: degrees.qualifications,
    degrees: degrees.degrees,
    jobFields,
  };
};

const mapDispatchToProps = {
  loadQualification,
  addNewQualification,
  editQualification,
  loadDegrees,
  getJobFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Qualifications);
