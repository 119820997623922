import { IconButton, Menu, Badge, MenuItem } from "@mui/material";
import React, { useState } from "react";

import { connect } from "react-redux";
import { deleteJcQualification } from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const JobClassificationQualificationCard = (props) => {
  const { jcQualification, deleteJcQualification, index } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [selectedQualification, setSelectedQualification] = useState(null);
  const [confirmRemoveQualification, setConfirmRemoveQualification] =
    useState(false);

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <td>
          {jcQualification.degreeName} in {jcQualification.qualificationName}
        </td>
        <td>
          {!jcQualification.yearsOfExperience && <span>N/A</span>}
          {!!jcQualification.yearsOfExperience && (
            <span>
              {jcQualification.yearsOfExperience} Year
              {jcQualification.yearsOfExperience > 1 && <>s</>}
            </span>
          )}
          {/* <IconButton
            style={{ float: "right" }}
            color="primary"
            size="small"
            onClick={() => {
              setSelectedQualification(jcQualification);
              setShowExperienceForm(true);
            }}
            aria-label="settings"
          >
            <span className="material-icons">more_time</span>
          </IconButton> */}
        </td>
        <td>
          <IconButton
            style={{ float: "right" }}
            color="error"
            size="small"
            onClick={() => {
              setSelectedQualification(jcQualification);
              setConfirmRemoveQualification(true);
            }}
            aria-label="settings"
          >
            <span className="material-icons">delete_outline</span>
          </IconButton>
        </td>
      </tr>

      {confirmRemoveQualification && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveQualification}
          message={`Are you sure you want to remove this qualification?`}
          setConfirmationDialog={setConfirmRemoveQualification}
          onYes={() => {
            deleteJcQualification(
              selectedQualification,
              setConfirmRemoveQualification,
              setSelectedQualification
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  deleteJcQualification,
})(JobClassificationQualificationCard);
