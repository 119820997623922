import {
  Card,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Badge,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";

import {
  deletePosition,
  deleteSharedPosition,
  setAsPlanner,
  setAsHeadOfUnity,
  getPosition,
} from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PositionEmployeesDialog from "./PositionEmployeesDialog";
import PositionProfileDialog from "./PositionProfileDialog";
// import UpdateSharedPositionDialog from "../components/UpdateSharedPositionDialog";
import AddRequisitionDialog from ".././../recruitment/components/AddRequisitionDialog";
import PositionRolesDialog from "./PositionRolesDialog";
import PositionSalarySettingsDialog from "./PositionSalarySettingsDialog";
import PositionSetActingDialog from "./PositionSetActingDialog";
import AddEmployeeDialog from "./AddEmployeeDialog";
import { currencyFormat } from "../../common/components/Utils";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import PendingIcon from "@mui/icons-material/Pending";
const PositionCard = (props) => {
  const {
    loading,
    position,
    positionIndex,
    deletePosition,
    deleteSharedPosition,
    isEntitySectorLocked,
    selectedUnit,
    env,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermissions } = useRequiredAuth();
  const open = Boolean(anchorEl);

  const [confirmRemovePosition, setConfirmRemovePosition] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [showPostionEmployeesModal, setShowPostionEmployeesModal] =
    useState(false);
  const [showUpdatePositionModal, setShowUpdatePositionModal] = useState(false);
  const [showUpdateSharedPositionModal, setShowUpdateSharedPositionModal] =
    useState(false);
  const [showAddRequisitionForm, setShowAddRequisitionForm] = useState(false);

  const [showPositionSetActingDialog, setShowPositionSetActingDialog] =
    useState(false);

  const [showPostionWithHoldsModal, setShowPostionWithHoldsModal] =
    useState(false);

  const [showPositionRolesModel, setShowPositionRolesModel] = useState(false);
  const [showPayrollSettingsDialog, setShowPayrollSettingsDialog] =
    useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const textColor = (position) => {
    let borderColor = "dark";
    if (position.numberOfVacantPosts !== 0) {
      borderColor = "danger";
    }

    if (position.isHeadOfUnit && position.numberOfVacantPosts === 0) {
      borderColor = "primary";
    }

    return borderColor;
  };

  // const checkDisabled = (position) => {
  //   if (position.isShared === false) return true;
  //   else return false;
  // };

  const [showEmployeeForm, setShowEmployeeForm] = useState(false);

  return (
    <>
      <tr className={`text-${textColor(position)}`}>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {positionIndex + 1}
        </th>
        <td>
          {" "}
          <span className={``}>
            {position.name}
            {position.isRISASharedStaff &&
              (selectedUnit.unitId === "1903000000-150200000000000000" ||
                selectedUnit.unitId === "1903000000-150201000000000000") && (
                <span>({position.entityName})</span>
              )}
          </span>
          {position.isCBM && (
            <span className="badge badge-secondary ml-1">(CBM)</span>
          )}
          {position.isTechnicalHead && (
            <span className="badge badge-secondary ml-1">(TECH._HEAD)</span>
          )}
          {position.isHeadOfInstitution && (
            <span className="badge badge-secondary ml-1">(HEAD_OF_INS.)</span>
          )}
          {position.isHeadOfUnit && (
            <span className="badge badge-secondary ml-1">(HEAD_OF_UNIT)</span>
          )}
          {position.isPlanner && (
            <span className="badge badge-secondary ml-1">(Planner.)</span>
          )}
          {position.isHR && (
            <span className="badge badge-secondary ml-1">(HR)</span>
          )}
          {position.isDAF && (
            <span className="badge badge-secondary ml-1">(DAF)</span>
          )}
          {(position.isShared ||
            position.isSharing ||
            position.isRISASharedStaff) && (
            <span className="badge badge-pill badge-light">
              <span
                style={{ fontSize: "14px" }}
                className={`ml-1 ${
                  position.isShared || position.isRISASharedStaff
                    ? "shared"
                    : "sharing"
                }`}
              >
                <i className="fas fa-share"></i>
              </span>
              <small>
                {position.isShared || position.isRISASharedStaff
                  ? "Shared"
                  : "Sharing"}
                {!!position.sharedByName && (
                  <span className="text-primary">
                    {" "}
                    by {position.sharedByName}
                  </span>
                )}
              </small>
            </span>
          )}
          {position.hasActing && (
            <span className="badge badge-pill badge-light acting">
              <span style={{ fontSize: "20px" }} className="ml-1">
                <i className="fas fa-user"></i>
              </span>
              <small className="ml-1">Acting</small>
            </span>
          )}
          {!position.isOnStructure && !position.isShared && (
            // <span className="badge badge-danger ml-1">Not on structure</span>
            <Chip
              style={{ borderRadius: "0px" }}
              className="ml-1 small-chip"
              label="Not on structure"
              color="error"
              size="small"
              variant="outlined"
            />
          )}
          {!!position.numberOnWaitingList &&
            position.numberOfVacantPosts > 0 &&
            !position.numberOfPendingWaitingListRequests && (
              <span className="badge badge-primary">
                ({position.numberOnWaitingList}) on waiting list
              </span>
            )}
          {!!position.numberOfPendingWaitingListRequests && (
            <span className="badge badge-success">
              ({position.numberOfPendingWaitingListRequests}) requested from
              waiting list
            </span>
          )}
          {!!position.wasPublished && (
            <span className="badge badge-primary">Advertized</span>
          )}
        </td>
        <td className="text-left">{position.hiringModeName}</td>

        <td className="text-center">
          {position.levelName}.{position.scaleName}
        </td>
        <td className="text-right">{position.index}</td>
        <td className="text-right">{position.indexValue}</td>

        <td className="text-right" style={{ backgroundColor: "#e9ecef" }}>
          {currencyFormat((position.indexValue || 0) * (position.index || 0))}
        </td>
        <td className="text-center">{position.employeeGroupName}</td>

        {/* <td className="text-center">{position.numberOfVacantPosts}</td> */}

        <td className="text-right">
          {position.numberOfPosts - position.numberOfVacantPosts}
          {"/"}
          {position.numberOfPosts}
        </td>
        <td>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {hasPermissions(["IS_HR", "IS_CBM"]) && (
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setShowUpdatePositionModal(true);
                }}
                className="text-dark font-weight-bold text-primary"
              >
                <span className="material-icons mr-1">work</span>
                Profile
              </MenuItem>
            )}
            {hasPermissions(["IS_HR"]) && (
              <MenuItem
                disabled={loading || position.numberOfVacantPosts < 1}
                onClick={() => {
                  handleCloseMenu();
                  setShowAddRequisitionForm(true);
                }}
                className="text-dark font-weight-light"
              >
                <span
                  className="material-icons mr-1"
                  style={{ transform: "rotateY(180deg)" }}
                >
                  reply
                </span>
                {position.wasPublished ? "Advertized" : "Advertize"}
              </MenuItem>
            )}
          </Menu>
        </td>
      </tr>

      {!!showUpdatePositionModal && (
        <PositionProfileDialog
          isEntitySectorLocked={isEntitySectorLocked}
          showUpdatePositionModal={showUpdatePositionModal}
          setShowUpdatePositionModal={setShowUpdatePositionModal}
          position={position}
        />
      )}

      <AddRequisitionDialog
        position={position}
        showAddRequisitionForm={showAddRequisitionForm}
        setShowAddRequisitionForm={setShowAddRequisitionForm}
      />
    </>
  );
};

const mapStateToProps = ({ env, loading, user }) => {
  return {
    env,
    loading,
    user,
  };
};

export default connect(mapStateToProps, {
  deletePosition,
  deleteSharedPosition,
  setAsPlanner,
  setAsHeadOfUnity,
  getPosition,
})(PositionCard);
