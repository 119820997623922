export const defaultState = {
  roles: [],
  permissions: [],
  modules: [],
  recruitment: {
    oversight: null,
    applicants: [],
    advertisementYears: null,
    appeals: [],
    scheduledExams: [],
    recruitmentStatistic: null,
  },
  disability: {
    disabilities: null,
    disabilityLevels: null,
  },
  certificate: {
    certificates: null,
    certificateFields: null,
  },
  competency: null,
  degrees: {
    degrees: null,
    qualifications: null,
  },
  adminPayroll: {
    adminAllowances: [],
    adminCreditor: [],
    adminBanks: [],
    adminCountries: [],
    adminCostCenter: [],
    adminPayrollApprover: [],
    adminPayrollOversight: [],
    adminPayrollPeriods: [],
    adminPayrollSOFunds: [],
  },
  adminDashboard: {
    structureStatistic: null,
    positions: [],
    employees: [],
    entities: [],
  },
  adminExit: {
    exitTypes: [],
    requests: [],
    exitMonthYears: null,
  },
};
