import { IconButton, Menu, Badge, MenuItem } from "@mui/material";
import React, { useState } from "react";

import { connect } from "react-redux";
import { deleteJcCompetency } from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const JobClassificationCompetencyCard = (props) => {
  const { jcCompetency, deleteJcCompetency, index } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [confirmRemoveCompetency, setConfirmRemoveCompetency] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>

        <td> {jcCompetency.competency && jcCompetency.competency.name}</td>

        <td>
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedCompetency(jcCompetency);
                  setConfirmRemoveCompetency(true);
                }}
                className="text-danger font-weight-light"
              >
                Delete
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>

      {confirmRemoveCompetency && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveCompetency}
          message={`Are you sure you want to remove this competency ${
            selectedCompetency.competency && selectedCompetency.competency.name
          }?`}
          setConfirmationDialog={setConfirmRemoveCompetency}
          onYes={() => {
            deleteJcCompetency(
              selectedCompetency,
              setConfirmRemoveCompetency,
              setSelectedCompetency
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  deleteJcCompetency,
})(JobClassificationCompetencyCard);
