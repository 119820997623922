import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { connect } from "react-redux";
import {
  CardContent,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@mui/material";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import JobClassification from "../../structure/pages/JobClassification";
import Oversight from "../../structure/pages/Oversight";
import SalaryIndexGrid from "../../structure/pages/SalaryIndexGrid";
import StructureDashboard from "../components/StructureDashboard";

const Structure = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            className="app-bar w-100 mb-0 pb-0"
          >
            <Toolbar className="mb-0 pb-0">
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                className="mb-0 pb-0"
                value={value}
                onChange={handleChange}
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
              </Tabs>

              <Box sx={{ flexGrow: 1 }} />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span className="lead text-uppercase">
                  Organization Structure Administration
                </span>
              </Typography>
            </Toolbar>
          </AppBar>

          <div>
            <TabPanel value={value} index={0}>
              <React.Fragment>
                <StructureDashboard />
              </React.Fragment>
            </TabPanel>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps, {})(Structure);
