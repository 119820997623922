import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import { getApplicationCertificates } from "../../../../store/profile/actions";
import NoResults from "../../../common/components/NoResults";

import PreviewPdfUrl from "../../../common/components/PreviewPdfUrl";
import AdvertSkeleton from "../../../common/components/AdvertSkeleton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { showError } from "../../../toastify";

export const Certificates = (props) => {
  const {
    env,
    loading,
    onWait,
    applicationId,
    applicationCertificates,
    getApplicationCertificates,
  } = props;

  useEffect(() => {
    getApplicationCertificates(applicationId);
  }, []);

  const [loadingDocument, setLoadingDocument] = useState(false);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="certificates">
        <CardContent>
          {loading && !onWait && !applicationCertificates?.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <div>
                  <Typography
                    className="mr-2"
                    variant="h5"
                    style={{ color: "#007bff" }}
                  >
                    Awards/Certificates
                  </Typography>
                </div>
              </div>

              {applicationCertificates.length === 0 ? (
                <NoResults />
              ) : (
                applicationCertificates.map((certificate, index) => (
                  <CertificateItemCard
                    key={certificate.id}
                    index={index}
                    certificate={certificate}
                    loading={loading}
                    setLoadingDocument={setLoadingDocument}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "#007bff",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  loading,
  env,
  onWait,
  applicationCertificates,
}) => ({
  loading,
  env,
  onWait,
  applicationCertificates,
});

export default connect(mapStateToProps, {
  // getApplicationCertificates,
})(Certificates);

const CertificateItemCard = (props) => {
  const { certificate } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <span className="font-weight-bold text-primary">
              {certificate.yearIssued}
            </span>
          }
          title={<Typography>{certificate.certificate.name}</Typography>}
          subheader={
            <span className="btn-link">{certificate.institutionName} </span>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center "
                style={{ borderRadius: "4px" }}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (certificate.docReferenceId) {
                      setOnViewDocument(true);
                    } else {
                      showError("Document is not available");
                    }
                  }}
                >
                  <FilePresentIcon
                    // fontSize="large"
                    style={{
                      color: !!certificate.docReferenceId
                        ? "#fe4066"
                        : "#c57c7cb8",
                    }}
                  />
                </IconButton>
              </div>
            </>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `certificate document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              certificate.docReferenceId,
          }}
        />
      )}
    </>
  );
};
