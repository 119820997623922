import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import JobClassificationQualifications from "./JobClassificationQualifications";
import AddJobClassificationQualificationDialog from "./AddJobClassificationQualificationDialog";
import AddIcon from "@mui/icons-material/Add";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import JobClassificationCompetency from "./JobClassificationCompetencies";
import JobClassificationCertificate from "./JobClassificationCertificates";
import {
  getJobClassificationQualifications,
  getJobClassificationCompetencies,
  getJobClassificationCertificates,
  getCompetencies,
  getCertificateFields,
  getCertificates,
} from "../../../store/structure/actions";
import { useEffect } from "react";
import { connect } from "react-redux";

const JobClassificationProfileDialog = (props) => {
  const {
    jobClassificationId,
    jobName,
    showJcProfileModal,
    setShowJcProfileModal,
    getJobClassificationQualifications,
    getJobClassificationCompetencies,
    getJobClassificationCertificates,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [showQualificationForm, setShowQualificationForm] = useState(false);

  const [showExperienceForm, setShowExperienceForm] = useState(false);
  const [showCompetencyForm, setShowCompetencyForm] = useState(false);

  const [showCertificateForm, setShowCertificateForm] = useState(false);

  const [selectedQualification, setSelectedQualification] = useState(null);

  const onClose = () => {
    setShowJcProfileModal(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(
    () => {
      getJobClassificationQualifications(jobClassificationId);
      getJobClassificationCertificates(jobClassificationId);
      getJobClassificationCompetencies(jobClassificationId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobClassificationId]
  );

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showJcProfileModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            <span className="text-dark">Job Profile | </span> {jobName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            <Box
              className="d-flex justify-content-center"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Qualifications" {...a11yProps(0)} />
                <Tab label="Certificates" {...a11yProps(1)} />
                <Tab label="Competencies" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <React.Fragment>
                <div
                  className="elevated rounded p-3 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                    minHeight: "60vh",
                  }}
                >
                  <div className="pb-1 text-right">
                    <Button
                      onClick={() => {
                        setShowQualificationForm(true);
                        setSelectedQualification(null);
                      }}
                      style={{
                        // float: "right",
                        borderRadius: "8px",
                        height: "35px",
                        // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                        border: `1px solid #007bff`,
                        color: "#fff",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "#007bff",
                        "&:hover": {
                          backgroundColor: "#f0f2f5",
                        },
                      }}
                      size="md"
                    >
                      <AddIcon />
                    </Button>
                  </div>

                  <div>
                    <JobClassificationQualifications
                      jobClassificationId={jobClassificationId}
                    />

                    <AddJobClassificationQualificationDialog
                      jobClassificationId={jobClassificationId}
                      selectedQualification={selectedQualification}
                      setIsEditing={setIsEditing}
                      showQualificationForm={showQualificationForm}
                      setShowQualificationForm={setShowQualificationForm}
                      setSelectedQualification={setSelectedQualification}
                      isEditing={isEditing}
                    />
                  </div>
                </div>
              </React.Fragment>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <React.Fragment>
                <div
                  className="elevated rounded p-3 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                    minHeight: "60vh",
                  }}
                >
                  <JobClassificationCertificate
                    jobClassificationId={jobClassificationId}
                  />
                </div>
              </React.Fragment>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <React.Fragment>
                <div
                  className="elevated rounded p-3 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                    minHeight: "60vh",
                  }}
                >
                  <JobClassificationCompetency
                    jobClassificationId={jobClassificationId}
                  />
                </div>
              </React.Fragment>
            </TabPanel>
          </div>
        </DialogContent>
        <DialogActions className="py-1"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};

export default connect(mapStateToProps, {
  getJobClassificationQualifications,
  getJobClassificationCompetencies,
  getJobClassificationCertificates,
  getCompetencies,
  getCertificateFields,
  getCertificates,
})(JobClassificationProfileDialog);
