import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, AlertTitle } from "@mui/material";

const NoRecordMessage = (props) => {
  const { isEmpty, title, message, onClickLink, link, textLink } = props;

  return (
    <>
      {isEmpty && (
        <div className="jumbotron jumbotron-fluid text-center">
          <div className="container">
            <p className="lead">{title}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default NoRecordMessage;
