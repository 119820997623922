import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { rejectRequisition } from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";

const RejectRequisitionDialog = (props) => {
  const {
    loading,
    onWait,
    showRejectRequisitionForm,
    setShowRejectRequisitionForm,
    selectedRequisition,
    rejectRequisition,
    setShowRequisitionDetails,
    setIsRejection,
    isRejection
  } = props;

  const [requisitionForm, setRequisitionForm] = useState({
    requisitionId: selectedRequisition.id,
    statusComments: "",
    statusId: 2,
  });
  const [errors, setErrors] = useState({
    statusCommentsHasError: false,
  });

  const [askToReject, setAskToReject] = useState(false);

  const formValidator = () => {
    const error = {
      statusCommentsHasError: false,
      hasError: false,
    };

    if (!requisitionForm.statusComments) {
      error.statusCommentsHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      rejectRequisition(
        requisitionForm,
        setRequisitionForm,
        setShowRejectRequisitionForm,
        setShowRequisitionDetails,
        setAskToReject,
        setIsRejection
      );
    }
  };

  const onClose = () => {
    setAskToReject(false);
    setShowRejectRequisitionForm(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRejectRequisitionForm}
        fullWidth
        maxWidth="sm"
      >
        {askToReject && (
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              Reject Requisition @{selectedRequisition.positionName}?
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            {!askToReject && (
              <div className="row">
                <div className="col-12 mt-3">
                  <div className="alert alert-info" role="alert">
                    <p>
                      Are you sure you want to reject this requisition @
                      {selectedRequisition.positionName}?
                    </p>
                  </div>
                </div>
              </div>
            )}
            {askToReject && (
              <div className="row">
                <div className="col-12 mt-3">
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="Comments"
                    autoFocus
                    label="Type your comments .."
                    variant="outlined"
                    placeholder="Type your comments  .."
                    value={requisitionForm.statusComments}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const statusComments = e.target.value;
                      setRequisitionForm({
                        ...requisitionForm,
                        statusComments,
                      });
                      const statusCommentsHasError = false;
                      setErrors({ ...errors, statusCommentsHasError });
                    }}
                  />

                  {errors.statusCommentsHasError && (
                    <div className="text-danger mt-2">
                      Comments is required{" "}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        {askToReject && (
          <DialogActions className="d-flex justify-content-center py-4">
            <button
              onClick={onSave}
              type="button"
              className="btn btn-primary text-uppercase  px-4"
              disabled={onWait || loading}
            >
              {onWait ? "Wait..." : "Reject"}
            </button>
          </DialogActions>
        )}

        {!askToReject && (
          <DialogActions className="d-flex justify-content-center py-4">
            <button
              onClick={()=>{
                setAskToReject(true);
              }}
              type="button"
              className="btn btn-success text-uppercase  px-4 btn-sm"
            >
              Yes
            </button>
            <button
              onClick={onClose}
              type="button"
              className="btn btn-secondary text-uppercase  px-4 btn-sm"
            >No
            </button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user,onWait }) => {
  return {
    loading,
    user,
    onWait
  };
};
export default connect(mapStateToProps, {
  rejectRequisition,
})(RejectRequisitionDialog);
