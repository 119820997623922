import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { Tooltip, Typography } from "@mui/material";
import defaultPofile from "../../assets/default-profile.jpg";
import SearchBox from "../../common/components/SearchBox";

import { getLiveCandidates } from "../../../store/psychometric/actions";
import { connect, useDispatch } from "react-redux";

function LiveCandidateCard({
  selectedCandidate,
  setSelectedCandidate,
  getLiveCandidates,
  liveCandidates,
  totalLiveCandidates,
  setTotalLiveCandidates,
}) {
  const [checked, setChecked] = useState([1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const [searchTerm, setSearchTerm] = React.useState("");

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
    };
    getLiveCandidates(query, setTotalLiveCandidates, setIsLoading);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  useEffect(() => {
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
    };
    getLiveCandidates(query, setTotalLiveCandidates, setIsLoading);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const query = {
        searchTerm: searchTerm,
        page: currentPage,
        size: itemPerPage,
      };
      getLiveCandidates(query, setTotalLiveCandidates, setIsLoading, true);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <div>
        <SearchBox
          onKeyPress={onSearch}
          onSearch={(search) => {
            setSearchTerm(search);

            if (!search) {
              const currentPage = 1;
              setCurrentPage(currentPage);
              const query = {
                searchTerm: "",
                page: currentPage,
                size: itemPerPage,
              };
              getLiveCandidates(query, setTotalLiveCandidates, setIsLoading1);
            }
          }}
          placeholder="Search…"
        />
      </div>

      <List dense className="live-candidates">
        {liveCandidates.map((candidate) => (
          <LiveCandidateCardItem
            key={candidate.id}
            candidate={candidate}
            selectedCandidate={selectedCandidate}
            setSelectedCandidate={setSelectedCandidate}
          />
        ))}
      </List>
    </>
  );
}

const mapStateToProps = ({ user, loading, liveCandidates }) => {
  return {
    user,
    loading,
    liveCandidates,
  };
};
export default connect(mapStateToProps, {
  getLiveCandidates,
})(LiveCandidateCard);

const LiveCandidateCardItem = ({
  candidate,
  selectedCandidate,
  setSelectedCandidate,
}) => {
  useEffect(() => {
    if (!!selectedCandidate && candidate.id === selectedCandidate?.id)
      setSelectedCandidate(candidate);

    return () =>
      candidate.id === selectedCandidate?.id && setSelectedCandidate(null);
  }, [candidate]);

  return (
    <>
      <ListItem
        onClick={() => setSelectedCandidate(candidate)}
        className={`${
          candidate.id === selectedCandidate?.id ? "active border" : ""
        } `}
        divider
        secondaryAction={
          <Tooltip
            title={`${candidate.isSessionLocked ? "Locked" : "Active"} `}
          >
            <span
              className={`material-icons text-${
                candidate.isSessionLocked ? "danger" : "success"
              } cursor-pointer`}
            >
              fiber_manual_record
            </span>
          </Tooltip>
        }
        disablePadding
      >
        <ListItemButton>
          <ListItemAvatar>
            <Avatar
              alt={`${candidate.lastName} ${candidate.firstName}`}
              src={defaultPofile}
            />
          </ListItemAvatar>
          <ListItemText
            id={candidate.id}
            primary={
              <span className="font-weight-bold">
                {candidate.no}: {candidate.lastName} {candidate.firstName}
              </span>
            }
            secondary={
              <React.Fragment>
                <small className="d-block">{candidate.positionName}</small>
                <small className="d-block">{candidate.entityName}</small>
                <small className="d-block text-primary  ">
                  {candidate.sectorName}
                </small>
                <Typography component="p"></Typography>
              </React.Fragment>
            }
          />
        </ListItemButton>
      </ListItem>
    </>
  );
};
