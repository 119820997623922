import { defaultState } from "./state";
import recruitment from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...recruitment };

const analytics = (analyticsState = defaultState.analytics, action) => {
  switch (action.type) {
    case types.SET_ANALYTICS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.analytics;

    default:
      return analyticsState;
  }
};

const jobPublications = (
  jobPublicationsState = defaultState.jobPublications,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_PUBLICATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.jobPublications;

    default:
      return jobPublicationsState;
  }
};

const applicantJobPublications = (
  applicantJobPublicationsState = defaultState.applicantJobPublications,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_JOB_PUBLICATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicantJobPublications;

    default:
      return applicantJobPublicationsState;
  }
};

const applicants = (applicantsState = defaultState.applicants, action) => {
  switch (action.type) {
    case types.SET_APPLICANTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicants;

    default:
      return applicantsState;
  }
};

const applicantExamMarking = (
  applicantExamMarkingState = defaultState.applicantExamMarking,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_EXAM_MARKING:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicantExamMarking;

    default:
      return applicantExamMarkingState;
  }
};

const applicantOralExamMarking = (
  applicantOralExamMarkingState = defaultState.applicantOralExamMarking,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_ORAL_EXAM_MARKING:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicantOralExamMarking;

    default:
      return applicantOralExamMarkingState;
  }
};

const applicantOverallResults = (
  applicantOverallResultsState = defaultState.applicantOverallResults,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_OVERALL_RESULTS:
      return action.data.sort((a, b) => b.score - a.score);

    case types.UPDATE_APPLICANT_OVERALL_RESULT: {
      const tmpApplicantOverallResultsState = [...applicantOverallResultsState];
      const index = tmpApplicantOverallResultsState.findIndex(
        ({ applicantId }) => applicantId === action.data.applicantId
      );

      tmpApplicantOverallResultsState[index] = { ...action.data };

      const tmp = [...tmpApplicantOverallResultsState];

      return tmp.sort((a, b) => b.score - a.score);
    }

    case types.CLEAN_STATE:
      return defaultState.applicantOverallResults;

    default:
      return applicantOverallResultsState;
  }
};

const appeals = (appealsState = defaultState.appeals, action) => {
  switch (action.type) {
    case types.SET_APPEALS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.appeals;

    default:
      return appealsState;
  }
};

const unConfirmedAppeals = (
  unConfirmedAppealsState = defaultState.unConfirmedAppeals,
  action
) => {
  switch (action.type) {
    case types.SET_UNCONFIRMED_APPEALS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.unConfirmedAppeals;

    default:
      return unConfirmedAppealsState;
  }
};

const questions = (questionsState = defaultState.questions, action) => {
  switch (action.type) {
    case types.SET_QUESTIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.questions;

    default:
      return questionsState;
  }
};

const tests = (testsState = defaultState.tests, action) => {
  switch (action.type) {
    case types.SET_TESTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.tests;

    default:
      return testsState;
  }
};
const tempQuestions = (
  tempQuestionsState = defaultState.tempQuestions,
  action
) => {
  switch (action.type) {
    case types.SET_TEMP_QUESTIONS:
      return action.data;

    case types.DELETE_TEMP_QUESTION:
      const tempQuestionState = [...tempQuestionsState];
      const index = tempQuestionState.findIndex(
        ({ id }) => id === action.data.id
      );

      tempQuestionState.splice(index, 1);

      return tempQuestionState;

    case types.UPDATE_TEMP_QUESTION: {
      const tempQuestionState = [...tempQuestionsState];
      const index = tempQuestionState.findIndex(
        ({ id }) => id === action.data.id
      );

      tempQuestionState[index] = { ...action.data };

      const tmp = [...tempQuestionState];

      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.tempQuestions;

    default:
      return tempQuestionsState;
  }
};

const examQuestions = (
  examQuestionsState = defaultState.examQuestions,
  action
) => {
  switch (action.type) {
    case types.SET_EXAM_QUESTIONS: {
      let tempExamQuestions1 = [];
      let tempExamQuestions2 = [];
      let tempExamQuestions3 = [];

      action.data.forEach((e) => {
        if (e.markedAsCanceled) tempExamQuestions1.push({ ...e });
        else if (e.isExamQuestionCanceled) tempExamQuestions2.push({ ...e });
        else tempExamQuestions3.push({ ...e });
      });

      const tmp = [
        ...tempExamQuestions1,
        ...tempExamQuestions2,
        ...tempExamQuestions3,
      ];

      return tmp;
    }

    case types.UPDATE_EXAM_QUESTION: {
      const tempExamQuestionsState = [...examQuestionsState];
      const index = tempExamQuestionsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tempExamQuestionsState[index] = { ...action.data };

      let tempExamQuestions1 = [];
      let tempExamQuestions2 = [];
      let tempExamQuestions3 = [];

      tempExamQuestionsState
        .sort((a, b) =>
          parseInt(a.questionNo) > parseInt(b.questionNo) ? 1 : -1
        )
        .forEach((e) => {
          if (e.markedAsCanceled) tempExamQuestions1.push({ ...e });
          else if (e.isExamQuestionCanceled) tempExamQuestions2.push({ ...e });
          else tempExamQuestions3.push({ ...e });
        });

      const tmp = [
        ...tempExamQuestions1,
        ...tempExamQuestions2,
        ...tempExamQuestions3,
      ];

      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.examQuestions;

    default:
      return examQuestionsState;
  }
};
//applicantExamSessions

const applicantExamSessions = (
  applicantExamSessionsState = defaultState.applicantExamSessions,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_EXAM_SESSIONS:
      return action.data;

    case types.UPDATE_APPLICANT_EXAM_SESSIONS: {
      const tempApplicantExamSessionsState = [...applicantExamSessionsState];
      const index = tempApplicantExamSessionsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tempApplicantExamSessionsState[index] = { ...action.data };
      const lockedSessions = [];
      const submittedExam = [];
      const others = [];

      tempApplicantExamSessionsState.forEach((e) => {
        if (e.isSessionLocked) lockedSessions.push({ ...e });
        else if (e.submittedOn) submittedExam.push({ ...e });
        else others.push({ ...e });
      });
      const tmp = [...lockedSessions, ...submittedExam, ...others];

      return tmp;
    }
    //START_APPLICANT_EXAM_SESSIONS
    case types.START_APPLICANT_EXAM_SESSIONS: {
      const tempApplicantExamSessionsState = [...applicantExamSessionsState];
      const session = tempApplicantExamSessionsState.find(
        ({ id }) => id === action.data.id
      );
      if (!session) tempApplicantExamSessionsState.push(action.data);
      const lockedSessions = [];
      const submittedExam = [];
      const others = [];

      tempApplicantExamSessionsState.forEach((e) => {
        if (e.isSessionLocked) lockedSessions.push({ ...e });
        else if (e.submittedOn) submittedExam.push({ ...e });
        else others.push({ ...e });
      });
      const tmp = [...lockedSessions, ...submittedExam, ...others];

      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.applicantExamSessions;

    default:
      return applicantExamSessionsState;
  }
};

const applicantWrittenExamResults = (
  applicantWrittenExamResultsState = defaultState.applicantWrittenExamResults,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_WRITTEN_EXAM_RESULTS:
      return action.data;

    case types.UPDATE_APPLICANT_WRITTEN_EXAM_SESSION: {
      const tempApplicantWrittenExamResultsState = [
        ...applicantWrittenExamResultsState,
      ];
      const index = tempApplicantWrittenExamResultsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tempApplicantWrittenExamResultsState[index] = { ...action.data };

      const tmp = [
        ...tempApplicantWrittenExamResultsState.sort((a, b) =>
          parseFloat(a.totalScore) > parseFloat(b.totalScore) ? 1 : -1
        ),
      ];

      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.applicantWrittenExamResults;

    default:
      return applicantWrittenExamResultsState;
  }
};
//applicantOralExamResults

const applicantOralExamResults = (
  applicantOralExamResultsState = defaultState.applicantOralExamResults,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_ORAL_EXAM_RESULTS:
      return action.data;

    case types.UPDATE_APPLICANT_ORAL_EXAM_SESSION: {
      const tempApplicantOralExamResultsState = [
        ...applicantOralExamResultsState,
      ];
      const index = tempApplicantOralExamResultsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tempApplicantOralExamResultsState[index] = { ...action.data };

      const tmp = [...tempApplicantOralExamResultsState];

      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.applicantOralExamResults;

    default:
      return applicantOralExamResultsState;
  }
};

const applicantWrittenExamAnswers = (
  applicantWrittenExamAnswersState = defaultState.applicantWrittenExamAnswers,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_WRITTEN_EXAM_ANSWERS:
      return action.data;

    case types.UPDATE_APPLICANT_WRITTEN_EXAM_ANSWER: {
      const tempApplicantWrittenExamAnswersState = [
        ...applicantWrittenExamAnswersState,
      ];
      const index = tempApplicantWrittenExamAnswersState.findIndex(
        ({ id }) => id === action.data.id
      );

      tempApplicantWrittenExamAnswersState[index] = { ...action.data };

      const tmp = [...tempApplicantWrittenExamAnswersState];

      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.applicantWrittenExamAnswers;

    default:
      return applicantWrittenExamAnswersState;
  }
};
//applicantOralExamAnswers

const applicantOralExamAnswers = (
  applicantOralExamAnswersState = defaultState.applicantOralExamAnswers,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_ORAL_EXAM_ANSWERS:
      return action.data;

    case types.ADD_APPLICANT_ORAL_EXAM_ANSWER: {
      const tempApplicantOralExamAnswersState = [
        ...applicantOralExamAnswersState,
      ];
      tempApplicantOralExamAnswersState.unshift(action.data);
      return tempApplicantOralExamAnswersState;
    }

    case types.UPDATE_APPLICANT_ORAL_EXAM_ANSWER: {
      const tempApplicantOralExamAnswersState = [
        ...applicantOralExamAnswersState,
      ];
      const index = tempApplicantOralExamAnswersState.findIndex(
        ({ id }) => id === action.data.id
      );

      tempApplicantOralExamAnswersState[index] = { ...action.data };

      const tmp = [...tempApplicantOralExamAnswersState];

      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.applicantOralExamAnswers;

    default:
      return applicantOralExamAnswersState;
  }
};

const requisitionStatus = (
  requisitionStatusState = defaultState.requisitionStatus,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITON_STATUS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requisitionStatus;

    default:
      return requisitionStatusState;
  }
};

const applicationsStatus = (
  applicationsStatusState = defaultState.applicationsStatus,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICATION_STATUS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicationsStatus;

    default:
      return applicationsStatusState;
  }
};

const advertisementStatus = (
  advertisementStatusState = defaultState.advertisementStatus,
  action
) => {
  switch (action.type) {
    case types.SET_ADVERTISEMENT_STATUS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.advertisementStatus;

    default:
      return advertisementStatusState;
  }
};

const requisitionCertificates = (
  requisitionCertificatesState = defaultState.requisitionCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_CERTIFICATES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requisitionCertificates;

    default:
      return requisitionCertificatesState;
  }
};

const requisitionCompetencies = (
  requisitionCompetenciesState = defaultState.requisitionCompetencies,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_COMPETENCIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requisitionCompetencies;

    default:
      return requisitionCompetenciesState;
  }
};

const requisitionQualifications = (
  requisitionQualificationsState = defaultState.requisitionQualifications,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_QUALIFICATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requisitionQualifications;

    default:
      return requisitionQualificationsState;
  }
};

const requisitionExperiences = (
  requisitionExperiencesState = defaultState.requisitionExperiences,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_EXPERIENCES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requisitionExperiences;

    default:
      return requisitionExperiencesState;
  }
};

const requisitions = (
  requisitionsState = defaultState.requisitions,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITONS:
      return action.data;
    case types.ADD_REQUISITION: {
      const tmpRequisitionsState = [...requisitionsState];
      tmpRequisitionsState.unshift(action.data);
      return tmpRequisitionsState;
    }

    case types.UPDATE_REQUISITION: {
      const tmpRequisitionsState = [...requisitionsState];
      const index = tmpRequisitionsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpRequisitionsState[index] = action.data;

      return tmpRequisitionsState;
    }

    case types.DELETE_REQUISITION:
      const tmpRequisitionsState = [...requisitionsState];
      const index = tmpRequisitionsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRequisitionsState.splice(index, 1);

      return tmpRequisitionsState;

    case types.CLEAN_STATE:
      return defaultState.requisitions;

    default:
      return requisitionsState;
  }
};

const advertisements = (
  advertisementsState = defaultState.advertisements,
  action
) => {
  switch (action.type) {
    case types.SET_ADVERTISEMENTS:
      return action.data;
    case types.ADD_ADVERTISEMENT: {
      const tmpAdvertisementsState = [...advertisementsState];
      tmpAdvertisementsState.unshift(action.data);
      return tmpAdvertisementsState;
    }

    case types.UPDATE_ADVERTISEMENT: {
      const tmpAdvertisementsState = [...advertisementsState];
      const index = tmpAdvertisementsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpAdvertisementsState[index] = action.data;

      return tmpAdvertisementsState;
    }

    case types.CLEAN_STATE:
      return defaultState.advertisements;

    default:
      return advertisementsState;
  }
};

//advertisementApplications

const advertisementApplications = (
  advertisementApplicationsState = defaultState.advertisementApplications,
  action
) => {
  switch (action.type) {
    case types.SET_ADVERTISEMENT_APPLICATIONS:
      return action.data;

    case types.UPDATE_ADVERTISEMENT_APPLICATION: {
      const tmpAdvertisementApplicationsState = [
        ...advertisementApplicationsState,
      ];
      const index = tmpAdvertisementApplicationsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpAdvertisementApplicationsState[index] = { ...action.data };

      const tmp = [...tmpAdvertisementApplicationsState];
      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.advertisementApplications;

    default:
      return advertisementApplicationsState;
  }
};

const writtenExams = (
  writtenExamsState = defaultState.writtenExams,
  action
) => {
  switch (action.type) {
    case types.SET_WRITTEN_EXAMS:
      return action.data;

    case types.UPDATE_WRITTEN_EXAM: {
      const tmpWrittenExamsState = [...writtenExamsState];
      const index = tmpWrittenExamsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpWrittenExamsState[index] = { ...action.data };

      const tmp = [...tmpWrittenExamsState];

      return tmp;
    }

    case types.ADD_WRITTEN_EXAM: {
      const tmpWrittenExamsState = [...writtenExamsState];
      tmpWrittenExamsState.unshift(action.data);
      return tmpWrittenExamsState;
    }

    case types.DELETE_WRITTEN_EXAM:
      const tmpWrittenExamsState = [...writtenExamsState];
      const index = tmpWrittenExamsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpWrittenExamsState.splice(index, 1);

      return tmpWrittenExamsState;

    case types.CLEAN_STATE:
      return defaultState.writtenExams;

    default:
      return writtenExamsState;
  }
};

const oralExams = (oralExamsState = defaultState.oralExams, action) => {
  switch (action.type) {
    case types.SET_ORAL_EXAMS:
      return action.data;

    case types.UPDATE_ORAL_EXAM: {
      const tmpOralExamsState = [...oralExamsState];
      const index = tmpOralExamsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpOralExamsState[index] = { ...action.data };

      const tmp = [...tmpOralExamsState];

      return tmp;
    }

    case types.ADD_ORAL_EXAM: {
      const tmpOralExamsState = [...oralExamsState];
      tmpOralExamsState.unshift(action.data);
      return tmpOralExamsState;
    }

    case types.DELETE_WRITTEN_EXAM:
      const tmpOralExamsState = [...oralExamsState];
      const index = tmpOralExamsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpOralExamsState.splice(index, 1);

      return tmpOralExamsState;

    case types.CLEAN_STATE:
      return defaultState.oralExams;

    default:
      return oralExamsState;
  }
};

const userProfile = (userProfileState = defaultState.userProfile, action) => {
  switch (action.type) {
    case types.SET_USER_PROFILE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.userProfile;

    default:
      return userProfileState;
  }
};

const convertedFileToBase64 = (
  convertedFileToBase64State = defaultState.convertedFileToBase64,
  action
) => {
  switch (action.type) {
    case types.SET_CONVERTED_FILE_TO_BASE64:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.convertedFileToBase64;

    default:
      return convertedFileToBase64State;
  }
};

//questionTypes

const questionTypes = (
  questionTypesState = defaultState.questionTypes,
  action
) => {
  switch (action.type) {
    case types.SET_QUESTION_TYPES:
      return action.data;

    case types.UPDATE_QUESTION_TYPE: {
      const tmpState = [...questionTypesState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = { ...action.data };

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.questionTypes;

    default:
      return questionTypesState;
  }
};
//hasExamQuestion

const hasExamQuestion = (
  hasExamQuestionState = defaultState.hasExamQuestion,
  action
) => {
  switch (action.type) {
    case types.HAS_EXAM_QUESTIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.hasExamQuestion;

    default:
      return hasExamQuestionState;
  }
};

const applicantSessionLogs = (
  applicantSessionLogsState = defaultState.applicantSessionLogs,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_EXAM_SESSION_LOGS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicantSessionLogs;

    default:
      return applicantSessionLogsState;
  }
};
//sessionAdditionalMinutes
const sessionAdditionalMinutes = (
  sessionAdditionalMinutesState = defaultState.sessionAdditionalMinutes,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_SESSION_ADDITIONALMINUTES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.sessionAdditionalMinutes;

    default:
      return sessionAdditionalMinutesState;
  }
};
//haslockedOver3Times

const haslockedOver3Times = (
  haslockedOver3TimesState = defaultState.haslockedOver3Times,
  action
) => {
  switch (action.type) {
    case types.SET_SESSION_UNLOCKED_OVER_3_TIMES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.haslockedOver3Times;

    default:
      return haslockedOver3TimesState;
  }
};

const applicantsNotAttended = (
  applicantsNotAttendedState = defaultState.applicantsNotAttended,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANTS_NOT_ATTENDED:
      return action.data;

    case types.DELETE_APPLICANT_NOT_ATTENDED:
      const tmpApplicantsNotAttendedState = [...applicantsNotAttendedState];
      const index = tmpApplicantsNotAttendedState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpApplicantsNotAttendedState.splice(index, 1);

      return tmpApplicantsNotAttendedState;

    case types.CLEAN_STATE:
      return defaultState.applicantsNotAttended;

    default:
      return applicantsNotAttendedState;
  }
};

const applicantsAttended = (
  applicantsAttendedState = defaultState.applicantsAttended,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANTS_ATTENDED:
      return action.data;
    case types.ADD_APPLICANT_ATTENDED: {
      const tmpApplicantsAttendedState = [...applicantsAttendedState];
      tmpApplicantsAttendedState.unshift(action.data);
      return tmpApplicantsAttendedState;
    }
    case types.DELETE_APPLICANT_ATTENDED:
      const tmpApplicantsAttendedState = [...applicantsAttendedState];
      const index = tmpApplicantsAttendedState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpApplicantsAttendedState.splice(index, 1);

      return tmpApplicantsAttendedState;

    case types.CLEAN_STATE:
      return defaultState.applicantsAttended;

    default:
      return applicantsAttendedState;
  }
};

const applicantWrittenExamResult = (
  applicantWrittenExamResultState = defaultState.applicantWrittenExamResult,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_WRITTEN_EXAM_RESULT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicantWrittenExamResult;

    default:
      return applicantWrittenExamResultState;
  }
};

//applicantOralExamResult
const applicantOralExamResult = (
  applicantOralExamResultState = defaultState.applicantOralExamResult,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_ORAL_EXAM_RESULT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicantOralExamResult;

    default:
      return applicantOralExamResultState;
  }
};
const searchedPanelist = (
  searchedPanelistState = defaultState.searchedPanelist,
  action
) => {
  switch (action.type) {
    case types.SET_SEARCHED_PANELISTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchedPanelist;

    default:
      return searchedPanelistState;
  }
};

const searchedInvigilator = (
  searchedInvigilatorState = defaultState.searchedInvigilator,
  action
) => {
  switch (action.type) {
    case types.SET_SEARCHED_INVIGILATORS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchedInvigilator;

    default:
      return searchedInvigilatorState;
  }
};

const searchedRecruitmentMember = (
  searchedRecruitmentMemberState = defaultState.searchedRecruitmentMember,
  action
) => {
  switch (action.type) {
    case types.SET_SEARCHED_RECRUITMENT_MEMBER:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchedRecruitmentMember;

    default:
      return searchedRecruitmentMemberState;
  }
};

const panelists = (panelistsState = defaultState.panelists, action) => {
  switch (action.type) {
    case types.SET_PANELISTS:
      return action.data;

    case types.ADD_PANELIST: {
      const tmpPanelistsState = [...panelistsState];
      tmpPanelistsState.unshift(action.data);
      return tmpPanelistsState;
    }
    case types.DELETE_PANELIST:
      const tmpPanelistsState = [...panelistsState];
      const index = tmpPanelistsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpPanelistsState.splice(index, 1);

      return tmpPanelistsState;
    case types.CLEAN_STATE:
      return defaultState.panelists;

    default:
      return panelistsState;
  }
};

const panels = (panelsState = defaultState.panels, action) => {
  switch (action.type) {
    case types.SET_PANELS:
      return action.data;

    case types.ADD_PANEL: {
      const tmpPanelState = [...panelsState];
      tmpPanelState.unshift(action.data);
      return tmpPanelState;
    }

    case types.UPDATE_PANEL: {
      const tmpPanelState = [...panelsState];
      const index = tmpPanelState.findIndex(({ id }) => id === action.data.id);

      tmpPanelState[index] = action.data;

      return tmpPanelState;
    }
    case types.DELETE_PANEL:
      const tmpPanelState = [...panelsState];
      const index = tmpPanelState.findIndex(({ id }) => id === action.data.id);

      tmpPanelState.splice(index, 1);

      return tmpPanelState;
    case types.CLEAN_STATE:
      return defaultState.panels;

    default:
      return panelsState;
  }
};

const invigilators = (
  invigilatorsState = defaultState.invigilators,
  action
) => {
  switch (action.type) {
    case types.SET_INVIGILATORS:
      return action.data;

    case types.ADD_INVIGILATOR: {
      const tmpInvigilatorsState = [...invigilatorsState];
      tmpInvigilatorsState.unshift(action.data);
      return tmpInvigilatorsState;
    }
    case types.DELETE_INVIGILATOR:
      const tmpInvigilatorsState = [...invigilatorsState];
      const index = tmpInvigilatorsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpInvigilatorsState.splice(index, 1);

      return tmpInvigilatorsState;
    case types.CLEAN_STATE:
      return defaultState.invigilators;

    default:
      return invigilatorsState;
  }
};

const examCenters = (examCentersState = defaultState.examCenters, action) => {
  switch (action.type) {
    case types.SET_EXAM_CENTERS:
      return action.data;

    case types.ADD_EXAM_CENTER: {
      const tmpExamCentersState = [...examCentersState];
      tmpExamCentersState.unshift(action.data);
      return tmpExamCentersState;
    }
    case types.DELETE_EXAM_CENTER:
      const tmpExamCentersState = [...examCentersState];
      const index = tmpExamCentersState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpExamCentersState.splice(index, 1);

      return tmpExamCentersState;
    case types.CLEAN_STATE:
      return defaultState.examCenters;

    default:
      return examCentersState;
  }
};

const recruitmentMembers = (
  recruitmentMembersState = defaultState.recruitmentMembers,
  action
) => {
  switch (action.type) {
    case types.SET_RECRUITMENT_MEMBERS:
      return action.data;

    case types.ADD_RECRUITMENT_MEMBER: {
      const tmpRecruitmentMembersStateState = [...recruitmentMembersState];
      tmpRecruitmentMembersStateState.unshift(action.data);
      return tmpRecruitmentMembersStateState;
    }
    case types.DELETE_RECRUITMENT_MEMBER:
      const tmpRecruitmentMembersStateState = [...recruitmentMembersState];
      const index = tmpRecruitmentMembersStateState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRecruitmentMembersStateState.splice(index, 1);

      return tmpRecruitmentMembersStateState;
    case types.CLEAN_STATE:
      return defaultState.recruitmentMembers;

    default:
      return recruitmentMembersState;
  }
};
//panelOralExams
const panelOralExams = (
  panelOralExamsState = defaultState.panelOralExams,
  action
) => {
  switch (action.type) {
    case types.SET_PANEL_ORAL_EXAMS:
      return action.data;

    case types.UPDATE_PANEL_ORAL_EXAM: {
      const tmpPanelOralExamsState = [...panelOralExamsState];
      const index = tmpPanelOralExamsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpPanelOralExamsState[index] = { ...action.data };

      const tmp = [...tmpPanelOralExamsState];

      return tmp;
    }

    case types.CLEAN_STATE:
      return defaultState.panelOralExams;

    default:
      return panelOralExamsState;
  }
};

const invigilatorWrittenExams = (
  invigilatorWrittenExamsState = defaultState.invigilatorWrittenExams,
  action
) => {
  switch (action.type) {
    case types.SET_INVIGILATOR_WRITTEN_EXAMS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.invigilatorWrittenExams;

    default:
      return invigilatorWrittenExamsState;
  }
};

//examinerOralExamResults

const examinerOralExamResults = (
  examinerOralExamResultsState = defaultState.examinerOralExamResults,
  action
) => {
  switch (action.type) {
    case types.SET_EXAMINER_ORAL_EXAM_RESULTS:
      return action.data.sort((a, b) => b.totalScore - a.totalScore);

    case types.UPDATE_EXAMINER_ORAL_EXAM_RESULT: {
      const tmpExaminerOralExamResultsState = [...examinerOralExamResultsState];
      const index = tmpExaminerOralExamResultsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpExaminerOralExamResultsState[index] = { ...action.data };

      const tmp = [...tmpExaminerOralExamResultsState];

      return tmp.sort((a, b) => b.totalScore - a.totalScore);
    }

    case types.CLEAN_STATE:
      return defaultState.examinerOralExamResults;

    default:
      return examinerOralExamResultsState;
  }
};

const appealAdvertisements = (
  appealAdvertisementsState = defaultState.appealAdvertisements,
  action
) => {
  switch (action.type) {
    case types.SET_APPEAL_ADVERTISEMENTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.appealAdvertisements;

    default:
      return appealAdvertisementsState;
  }
};
const showAddOralMarkDialog = (
  showAddOralMarkDialogState = defaultState.showAddOralMarkDialog,
  action
) => {
  switch (action.type) {
    case types.SET_SHOW_ADD_ORAL_MARK_DIALOG:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.showAddOralMarkDialog;

    default:
      return showAddOralMarkDialogState;
  }
};
const showAddOralSchemaDialog = (
  showAddOralSchemaDialogState = defaultState.showAddOralSchemaDialog,
  action
) => {
  switch (action.type) {
    case types.SET_SHOW_ADD_ORAL_SCHEMA_DIALOG:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.showAddOralSchemaDialog;

    default:
      return showAddOralSchemaDialogState;
  }
};

const applicantOralExamSchema = (
  applicantOralExamSchemaState = defaultState.applicantOralExamSchema,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_ORAL_EXAM_SCHEMA:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicantOralExamSchema;

    default:
      return applicantOralExamSchemaState;
  }
};
const waitingListPositions = (
  waitingListPositionsState = defaultState.waitingListPositions,
  action
) => {
  switch (action.type) {
    case types.SET_WAITING_LIST_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.waitingListPositions;

    default:
      return waitingListPositionsState;
  }
};

const waitingListCandidates = (
  waitingListCandidatesState = defaultState.waitingListCandidates,
  action
) => {
  switch (action.type) {
    case types.SET_WAITINGLIST_CANDIDATES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.waitingListCandidates;

    default:
      return waitingListCandidatesState;
  }
};

const waitingListRequisitions = (
  waitingListRequisitionsState = defaultState.waitingListRequisitions,
  action
) => {
  switch (action.type) {
    case types.SET_WAITINGLIST_REQUISITIONS:
      return action.data;

    case types.ADD_WAITINGLIST_REQUISITION: {
      const tmpWaitingListRequisitionsState = [...waitingListRequisitionsState];
      tmpWaitingListRequisitionsState.unshift(action.data);
      return tmpWaitingListRequisitionsState;
    }

    case types.UPDATE_WAITINGLIST_REQUISITION: {
      const tmpWaitingListRequisitionsState = [...waitingListRequisitionsState];
      const index = tmpWaitingListRequisitionsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpWaitingListRequisitionsState[index] = { ...action.data };

      return tmpWaitingListRequisitionsState;
    }

    case types.DELETE_WAITINGLIST_REQUISITION: {
      const tmpWaitingListRequisitionsState = [...waitingListRequisitionsState];

      const index = tmpWaitingListRequisitionsState.findIndex(
        ({ id }) => id === action.id
      );

      if (index >= 0) tmpWaitingListRequisitionsState.splice(index, 1);

      return tmpWaitingListRequisitionsState;
    }

    case types.CLEAN_STATE:
      return defaultState.waitingListRequisitions;

    default:
      return waitingListRequisitionsState;
  }
};
//waitingListJobOffers
const waitingListJobOffers = (
  waitingListJobOffersState = defaultState.waitingListJobOffers,
  action
) => {
  switch (action.type) {
    case types.SET_WAITINGLIST_JOB_OFFERS:
      return action.data;

    case types.UPDATE_WAITINGLIST_JOB_OFFER: {
      const tmpWaitingListState = [...waitingListJobOffersState];
      const index = tmpWaitingListState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpWaitingListState[index] = { ...action.data };

      return tmpWaitingListState;
    }
    case types.CLEAN_STATE:
      return defaultState.waitingListJobOffers;

    default:
      return waitingListJobOffersState;
  }
};

const waitingListOffers = (
  waitingListOffersState = defaultState.waitingListOffers,
  action
) => {
  switch (action.type) {
    case types.SET_WAITINGLIST_OFFERS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.waitingListOffers;

    default:
      return waitingListOffersState;
  }
};
//waitingListPositionByEntitySector
const waitingListPositionByEntitySector = (
  waitingListPositionByEntitySectorState = defaultState.waitingListPositionByEntitySector,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTORS_WAITINGLIST_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.waitingListPositionByEntitySector;

    default:
      return waitingListPositionByEntitySectorState;
  }
};
//inprogressAppeals
const inprogressAppeals = (
  inprogressAppealsState = defaultState.inprogressAppeals,
  action
) => {
  switch (action.type) {
    case types.SET_INPROGRESS_APPEALS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.inprogressAppeals;

    default:
      return inprogressAppealsState;
  }
};
//archivedAppeals
const archivedAppeals = (
  archivedAppealsState = defaultState.archivedAppeals,
  action
) => {
  switch (action.type) {
    case types.SET_ARCHIVED_APPEALS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.archivedAppeals;

    default:
      return archivedAppealsState;
  }
};
const allOverdueAppeals = (
  allOverdueAppealsState = defaultState.allOverdueAppeals,
  action
) => {
  switch (action.type) {
    case types.SET_ALL_OVERDUE_APPEALS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.allOverdueAppeals;

    default:
      return allOverdueAppealsState;
  }
};

const activeCenters = (
  activeCentersState = defaultState.activeCenters,
  action
) => {
  switch (action.type) {
    case types.SET_ACTIVE_CENTERS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.activeCenters;

    default:
      return activeCentersState;
  }
};

const examCenterNoneSelected = (
  examCenterNoneSelectedState = defaultState.examCenterNoneSelected,
  action
) => {
  switch (action.type) {
    case types.SET_EXAM_CENTER_NONE_SELECTED:
      return action.data;

    case types.DELETE_EXAM_CENTER_NONE_SELECTED:
      const tmpExamCenterNoneSelectedState = [...examCenterNoneSelectedState];
      const index = tmpExamCenterNoneSelectedState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpExamCenterNoneSelectedState.splice(index, 1);

      return tmpExamCenterNoneSelectedState;

    case types.CLEAN_STATE:
      return defaultState.examCenterNoneSelected;

    default:
      return examCenterNoneSelectedState;
  }
};

//applicantOralExamResult

export default {
  analytics,
  jobPublications,
  applicantJobPublications,
  applicants,
  applicantExamMarking,
  applicantOralExamMarking,
  applicantOverallResults,
  appeals,
  questions,
  tests,
  tempQuestions,
  requisitions,
  requisitionStatus,
  requisitionCertificates,
  requisitionCompetencies,
  requisitionQualifications,
  requisitionExperiences,
  advertisements,
  advertisementApplications,
  applicationsStatus,
  advertisementStatus,
  userProfile,
  convertedFileToBase64,
  writtenExams,
  oralExams,
  questionTypes,
  hasExamQuestion,
  examQuestions,
  applicantExamSessions,
  applicantSessionLogs,
  haslockedOver3Times,
  applicantsNotAttended,
  applicantsAttended,
  sessionAdditionalMinutes,
  applicantWrittenExamResults,
  applicantWrittenExamAnswers,
  applicantWrittenExamResult,
  applicantOralExamResults,
  applicantOralExamResult,
  applicantOralExamAnswers,
  searchedPanelist,
  panelists,
  panelOralExams,
  examinerOralExamResults,
  searchedInvigilator,
  invigilators,
  invigilatorWrittenExams,
  recruitmentMembers,
  searchedRecruitmentMember,
  appealAdvertisements,
  unConfirmedAppeals,
  showAddOralMarkDialog,
  showAddOralSchemaDialog,
  applicantOralExamSchema,
  waitingListPositions,
  waitingListRequisitions,
  waitingListJobOffers,
  waitingListCandidates,
  waitingListOffers,
  waitingListPositionByEntitySector,
  inprogressAppeals,
  archivedAppeals,
  allOverdueAppeals,
  activeCenters,
  examCenters,
  examCenterNoneSelected,
  panels,
};
