import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

const HRMessage = ({ user, hrMessage }) => {
  return (
    <>
      <div
        className="alert alert-warning  py-0 mb-0 weak-password-div  text-center text-uppercase"
        style={{
          borderTopColor: "#ff5722",
          borderRadius: "0px",
          height: "28px",
        }}
      >
        <marquee behavior="" direction="">
          <strong style={{ color: "red" }}>
            Beware Dear RBM users, this message serves as an alert and reminder
            that penalties will be applied to any delay in reporting and
            evaluation from quarter three. We thank you!
          </strong>
        </marquee>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, hrMessage }) => {
  return {
    user,
    hrMessage,
  };
};

export default connect(mapStateToProps)(HRMessage);
