import { defaultState } from "./state";
import admin from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...admin };

const roles = (rolesState = defaultState.roles, action) => {
  switch (action.type) {
    case types.SET_ROLES:
      return action.data;

    case types.ADD_ROLE:
      const tmpRolesState = [...rolesState];
      tmpRolesState.unshift(action.data);
      return tmpRolesState;

    case types.DELETE_ROLE: {
      const tmpRolesState = [...rolesState];
      const index = tmpRolesState.findIndex(({ id }) => id === action.data.id);

      tmpRolesState.splice(index, 1);

      return tmpRolesState;
    }

    case types.UPDATE_ROLE: {
      const tmpRolesState = [...rolesState];
      const index = tmpRolesState.findIndex(({ id }) => id === action.data.id);
      tmpRolesState[index] = action.data;

      return tmpRolesState;
    }

    case types.CLEAN_STATE:
      return defaultState.roles;

    default:
      return rolesState;
  }
};

const permissions = (permissionsState = defaultState.permissions, action) => {
  switch (action.type) {
    case types.SET_PERMISSIONS:
      return action.data;

    case types.ADD_PERMISSION:
      const tmpPermissionsState = [...permissionsState];
      tmpPermissionsState.unshift(action.data);
      return tmpPermissionsState;

    case types.DELETE_PERMISSION: {
      const tmpPermissionsState = [...permissionsState];
      const index = tmpPermissionsState.findIndex(
        ({ name }) => name === action.data.name
      );

      tmpPermissionsState.splice(index, 1);

      return tmpPermissionsState;
    }

    case types.CLEAN_STATE:
      return defaultState.permissions;

    default:
      return permissionsState;
  }
};

const modules = (modulesState = defaultState.modules, action) => {
  switch (action.type) {
    case types.SET_MODULES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.modules;

    default:
      return modulesState;
  }
};

const recruitment = (state = defaultState.recruitment, action) => {
  let result = [];
  let index = -1;
  switch (action.type) {
    case types.LOAD_ALL_ACTIVE_ADVERTISEMENT:
      return { ...state, oversight: action.data };
    case types.LOAD_ADVERTISEMENT_APPLICANTS:
      result = [...state.applicants];
      index = state.applicants.findIndex(
        (item) => item.advertisementId === action.data.advertisementId
      );
      if (index >= 0) {
        result[index].data = action.data;
      } else {
        result = [...state.applicants, action.data];
      }
      return { ...state, applicants: result };
    case types.SET_ADVERTISEMENT_YEARS:
      return { ...state, advertisementYears: action.data };
    case types.SET_ADMIN_APPEALS:
      return { ...state, appeals: [...state.appeals, ...action.data] };
    case types.SET_SCHEDULED_EXAMS:
      return { ...state, scheduledExams: action.data };
    case types.SET_RECRUITMENT_DASHBOARD:
      return { ...state, recruitmentStatistic: action.data };
    case types.LOGOUT:
      return defaultState.recruitment;

    default:
      return state;
  }
};

const disability = (state = defaultState.disability, action) => {
  let index = -1,
    result = null;
  switch (action.type) {
    case types.LOAD_DISABILITIES:
      return { ...state, disabilities: action.data };
    case types.UPDATE_DISABILITY:
      result = null;
      if (state.disabilities) {
        result = [...state.disabilities];
        index = state.disabilities.findIndex(
          (item) => item.id === action.data.id
        );
        if (index >= 0) {
          result[index].name = action.data.name;
        }
      }
      index = -1;
      return { ...state, disabilities: result };
    case types.LOAD_DISABILITY_LEVELS:
      return { ...state, disabilityLevels: action.data };
    case types.UPDATE_DISABILITY_LEVEL:
      if (state.disabilityLevels) {
        result = [...state.disabilityLevels];
        index = state.disabilityLevels.findIndex(
          (item) => item.id === action.data.id
        );
        if (index >= 0) {
          result[index].name = action.data.name;
        }
      }
      index = -1;
      return { ...state, disabilityLevels: result };

    case types.CLEAN_STATE:
    case types.LOGOUT:
      return defaultState.disability;

    default:
      return state;
  }
};

const certificate = (state = defaultState.certificate, action) => {
  let index = -1,
    result = null;
  switch (action.type) {
    case types.LOAD_CERTIFICATE:
      return { ...state, certificates: action.data };
    case types.UPDATE_CERTIFICATE:
      result = null;
      if (state.certificates) {
        result = [...state.certificates];
        index = state.certificates.findIndex(
          (item) => item.id === action.data.id
        );
        if (index >= 0) {
          result[index] = action.data;
        }
      }
      index = -1;
      return { ...state, certificates: result };
    case types.LOAD_CERTIFICATE_FIELD:
      return { ...state, certificateFields: action.data };
    case types.UPDATE_CERTIFICATE_FIELD:
      if (state.certificateFields) {
        result = [...state.certificateFields];
        index = state.certificateFields.findIndex(
          (item) => item.id === action.data.id
        );
        if (index >= 0) {
          result[index].name = action.data.name;
        }
      }
      index = -1;
      return { ...state, certificateFields: result };

    case types.CLEAN_STATE:
    case types.LOGOUT:
      return defaultState.certificate;

    default:
      return state;
  }
};

const competencies = (state = defaultState.competency, action) => {
  let index = -1,
    result = null;
  switch (action.type) {
    case types.LOAD_COMPETENCY:
      return action.data;
    case types.UPDATE_COMPETENCY:
      if (state) {
        result = [...state];
        index = state.findIndex((item) => item.id === action.data.id);
        if (index >= 0) {
          result[index].name = action.data.name;
        }
      }
      index = -1;
      return result;

    case types.CLEAN_STATE:
    case types.LOGOUT:
      return defaultState.competency;

    default:
      return state;
  }
};

const degrees = (state = defaultState.degrees, action) => {
  let index = -1,
    result = null;
  switch (action.type) {
    case types.LOAD_DEGREES:
      return { ...state, degrees: action.data };
    case types.UPDATE_DEGREE:
      result = null;
      if (state.degrees) {
        result = [...state.degrees];
        index = state.degrees.findIndex((item) => item.id === action.data.id);
        if (index >= 0) {
          result[index].name = action.data.name;
        }
      }
      index = -1;
      return { ...state, degrees: result };
    case types.LOAD_QUALIFICATION:
      return { ...state, qualifications: action.data };
    case types.UPDATE_QUALIFICATION:
      result = null;
      if (state.qualifications) {
        result = [...state.qualifications];
        index = state.qualifications.findIndex(
          (item) => item.id === action.data.id
        );
        if (index >= 0) {
          result[index] = action.data;
        }
      }
      index = -1;
      return { ...state, qualifications: result };

    case types.CLEAN_STATE:
    case types.LOGOUT:
      return defaultState.degrees;

    default:
      return state;
  }
};

const adminPayroll = (state = defaultState.adminPayroll, action) => {
  let result = [],
    copy;
  let index = -1;
  switch (action.type) {
    case types.SET_ADMIN_COUNTRY:
      return { ...state, adminCountries: action.data };
    // ******* ALLOWANCE
    case types.SET_ADMIN_ALLOWANCE:
      return { ...state, adminAllowances: action.data };
    case types.UPDATE_ADMIN_ALLOWANCE: {
      result = [...state.adminAllowances];
      index = state.adminAllowances.findIndex(
        (item) => item.id === action.data.allowanceId
      );
      if (index >= 0) {
        copy = result[index];
        result[index] = {
          ...copy,
          id: action.data.allowanceId,
          name: action.data.name,
          calculationMethodId: action.data.selectedCalculationMethod.id,
          basePayrollComponentId: action.data.selectedBasePayroll
            ? action.data.selectedBasePayroll.id
            : null,
          allowanceTypeId: action.data.selectedAllowanceType.id,
          payTPR: action.data.payTPR,
          payCSR: action.data.payCSR,
          payMaternity: action.data.payMaternity,
          payMedical: action.data.payMedical,
          amount: action.data.amount,
          isForEmployee: action.data.isForEmployee,
          calculationMethod: action.data.selectedCalculationMethod,
          basePayrollComponent: action.data.selectedBasePayroll,
          allowanceType: action.data.selectedAllowanceType,
        };
      }
      return { ...state, adminAllowances: result };
    }
    case types.ACTIVATE_ADMIN_ALLOWANCE: {
      result = [...state.adminAllowances];
      index = state.adminAllowances.findIndex(
        (item) => item.id === action.data
      );
      if (index >= 0)
        result[index].isActive = !state.adminAllowances[index].isActive;

      return { ...state, adminAllowances: result };
    }
    // ******* CREDITOR
    case types.SET_ADMIN_CREDITOR:
      return { ...state, adminCreditor: action.data };
    case types.UPDATE_ADMIN_CREDITOR: {
      result = [...state.adminCreditor];
      index = state.adminCreditor.findIndex(
        (item) => item.id === action.data.creditorId
      );
      if (index >= 0) {
        copy = result[index];
        result[index] = {
          ...copy,
          id: action.data.creditorId,
          name: action.data.name,
          calculationMethodId: action.data.selectedCalculationMethod.id,
          basePayrollComponentId: action.data.selectedBasePayroll
            ? action.data.selectedBasePayroll.id
            : null,
          calculationMethod: action.data.selectedCalculationMethod,
          basePayrollComponent: action.data.selectedBasePayroll,
          rate: action.data.rate,
          bankId: action.data.selectedBank.id,
          acountNumber: action.data.accountNumber,
          bank: action.data.selectedBank,
        };
      }
      return { ...state, adminCreditor: result };
    }
    case types.ACTIVATE_ADMIN_CREDITOR: {
      result = [...state.adminCreditor];
      index = state.adminCreditor.findIndex((item) => item.id === action.data);
      if (index >= 0)
        result[index].isActive = !state.adminCreditor[index].isActive;

      return { ...state, adminCreditor: result };
    }
    // ******* BANK
    case types.SET_ADMIN_BANK:
      return { ...state, adminBanks: action.data };
    case types.ADD_ADMIN_BANK:
      return {
        ...state,
        adminBanks: [
          ...state.adminBanks,
          {
            id: action.data.bankId,
            name: action.data.name,
            acronym: action.data.acronym,
            countryId: action.data.country ? action.data.country.id : null,
            swiftCode: action.data.swiftCode,
            country: action.data.country || null,
            isActive: true,
          },
        ],
      };
    case types.UPDATE_ADMIN_BANK: {
      result = [...state.adminBanks];
      index = state.adminBanks.findIndex(
        (item) => item.id === action.data.bankId
      );
      if (index >= 0) {
        copy = result[index];
        result[index] = {
          id: action.data.bankId,
          name: action.data.name,
          acronym: action.data.acronym,
          countryId: action.data.country ? action.data.country.id : null,
          swiftCode: action.data.swiftCode,
          country: action.data.country || null,
        };
      }
      return { ...state, adminBanks: result };
    }
    case types.ACTIVATE_ADMIN_BANK: {
      result = [...state.adminBanks];
      index = state.adminBanks.findIndex((item) => item.id === action.data);
      if (index >= 0)
        result[index].isActive = !state.adminBanks[index].isActive;

      return { ...state, adminBanks: result };
    }
    // ******* COST CENTER
    case types.SET_ADMIN_COST_CENTER:
      return { ...state, adminCostCenter: action.data };
    case types.ADD_ADMIN_COST_CENTER:
      return {
        ...state,
        adminCostCenter: [
          ...state.adminCostCenter,
          {
            id: action.data.ifmisCode,
            name: action.data.name,
            tinNumber: action.data.tinNumber,
            ifmisCode: action.data.ifmisCode,
            countryId: action.data.country ? action.data.country.id : null,
            country: action.data.country || null,
            isActive: true,
          },
        ],
      };
    case types.UPDATE_ADMIN_COST_CENTER: {
      result = [...state.adminCostCenter];
      index = state.adminCostCenter.findIndex(
        (item) => item.id === action.data.costCenterId
      );
      if (index >= 0) {
        copy = result[index];
        result[index] = {
          ...copy,
          name: action.data.name,
          tinNumber: action.data.tinNumber,
          ifmisCode: action.data.ifmisCode,
          countryId: action.data.country ? action.data.country.id : null,
          country: action.data.country || null,
        };
      }
      return { ...state, adminCostCenter: result };
    }
    case types.ACTIVATE_ADMIN_COST_CENTER: {
      result = [...state.adminCostCenter];
      index = state.adminCostCenter.findIndex(
        (item) => item.id === action.data
      );
      if (index >= 0)
        result[index].isActive = !state.adminCostCenter[index].isActive;

      return { ...state, adminCostCenter: result };
    }
    // ******* PAYROLL APPROVER
    case types.SET_ADMIN_PAYROLL_APPROVER:
      return { ...state, adminPayrollApprover: action.data };
    case types.ADD_ADMIN_PAYROLL_APPROVER:
      return {
        ...state,
        adminPayrollApprover: [
          ...state.adminPayrollApprover,
          {
            id: action.data.id,
            costCenterId: action.data.costCenter?.id,
            costCenter: action.data.costCenter,
            userId: action.data.user?.id,
            user: action.data.user,
            approvalLevel: action.data.approvalLevel,
            isActive: true,
          },
        ],
      };
    case types.UPDATE_ADMIN_PAYROLL_APPROVER: {
      result = [...state.adminPayrollApprover];
      index = state.adminPayrollApprover.findIndex(
        (item) => item.id === action.data.oldId
      );
      if (index >= 0) {
        result[index] = {
          id: action.data.id,
          costCenterId: action.data.costCenter?.id,
          costCenter: action.data.costCenter,
          userId: action.data.user?.id,
          user: action.data.user,
          approvalLevel: action.data.approvalLevel,
          isActive: true,
        };
      }
      return { ...state, adminPayrollApprover: result };
    }
    case types.REMOVE_ADMIN_PAYROLL_APPROVER: {
      result = state.adminPayrollApprover.filter(
        (item) => item.id !== action.data
      );
      return { ...state, adminPayrollApprover: result };
    }
    case types.SET_PAYROLL_OVERSIGHT:
      return { ...state, adminPayrollOversight: action.data };
    case types.SET_PAYROLL_PERIODS:
      return { ...state, adminPayrollPeriods: action.data };
    // ******* SOURCE OF FUNDS
    case types.SET_ADMIN_SOFUNDS:
      return { ...state, adminPayrollSOFunds: action.data };
    case types.ADD_ADMIN_PAYROLL_SOFUNDS:
      return {
        ...state,
        adminPayrollSOFunds: [
          ...state.adminPayrollSOFunds,
          {
            id: action.data.id,
            name: action.data.name,
            payTPR: action.data.payTPR,
            payCSR: action.data.payCSR,
            payMaternity: action.data.payMaternity,
            payMedical: action.data.payMedical,
            payCBHI: action.data.payCBHI,
            isActive: true,
          },
        ],
      };
    case types.UPDATE_ADMIN_SOFUNDS: {
      result = [...state.adminPayrollSOFunds];
      index = state.adminPayrollSOFunds.findIndex(
        (item) => item.id === action.data.soFundId
      );
      if (index >= 0) {
        copy = result[index];
        result[index] = {
          ...copy,
          name: action.data.name,
          payTPR: action.data.payTPR,
          payCSR: action.data.payCSR,
          payMaternity: action.data.payMaternity,
          payMedical: action.data.payMedical,
          payCBHI: action.data.payCBHI,
        };
      }
      return { ...state, adminPayrollSOFunds: result };
    }
    case types.ACTIVATE_ADMIN_SOFUNDS: {
      result = [...state.adminPayrollSOFunds];
      index = state.adminPayrollSOFunds.findIndex(
        (item) => item.id === action.data
      );
      if (index >= 0)
        result[index].isActive = !state.adminPayrollSOFunds[index].isActive;

      return { ...state, adminPayrollSOFunds: result };
    }
    case types.LOGOUT:
      return defaultState.adminPayroll;
    default:
      return state;
  }
};

const adminStructure = (state = defaultState.adminDashboard, action) => {
  switch (action.type) {
    case types.SET_STRUCTURE_DASHBOARD:
      return { ...state, structureStatistic: action.data };
    case types.SET_STRUCTURE_DASHBOARD_POSITIONS:
      return {
        ...state,
        positions: state.positions
          ? [...state.positions, action.data]
          : [action.data],
      };
    case types.SET_STRUCTURE_DASHBOARD_EMPLOYEES:
      return {
        ...state,
        employees: state.employees
          ? [...state.employees, action.data]
          : [action.data],
      };
    case types.SET_STRUCTURE_DASHBOARD_ENTITIES:
      return {
        ...state,
        entities: state.entities
          ? [...state.entities, action.data]
          : [action.data],
      };

    case types.LOGOUT:
      return defaultState.adminPayroll;
    default:
      return state;
  }
};

const adminExit = (state = defaultState.adminExit, action) => {
  let result = [],
    copy;
  let index = -1;
  switch (action.type) {
    case types.SET_ADMIN_EXIT_TYPE:
      return { ...state, exitTypes: action.data };
    case types.ADD_ADMIN_EXIT_TYPE:
      return { ...state, exitTypes: [state.exitTypes, ...action.data] };
    case types.UPDATE_EXIT_TYPE: {
      index = state.exitTypes.findIndex(
        (item) => item.id === action.data.exitTypeId
      );
      copy = [...state.exitTypes];
      if (index >= 0)
        copy[index] = { id: action.data.exitTypeId, ...action.data.exitType };
      return { ...state, exitTypes: copy };
    }
    case types.SET_ADMIN_EXIT_YEARS_MONTHS:
      return { ...state, exitMonthYears: action.data };

    case types.LOGOUT:
      return defaultState.adminExit;
    default:
      return state;
  }
};

export default {
  roles,
  permissions,
  modules,
  recruitment,
  disability,
  certificate,
  competencies,
  degrees,
  adminPayroll,
  adminStructure,
  adminExit,
};
