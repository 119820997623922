import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import AppBar from "@mui/material/AppBar";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Chip,
} from "@mui/material";

import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";

import { psyLockCandidate } from "../../../store/psychometric/actions";

// import { getQuestionTypes } from "../../../store/recruitment/actions";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";

// import ExamSettingDialog from "./ExamSettingDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import axios from "axios";
// import UploadQuestionsDialog from "../components/UploadQuestionsDialog";
import AddOrUpdateQuestionDialog from "../components/AddOrUpdateQuestionDialog";
import AlertDialog from "../../common/components/AlertDialog";

import { Link, useLocation } from "react-router-dom";
import { showError } from "../../toastify";

import defaultPofile from "../../assets/default-profile.jpg";
import LiveCandidateCard from "../components/LiveCandidateCard";
import LiveCandidateLogs from "../components/LiveCandidateLogs";
import LiveCandidateImages from "../components/LiveCandidateImages";

const LiveCandidatesPage = (props) => {
  const { user, loading, psyLockCandidate } = props;

  const { hasPermissions } = useRequiredAuth();

  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [totalLiveCandidates, setTotalLiveCandidates] = useState(0);

  const [confirmLockCandidate, setConfirmLockCandidate] = useState(false);

  const [comment, setComment] = useState("");

  return (
    <>
      <div className=" mt-n3">
        <AppBar
          position="static"
          elevation={0}
          style={{ overflow: "hidden" }}
          className="app-bar pr-2"
        >
          <Toolbar className=" bg-light ">
            <Typography
              variant="span"
              noWrap
              component="div"
              className=" text-uppercase d-inline-flex align-items-center"
            >
              <strong>
                <span className="text-primary">({totalLiveCandidates})</span>{" "}
                Live candidate
                {totalLiveCandidates > 1 ? "s" : ""}
              </strong>
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>
        <div className="container-fluid px-0">
          <div className="row no-gutters mx-0">
            <div className="col-md-3">
              <div
                className="px-2 py-2  border bg-light rounded"
                style={{
                  height: "67vh",
                  overflow: "auto",
                }}
              >
                <LiveCandidateCard
                  selectedCandidate={selectedCandidate}
                  setSelectedCandidate={setSelectedCandidate}
                  totalLiveCandidates={totalLiveCandidates}
                  setTotalLiveCandidates={setTotalLiveCandidates}
                />
              </div>
            </div>
            <div className="col-md-9 ">
              <CardContent className="p-0 pl-2">
                <div className="row mx-0  justify-content-center">
                  {!selectedCandidate && (
                    <div
                      className="text-center col-12 d-flex justify-content-center flex-column"
                      style={{
                        height: "50vh",
                      }}
                    >
                      <p className="lead">Select candidate to view more</p>
                      <span
                        className="material-icons"
                        style={{ fontSize: "64px" }}
                      >
                        manage_search
                      </span>
                    </div>
                  )}

                  {!!selectedCandidate && (
                    <div
                      className="col-7 bg-white border rounded"
                      style={{
                        height: "67vh",
                        overflow: "auto",
                      }}
                    >
                      <LiveCandidateLogs
                        psychometricTestSessionId={selectedCandidate.id}
                      />
                    </div>
                  )}

                  {!!selectedCandidate && (
                    <div className="col-5 bg-light pt-3 d-flex align-items-center flex-column text-center">
                      <Avatar
                        alt="Remy Sharp"
                        src={defaultPofile}
                        sx={{ width: 56, height: 56 }}
                      />

                      <Typography className="font-weight-bold btn btn-link">
                        {selectedCandidate.no}: {selectedCandidate.lastName}
                        {selectedCandidate.firstName}
                      </Typography>
                      <div> {selectedCandidate.positionName}</div>
                      <div>{selectedCandidate.entityName}</div>
                      <small className="badge badge-pill d-block mb-2">
                        {selectedCandidate.sectorName}
                      </small>
                      <div>
                        {selectedCandidate.isSessionLocked ? (
                          <Chip label="Locked" color="error" />
                        ) : (
                          <Chip label="Active" color="success" />
                        )}
                      </div>

                      <div>
                        <Tooltip
                          title={`${
                            selectedCandidate.isSessionLocked
                              ? "Unlock candidate"
                              : "Lock candidate"
                          }`}
                        >
                          <IconButton
                            onClick={() => {
                              setComment("");
                              setConfirmLockCandidate(true);
                            }}
                            color="inherit"
                          >
                            {selectedCandidate.isSessionLocked ? (
                              <span className="material-icons">lock_open</span>
                            ) : (
                              <span className="material-icons">lock</span>
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
            </div>
          </div>
        </div>
      </div>

      {confirmLockCandidate && (
        <ConfirmationDialog
          disabled={!comment}
          btnMessage={selectedCandidate.isSessionLocked ? "unlock" : "lock"}
          confirmationDialog={setConfirmLockCandidate}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong
                  className={`text-${
                    selectedCandidate.isSessionLocked ? "success" : "danger"
                  }`}
                >
                  {" "}
                  {selectedCandidate.isSessionLocked ? "unlock" : "lock"}
                </strong>{" "}
                this candidate?
              </p>

              <TextField
                autoFocus
                required
                id="comment"
                label="Reason"
                fullWidth
                multiline
                rows={3}
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </>
          }
          setConfirmationDialog={setConfirmLockCandidate}
          onYes={() => {
            psyLockCandidate(
              {
                sessionId: selectedCandidate.id,
                isSessionLocked: !selectedCandidate.isSessionLocked,
                comment,
              },
              () => setConfirmLockCandidate(false)
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};
export default connect(mapStateToProps, { psyLockCandidate })(
  LiveCandidatesPage
);
