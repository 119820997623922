import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  loadEntityAdvertisements,
  loadYearlyInstitutions,
} from "../../../store/admin/actions";
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
// import ShowApplicationList from "../../recruitment/components/ShowApplicationList";
import ExposureRoundedIcon from "@mui/icons-material/ExposureRounded";
// import ExamSettingDialog from "../../recruitment/pages/ExamSettingDialog";
import AdminAppealDialog from "./AdminAppealDialog";
import BalanceRoundedIcon from "@mui/icons-material/BalanceRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import GroupIcon from "@mui/icons-material/Group";
import ApplicationDialog from "../../recruitment/pages/ApplicationDialog";

const RecruitmentOversight = (props) => {
  const {
    loading,
    oversight,
    loadEntityAdvertisements,
    loadYearlyInstitutions,
    selectedYear,
  } = props;
  const theme = useTheme();
  const [loadingOversight, setLoadingOversight] = useState(true);
  const [loadingAdvertisements, setLoadingAdvertisements] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [searchAdvert, setSearchAdvert] = useState("");
  const [showApplications, setShowApplications] = useState(null);
  const [showExamSettings, setShowExamSettings] = useState(null);
  const [showAppeals, setShowAppeals] = useState(null);
  const [showAdvertisements, setShowAdvertisements] = useState(null);
  const [advertisements, setAdvertisements] = useState([]);
  const [filteredAdvertisements, setFilteredAdvertisements] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);

  const onSearch = (term) => {
    setSearchInput(term);
  };

  const onSearchAdvert = (term) => {
    setSearchAdvert(term);
  };

  const onSelectAdvertisement = (advertisement) => {
    setShowAdvertisements(advertisement);
    loadEntityAdvertisements(advertisement.id, (status, data) => {
      setLoadingAdvertisements(status);
      setAdvertisements(data);
    });
  };

  useEffect(() => {
    if (selectedYear !== null) {
      if (oversight === null || oversight.length === 0) {
        loadYearlyInstitutions(
          selectedYear && selectedYear.adYear !== "ALL"
            ? selectedYear.adYear
            : 0,
          setLoadingOversight
        );
      } else if (
        selectedYear &&
        selectedYear.adYear !== "ALL" &&
        !loadingOversight
      ) {
        loadYearlyInstitutions(selectedYear.adYear, setLoadingOversight);
      } else {
        setLoadingOversight(false);
      }
    }
    return () => {
      setLoadingOversight(true);
      setSearchInput("");
      setShowApplications(null);
    };
  }, [selectedYear]);

  useEffect(() => {
    if (searchAdvert.length > 0) {
      setFilteredAdvertisements(
        advertisements.filter(
          ({ positionName, levelName, indexValue, unitName, adStatusName }) =>
            (
              (positionName || "") +
              (levelName || "") +
              (indexValue || "") +
              (unitName || "") +
              (adStatusName || "")
            )
              .toLowerCase()
              .includes(searchAdvert.toLowerCase())
        )
      );
    } else {
      setFilteredAdvertisements(advertisements);
    }
  }, [searchAdvert, advertisements]);

  useEffect(() => {
    if (searchInput.length > 0 && oversight) {
      setFilteredEntities(
        oversight.filter(
          ({
            entityName,
            entityAcronym,
            positionName,
            levelName,
            indexValue,
            unitName,
            adStatusName,
          }) =>
            (
              (entityName || "") +
              (entityAcronym || "") +
              (positionName || "") +
              (levelName || "") +
              (indexValue || "") +
              (unitName || "") +
              (adStatusName || "")
            )
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        )
      );
    } else {
      setFilteredEntities(oversight || []);
    }
  }, [searchInput, oversight]);

  return (
    <div>
      <div
        style={{
          marginLeft: "1em",
          marginRight: "1em",
          backgroundColor: "#fff",
          borderRadius: "0.5rem",
          boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
          MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
          height: "65vh",
          overflowX: "auto",
        }}
      >
        <table className="table table-sm table-hover table-striped fixTableHead">
          <thead>
            <tr>
              <th className="py-2">No</th>
              <th className="py-2">
                <Box
                  sx={{ display: { xs: "flex", md: "flex" } }}
                  className="align-items-center"
                >
                  <span className="mx-2">Institutions </span>
                  <SearchBox
                    onSearch={onSearch}
                    placeholder="Search…"
                    disabled={
                      loading ||
                      loadingOversight ||
                      oversight === null ||
                      oversight.length <= 1
                    }
                  />
                </Box>
              </th>
              <th className="py-2 text-center">New</th>
              <th className="py-2 text-center">Shortlist Published</th>
              <th className="py-2 text-center">Written Exam Published</th>
              <th className="py-2 text-center">Oral Exam Published</th>
              <th className="py-2 text-center">Re-advertised</th>
              <th className="py-2 text-center">NPSC-Readvert requested</th>
              <th className="py-2 text-center">NPSC-Readvert Approved</th>
              <th className="py-2 text-center">NPSC-Readvert Rejected</th>
              <th className="py-2 text-center">Canceled</th>
              <th className="py-2 text-center">Closed</th>
              <th className="py-2">Action</th>
            </tr>
          </thead>
          {loadingOversight || oversight === null ? (
            <TableSkeleton rows={13} />
          ) : (
            <tbody>
              {filteredEntities.length > 0 ? (
                filteredEntities.map((advert, esIndex) => (
                  <tr
                    style={{
                      background: esIndex % 2 === 0 ? "" : "#f6f7f7",
                      borderBottom: "2px dotted #007bff!important",
                    }}
                    className="cursor-pointer"
                    key={advert.id}
                    onClick={() => onSelectAdvertisement(advert)}
                  >
                    <td>{esIndex + 1}</td>
                    <td>
                      {advert.entityName}{" "}
                      {advert.entityAcronym && <>({advert.entityAcronym})</>}
                    </td>
                    <td align="center">{advert.new}</td>
                    <td align="center">{advert.shortlistPublished} </td>
                    <td align="center">{advert.writtenPublished}</td>
                    <td align="center">{advert.oralPublished}</td>
                    <td align="center">{advert.readvertised}</td>
                    <td align="center">{advert.readvertRequested}</td>
                    <td align="center">{advert.readvertApproved}</td>
                    <td align="center">{advert.readvertRejected}</td>
                    <td align="center">{advert.canceled}</td>
                    <td align="center">{advert.closed}</td>
                    <td className="text-right">
                      <button type="button" className="btn btn-success m-2">
                        <OpenInNewIcon />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={13} align="center">
                      No date found
                    </td>
                  </tr>
                </tbody>
              )}
            </tbody>
          )}
        </table>
      </div>

      {showApplications && (
        <ApplicationDialog
          showApplication={true}
          setShowApplication={() => setShowApplications(null)}
          selectedAdvertisement={showApplications}
          isForOverSite
        />
      )}

      {/* {showExamSettings && (
        <ExamSettingDialog
          showExamSettings={showExamSettings}
          setShowExamSettings={(e) => !e && setShowExamSettings(null)}
          selectedAdvertisement={showExamSettings}
          userType="ADMIN"
        />
      )} */}

      {showAppeals && (
        <AdminAppealDialog
          selectedAdvertisement={showAppeals}
          closeAppealDialog={() => setShowAppeals(null)}
        />
      )}

      {showAdvertisements && (
        <Dialog
          onClose={() => {
            setShowAdvertisements(null);
            setAdvertisements([]);
          }}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle className="text-primary">
            <Typography variant="h6" noWrap component="div">
              <span className="text-dark">{showAdvertisements.entityName}</span>
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => {
                setShowAdvertisements(null);
                setAdvertisements([]);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Box sx={{ display: "flex", width: "100%" }}>
              <div
                className="w-100"
                style={{
                  height: "500px",
                }}
              >
                <div
                  className="mt-3"
                  style={{
                    padding: theme.spacing(0.4),
                    height: "auto",
                    maxHeight: "auto",
                    backgroundColor: "#fff",
                    borderRadius: "0.5rem",
                    overflow: "visible",
                    boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                    MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                  }}
                >
                  <CardContent
                    style={{
                      height: "auto",
                      minHeight: "auto",
                      overflow: "hidden",
                    }}
                  >
                    <table className="table table-sm table-hover table-striped fixTableHead">
                      <thead>
                        <tr>
                          <th className="py-2">No</th>
                          <th className="py-2">
                            <Box
                              sx={{ display: { xs: "flex", md: "flex" } }}
                              className="align-items-center"
                            >
                              <span className="badge badge-secondary">
                                {filteredAdvertisements.length}
                              </span>{" "}
                              <span className="mx-2">Position</span>
                              <SearchBox
                                onSearch={onSearchAdvert}
                                placeholder="Search…"
                                disabled={
                                  loading ||
                                  loadEntityAdvertisements ||
                                  advertisements.length <= 1
                                }
                              />
                            </Box>
                          </th>
                          <th className="py-2 text-center">Level</th>
                          <th className="py-2 text-center">IV</th>
                          <th className="py-2">Hiring Mode</th>
                          <th className="py-2 text-center">Posts</th>
                          <th className="py-2">Is Internal</th>
                          <th className="py-2">Exams</th>
                          <th className="py-2">Date of Publish</th>
                          <th className="py-2">Status</th>
                          <th className="py-2">Action</th>
                        </tr>
                      </thead>
                      {loadingAdvertisements ? (
                        <TableSkeleton rows={10} />
                      ) : (
                        <tbody>
                          {filteredAdvertisements.length > 0 ? (
                            filteredAdvertisements.map((advert, esIndex) => (
                              <OversightItem
                                key={esIndex}
                                esIndex={esIndex}
                                data={advert}
                                loading={loading}
                                setShowApplication={setShowApplications}
                                setShowExamSettings={setShowExamSettings}
                                setShowAppeals={setShowAppeals}
                              />
                            ))
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan={12} align="center">
                                  No date found
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </tbody>
                      )}
                    </table>
                  </CardContent>
                </div>
              </div>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

RecruitmentOversight.propTypes = {};

const mapStateToProps = ({ user, loading, recruitment }) => {
  return {
    user,
    loading,
    oversight: recruitment.oversight,
  };
};

const mapDispatchToProps = {
  loadEntityAdvertisements,
  loadYearlyInstitutions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruitmentOversight);

// ---------------------------- child components -----------------------
const OversightItem = (props) => {
  const { esIndex, data, loading } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => setAnchorEl(null);
  }, []);

  return (
    <tr
      style={{
        background: esIndex % 2 === 0 ? "" : "#f6f7f7",
        borderBottom: "2px dotted #007bff!important",
      }}
    >
      <td>{esIndex + 1}</td>
      <td>{data.positionName}</td>
      <td align="center">{data.levelName} </td>
      <td align="center">{data.indexValue}</td>
      <td>{data.hiringModeName}</td>
      <td align="center">{data.numberOfPosts}</td>
      <td align="center">{data.reqIsInternal ? "Yes" : "No"}</td>
      <td>
        <Box
          sx={{ display: { xs: "flex", md: "flex" } }}
          className="align-items-center"
        >
          {data.willHaveWrittenExam ? (
            <CheckBoxRoundedIcon
              className="mr-1 text-primary"
              fontSize="small"
            />
          ) : (
            <CheckBoxOutlineBlankRoundedIcon
              className="mr-1 text-secondary"
              fontSize="small"
            />
          )}{" "}
          Written
          {data.willHaveOralExam ? (
            <CheckBoxRoundedIcon
              className="mx-1 text-primary"
              fontSize="small"
            />
          ) : (
            <CheckBoxOutlineBlankRoundedIcon
              className="mx-1 text-secondary"
              fontSize="small"
            />
          )}{" "}
          Oral
        </Box>
      </td>
      <td>{moment(data.closingDate).format("lll")}</td>
      <td>
        <span
          className={`badge  badge-${
            data.adStatusId === 0
              ? "secondary"
              : data.adStatusId === 1
              ? "success"
              : data.adStatusId === 7 || data.adStatusId === 8
              ? "danger"
              : data.adStatusId === 2 || data.adStatusId === 3
              ? "primary"
              : "info"
          }`}
        >
          {data.adStatusName}
        </span>
      </td>
      <td>
        <span
          onClick={handleOpenMenu}
          className="material-icons text-info ml-1"
          style={{ cursor: "pointer" }}
        >
          more_vert
        </span>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 2,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 15,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <span>
            <MenuItem
              disabled={loading}
              onClick={() => {
                props.setShowApplication(data);
                handleCloseMenu();
              }}
              className="text-dark font-weight-light"
            >
              <Groups2RoundedIcon className="mr-1" />
              Applications
            </MenuItem>

            <Divider className="my-1" />
          </span>

          <span>
            <MenuItem
              disabled={loading}
              onClick={() => {
                props.setShowExamSettings(data);
                handleCloseMenu();
              }}
              className="text-dark font-weight-light"
            >
              <ExposureRoundedIcon className="mr-1" />
              Exams
            </MenuItem>

            <Divider className="my-1" />
          </span>
          <span>
            <MenuItem
              disabled={loading}
              onClick={() => {
                props.setShowAppeals(data);
                handleCloseMenu();
              }}
              className="text-dark font-weight-light"
            >
              <BalanceRoundedIcon className="mr-1" />
              Appeals
            </MenuItem>
          </span>
        </Menu>
      </td>
    </tr>
  );
};

OversightItem.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  setShowApplication: PropTypes.func,
};
