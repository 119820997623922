import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

export const useRequiredAuth = () => {
  const user = useSelector((state) => state.user);
  const env = useSelector((state) => state.env);

  return {
    isAuth: () =>
      !isEmpty(user) && !isEmpty(user.selectedEntitySector) ? true : false,

    hasPermissions: (permissions) => {
      return !isEmpty(user) && !!permissions.length
        ? permissions.find(
            (permission) =>
              user?.selectedEntitySector?.permissions[permission] ||
              user?.selectedEntitySector?.permissions["IS_ADMIN"]
          )
          ? true
          : false
        : false;
    },
  };
};
