import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import AppBar from "@mui/material/AppBar";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import defaultImage from "../../assets/defaultImage.png";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Autocomplete,
  ListItem,
  ListItemText,
  List,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import GroupIcon from "@mui/icons-material/Group";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import moment from "moment";

import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import AvatarEditor from "react-avatar-editor";
import {
  addOrUpdateQuestionBankQuestion,
  addOrUpdateTempQuestionBankQuestion,
} from "../../../store/psychometric/actions";
import axios from "axios";
import { showError } from "../../toastify";
import ImageUploader from "react-images-upload";

const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const languages = [
  { id: "en", name: "ENG" },
  { id: "fr", name: "FR" },
  { id: "kn", name: "KN" },
];

const AddOrUpdateQuestionDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    knowledgeSubDomains,
    knowledgeLevels,
    questionTypes,
    addOrUpdateQuestionBankQuestion,
    addOrUpdateTempQuestionBankQuestion,
    defaultQuestionOption,
    defaultQuestion,
    question,
    setQuestion,
    isTemp,
  } = props;

  const [questionType, setQuestionType] = useState(
    questionTypes.find(({ id }) => id === (question.questionTypeId || 1)) ||
      null
  );
  const [knowledgeDomain, setKnowledgeDomain] = useState(
    knowledgeSubDomains.find(
      ({ id }) => id === question.knowledgeSubDomainId
    ) || null
  );

  const [knowledgeLevel, setKnowledgeLevel] = useState(
    knowledgeLevels.find(({ id }) => id === question.knowledgeLevelId) || null
  );

  const onClose = () => {
    setShowDialog(false);
    setQuestion(defaultQuestion);
  };

  const [hasPhoto, setHasPhoto] = useState(!!question.questionImage);

  const [editor, setEditor] = useState(null);

  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  const [avatar, setAvatar] = useState({
    selectedImage: "",
    scaleValue: 0.5,
  });

  const onScaleChane = (e) => {
    const scaleValue = +e.target.value;
    setAvatar({ ...avatar, scaleValue });
  };

  const onFileChange = (e) => {
    const selectedImage = e.target.files[0];
    if (
      selectedImage.type.endsWith("png") ||
      selectedImage.type.endsWith("jpg") ||
      selectedImage.type.endsWith("jpeg")
    ) {
      setAvatar({ ...avatar, selectedImage });
    }
  };

  const [showEditPictureDialog, setShowEditPictureDialog] = useState(false);

  const onCloseEditPictureDialog = () => {
    setShowEditPictureDialog(false);
  };

  const deleteQuestionOption = async (id, cb) => {
    try {
      if (!!id) await axios.delete("/api/psy/question-options/" + id);

      cb();
    } catch (error) {}
  };

  const [errors, setErrors] = useState({
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      hasError: false,
    };

    if (!questionType) {
      error.questionTypeHasError = true;
      error.hasError = true;
    }

    if (!knowledgeLevel) {
      error.knowledgeLevelHasError = true;
      error.hasError = true;
    }

    if (!knowledgeDomain) {
      error.knowledgeDomainHasError = true;
      error.hasError = true;
    }

    if (!question.questionTimeInSec) {
      error.questionTimeInSecHasError = true;
      error.hasError = true;
    }

    if (!question.score) {
      error.scoreHasError = true;
      error.hasError = true;
    }

    if (!!hasPhoto && !question.questionImage) {
      error.questionImageHasError = true;
      error.hasError = true;
    }

    languages.forEach((language) => {
      if (!question[`${language.id}`]) {
        error[`question_${language.id}HasError`] = true;
        error.hasError = true;
      }
    });

    let hasRightAnswer = false;

    question.options.forEach((option, index) => {
      if (!!option.isCorrect) hasRightAnswer = true;

      if (questionType?.id !== 4 || index < 2) {
        languages.forEach((language) => {
          if (!option[`${language.id}`]) {
            error[
              `question_${language.id}_${option.optionNumber}HasError`
            ] = true;
            error.hasError = true;
          }

          if (
            questionType?.id === 5 &&
            !option[
              `match${
                language.id.charAt(0).toUpperCase() + language.id.slice(1)
              }`
            ]
          ) {
            error[
              `matchQuestion_${language.id}_${option.optionNumber}HasError`
            ] = true;
            error.hasError = true;
          }
        });
      }
    });

    if (!hasRightAnswer && questionType?.id !== 5) {
      error.optionNumberHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");

      return true;
    }
    return false;
  };

  useEffect(() => {
    setErrors({
      hasError: false,
    });
  }, [question]);

  const onSave = (e) => {
    if (!formValidator()) {
      const formData = new FormData();
      formData.append("id", question.id);
      formData.append("en", question.en);
      formData.append("fr", question.fr);
      formData.append("kn", question.kn);
      formData.append("questionTimeInSec", question.questionTimeInSec);
      formData.append("score", question.score);
      formData.append("questionTypeId", questionType?.id || "");
      formData.append("knowledgeLevelId", knowledgeLevel?.id || "");
      formData.append("knowledgeSubDomainId", knowledgeDomain?.id || "");
      formData.append("actionType", question?.actionType);

      if (questionType?.id !== 2) {
        if (questionType?.id === 4) {
          formData.append(
            "options",
            JSON.stringify(question.options.slice(0, 2))
          );
        } else formData.append("options", JSON.stringify(question.options));
      }

      if (!!hasPhoto)
        formData.append("questionImage", question.questionImage || "");

      if (!!isTemp) addOrUpdateTempQuestionBankQuestion(formData, onClose);
      else addOrUpdateQuestionBankQuestion(formData, onClose);
    }
  };

  const [pictures, setPictures] = useState([]);

  const onDrop = (picture) => {
    setPictures([picture]);

    console.log(picture);
  };

  return (
    <Dialog onClose={onClose} open={showDialog} fullWidth maxWidth="xl">
      <DialogTitle>
        <span className="d-flex align-items-center">Create New Question</span>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          className="row mx-0 bg-light align-items-start"
          style={{
            minHeight: "75vh",
          }}
        >
          <fieldset
            className={`col-12 row no-gutters px-3 form-group border border `}
          >
            <legend
              className={`col-12 w-auto text-primary`}
              style={{
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontWeight: 400,
                fontSize: "0.82rem",
                lineHeight: "1em",
                letterSpacing: "0.00938em",
              }}
            >
              Question details{" "}
              <Tooltip title={`${!!hasPhoto ? "Question image" : "No image"}`}>
                <IconButton
                  color="primary"
                  edge="end"
                  aria-label="add item"
                  onClick={() => {
                    setHasPhoto(!hasPhoto);
                  }}
                >
                  {!!hasPhoto && <PhotoCameraIcon />}
                  {!hasPhoto && <NoPhotographyIcon />}
                </IconButton>
              </Tooltip>
            </legend>
            <div className="col-12 row mt-2 mx-0 no-gutters">
              <div className="col-3 mb-3 px-1">
                <Autocomplete
                  size="small"
                  id="questionType"
                  name="questionType"
                  defaultValue={null}
                  value={questionType}
                  options={questionTypes.filter(({ isActive }) => !!isActive)}
                  onChange={(event, questionType) => {
                    setQuestionType(questionType || null);
                    setQuestion({
                      ...question,
                      questionTypeId: questionType?.id || null,
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <ListItem dense {...props}>
                      <ListItemText primary={option.name} />
                    </ListItem>
                  )}
                  // getOptionDisabled={(option) => option.id === 5}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Question type"
                      placeholder="Question type"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.questionTypeHasError && (
                  <small className="text-danger">
                    Question type is required
                  </small>
                )}
              </div>
              <div className="col mb-3 pr-1">
                <Autocomplete
                  size="small"
                  id="knowledgeLevel"
                  name="knowledgeLevel"
                  defaultValue={null}
                  value={knowledgeLevel}
                  options={knowledgeLevels.filter(({ isActive }) => !!isActive)}
                  onChange={(event, level) => {
                    setKnowledgeLevel(level || null);
                    setQuestion({
                      ...question,
                      knowledgeLevelId: level?.id || null,
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <ListItem dense {...props}>
                      <ListItemText primary={option.name} />
                    </ListItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Knowledge level"
                      placeholder="Knowledge level"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.knowledgeLevelHasError && (
                  <small className="text-danger">
                    Knowledge level is required
                  </small>
                )}
              </div>
              <div className="col mb-3 px-1">
                <Autocomplete
                  size="small"
                  id="knowledgeDomain"
                  name="knowledgeDomain"
                  defaultValue={null}
                  value={knowledgeDomain}
                  options={knowledgeSubDomains.filter(
                    ({ isActive }) => !!isActive
                  )}
                  onChange={(event, domain) => {
                    setKnowledgeDomain(domain || null);
                    setQuestion({
                      ...question,
                      knowledgeSubDomainId: domain?.id || null,
                    });
                  }}
                  getOptionLabel={(option) =>
                    option.name + " / " + option.knowledgeDomainName
                  }
                  renderOption={(props, option) => (
                    <ListItem dense {...props}>
                      <ListItemText
                        primary={option.name}
                        secondary={
                          <>
                            <>{option.knowledgeDomainName}</>
                          </>
                        }
                      />
                    </ListItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Question domain"
                      placeholder="Question domain"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.knowledgeDomainHasError && (
                  <small className="text-danger">
                    Question domain is required
                  </small>
                )}
              </div>

              <div className="col-2 pl-1">
                <TextField
                  error={errors.questionTimeInSecHasError}
                  type="number"
                  fullWidth
                  size="small"
                  value={question.questionTimeInSec}
                  name="questionTimeInSec"
                  label="Seconds"
                  variant="outlined"
                  placeholder="Seconds"
                  onBlur={() => {
                    if (question.questionTimeInSec === "")
                      setQuestion({
                        ...question,
                        questionTimeInSec: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+question.questionTimeInSec === 0)
                      setQuestion({
                        ...question,
                        questionTimeInSec: "",
                      });
                  }}
                  onChange={(e) => {
                    const questionTimeInSec = +e.target.value || 0;
                    setQuestion({
                      ...question,
                      questionTimeInSec: questionTimeInSec,
                    });
                  }}
                />

                {errors.questionTimeInSecHasError && (
                  <small className="text-danger">
                    Question duration is required
                  </small>
                )}
              </div>
              <div className="col-2 pl-1">
                <TextField
                  error={errors.scoreHasError}
                  type="number"
                  fullWidth
                  size="small"
                  value={question.score}
                  name="score"
                  label="Score"
                  variant="outlined"
                  placeholder="Score"
                  onBlur={() => {
                    if (question.score === "")
                      setQuestion({
                        ...question,
                        score: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+question.score === 0)
                      setQuestion({
                        ...question,
                        score: "",
                      });
                  }}
                  onChange={(e) => {
                    const score = +e.target.value || 0;
                    setQuestion({
                      ...question,
                      score: score,
                    });
                  }}
                />
                {errors.scoreHasError && (
                  <small className="text-danger">
                    Question score is required
                  </small>
                )}
              </div>
            </div>
            <div className="col-12 row  align-items-center no-gutters mx-0">
              {!!hasPhoto && (
                <div
                  className="col-3 pr-2 question-image"
                  onClick={() => setShowEditPictureDialog(true)}
                >
                  {!!question.questionImage && (
                    <img
                      src={`data:image/png;base64,${question.questionImage}`}
                      className="img-fluid border rounded"
                      alt="default Image"
                      style={{
                        width: "100%",
                      }}
                    />
                  )}
                  {!question.questionImage && (
                    <img
                      src={defaultImage}
                      className="img-fluid border rounded"
                      alt="default Image"
                      style={{
                        width: "100%",
                      }}
                    />
                  )}
                  <div className="middle">
                    <div className="text">Edit image</div>
                  </div>

                  {errors.questionImageHasError && (
                    <small className="text-danger">
                      Question image is required
                    </small>
                  )}
                </div>
              )}
              <div
                className={`${!!hasPhoto ? "col-9" : "col-12"} row no-gutters`}
              >
                <div className="col-12 mt-n3">
                  {languages.map((language, index) => (
                    <div key={language.id}>
                      <TextField
                        error={!!errors[`question_${language.id}HasError`]}
                        className="mt-3"
                        rows={2}
                        multiline
                        fullWidth
                        size="small"
                        name={`question_${language.id}`}
                        label={language.name}
                        placeholder={language.name}
                        variant="outlined"
                        value={question[`${language.id}`] || ""}
                        onChange={(e) => {
                          const tempQuestion = { ...question };
                          tempQuestion[`${language.id}`] = e.target.value || "";
                          setQuestion({
                            ...tempQuestion,
                          });
                        }}
                      />
                      {!!errors[`question_${language.id}HasError`] && (
                        <small className="text-danger">
                          {language.name} is required
                        </small>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {questionType?.id !== 2 && (
              <fieldset
                className={`col-12 row  mx-0 form-group border border bg-white`}
              >
                <legend
                  className={`col-12 w-auto text-primary`}
                  style={{
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: 400,
                    fontSize: "0.82rem",
                    lineHeight: "1em",
                    letterSpacing: "0.00938em",
                  }}
                >
                  Question options ({question.options.length})
                  <Tooltip title="Add new option">
                    <span>
                      <IconButton
                        disabled={
                          (questionType?.id === 4 &&
                            question?.options?.length >= 2) ||
                          question?.options?.length >= 4
                        }
                        color="primary"
                        edge="end"
                        aria-label="add item"
                        onClick={() => {
                          if (
                            questionType?.id === 4 &&
                            question?.options?.length >= 2
                          )
                            return;

                          const tempOptions = [...question.options];
                          tempOptions.push({
                            ...defaultQuestionOption[0],
                          });

                          tempOptions.forEach((item, i) => {
                            item.optionNumber = letters[i];
                          });

                          setQuestion({
                            ...question,
                            options: tempOptions,
                          });
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </legend>

                <List className="col-12">
                  {errors.optionNumberHasError && (
                    <small className="text-danger ml-3">
                      Please, select the right answer
                    </small>
                  )}
                  {question.options.map(
                    (option, optionIndex) =>
                      (questionType?.id !== 4 || optionIndex < 2) && (
                        <React.Fragment key={optionIndex}>
                          <ListItem
                            className="animated"
                            dense
                            secondaryAction={
                              <>
                                {question.options.length > 4 && (
                                  <IconButton
                                    color="error"
                                    edge="end"
                                    aria-label="Remove item"
                                    onClick={() => {
                                      deleteQuestionOption(option.id, () => {
                                        const tempOptions = [
                                          ...question.options,
                                        ];
                                        tempOptions.splice(optionIndex, 1);

                                        tempOptions.forEach((item, i) => {
                                          item.optionNumber = letters[i];
                                        });
                                        setQuestion({
                                          ...question,
                                          options: tempOptions,
                                        });
                                      });
                                    }}
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                )}
                              </>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>{option.optionNumber}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <div className="row">
                                  {questionType?.id !== 5 && (
                                    <Checkbox
                                      title="Select the correct answer"
                                      checked={option.isCorrect}
                                      onChange={(e) => {
                                        const tempOptions = [
                                          ...question.options,
                                        ];

                                        tempOptions.forEach((item) => {
                                          item.isCorrect = false;
                                        });

                                        tempOptions[optionIndex].isCorrect =
                                          e.target.checked;

                                        setQuestion({
                                          ...question,
                                          options: tempOptions,
                                        });
                                      }}
                                    />
                                  )}

                                  {languages.map((language, languageIndex) => (
                                    <div className="col" key={language.id}>
                                      <TextField
                                        error={
                                          !!errors[
                                            `question_${language.id}_${option.optionNumber}HasError`
                                          ]
                                        }
                                        rows={1}
                                        multiline
                                        fullWidth
                                        size="small"
                                        name={`question_${language.id}_${option.optionNumber}`}
                                        label={language.name}
                                        placeholder={language.name}
                                        variant="outlined"
                                        value={option[`${language.id}`] || ""}
                                        onChange={(e) => {
                                          const tempOptions = [
                                            ...question.options,
                                          ];
                                          tempOptions[optionIndex][
                                            `${language.id}`
                                          ] = e.target.value || "";

                                          setQuestion({
                                            ...question,
                                            options: tempOptions,
                                          });
                                        }}
                                      />
                                      {errors[
                                        `question_${language.id}_${option.optionNumber}HasError`
                                      ] && (
                                        <small className="text-danger">
                                          Required
                                        </small>
                                      )}
                                      {questionType?.id === 5 && (
                                        <>
                                          <TextField
                                            error={
                                              errors[
                                                `matchQuestion_${language.id}_${option.optionNumber}HasError`
                                              ]
                                            }
                                            className="mt-2"
                                            rows={1}
                                            multiline
                                            fullWidth
                                            size="small"
                                            name={`matchQuestion_${language.id}_${option.optionNumber}`}
                                            label={`match${language.name}`}
                                            placeholder={`match${language.name}`}
                                            variant="outlined"
                                            value={
                                              option[
                                                `match${
                                                  language.id
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  language.id.slice(1)
                                                }`
                                              ] || ""
                                            }
                                            onChange={(e) => {
                                              const tempOptions = [
                                                ...question.options,
                                              ];
                                              tempOptions[optionIndex][
                                                `match${
                                                  language.id
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  language.id.slice(1)
                                                }`
                                              ] = e.target.value || "";

                                              setQuestion({
                                                ...question,
                                                options: tempOptions,
                                              });
                                            }}
                                          />
                                          {errors[
                                            `matchQuestion_${language.id}_${option.optionNumber}HasError`
                                          ] && (
                                            <small className="text-danger">
                                              Required
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              }
                            />
                          </ListItem>
                          {optionIndex < question.options.length - 1 && (
                            <>
                              <Divider />
                            </>
                          )}
                        </React.Fragment>
                      )
                  )}
                </List>
              </fieldset>
            )}
          </fieldset>

          {showEditPictureDialog && (
            <Dialog
              onClose={onCloseEditPictureDialog}
              aria-labelledby="customized-dialog-title"
              open={showEditPictureDialog}
              fullWidth={true}
              maxWidth={"sm"}
            >
              <DialogTitle className="text-primary font-weight-bold">
                <span className="d-flex align-items-center text-uppercase text-success">
                  Upload image
                </span>

                <IconButton
                  aria-label="close"
                  onClick={onCloseEditPictureDialog}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <span className="material-icons">close</span>
                </IconButton>
              </DialogTitle>
              <DialogContent className="pb-1">
                <div className="row">
                  {/* <div className="col-12">
                    <ImageUploader
                      singleImage
                      {...props}
                      withIcon={true}
                      onChange={onDrop}
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      maxFileSize={5242880}
                    />
                    <img src={pictures[0]} alt="loading..." />
                  </div> */}
                  <div className={`col-12`}>
                    <small>
                      Select image{" "}
                      <small className="badge badge-pill text-primary">
                        (Only PNG, and JPG images are allowed)
                      </small>
                    </small>
                    <Button
                      fullWidth
                      className="d-flex justify-content-start border border-dark"
                      component="label"
                      style={{
                        borderRadius: "0px",
                        height: "35px",
                        color: "#000",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "#eee",
                      }}
                      size="md"
                    >
                      <input
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={onFileChange}
                        className="form-control-file"
                      />
                    </Button>
                  </div>
                  <div className="col-12 mt-2">
                    <AvatarEditor
                      image={avatar.selectedImage}
                      width={537}
                      height={192}
                      border={8}
                      scale={avatar.scaleValue}
                      ref={setEditorRef}
                      rotate={0}
                      borderRadius={0}
                    />
                  </div>
                  <div className="col-12 ">
                    <Tooltip title="Adjust">
                      <input
                        type="range"
                        style={{ width: "100%" }}
                        min="0"
                        max="2"
                        step="0.01"
                        name="scaleValue"
                        value={avatar.scaleValue}
                        onChange={onScaleChane}
                      />
                    </Tooltip>

                    <small className="mt-n2 d-block ">
                      Adjust your image to fit into the frame
                    </small>
                  </div>
                  <div className="col-12 d-flex justify-content-center py-3">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setQuestion({
                          ...question,
                          questionImage:
                            editor
                              ?.getImageScaledToCanvas()
                              ?.toDataURL()
                              ?.split(",")[1] || "",
                        });

                        onCloseEditPictureDialog();
                      }}
                      type="button"
                      className="px-5"
                      disabled={!avatar.selectedImage}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </DialogContent>

      <DialogActions className="d-flex justify-content-center py-4">
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          type="button"
          className="px-5"
          disabled={loading}
        >
          {loading ? "Wait..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({
  user,
  loading,
  knowledgeSubDomains,
  knowledgeLevels,
  questionTypes,
}) => {
  return {
    user,
    loading,
    knowledgeSubDomains,
    knowledgeLevels,
    questionTypes,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateQuestionBankQuestion,
  addOrUpdateTempQuestionBankQuestion,
})(AddOrUpdateQuestionDialog);
