import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
// import { getApplicationLanguages } from "../../../../store/profile/actions";

import NoResults from "../../../common/components/NoResults";
import AdvertSkeleton from "../../../common/components/AdvertSkeleton";
export const LanguageList = (props) => {
  const {
    env,
    loading,
    onWait,
    applicationLanguages,
    getApplicationLanguages,
    applicationId,
  } = props;

  useEffect(() => {
    getApplicationLanguages(applicationId);
  }, []);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="languages">
        <CardContent>
          {loading && !onWait && !applicationLanguages.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                {/* title */}
                <div>
                  <Typography variant="h5" style={{ color: "#007bff" }}>
                    Languages
                  </Typography>
                </div>
              </div>

              {applicationLanguages.length === 0 ? (
                <NoResults />
              ) : (
                applicationLanguages.map((language, index) => (
                  <LanguageItemCard
                    key={language.id}
                    index={index}
                    language={language}
                    loading={loading}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({ env, loading, onWait, applicationLanguages }) => ({
  env,
  loading,
  onWait,
  applicationLanguages,
});

export default connect(mapStateToProps, {
  // getApplicationLanguages,
})(LanguageList);

const LanguageItemCard = (props) => {
  const { index, env, loading, language } = props;

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card`}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          title={<Typography>{language.language.name}</Typography>}
          subheader={
            <div className="d-flex  mb-2">
              <div className="mr-3">
                <span className="mr-1">Reading:</span>
                <span
                  className={`badge badge-${
                    language.reading === "Excellent"
                      ? "success"
                      : language.reading === "Very Good"
                      ? "primary"
                      : "secondary"
                  }`}
                >
                  {language.reading}
                </span>
              </div>
              <div className="mr-3">
                <span className="mr-1">Writing:</span>
                <span
                  className={`badge badge-${
                    language.writting === "Excellent"
                      ? "success"
                      : language.writting === "Very Good"
                      ? "primary"
                      : "secondary"
                  }`}
                >
                  {language.writting}
                </span>
              </div>
              <div className="mr-3">
                <span className="mr-1">Speaking:</span>
                <span
                  className={`badge badge-${
                    language.speaking === "Excellent"
                      ? "success"
                      : language.speaking === "Very Good"
                      ? "primary"
                      : "secondary"
                  }`}
                >
                  {language.speaking}
                </span>
              </div>
            </div>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />
    </>
  );
};
