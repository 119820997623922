import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  Typography,
  FormGroup,
  Checkbox,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  // getSalaryStructure,
  savePosition,
} from "../../../store/structure/actions";
// import {
//   // getSourceOfFunds,
//   getPayrollGroups,
//   // getCostCenters,
// } from "../../../store/payroll/actions";
// import PositionQualification from "./PositionQualifications";
// import PositionExperience from "./PositionExperience";
// import PositionCompetency from "./PositionCompetency";
// import PositionCertificate from "./PositionCertificate";

import { showError } from "../../../app/toastify";

const AddPostionDialog = (props) => {
  const {
    loading,
    user,
    salaryStructures,
    selectedUnit,
    showPostionForm,
    // getSalaryStructure,
    setShowPositionForm,
    savePosition,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    // getSourceOfFunds,
    getPayrollGroups,
    // getCostCenters,
  } = props;

  const defaultPositionForm = {
    costCenterId: "",
    payrollGroupId: "",
    sourceOfFundId: "",
    unitId: "",
    salaryStructureId: "",
    isOnStructure: true,
  };

  const [positionForm, setPositionForm] = useState(defaultPositionForm);

  const [salaryStructure, setSalaryStructure] = useState(null);
  const [sourceOfFund, setSourceOfFund] = useState(null);
  const [payrollGroup, setPayrollGroup] = useState(null);
  const [costCenter, setCostCenter] = useState(null);

  const [errors, setErrors] = useState({
    positionHasError: false,
    hiringModeHasError: false,
    employeeGroupHasError: false,
    jobFieldHasError: false,
    sourceOfFundHasError: false,
    descriptionHasError: false,
    payrollGroupHasError: false,
    costCenterHasError: false,
  });

  useEffect(() => {
    setSourceOfFund(null);
    setPayrollGroup(null);
    setCostCenter(null);

    if (!!salaryStructure)
      setPositionForm({
        ...positionForm,
        unitId: selectedUnit.unitId,
        salaryStructureId: salaryStructure.id,
      });
    else setPositionForm(defaultPositionForm);
  }, [salaryStructure]);

  const formValidator = () => {
    const error = {
      positionHasError: false,
      sourceOfFundHasError: false,
      payrollGroupHasError: false,
      costCenterHasError: false,
      hasError: false,
    };

    if (!salaryStructure) {
      error.positionHasError = true;
      error.hasError = true;
    }

    if (!sourceOfFund) {
      error.sourceOfFundHasError = true;
      error.hasError = true;
    }

    if (!payrollGroup) {
      error.payrollGroupHasError = true;
      error.hasError = true;
    }
    if (!costCenter) {
      error.costCenterHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      savePosition(positionForm, setShowPositionForm);
    }
  };

  const onClose = () => {
    setShowPositionForm(false);

    setPositionForm(defaultPositionForm);
  };

  const payrollGroupDropdown = () => {
    return payrollGroups.filter(
      (payrollGroup) =>
        payrollGroup?.employeeGroupId === salaryStructure?.employeeGroupId
    );
  };

  const salaryStructureDropdown = () => {
    const temp = [];
    salaryStructures.forEach((item, index) =>
      temp.push({ ...item, name: (index + 1).toString() + ":" + item.name })
    );

    return temp;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showPostionForm}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark"> Add new position | </span>{" "}
                <span className="ml-0">{selectedUnit.unitName}</span>{" "}
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#f9f9f9",
              border: "1px solid #17a2b8",
              minHeight: "60vh",
            }}
          >
            <div className="row">
              <div className="col-12 mt-3">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="position-title"
                  defaultValue={null}
                  value={salaryStructure}
                  options={salaryStructureDropdown()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, salaryStructure) => {
                    setSalaryStructure(salaryStructure || null);
                    // selectSalaryStructure(salaryStructure);
                    setErrors({ ...errors, positionHasError: false });
                  }}
                  getOptionLabel={(option) =>
                    option.name +
                    " (" +
                    option.levelName +
                    "." +
                    option.scaleName +
                    ")"
                  }
                  renderOption={(props, salaryStructure) => (
                    <Box component="li" {...props}>
                      {salaryStructure.name +
                        " (" +
                        salaryStructure.levelName +
                        "." +
                        salaryStructure.scaleName +
                        ")"}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="--Select position--"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "salaryStructure", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.positionHasError && (
                  <small className="text-danger"> Position is required </small>
                )}
              </div>

              <div className="col-12 mt-3">
                <table className="table  table-bordered table-sm">
                  <thead style={{ fontSize: "14px" }}>
                    <tr>
                      <th colSpan={9} className="text-center py-1">
                        Position Details
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        Name
                      </th>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        Posts
                      </th>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        Level
                      </th>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        Scale
                      </th>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        IndexValue
                      </th>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        EmployeeGroup
                      </th>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        HiringMode
                      </th>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        JobField
                      </th>
                      <th
                        className="py-1 px-1"
                        style={{ backgroundColor: "#f3f3f3" }}
                      >
                        Classification
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ maxWidth: "150px" }}>
                        {!!salaryStructure ? salaryStructure.name : "-"}
                      </td>

                      <td>
                        {!!salaryStructure
                          ? salaryStructure.numberOfPosts
                          : "-"}
                      </td>
                      <td>
                        {!!salaryStructure ? salaryStructure.levelName : "-"}
                      </td>
                      <td>
                        {!!salaryStructure ? salaryStructure.scaleName : "-"}
                      </td>
                      <td>
                        {!!salaryStructure ? salaryStructure.indexValue : "-"}
                      </td>

                      <td>
                        {!!salaryStructure
                          ? salaryStructure.employeeGroupName
                          : "-"}
                      </td>
                      <td>
                        {!!salaryStructure
                          ? salaryStructure.hiringModeName
                          : "-"}
                      </td>
                      <td>
                        {!!salaryStructure ? salaryStructure.jobFieldName : "-"}
                      </td>
                      <td>
                        {!!salaryStructure ? salaryStructure.jobName : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        <FormGroup
                          row
                          className="d-flex justify-content-center  bg-light"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={!salaryStructure}
                                disableRipple
                                checked={
                                  salaryStructure?.hasTransportAllowance ||
                                  false
                                }
                              />
                            }
                            label="Has Transport All."
                          />
                          <FormControlLabel
                            className="mr-0"
                            control={
                              <Checkbox
                                disabled={!salaryStructure}
                                disableRipple
                                checked={
                                  salaryStructure?.hasHousingAllowance || false
                                }
                              />
                            }
                            label="Has Housing All."
                          />
                        </FormGroup>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-4 mt-3">
                <Autocomplete
                  size="small"
                  id="source-of-fund"
                  defaultValue={null}
                  value={sourceOfFund}
                  options={sourceOfFunds}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, sourceOfFund) => {
                    setSourceOfFund(sourceOfFund || null);
                    const sourceOfFundId = sourceOfFund ? sourceOfFund.id : "";
                    setPositionForm({ ...positionForm, sourceOfFundId });
                    setErrors({ ...errors, sourceOfFundHasError: false });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, sourceOfFund) => (
                    <Box component="li" {...props}>
                      {sourceOfFund.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="--Select source of fund--"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "sourceOfFund", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.sourceOfFundHasError && (
                  <small className="text-danger ">
                    Source of fund is required{" "}
                  </small>
                )}
              </div>

              <div className="col-4 mt-3">
                <Autocomplete
                  size="small"
                  id="costCenter"
                  defaultValue={null}
                  value={costCenter}
                  options={costCenters}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, costCenter) => {
                    setCostCenter(costCenter || null);
                    const costCenterId = costCenter ? costCenter.id : "";
                    setPositionForm({ ...positionForm, costCenterId });

                    setErrors({ ...errors, costCenterHasError: false });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, costCenter) => (
                    <Box component="li" {...props}>
                      {costCenter.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="--Select costcenter--"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "costCenter", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.costCenterHasError && (
                  <small className="text-danger">Costcenter is required </small>
                )}
              </div>

              <div className="col-4 mt-3">
                <Autocomplete
                  size="small"
                  id="payrollGroup"
                  defaultValue={null}
                  value={payrollGroup}
                  options={payrollGroupDropdown()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, payrollGroup) => {
                    setPayrollGroup(payrollGroup || null);
                    const payrollGroupId = payrollGroup ? payrollGroup.id : "";
                    setPositionForm({ ...positionForm, payrollGroupId });
                    setErrors({ ...errors, payrollGroupHasError: false });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, payrollGroup) => (
                    <Box component="li" {...props}>
                      {payrollGroup.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="--Select payroll group--"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "payrollGroup", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.payrollGroupHasError && (
                  <small className="text-danger ">
                    Payroll group is required{" "}
                  </small>
                )}
              </div>

              {/* <div className="col-12 mt-4">
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={6}
                    name="description"
                    autoFocus
                    label="Job Responsibilities"
                    variant="outlined"
                    placeholder="Job Responsibilities"
                    value={positionForm.description}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const description = e.target.value;
                      setPositionForm({ ...positionForm, description });
                      const descriptionHasError = false;
                      setErrors({ ...errors, descriptionHasError });
                    }}
                  />

                  {errors.descriptionHasError && (
                    <div className="text-danger mt-2">
                      Description is required{" "}
                    </div>
                  )}
                </div> */}
            </div>
            <FormGroup
              row
              className=" d-flex justify-content-center mt-3"
              style={{
                backgroundColor: "#e9ecef",
                border: "1px solid #cfd3d8",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!sourceOfFund}
                    disableRipple
                    checked={sourceOfFund?.payTPR || false}
                  />
                }
                label="Pay TPR"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!sourceOfFund}
                    disableRipple
                    checked={sourceOfFund?.payCSR || false}
                  />
                }
                label="Pay CSR"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!sourceOfFund}
                    disableRipple
                    checked={sourceOfFund?.payMedical || false}
                  />
                }
                label="Pay Medical"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!sourceOfFund}
                    disableRipple
                    checked={sourceOfFund?.payMaternity || false}
                  />
                }
                label="Pay Maternity"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!sourceOfFund}
                    disableRipple
                    checked={sourceOfFund?.payCBHI || false}
                  />
                }
                label="Pay CBHI"
              />
            </FormGroup>

            <div className="col-12 mt-1 d-flex justify-content-end">
              <FormControl
                disabled={!salaryStructure}
                className="mt-3  d-block"
              >
                <FormLabel className="text-primary text-uppercase">
                  Is it on structure?
                </FormLabel>
                <RadioGroup
                  className="mt-0 d-flex justify-content-end"
                  row
                  name="isOnStructure"
                  value={positionForm?.isOnStructure}
                  onChange={(event) => {
                    setPositionForm({
                      ...positionForm,
                      isOnStructure:
                        event.target.value === "false" ? false : true,
                    });
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio disableRipple size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio disableRipple size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          {/* <div
              className="elevated rounded p-3 mt-3"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="row">
                <div className="col-12 text-left">
                  <h6 className="mb-2">Roles</h6>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.isOnStructure}
                        onChange={(e) => {
                          const isOnStructure = !positionForm.isOnStructure;
                          setPositionForm({
                            ...positionForm,
                            isOnStructure,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Is it on structure?"
                  />

                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.payMaternity}
                        onChange={(e) => {
                          const payMaternity = !positionForm.payMaternity;
                          setPositionForm({
                            ...positionForm,
                            payMaternity,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Pay Maternity?"
                  />

                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.payCBHI}
                        onChange={(e) => {
                          const payCBHI = !positionForm.payCBHI;
                          setPositionForm({
                            ...positionForm,
                            payCBHI,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Pay CBHI?"
                  />
                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.isHeadOfUnit}
                        onChange={(e) => {
                          const isHeadOfUnit = !positionForm.isHeadOfUnit;
                          setPositionForm({
                            ...positionForm,
                            isHeadOfUnit,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Is Head Of Unit?"
                  />
                </div>
                <div className="col-4">
                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.payTPR}
                        onChange={(e) => {
                          const payTPR = !positionForm.payTPR;
                          setPositionForm({
                            ...positionForm,
                            payTPR,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Pay TPR?"
                  />

                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.payCSR}
                        onChange={(e) => {
                          const payCSR = !positionForm.payCSR;
                          setPositionForm({
                            ...positionForm,
                            payCSR,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Pay CSR?"
                  />

                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.payMedical}
                        onChange={(e) => {
                          const payMedical = !positionForm.payMedical;
                          setPositionForm({
                            ...positionForm,
                            payMedical,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Pay Medical?"
                  />

                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.isPlanner}
                        onChange={(e) => {
                          const isPlanner = !positionForm.isPlanner;
                          setPositionForm({
                            ...positionForm,
                            isPlanner,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Is Planner?"
                  />
                </div>
                <div className="col-lg-4">
                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.isRBMOversight}
                        onChange={(e) => {
                          const isRBMOversight = !positionForm.isRBMOversight;
                          setPositionForm({
                            ...positionForm,
                            isRBMOversight,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Is RBM Oversight?"
                  />
                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.isHR}
                        onChange={(e) => {
                          const isHR = !positionForm.isHR;
                          setPositionForm({
                            ...positionForm,
                            isHR,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Is HR?"
                  />

                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.isDAF}
                        onChange={(e) => {
                          const isDAF = !positionForm.isDAF;
                          setPositionForm({
                            ...positionForm,
                            isDAF,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Is DAF?"
                  />

                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.hasTransportAllowance}
                        onChange={(e) => {
                          const hasTransportAllowance =
                            !positionForm.hasTransportAllowance;
                          setPositionForm({
                            ...positionForm,
                            hasTransportAllowance,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Has Transport Allowance?"
                  />

                  <FormControlLabel
                    className="col"
                    control={
                      <Checkbox
                        checked={positionForm.hasHousingAllowance}
                        onChange={(e) => {
                          const hasHousingAllowance =
                            !positionForm.hasHousingAllowance;
                          setPositionForm({
                            ...positionForm,
                            hasHousingAllowance,
                          });
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Has Housing Allowance?"
                  />
                </div>
              </div>
            </div>
            
            {salaryStructure && (
              <React.Fragment>
                <div
                  className="elevated rounded p-3 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                  }}
                >
                  <div className="row">
                    <div className="col-8 col-md-8 col-lg-8">
                      <h6 className="mb-2">Qualifications</h6>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                      <PositionQualification
                        jobClassificationId={
                          salaryStructure.jobClassificationId
                        }
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="elevated rounded p-3 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                  }}
                >
                  <div className="row">
                    <div className="col-8 col-md-8 col-lg-8">
                      <h6 className="mb-2">Experiences</h6>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                      <PositionExperience
                        jobClassificationId={
                          salaryStructure.jobClassificationId
                        }
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="elevated rounded p-3 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                  }}
                >
                  <div className="row">
                    <div className="col-8 col-md-8 col-lg-8">
                      <h6 className="mb-2">
                        {" "}
                        Required Competencies and Key Technical Skills
                      </h6>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                      <PositionCompetency
                        jobClassificationId={
                          salaryStructure.jobClassificationId
                        }
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="elevated rounded p-3 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                  }}
                >
                  <div className="row">
                    <div className="col-8 col-md-8 col-lg-8">
                      <h6 className="mb-2"> Professional Certificates </h6>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                      <PositionCertificate
                        jobClassificationId={
                          salaryStructure.jobClassificationId
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )} */}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            onClick={onSave}
            type="button"
            variant="contained"
            color="primary"
            className=" px-5"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  salaryStructures,
  sourceOfFunds,
  payrollGroups,
  costCenters,
}) => {
  return {
    loading,
    user,
    salaryStructures,
    sourceOfFunds,
    payrollGroups,
    costCenters,
  };
};
export default connect(mapStateToProps, {
  // getSalaryStructure,
  savePosition,
  // getSourceOfFunds,
  // getPayrollGroups,
  // getCostCenters,
})(AddPostionDialog);
