import React from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import PdfViewer from "./PdfViewer";

const PreviewPdfUrl = (props) => {
  const { showDocument, setShowDocument, document, isBase64 } = props;

  const onClose = () => {
    setShowDocument(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDocument}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div" className="mr-5">
            <AttachFileIcon className="mb-2 mr-2 text-dark" />
            {document?.name}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ margin: 0, padding: 0, height: "600px" }}>
          <PdfViewer url={document.url} isBase64={isBase64} />
        </DialogContent>
        {/* <DialogActions className="d-flex justify-content-center py-4"></DialogActions> */}
      </Dialog>
    </>
  );
};

export default PreviewPdfUrl;
