import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  getJobClassifications,
  deleteJobClassification,
  getJobFields,
  getDegrees,
  getJobs,
  getEntityClasses,
  getJobCategories,
  getLevels,
  getCompetencies,
  getCertificateFields,
  getCertificates,
  getAllowanceTypes,
} from "../../../store/structure/actions";
// import {
//   getCalculationMethods,
//   getBasePayrollComponents,
//   getSystemAllowances,
// } from "../../../store/payroll/actions";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import JobClassificationEmployeesDialog from "../components/JobClassificationEmployeesDialog";
import JobClassificationProfileDialog from "../components/JobClassificationProfileDialog";
import {
  Typography,
  Box,
  Toolbar,
  Chip,
  Button,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  Autocomplete,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import AddJobClassificationDialog from "../components/AddJobClassificationDialog";
import JobClassificationAllowancesDialog from "../components/JobClassificationAllowancesDialog";
// import UpdateJobDialog from "../components/UpdateJobDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

import loadingGif from "../../assets/loading.gif";
import { Link } from "react-router-dom";
import AllJobsDialog from "../components/AllJobsDialog";
import AllJobFieldsDialog from "../components/AllJobFieldsDialog";

const ActiveButton = {
  borderRadius: "8px",
  height: "35px",
  border: `1px solid #007bff`,
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "#007bff",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const JobClassification = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Org. Structure | Job Classification";
  });

  const {
    loading,
    jobClassifications,
    getJobClassifications,
    deleteJobClassification,
    calculationMethods,
    getCalculationMethods,
    basePayrollComponents,
    getBasePayrollComponents,
    systemAllowances,
    getSystemAllowances,
    getDegrees,
    getJobFields,
    getJobs,
    getEntityClasses,
    getJobCategories,
    getLevels,

    levels,
    degrees,
    jobFields,
    jobs,
    entityClasses,
    jobCategories,
    getCompetencies,
    getCertificateFields,
    getCertificates,
    competencies,
    certificateFields,
    certificates,
    allowanceTypes,
    getAllowanceTypes,
  } = props;
  const [showAddJobForm, setShowAddJobForm] = useState(false);
  const [entityClass, setEntityClass] = useState(null);

  const [filteredJobClassifications, setFilteredJobClassifications] = useState([
    ...jobClassifications,
  ]);
  const [confirmRemoveJob, setConfirmRemoveJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedJobField, setSelectedJobField] = useState(null);

  const [showAllJobsDialog, setShowAllJobsDialog] = useState(false);
  const [showAllJobFieldsDialog, setShowAllJobFieldsDialog] = useState(false);

  //const theme = useTheme();

  useEffect(
    () => {
      if (!levels.length) getLevels();
      if (!degrees.length) getDegrees();
      if (!jobFields.length) getJobFields();
      if (!jobs.length) getJobs();
      if (!entityClasses.length) getEntityClasses();
      if (!jobCategories.length) getJobCategories();

      if (!systemAllowances.length) getSystemAllowances();
      if (!calculationMethods.length) getCalculationMethods();
      if (!basePayrollComponents.length) getBasePayrollComponents();
      if (!allowanceTypes.length) getAllowanceTypes();
      // if (!competencies.length) getCompetencies();
      // if (!certificateFields.length) getCertificateFields();
      // if (!certificates.length) getCertificates();

      if (!jobClassifications.length) getJobClassifications();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (entityClass && !!jobClassifications.length) {
      const filtered = [...jobClassifications].filter((row) =>
        row.id.includes(entityClass.id)
      );
      setFilteredJobClassifications(filtered);
    } else {
      setFilteredJobClassifications([...jobClassifications]);
    }
  }, [entityClass, jobClassifications]);

  const getNo = (idToSearch) =>
    jobClassifications.findIndex(({ id }) => id === idToSearch);

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        className="text-dark  my-0 py-0"
        style={{ backgroundColor: "#e9ecef" }}
      >
        <Toolbar className=" my-0 py-1" style={{ minHeight: "0px" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="d-flex align-items-center my-0 py-0"
            // sx={{ display: { xs: "none", sm: "block" } }}
          >
            <span className="text-uppercase">
              Job Classifications{" "}
              <Button
                disabled={loading}
                onClick={() => setShowAddJobForm(true)}
                style={{
                  ...ActiveButton,
                }}
                size="small"
                className="ml-2"
              >
                <span className="material-icons">add</span> New
              </Button>
            </span>

            {/* <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <ChevronRightIcon className="text-dark" />
            </span>
            <span style={{ color: "#007bff" }}>
              Job Classifications
            </span> */}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 3 }}>
            <Autocomplete
              disabled={!jobClassifications.length}
              size="small"
              name="entityClass"
              defaultValue={null}
              value={entityClass}
              options={jobClassifications}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, entityClass) => {
                if (!!jobClassifications.length) setEntityClass(entityClass);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, entityClass) => (
                <Box component="li" {...props}>
                  {entityClass.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder="Filter by entity class"
                  {...params}
                  label="Filter by entity class"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "entityClass", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Box>
          <Box
            sx={{ flexGrow: 1 }}
            className="d-flex justify-content-end align-items-center"
          >
            <Tooltip title="Download">
              <span>
                <IconButton disabled className="p-0" color="primary">
                  <span className="material-icons" style={{ fontSize: "28px" }}>
                    cloud_download
                  </span>
                </IconButton>
              </span>
            </Tooltip>

            <Button
              disabled={loading}
              onClick={() => setShowAllJobsDialog(true)}
              size="small"
              variant="outlined"
              className="ml-2 "
            >
              All Jobs
            </Button>

            <Button
              disabled={loading}
              onClick={() => setShowAllJobFieldsDialog(true)}
              size="small"
              variant="outlined"
              className="ml-2 "
            >
              All Fields
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* <Divider /> */}

      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "0.5rem",
          boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
          MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
          height: "58vh",
          width: "100vw",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        {showAllJobFieldsDialog && (
          <AllJobFieldsDialog
            showAllJobFieldsDialog={showAllJobFieldsDialog}
            setShowAllJobFieldsDialog={setShowAllJobFieldsDialog}
          />
        )}

        {showAllJobsDialog && (
          <AllJobsDialog
            showAllJobsDialog={showAllJobsDialog}
            setShowAllJobsDialog={setShowAllJobsDialog}
          />
        )}

        {showAddJobForm && (
          <AddJobClassificationDialog
            showAddJobForm={showAddJobForm}
            setShowAddJobForm={setShowAddJobForm}
          />
        )}

        {confirmRemoveJob && (
          <ConfirmationDialog
            confirmationDialog={confirmRemoveJob}
            message={`Are you sure you want to remove ${selectedJob.jobName} from ${selectedJob.entityClassName}?`}
            setConfirmationDialog={setConfirmRemoveJob}
            onYes={() => {
              deleteJobClassification(
                selectedJob.jobClassificationId,
                setConfirmRemoveJob
              );
            }}
          />
        )}

        {/* {loading && (
          <div className="container mt-2" style={{ width: "100%" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
          </div>
        )} */}

        {loading && !jobClassifications.length && (
          <div style={{ height: "50vh", position: "relative" }}>
            <img
              src={loadingGif}
              height="128"
              alt="loading"
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                margin: "auto",
              }}
            />
          </div>
        )}

        {!!jobClassifications.length && (
          <table
            className="table table-bordered "
            style={{ borderTop: "none" }}
          >
            {/* <thead>
              <tr>
                <th style={{ width: "50%" }}>Job Classifications </th>
                <th style={{ width: "50%" }}></th>
              </tr>
            </thead> */}
            <tbody>
              {filteredJobClassifications.map((jobClassification, JcIndex) => (
                <tr
                  key={jobClassification.id}
                  style={{
                    background: JcIndex % 2 === 0 ? "" : "#f6f7f7",
                    borderBottom: "2px dotted #007bff!important",
                  }}
                >
                  <td>
                    <span className="span text-center">
                      <strong>
                        ({getNo(jobClassification.id) + 1}) <br />
                        {jobClassification.name}{" "}
                      </strong>{" "}
                    </span>
                  </td>
                  <td>
                    <table
                      style={{
                        background: JcIndex % 2 === 0 ? "" : "#f6f7f7",
                      }}
                    >
                      <tbody>
                        {jobClassification.jobCategories.map(
                          (category, catIndex) => (
                            <tr key={catIndex}>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <strong
                                  style={{
                                    writingMode: "vertical-rl",
                                    textAlign: "center",
                                  }}
                                >
                                  {category.jobCategoryName}
                                </strong>
                              </td>

                              <td>
                                <table
                                  style={{
                                    background:
                                      JcIndex % 2 === 0 ? "" : "#f6f7f7",
                                  }}
                                  className="table table-bordered"
                                >
                                  <tbody>
                                    {category.levels.map((level, lvelIndex) => (
                                      <tr key={lvelIndex}>
                                        <td width={25}>{level.levelName}</td>
                                        <td>
                                          <IconButton
                                            size="small"
                                            color="info"
                                            className="float-right"
                                          >
                                            <span className="material-icons ">
                                              more_vert
                                            </span>
                                          </IconButton>

                                          {level.jobs.map((job, jobIndex) => (
                                            <React.Fragment key={jobIndex}>
                                              <Chip
                                                label={
                                                  <ViewJob
                                                    jobClassification={
                                                      jobClassification
                                                    }
                                                    job={job}
                                                    category={category}
                                                    level={level}
                                                    setConfirmRemoveJob={
                                                      setConfirmRemoveJob
                                                    }
                                                    setSelectedJob={
                                                      setSelectedJob
                                                    }
                                                    selectedJob={selectedJob}
                                                    confirmRemoveJob={
                                                      confirmRemoveJob
                                                    }
                                                    setSelectedJobField={
                                                      setSelectedJobField
                                                    }
                                                    selectedJobField={
                                                      selectedJobField
                                                    }
                                                  />
                                                }
                                                variant="outlined"
                                                style={{ margin: "5px" }}
                                                size="small"
                                              />
                                            </React.Fragment>
                                          ))}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  loading,
  jobClassifications,
  calculationMethods,
  basePayrollComponents,
  systemAllowances,
  levels,
  degrees,
  jobFields,
  jobs,
  entityClasses,
  jobCategories,
  competencies,
  certificateFields,
  certificates,
  allowanceTypes,
}) => {
  return {
    loading,
    jobClassifications,
    calculationMethods,
    basePayrollComponents,
    systemAllowances,
    levels,
    degrees,
    jobFields,
    jobs,
    entityClasses,
    jobCategories,

    competencies,
    certificateFields,
    certificates,
    allowanceTypes,
  };
};
export default connect(mapStateToProps, {
  getJobClassifications,
  deleteJobClassification,
  // getCalculationMethods,
  // getBasePayrollComponents,
  // getSystemAllowances,
  getDegrees,
  getJobFields,
  getJobs,
  getEntityClasses,
  getJobCategories,
  getLevels,
  getCompetencies,
  getCertificateFields,
  getCertificates,
  getAllowanceTypes,
})(JobClassification);

const ViewJob = (props) => {
  const {
    job,
    jobClassification,
    category,
    level,

    setConfirmRemoveJob,
    setSelectedJob,
    selectedJob,
    confirmRemoveJob,
    setSelectedJobField,
    selectedJobField,
  } = props;

  const [selectedJobClassification, setSelectedJobClassification] =
    useState(null);

  const [
    showJobClassificationSettingForm,
    setShowJobClassificationSettingForm,
  ] = useState(false);

  const [showJcEmployeesModal, setShowJcEmployeesModal] = useState(false);
  const [showJcProfileModal, setShowJcProfileModal] = useState(false);

  const [showUpdateJobForm, setShowUpdateJobForm] = useState(false);
  const [showUpdateJobFieldForm, setShowUpdateJobFieldForm] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [jobClassificationId, setJobClassificationId] = useState(null);
  const [jobName, setJobName] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* {showUpdateJobForm && (
        <UpdateJobDialog
          setShowUpdateJobForm={setShowUpdateJobForm}
          showUpdateJobForm={showUpdateJobForm}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
        />
      )} */}

      {/* {showUpdateJobFieldForm && (
        <UpdateJobClassificationFieldDialog
          setShowUpdateJobFieldForm={setShowUpdateJobFieldForm}
          showUpdateJobFieldForm={showUpdateJobFieldForm}
          selectedJobField={selectedJobField}
          setSelectedJobField={setSelectedJobField}
        />
      )} */}

      {showJobClassificationSettingForm && (
        <JobClassificationAllowancesDialog
          selectedJobClassification={selectedJobClassification}
          showJobClassificationSettingForm={showJobClassificationSettingForm}
          jobClassification={jobClassification}
          setShowJobClassificationSettingForm={
            setShowJobClassificationSettingForm
          }
        />
      )}

      {showJcEmployeesModal && (
        <JobClassificationEmployeesDialog
          showJcEmployeesModal={showJcEmployeesModal}
          setShowJcEmployeesModal={setShowJcEmployeesModal}
          jobName={jobName}
          jobClassificationId={jobClassificationId}
        />
      )}

      {showJcProfileModal && (
        <JobClassificationProfileDialog
          showJcProfileModal={showJcProfileModal}
          setShowJcProfileModal={setShowJcProfileModal}
          jobName={jobName}
          jobClassificationId={jobClassificationId}
        />
      )}

      <span className="d-flex align-items-center">
        {job.jobName}

        {/* <IconButton size="small" aria-label="settings" >
        </IconButton> */}
        <span
          onClick={handleOpenMenu}
          className="material-icons text-info ml-1"
          style={{ cursor: "pointer" }}
        >
          more_horiz
        </span>
      </span>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 2,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 15,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <span>
          {/* <MenuItem
            onClick={() => {
              const jobClassificationId =
                job.jobId +
                jobClassification.id +
                category.jobCategoryId +
                level.levelId;

              setSelectedJob({
                jobClassificationId: jobClassificationId,
                jobId: job.jobId,
                jobName: job.jobName,
                entityClassId: jobClassification.id,
              });

              setShowUpdateJobForm(true);
              handleCloseMenu();
            }}
            className="text-dark font-weight-light"
          >
            <IconButton color="primary" size="small" className=" ">
              <span className="material-icons">edit</span>
            </IconButton>
            Edit title
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              const jobClassificationId =
                job.jobId +
                jobClassification.id +
                category.jobCategoryId +
                level.levelId;

              setJobClassificationId(jobClassificationId);
              setJobName(job.jobName);
              setShowJcProfileModal(true);
              handleCloseMenu();
            }}
            className="text-dark font-weight-light"
          >
            <IconButton color="primary" size="small">
              <span className="material-icons">work</span>
            </IconButton>
            Job profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              const jobClassificationId =
                job.jobId +
                jobClassification.id +
                category.jobCategoryId +
                level.levelId;

              setJobClassificationId(jobClassificationId);
              setJobName(job.jobName);
              setShowJcEmployeesModal(true);
              handleCloseMenu();
            }}
            className="text-dark font-weight-light"
          >
            <IconButton color="primary" size="small" className=" ">
              <span className="material-icons">group</span>
            </IconButton>
            Employees
          </MenuItem>

          <MenuItem
            onClick={() => {
              const jobClassificationId =
                job.jobId +
                jobClassification.id +
                category.jobCategoryId +
                level.levelId;
              setSelectedJobClassification({ id: jobClassificationId });
              setShowJobClassificationSettingForm(true);
              handleCloseMenu();
            }}
            className="text-dark font-weight-light"
          >
            <IconButton color="primary" size="small" className=" ">
              <span className="material-icons">bookmark_add</span>
            </IconButton>
            Allowances
          </MenuItem>

          {/* <MenuItem
            onClick={() => {
              const jobClassificationId =
                job.jobId +
                jobClassification.id +
                category.jobCategoryId +
                level.levelId;

              setSelectedJobField({
                jobClassificationId: jobClassificationId,
                entityClassId: jobClassification.id,
                jobFieldId: category.jobFieldId,
              });

              setShowUpdateJobFieldForm(true);
              handleCloseMenu();
            }}
            className="text-dark font-weight-light"
          >
            <IconButton color="primary" size="small" className=" ">
              <span className="material-icons">push_pin</span>
            </IconButton>
            Fields
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              const jobClassificationId =
                job.jobId +
                jobClassification.id +
                category.jobCategoryId +
                level.levelId;

              setSelectedJob({
                jobClassificationId: jobClassificationId,
                jobName: job.jobName,
                entityClassName: jobClassification.name,
              });

              setConfirmRemoveJob(true);
              handleCloseMenu();
            }}
            className="text-danger font-weight-light"
          >
            <IconButton color="error" size="small" className=" ">
              <span className="material-icons">delete</span>
            </IconButton>
            Delete
          </MenuItem>
        </span>
      </Menu>
    </>
  );
};
