import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  Divider,
  MenuItem,
  Badge,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { textCapitalized } from "../../common/components/Utils";
import {
  getAppealDetails,
  deleteFirstLevelAppealDecision,
  deleteSecondLevelAppealDecision,
  getApplicantWrittenResult,
  getApplicantOralResult,
  closeAppeal,
  getRequisition,
} from "../../../store/recruitment/actions";
// import { getUserDetails } from "../../../store/profile/actions";
import moment from "moment";
import PreLoader from "../../common/components/PreLoader";
import AppealResolveDialog from "./AppealResolveDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ViewAppealResolved from "./ViewAppealResolved";
import ApplicationCv from "../../common/components/ApplicationCv";
import FirstLevelAppealDecisionDialog from "./FirstLevelAppealDecisionDialog";
import SecondLevelAppealDecisionDialog from "./SecondLevelAppealDecisionDialog";

import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ApplicantWrittenExamAnswerDialog from "./ApplicantWrittenExamAnswerDialog";
import ApplicantOralExamAnswerDialog from "./ApplicantOralExamAnswerDialog";
import ForwardedBackReasonDialog from "./ForwardedBackReasonDialog";
import RequisitionDetailsDialog from "./RequisitionDetailsDialog";
// import UserResume from "../../profile/UserResume";
const ViewAppealDialog = (props) => {
  const {
    user,
    loading,
    appeal,
    viewAppeal,
    setViewAppeal,
    getAppealDetails,
    appealDetails,
    entitySectorName,
    deleteFirstLevelAppealDecision,
    deleteSecondLevelAppealDecision,
    getApplicantWrittenResult,
    getApplicantOralResult,
    appealCategory,
    userType,
    closeAppeal,
    getRequisition,
    getUserDetails,
  } = props;
  const scrollRef = useRef(null);
  const [showAppealResolved, setAppealResolved] = useState(false);
  const [makeAppealFLDecision, setMakeAppealFLDecision] = useState(false);
  const [makeAppealSLDecision, setMakeAppealSLDecision] = useState(false);
  const [resolveAppeal, setResolveAppeal] = useState(false);
  const [confirmAppealResolved, setConfirmAppealResolved] = useState(false);

  const [firstLevelAppeal, setFirstLevelAppeal] = useState(null);
  const [secondLevelAppeal, setSecondLevelAppeal] = useState(null);
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [showForwardedBackReason, setShowForwardedBackReason] = useState(false);
  const [confirmDeleteFirstLevelDecision, setConfirmDeleteFirstLevelDecision] =
    useState(false);
  const [showApplicantWrittenExamAnswers, setShowApplicantWrittenExamAnswers] =
    useState(false);

  const [showApplicantOralExamAnswers, setShowApplicantOralExamAnswers] =
    useState(false);
  const [appealedEntitySectorId, setAppealedEntitySectorId] = useState("");

  const [NPSCEntitySectorId, setNPSCEntitySectorId] = useState(
    "0302000000-060300000000000000-07"
  );
  const [showRequisitionDetails, setShowRequisitionDetails] = useState(false);
  const [selectedRequisition, setSelectedRequisition] = useState(null);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);

  const [
    confirmDeleteSecondLevelDecision,
    setConfirmDeleteSecondLevelDecision,
  ] = useState(false);
  const { hasPermissions } = useRequiredAuth();
  useEffect(
    () => {
      getAppealDetails(
        appeal.applicationId,
        appeal.appealTypeIdL1 || appeal.appealTypeId
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appeal]
  );

  useEffect(
    () => {
      const firstLevelAppeal = appealDetails.find(({ level }) => level === 1);
      const secondLevelAppeal = appealDetails.find(({ level }) => level === 2);
      setAppealedEntitySectorId(
        firstLevelAppeal?.advertisement?.requisition?.position?.unit
          ?.entitySector?.id || ""
      );

      setFirstLevelAppeal(firstLevelAppeal);
      setSecondLevelAppeal(secondLevelAppeal);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appealDetails]
  );

  useEffect(() => {
    if (scrollRef.current) {
      const scrollDiv = scrollRef.current;
      scrollDiv.scrollTop = scrollDiv?.scrollHeight;
    }
  }, []);

  const onClose = () => {
    setViewAppeal(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open1 = Boolean(anchorEl1);
  const handleOpenMenu1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleCloseMenu1 = () => {
    setAnchorEl1(null);
  };
  const handleOpenCurrentProfile = () => {
    setShowCurrentProfile(true);
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={viewAppeal}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-4">
              <h5>
                {firstLevelAppeal && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <small className="text-primary fw-bold text-truncate">
                          {textCapitalized(
                            firstLevelAppeal?.user?.firstName || ""
                          )}{" "}
                          {textCapitalized(
                            firstLevelAppeal?.user?.lastName || ""
                          )}
                        </small>{" "}
                      </div>
                      <div className="col-12">
                        <small
                          className="fw-bold text-truncate text-dark"
                          style={{ fontSize: "12px" }}
                        >
                          {textCapitalized(
                            firstLevelAppeal?.advertisement?.requisition
                              ?.position?.unit?.entitySector?.entity?.name
                          )}
                        </small>
                        <span
                          style={{ fontSize: "12px" }}
                          className="fas fa-angle-right ml-1"
                        ></span>
                        <small
                          className="ml-1 fw-bold text-truncate"
                          style={{ fontSize: "12px" }}
                        >
                          {textCapitalized(
                            firstLevelAppeal?.advertisement?.requisition
                              ?.position?.unit?.name
                          )}
                        </small>
                      </div>
                    </div>
                  </>
                )}
              </h5>
            </div>
            <div className="col-8">
              <div className="row">
                <div className="col-10">
                  <div className="d-flex justify-content-end  mt-2">
                    {hasPermissions([
                      "IS_NPSC",
                      "IS_NPSC_APPROVER",
                      "IS_HR",
                      "IS_CBM",
                      "IS_DAF",
                    ]) &&
                      firstLevelAppeal &&
                      firstLevelAppeal &&
                      firstLevelAppeal.typeId === 3 && (
                        <button
                          onClick={() =>
                            getApplicantOralResult(
                              firstLevelAppeal.applicationId,
                              setShowApplicantOralExamAnswers
                            )
                          }
                          className="btn btn-outline-success text-capitalize btn-sm mr-3"
                          type="button"
                        >
                          View answer sheet
                        </button>
                      )}

                    {hasPermissions([
                      "IS_NPSC",
                      "IS_NPSC_APPROVER",
                      "IS_HR",
                      "IS_CBM",
                      "IS_DAF",
                    ]) &&
                      firstLevelAppeal &&
                      firstLevelAppeal &&
                      firstLevelAppeal.typeId === 2 && (
                        <button
                          onClick={() =>
                            getApplicantWrittenResult(
                              firstLevelAppeal.applicationId,
                              setShowApplicantWrittenExamAnswers
                            )
                          }
                          className="btn btn-outline-success text-capitalize btn-sm mr-3"
                          type="button"
                        >
                          View answer sheet
                        </button>
                      )}
                    {appeal && firstLevelAppeal && (
                      <button
                        type="button"
                        onClick={() => {
                          getRequisition(
                            firstLevelAppeal?.advertisement?.requisition?.id,
                            setShowRequisitionDetails,
                            setSelectedRequisition
                          );
                        }}
                        className="btn btn-sm btn-outline-dark  text-capitalize mr-3"
                      >
                        Job Profile
                      </button>
                    )}
                    {appeal && firstLevelAppeal && (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            setShowApplicationCv(true);
                          }}
                          className="btn btn-sm  btn-outline-primary text-capitalize mr-3"
                        >
                          Application CV
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            getUserDetails(
                              firstLevelAppeal.user.id,
                              handleOpenCurrentProfile
                            );
                          }}
                          className="btn btn-sm  btn-outline-primary text-capitalize "
                        >
                          {loading ? "Wait...." : "Current CV"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-2">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {makeAppealSLDecision && userType !== "ADMIN" && (
            <SecondLevelAppealDecisionDialog
              makeAppealSLDecision={makeAppealSLDecision}
              setMakeAppealSLDecision={setMakeAppealSLDecision}
              secondLevelAppeal={secondLevelAppeal}
            />
          )}
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div
              ref={scrollRef}
              className="pl-3 pr-3 pb-3 m-2"
              style={{
                position: "relative",
                height: "400px",
                overflow: "auto",
              }}
            >
              <PreLoader isLoading={loading} />
              {firstLevelAppeal &&
                appeal &&
                appeal.applicantRejectionReason && (
                  <div className="w-100  float-start">
                    <div className="d-flex flex-row justify-content-start mt-0 mr-3 w-100 float-start">
                      <div
                        className="card  w-100"
                        style={{
                          backgroundColor: "#fff",
                          borderLeft: "2px solid #ffc107",
                          borderLeft: "2px solid #ffc107",
                          borderRadius: "4px",
                        }}
                      >
                        <div
                          className="card-header"
                          style={{
                            background: "#6c757d",
                            color: " #ffff",
                          }}
                        >
                          Application rejection reason
                        </div>
                        <div className="card-body w-100">
                          <div
                            className="small p-0 ms-2 mb-0 rounded-3"
                            dangerouslySetInnerHTML={{
                              __html:
                                appeal.reason ||
                                appeal.applicantRejectionReason,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {firstLevelAppeal && (
                <div className="w-100  mt-2 float-start">
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <span
                      className="badge rounded-pill badge-primary px-3 fw-bold"
                      style={{
                        // backgroundColor: "#f5f6f7"
                        backgroundColor: "#dfe7f6",
                        color: " #2c58a0",
                      }}
                    >
                      First level Appeal
                    </span>
                  </div>

                  <div className="d-flex flex-row justify-content-start mt-4 w-100">
                    <div className="w-100">
                      <div
                        className="card rounded-3  w-100"
                        style={{
                          background: "rgba(0, 99, 207, 0.08)",
                          borderColor: "rgba(0, 99, 207, 0.08)",
                        }}
                      >
                        <div
                          className="card-header"
                          style={{
                            background: "rgb(223, 231, 246)",
                            color: " #2c58a0",
                          }}
                        >
                          <div className="row">
                            <div className="col-6">
                              <small className="small card-title fw-bold text-truncate">
                                Appealed on {appeal && appeal.subject}
                                <small className="ml-2 fw-bold text-truncate text-dark">
                                  @
                                  {
                                    firstLevelAppeal?.advertisement?.requisition
                                      ?.position?.name
                                  }
                                  {!!appeal.isOverdueAppealforwardedBack &&
                                    !!appeal.forwardedBackReason && (
                                      <span className="ml-2">
                                        {" "}
                                        |
                                        <button
                                          onClick={() => {
                                            handleCloseMenu1();
                                            setShowForwardedBackReason(true);
                                          }}
                                          className=" btn btn-link text-primary font-weight-light"
                                        >
                                          Sent 1 <super>st</super> Reason
                                        </button>
                                      </span>
                                    )}
                                </small>
                              </small>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-end ">
                                {(hasPermissions([
                                  "IS_HR",
                                  "IS_RECRUITMENT_MEMBER",
                                ]) ||
                                  (appealCategory === "OverdueAppeals" &&
                                    ((hasPermissions(["IS_NPSC"]) &&
                                      NPSCEntitySectorId !==
                                        appealedEntitySectorId) ||
                                      (hasPermissions([
                                        "IS_RECRUITMENT_ADMIN",
                                      ]) &&
                                        NPSCEntitySectorId ===
                                          appealedEntitySectorId)))) &&
                                  firstLevelAppeal &&
                                  !firstLevelAppeal.decision &&
                                  firstLevelAppeal.statusId === 1 &&
                                  userType !== "ADMIN" && (
                                    <a
                                      onClick={() =>
                                        setMakeAppealFLDecision(true)
                                      }
                                      className="btn  btn-primary text-capitalize btn-sm mr-3"
                                      href="#!"
                                    >
                                      Make Decision
                                    </a>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card-body"
                          style={{
                            // backgroundColor: "#f5f6f7"
                            background: "rgb(223, 231, 246)",
                          }}
                        >
                          <p
                            className="small p-0 ms-2 mb-0 rounded-3"
                            dangerouslySetInnerHTML={{
                              __html: firstLevelAppeal.message,
                            }}
                          ></p>

                          <p
                            className="small text-muted mb-0 rounded-3 mt-2 float-end"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            {moment(firstLevelAppeal.createOn).format("lll")}
                          </p>

                          <div className="w-100  mt-0 float-start">
                            {firstLevelAppeal && firstLevelAppeal.decision && (
                              <>
                                {/* <div className="d-flex flex-row justify-content-center mt-4">
                                  <span className="badge rounded-pill badge-success ml-3 fw-bold">
                                    First level decision
                                  </span>
                                </div> */}
                                <div className="d-flex flex-row justify-content-start ml-3 mt-4 mr-3 w-100 float-start">
                                  <div
                                    className="card  w-100"
                                    style={{
                                      backgroundColor: "#fff",
                                      borderLeft: "2px solid #3f51b5",
                                      borderLeft: "2px solid #3f51b5",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "#fff",
                                      }}
                                    >
                                      <div className="row">
                                        <div className="col-6">
                                          <small className="small card-title text-primary fw-bold text-truncate">
                                            {textCapitalized(
                                              firstLevelAppeal?.respondedEntitySectorName ||
                                                ""
                                            )}
                                          </small>

                                          <small
                                            style={{
                                              fontSize: "11px",
                                            }}
                                            className="float-end w-100 fw-bold"
                                          >
                                            Decision taken by:
                                            {textCapitalized(
                                              firstLevelAppeal?.decisionMaker
                                                ?.firstName
                                            )}{" "}
                                            {textCapitalized(
                                              firstLevelAppeal?.decisionMaker
                                                ?.lastName
                                            )}
                                          </small>
                                        </div>
                                        <div className="col-6">
                                          <div className="d-flex justify-content-end ">
                                            {((appealCategory ===
                                              "OverdueAppeals" &&
                                              hasPermissions(["IS_NPSC"])) ||
                                              hasPermissions([
                                                "IS_HR",
                                                "IS_RECRUITMENT_MEMBER",
                                              ])) &&
                                              user &&
                                              firstLevelAppeal &&
                                              firstLevelAppeal.statusId === 2 &&
                                              firstLevelAppeal.level === 1 &&
                                              !firstLevelAppeal.isStatusConfirmed &&
                                              userType !== "ADMIN" &&
                                              user.id ===
                                                firstLevelAppeal.decisionBy && (
                                                <>
                                                  <IconButton
                                                    size="small"
                                                    onClick={handleOpenMenu}
                                                    // onMouseMove={handleOpenMenu}
                                                    aria-label="settings"
                                                  >
                                                    <Badge color="info">
                                                      <span className="material-icons">
                                                        more_vert
                                                      </span>
                                                    </Badge>
                                                  </IconButton>
                                                  <Menu
                                                    anchorEl={anchorEl}
                                                    id="account-menu"
                                                    open={open}
                                                    onClose={handleCloseMenu}
                                                    PaperProps={{
                                                      elevation: 0,
                                                      sx: {
                                                        overflow: "auto",
                                                        filter:
                                                          "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

                                                        mt: 1.5,
                                                        "& .MuiAvatar-root": {
                                                          width: 32,
                                                          height: 32,
                                                          ml: -0.5,
                                                          mr: 2,
                                                        },
                                                        "&:before": {
                                                          content: '""',
                                                          display: "block",
                                                          position: "absolute",
                                                          top: 0,
                                                          right: 15,
                                                          width: 10,
                                                          height: 10,
                                                          bgcolor:
                                                            "background.paper",
                                                          transform:
                                                            "translateY(-50%) rotate(45deg)",
                                                          zIndex: 0,
                                                        },
                                                      },
                                                    }}
                                                    transformOrigin={{
                                                      horizontal: "right",
                                                      vertical: "top",
                                                    }}
                                                    anchorOrigin={{
                                                      horizontal: "right",
                                                      vertical: "bottom",
                                                    }}
                                                  >
                                                    <span>
                                                      <MenuItem
                                                        onClick={() => {
                                                          handleCloseMenu();
                                                          setMakeAppealFLDecision(
                                                            true
                                                          );
                                                        }}
                                                        className="text-dark font-weight-light"
                                                      >
                                                        Edit
                                                      </MenuItem>
                                                    </span>
                                                    <span>
                                                      <Divider className="my-1" />
                                                      <MenuItem
                                                        onClick={() => {
                                                          handleCloseMenu();
                                                          setConfirmDeleteFirstLevelDecision(
                                                            true
                                                          );
                                                        }}
                                                        className="text-danger font-weight-light"
                                                      >
                                                        Delete
                                                      </MenuItem>
                                                    </span>
                                                  </Menu>
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="card-body"
                                      style={{
                                        backgroundColor: "#fff",
                                      }}
                                    >
                                      <p className="small p-0 ms-0 mb-0 rounded-3">
                                        {" "}
                                        <span
                                          className={`badge badge-pill mr-3 ${
                                            firstLevelAppeal.isAppealValid
                                              ? "badge-success"
                                              : "badge-danger"
                                          }`}
                                          style={{
                                            backgroundColor:
                                              firstLevelAppeal.isAppealValid
                                                ? "#d6f0e0"
                                                : "#f9e1e5",
                                            color:
                                              firstLevelAppeal.isAppealValid
                                                ? "#0d6832"
                                                : "#af233a",
                                          }}
                                        >
                                          Your appeal is{" "}
                                          {!firstLevelAppeal.isAppealValid &&
                                            "not"}{" "}
                                          valid
                                        </span>
                                        <p className="mt-2">
                                          {firstLevelAppeal.decision}
                                        </p>
                                      </p>

                                      <div className="row mt-0">
                                        <div className="col-12">
                                          <p className="small mt-3 mb-0 rounded-3 text-primary float-end ">
                                            {firstLevelAppeal &&
                                              firstLevelAppeal.statusId === 3 &&
                                              !secondLevelAppeal && (
                                                <span
                                                  className="badge rounded-pill badge-success mr-3 fw-bold"
                                                  style={{
                                                    backgroundColor: "#dfe7f6",
                                                    color: " #2c58a0",
                                                  }}
                                                >
                                                  Appeal accepted by{" "}
                                                  {textCapitalized(
                                                    firstLevelAppeal.user
                                                      .firstName
                                                  )}{" "}
                                                  {textCapitalized(
                                                    firstLevelAppeal.user
                                                      .lastName
                                                  )}
                                                </span>
                                              )}

                                            <span
                                              style={{
                                                fontSize: "11px",
                                              }}
                                            >
                                              {moment(
                                                firstLevelAppeal.decisionOn
                                              ).format("lll")}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {secondLevelAppeal && (
                <div className="w-100  mt-3 float-start">
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <span
                      className="badge rounded-pill badge-success ml-3 fw-bold"
                      style={{
                        backgroundColor: "#d6f0e0",
                        color: "#0d6832",
                      }}
                    >
                      Second level Appeal
                    </span>
                  </div>

                  <div className="d-flex flex-row justify-content-start mt-4  w-100 float-start">
                    <div className="w-100">
                      <div
                        className="card rounded-3"
                        style={{
                          // backgroundColor: "#f5f6f7"
                          background: "#d6f0e0",
                          borderColor: "#d6f0e0",
                        }}
                      >
                        <div
                          className="card-header"
                          style={{
                            // backgroundColor: "#f5f6f7"
                            background: "#d6f0e0",
                            color: " #2c58a0",
                          }}
                        >
                          <div className="row">
                            <div className="col-8">
                              <small className="small card-title fw-bold text-truncate">
                                Re-appealed on {appeal && appeal.subject}
                              </small>
                            </div>
                            <div className="col-4">
                              {secondLevelAppeal &&
                                !secondLevelAppeal.decision &&
                                secondLevelAppeal.statusId === 1 &&
                                appeal &&
                                ((hasPermissions(["IS_NPSC"]) &&
                                  NPSCEntitySectorId !==
                                    appealedEntitySectorId) ||
                                  (hasPermissions(["IS_RECRUITMENT_ADMIN"]) &&
                                    NPSCEntitySectorId ===
                                      appealedEntitySectorId)) &&
                                userType !== "ADMIN" && (
                                  <div className=" d-flex justify-content-end ">
                                    <a
                                      onClick={() =>
                                        setMakeAppealSLDecision(true)
                                      }
                                      className="btn  btn-success text-capitalize"
                                      href="#!"
                                    >
                                      Make Decision
                                    </a>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <p
                            className="small p-0 ms-2 mb-0 rounded-3"
                            dangerouslySetInnerHTML={{
                              __html: secondLevelAppeal.message,
                            }}
                          ></p>

                          <p
                            className="small text-muted mb-0 rounded-3 mt-2 float-end"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            {moment(secondLevelAppeal.createOn).format("lll")}
                          </p>

                          <div className="w-100  mt-0 float-start">
                            {secondLevelAppeal &&
                              secondLevelAppeal.decision && (
                                <>
                                  <div className="d-flex flex-row justify-content-start ml-3 mt-4 mr-3 w-100 float-start">
                                    <div
                                      className="card  w-100"
                                      style={{
                                        backgroundColor: "#fff",
                                        borderLeft: "2px solid #3f51b5",
                                        borderLeft: "2px solid #3f51b5",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      <div
                                        className="card-header"
                                        style={{
                                          backgroundColor: "#fff",
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col-6">
                                            <small className="small card-title text-primary fw-bold">
                                              {textCapitalized(
                                                secondLevelAppeal?.respondedEntitySectorName ||
                                                  ""
                                              )}
                                            </small>

                                            <small
                                              style={{
                                                fontSize: "11px",
                                              }}
                                              className="float-end w-100 fw-bold"
                                            >
                                              Decision taken by:
                                              {textCapitalized(
                                                secondLevelAppeal.decisionMaker
                                                  .firstName
                                              )}
                                              {textCapitalized(
                                                secondLevelAppeal.decisionMaker
                                                  .lastName
                                              )}
                                            </small>
                                          </div>
                                          <div className="col-6">
                                            <div className="d-flex justify-content-end ">
                                              {hasPermissions(["IS_HR"]) &&
                                                secondLevelAppeal &&
                                                secondLevelAppeal.isAllowedToResolveAppeal &&
                                                !secondLevelAppeal.resolvedMessage &&
                                                userType !== "ADMIN" && (
                                                  <a
                                                    onClick={() =>
                                                      setResolveAppeal(true)
                                                    }
                                                    className="btn ml-2 float-right btn-success btn-sm text-capitalize "
                                                    href="#!"
                                                  >
                                                    Resolve now!
                                                  </a>
                                                )}

                                              {((hasPermissions(["IS_NPSC"]) &&
                                                NPSCEntitySectorId !==
                                                  appealedEntitySectorId) ||
                                                (hasPermissions([
                                                  "IS_RECRUITMENT_ADMIN",
                                                ]) &&
                                                  NPSCEntitySectorId ===
                                                    appealedEntitySectorId)) &&
                                                secondLevelAppeal &&
                                                secondLevelAppeal.statusId ===
                                                  2 &&
                                                secondLevelAppeal.isAllowedToResolveAppeal &&
                                                secondLevelAppeal.resolvedMessage &&
                                                userType !== "ADMIN" && (
                                                  <a
                                                    onClick={() =>
                                                      setConfirmAppealResolved(
                                                        true
                                                      )
                                                    }
                                                    className="btn btn-primary mr-2 btn-sm text-capitalize float-end"
                                                    href="#!"
                                                  >
                                                    Close an appeal
                                                  </a>
                                                )}

                                              {hasPermissions([
                                                "IS_NPSC",
                                                "IS_NPSC_APPROVER",
                                                "IS_HR",
                                                "IS_CBM",
                                                "IS_DAF",
                                                "IS_RECRUITMENT_ADMIN",
                                              ]) &&
                                                secondLevelAppeal &&
                                                secondLevelAppeal.isAllowedToResolveAppeal &&
                                                secondLevelAppeal.resolvedMessage && (
                                                  <a
                                                    onClick={() =>
                                                      setAppealResolved(true)
                                                    }
                                                    className="btn float-right  btn-success btn-sm text-capitalize "
                                                    href="#!"
                                                  >
                                                    View resolution
                                                  </a>
                                                )}
                                              {((hasPermissions([
                                                "IS_NPSC",
                                                "IS_NPSC_APPROVER",
                                              ]) &&
                                                NPSCEntitySectorId !==
                                                  appealedEntitySectorId) ||
                                                (hasPermissions([
                                                  "IS_RECRUITMENT_ADMIN",
                                                ]) &&
                                                  NPSCEntitySectorId ===
                                                    appealedEntitySectorId)) &&
                                                secondLevelAppeal &&
                                                user &&
                                                secondLevelAppeal.statusId ===
                                                  2 &&
                                                secondLevelAppeal.level === 2 &&
                                                !secondLevelAppeal.isStatusConfirmed &&
                                                userType !== "ADMIN" &&
                                                user.id ===
                                                  secondLevelAppeal.decisionBy && (
                                                  <>
                                                    <IconButton
                                                      size="small"
                                                      onClick={handleOpenMenu}
                                                      // onMouseMove={handleOpenMenu}
                                                      aria-label="settings"
                                                    >
                                                      <Badge color="info">
                                                        <span className="material-icons">
                                                          more_vert
                                                        </span>
                                                      </Badge>
                                                    </IconButton>
                                                    <Menu
                                                      anchorEl={anchorEl}
                                                      id="account-menu"
                                                      open={open}
                                                      onClose={handleCloseMenu}
                                                      PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                          overflow: "auto",
                                                          filter:
                                                            "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

                                                          mt: 1.5,
                                                          "& .MuiAvatar-root": {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 2,
                                                          },
                                                          "&:before": {
                                                            content: '""',
                                                            display: "block",
                                                            position:
                                                              "absolute",
                                                            top: 0,
                                                            right: 15,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor:
                                                              "background.paper",
                                                            transform:
                                                              "translateY(-50%) rotate(45deg)",
                                                            zIndex: 0,
                                                          },
                                                        },
                                                      }}
                                                      transformOrigin={{
                                                        horizontal: "right",
                                                        vertical: "top",
                                                      }}
                                                      anchorOrigin={{
                                                        horizontal: "right",
                                                        vertical: "bottom",
                                                      }}
                                                    >
                                                      <span>
                                                        <MenuItem
                                                          onClick={() => {
                                                            handleCloseMenu();
                                                            setMakeAppealSLDecision(
                                                              true
                                                            );
                                                          }}
                                                          className="text-dark font-weight-light"
                                                        >
                                                          Edit
                                                        </MenuItem>
                                                      </span>
                                                      <span>
                                                        <Divider className="my-1" />
                                                        <MenuItem
                                                          onClick={() => {
                                                            handleCloseMenu();
                                                            setConfirmDeleteSecondLevelDecision(
                                                              true
                                                            );
                                                          }}
                                                          className="text-danger font-weight-light"
                                                        >
                                                          Delete
                                                        </MenuItem>
                                                      </span>
                                                    </Menu>
                                                  </>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body">
                                        <p className="small p-0 ms-1 mb-0 rounded-3">
                                          <span
                                            className={`badge badge-pill mr-3 ${
                                              secondLevelAppeal.isAppealValid
                                                ? "badge-success"
                                                : "badge-danger"
                                            } `}
                                            style={{
                                              backgroundColor:
                                                secondLevelAppeal.isAppealValid
                                                  ? "#d6f0e0"
                                                  : "#f9e1e5",
                                              color:
                                                secondLevelAppeal.isAppealValid
                                                  ? "#0d6832"
                                                  : "#af233a",
                                            }}
                                          >
                                            Your appeal is{" "}
                                            {!secondLevelAppeal.isAppealValid &&
                                              "not"}{" "}
                                            valid
                                          </span>
                                          <p className="mt-2">
                                            {secondLevelAppeal.decision}
                                          </p>
                                        </p>

                                        <p className="small mt-1 mb-0 rounded-3 text-primary float-end ">
                                          <span
                                            style={{
                                              fontSize: "11px",
                                            }}
                                          >
                                            {moment(
                                              secondLevelAppeal.decisionOn
                                            ).format("lll")}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {secondLevelAppeal && secondLevelAppeal.statusId === 4 && (
              <div>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <span className="badge rounded-pill ap-badge-danger ml-3 fw-bold">
                    Appeal closed
                  </span>
                </div>
              </div>
            )}

            {firstLevelAppeal &&
              (firstLevelAppeal.statusId === 4 ||
                firstLevelAppeal.statusId === 3) &&
              !secondLevelAppeal && (
                <div>
                  <div className="d-flex flex-row justify-content-center mt-4">
                    <span className="badge rounded-pill ap-badge-danger ml-3 fw-bold">
                      Appeal closed
                    </span>
                  </div>
                </div>
              )}

            {secondLevelAppeal &&
              secondLevelAppeal.statusId === 2 &&
              secondLevelAppeal.isAllowedToResolveAppeal &&
              !secondLevelAppeal.resolvedMessage && (
                <div className="d-flex flex-row justify-content-center mt-4">
                  <span className="badge rounded-pill badge-warning mr-3 px-2 fw-bold">
                    Waiting for resolution..
                  </span>
                </div>
              )}

            {secondLevelAppeal &&
              secondLevelAppeal.statusId === 2 &&
              secondLevelAppeal.isAllowedToResolveAppeal &&
              secondLevelAppeal.resolvedMessage && (
                <div className="d-flex flex-row justify-content-center mt-4">
                  <span className="badge rounded-pill badge-success mr-3 px-2 fw-bold">
                    Resolved
                  </span>
                  <span className="badge rounded-pill badge-warning mr-3 px-2 fw-bold">
                    Waiting for NPSC to close an appeal.
                  </span>
                </div>
              )}

            {firstLevelAppeal &&
              firstLevelAppeal.statusId === 2 &&
              firstLevelAppeal.level === 1 &&
              !firstLevelAppeal.isStatusConfirmed && (
                <div>
                  <div className="d-flex flex-row justify-content-center mt-4">
                    <span className="badge rounded-pill ap-badge-info ml-3 px-2 fw-bold">
                      Waiting for confirmation..
                    </span>
                  </div>
                </div>
              )}

            {secondLevelAppeal &&
              secondLevelAppeal.statusId === 2 &&
              secondLevelAppeal.level === 2 &&
              !secondLevelAppeal.isStatusConfirmed && (
                <div>
                  <div className="d-flex flex-row justify-content-center mt-4">
                    <span className="badge rounded-pill ap-badge-info ml-3 px-2 fw-bold">
                      Waiting for confirmation..
                    </span>
                  </div>
                </div>
              )}
            {firstLevelAppeal &&
              firstLevelAppeal.statusId === 2 &&
              !!firstLevelAppeal.isStatusConfirmed &&
              firstLevelAppeal.level === 1 &&
              !secondLevelAppeal && (
                <span className="d-flex flex-row justify-content-center mt-4">
                  <span className="badge rounded-pill ap-badge-warning ml-3 px-2 fw-bold">
                    {" "}
                    Waiting for Appealer to close an appeal
                  </span>
                </span>
              )}
            {showForwardedBackReason && (
              <ForwardedBackReasonDialog
                showForwardedBackReason={showForwardedBackReason}
                setShowForwardedBackReason={setShowForwardedBackReason}
                selectedAppeal={appeal}
              />
            )}
            {/* {showCurrentProfile && (
              <UserResume
                showProfileDialog={showCurrentProfile}
                setShowProfileDialog={setShowCurrentProfile}
              />
            )} */}
            {showApplicationCv && firstLevelAppeal && (
              <ApplicationCv
                showApplicationCv={showApplicationCv}
                setShowApplicationCv={setShowApplicationCv}
                application={{
                  id: appeal.applicationId,
                  idNumber: firstLevelAppeal?.user?.idNumber || "",
                  phoneNumber: firstLevelAppeal?.user?.phoneNumber || "",
                  userId: firstLevelAppeal?.user?.id || "",
                  lastName: firstLevelAppeal?.user?.lastName || "",
                  firstName: firstLevelAppeal?.user?.firstName || "",
                  email: firstLevelAppeal?.user?.email || "",
                }}
              />
            )}

            {makeAppealFLDecision && (
              <FirstLevelAppealDecisionDialog
                makeAppealFLDecision={makeAppealFLDecision}
                setMakeAppealFLDecision={setMakeAppealFLDecision}
                firstLevelAppeal={firstLevelAppeal}
              />
            )}

            {resolveAppeal && secondLevelAppeal && (
              <AppealResolveDialog
                resolveAppeal={resolveAppeal}
                setResolveAppeal={setResolveAppeal}
                secondLevelAppeal={secondLevelAppeal}
              />
            )}

            {showApplicantWrittenExamAnswers && (
              <ApplicantWrittenExamAnswerDialog
                showApplicantWrittenExamAnswers={
                  showApplicantWrittenExamAnswers
                }
                setShowApplicantWrittenExamAnswers={
                  setShowApplicantWrittenExamAnswers
                }
              />
            )}

            {showApplicantOralExamAnswers && (
              <ApplicantOralExamAnswerDialog
                showApplicantOralExamAnswers={showApplicantOralExamAnswers}
                setShowApplicantOralExamAnswers={
                  setShowApplicantOralExamAnswers
                }
              />
            )}

            {confirmDeleteFirstLevelDecision && (
              <ConfirmationDialog
                confirmationDialog={confirmDeleteFirstLevelDecision}
                message={`Are you sure you want to delete the appeal decision?`}
                setConfirmationDialog={setConfirmDeleteFirstLevelDecision}
                noPreFormat={true}
                onYes={() => {
                  deleteFirstLevelAppealDecision(
                    firstLevelAppeal.id,
                    setConfirmDeleteFirstLevelDecision
                  );
                }}
              />
            )}

            {confirmDeleteSecondLevelDecision && (
              <ConfirmationDialog
                confirmationDialog={confirmDeleteSecondLevelDecision}
                message={`Are you sure you want to delete the appeal decision?`}
                setConfirmationDialog={setConfirmDeleteSecondLevelDecision}
                noPreFormat={true}
                onYes={() => {
                  deleteSecondLevelAppealDecision(
                    secondLevelAppeal.id,
                    setConfirmDeleteSecondLevelDecision
                  );
                }}
              />
            )}

            {confirmAppealResolved && secondLevelAppeal && appeal && (
              <ConfirmationDialog
                confirmationDialog={confirmAppealResolved}
                message={`Are you sure you want to close an appeal?`}
                setConfirmationDialog={setConfirmAppealResolved}
                onYes={() => {
                  closeAppeal(
                    {
                      appealId: secondLevelAppeal.id,
                    },
                    setConfirmAppealResolved
                  );
                }}
              />
            )}

            {showRequisitionDetails && (
              <RequisitionDetailsDialog
                showRequisitionDetails={showRequisitionDetails}
                setShowRequisitionDetails={setShowRequisitionDetails}
                selectedRequisition={selectedRequisition}
              />
            )}

            {showAppealResolved && (
              <ViewAppealResolved
                showAppealResolved={showAppealResolved}
                setAppealResolved={setAppealResolved}
                contents={
                  <>
                    {firstLevelAppeal && secondLevelAppeal && (
                      <div className="w-100  float-start">
                        <div className="d-flex flex-row justify-content-start mt-4 mr-3 w-100 float-start">
                          <div
                            className="card  w-100"
                            style={{
                              backgroundColor: "#fff",
                              borderLeft: "2px solid #3f51b5",
                              borderLeft: "2px solid #3f51b5",
                              borderRadius: "4px",
                            }}
                          >
                            <div
                              className="card-header"
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              <small className="small card-title text-primary fw-bold text-truncate">
                                {textCapitalized(
                                  firstLevelAppeal?.respondedEntitySectorName ||
                                    ""
                                )}
                              </small>

                              <small
                                style={{
                                  fontSize: "11px",
                                }}
                                className="float-end w-100 fw-bold"
                              >
                                Resolved by:
                                {textCapitalized(
                                  secondLevelAppeal.resolver.firstName
                                )}{" "}
                                {textCapitalized(
                                  secondLevelAppeal.resolver.lastName
                                )}
                              </small>
                            </div>
                            <div className="card-body w-100">
                              <p
                                className="small p-0 ms-2 mb-0 rounded-3"
                                dangerouslySetInnerHTML={{
                                  __html: secondLevelAppeal.resolvedMessage,
                                }}
                              ></p>

                              <p className="small mt-2 mb-0 rounded-3 text-primary float-end ">
                                <span
                                  style={{
                                    fontSize: "11px",
                                  }}
                                >
                                  {moment(secondLevelAppeal.resolvedOn).format(
                                    "lll"
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                }
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, appeal, appealDetails }) => {
  return {
    user,
    loading,
    appeal,
    appealDetails,
  };
};
export default connect(mapStateToProps, {
  getAppealDetails,
  deleteFirstLevelAppealDecision,
  deleteSecondLevelAppealDecision,
  getApplicantWrittenResult,
  getApplicantOralResult,
  closeAppeal,
  getRequisition,
  // getUserDetails,
})(ViewAppealDialog);
