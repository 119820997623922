import { Box } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getScheduledExams } from "../../../store/admin/actions";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";

const ScheduledExam = (props) => {
  const { loading, scheduledExams, getScheduledExams } = props;
  const [loadingExams, setLoadingExams] = useState(true);

  useEffect(() => {
    if (scheduledExams.length === 0) {
      getScheduledExams((status, data) => {
        setLoadingExams(status);
        // if (!status && data) setFilteredExams(...data);
      });
    } else setLoadingExams(false);
    return () => setLoadingExams(true);
  }, []);

  //PAGINATION
  const [searchInput, setSearchInput] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };

  const doSearch = (data, value) => {
    if (value.length === 0) return data;
    return data.filter(
      ({
        entityName,
        entityAcronym,
        unitName,
        positionName,
        writtenExamDate,
        writtenExamStartTime,
        writtenExamEndTime,
        oralExamDate,
        oralExamTime,
      }) =>
        (
          (entityName || "") +
          (entityAcronym || "") +
          (unitName || "") +
          (positionName || "") +
          (writtenExamDate || "") +
          (writtenExamStartTime || "") +
          (writtenExamEndTime || "") +
          (oralExamDate || "") +
          (oralExamTime || "")
        )
          .toLowerCase()
          .includes(value.toLowerCase())
    );
  };

  const FILTERED_DATA = doSearch(scheduledExams ?? [], searchInput);
  const NUMBER_OF_PAGES = Math.ceil(FILTERED_DATA.length / itemsPerPage);
  const DATA_PER_PAGE = paginate(FILTERED_DATA);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % FILTERED_DATA.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <div
      style={{
        marginLeft: "1em",
        marginRight: "1em",
        backgroundColor: "#fff",
        borderRadius: "0.5rem",
        boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
        MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
        height: "65vh",
        overflowX: "auto",
      }}
    >
      <table className="table table-sm table-hover table-striped fixTableHead">
        <thead
          style={{
            borderLeft: "#ccc 1px solid",
            borderRight: "#ccc 1px solid",
          }}
        >
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col" className="text-left" rowSpan={2}>
              #
            </th>

            <th scope="col" className="text-left" rowSpan={2}>
              <Box
                sx={{ display: { xs: "flex", md: "flex" } }}
                className="align-items-center"
              >
                <span className="mx-2">Institution</span>
                <SearchBox
                  onSearch={setSearchInput}
                  placeholder="Search…"
                  disabled={
                    loading || loadingExams || scheduledExams.length <= 1
                  }
                />
              </Box>
            </th>
            <th scope="col" className="text-left" rowSpan={2}>
              Unit
            </th>
            <th scope="col" className="text-left" rowSpan={2}>
              Position
            </th>
            <th
              scope="col"
              className="text-center"
              colSpan={3}
              style={{
                backgroundColor: "#96abb5",
                borderLeft: "#96abb5 1px solid",
              }}
            >
              Written exam
            </th>
            <th
              scope="col"
              className="text-center"
              colSpan={3}
              style={{ backgroundColor: "#ccc", borderLeft: "#ccc 1px solid" }}
            >
              Oral exam
            </th>
          </tr>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th
              scope="col"
              className="text-center"
              style={{
                backgroundColor: "#96abb5",
                borderLeft: "#96abb5 1px solid",
              }}
            >
              Date
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ backgroundColor: "#96abb5" }}
            >
              Time
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ backgroundColor: "#96abb5" }}
            >
              Status
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ backgroundColor: "#ccc", borderLeft: "#ccc 1px solid" }}
            >
              Date
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ backgroundColor: "#ccc" }}
            >
              Time
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ backgroundColor: "#ccc" }}
            >
              Status
            </th>
          </tr>
        </thead>
        {loadingExams ? (
          <TableSkeleton cols={10} />
        ) : (
          <tbody
            style={{
              borderBottom: "#ccc 1px solid",
              borderLeft: "#ccc 1px solid",
              borderRight: "#ccc 1px solid",
            }}
          >
            {FILTERED_DATA.length ? (
              DATA_PER_PAGE.map((examItem, index) => (
                <tr key={index + examItem.advertisementId}>
                  <td className="text-left">
                    <strong> {index + 1} </strong>
                  </td>
                  <td className="text-left">
                    {examItem.entityName}{" "}
                    {examItem.entityAcronym
                      ? `(${examItem.entityAcronym})`
                      : ""}
                  </td>
                  <td className="text-left text-truncate">
                    {examItem.unitName}
                  </td>
                  <td className="text-left text-truncate">
                    {examItem.positionName}
                  </td>
                  {/* ########################## WRITTEN EXAM ############################# */}
                  {/* DISPLAY DATE OF WRITTEN EXAM----------- */}
                  <td
                    className="text-center"
                    style={{ borderLeft: "#96abb5 1px solid" }}
                  >
                    {examItem.writtenExamDate
                      ? moment(`${examItem.writtenExamDate}`).format("ll")
                      : "-"}
                  </td>
                  {/* DISPLAY TIME OF WRITTEN EXAM----------- */}
                  <td className="text-center">
                    {examItem.writtenExamStartTime
                      ? `${examItem.writtenExamStartTime} - ${examItem.writtenExamEndTime}`
                      : "-"}
                  </td>
                  {/* WRITTEN EXAM STATUS----------- */}
                  <td className="text-center">
                    {examItem.writtenExamDate ? (
                      examItem.isWrittenActive === "Pending" ? (
                        <span className="badge badge-info px-2 fw-bold">
                          Pending
                        </span>
                      ) : (
                        <span className="badge badge-success px-2 fw-bold">
                          To day
                        </span>
                      )
                    ) : (
                      "-"
                    )}
                  </td>

                  {/* ########################## ORAL EXAM ############################# */}
                  {/* DISPLAY DATE OF ORAL EXAM----------- */}
                  <td
                    className="text-center"
                    style={{ borderLeft: "#ccc 1px solid" }}
                  >
                    {examItem.oralExamDate
                      ? moment(`${examItem.oralExamDate}`).format("ll")
                      : "-"}
                  </td>
                  {/* DISPLAY TIME OF WRITTEN EXAM----------- */}
                  <td className="text-center">
                    {examItem.oralExamTime ? `${examItem.oralExamTime}` : "-"}
                  </td>
                  {/* ORAL EXAM STATUS----------- */}
                  <td className="text-center">
                    {examItem.oralExamDate ? (
                      examItem.isOralActive === "Pending" ? (
                        <span className="badge badge-info mr-3 px-2 fw-bold">
                          Pending
                        </span>
                      ) : (
                        <span className="badge badge-success mr-3 px-2 fw-bold">
                          To day
                        </span>
                      )
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10} align="center" className="py-3">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>

      {/* scheduled exams pagination controller ---------------- */}
      {!!NUMBER_OF_PAGES && (
        <div
          className="justify-content-center mt-2"
          style={{ overflow: "hidden" }}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={NUMBER_OF_PAGES}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
            activeLinkClassName="active"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ loading, recruitment }) => {
  return { loading, scheduledExams: recruitment.scheduledExams };
};
export default connect(mapStateToProps, {
  getScheduledExams,
})(ScheduledExam);
