import React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from "@mui/material";

const ListSkeleton = ({
  rows = 5,
  showAvatar = true,
  avatarSize = 30,
  showIcons = true,
  iconSize = 21,
}) => {
  const arrayRows = Array.from(Array(rows).keys());
  return (
    <List
      className="mt-2"
      sx={{
        borderRadius: "8px",
        border: `1px solid #c8c6c6`,
        backgroundColor: "#f9f9f9",
      }}
      dense
    >
      {arrayRows.map((list, index) => (
        <React.Fragment key={list + Math.random()}>
          <ListItem
            dense
            secondaryAction={
              showIcons && (
                <Skeleton
                  variant="rounded"
                  width={iconSize}
                  height={iconSize}
                  // key={list + Math.random()}
                />
              )
            }
          >
            {showAvatar && (
              <ListItemAvatar>
                <Skeleton
                  variant="circular"
                  width={avatarSize}
                  height={avatarSize}
                  // key={list + Math.random()}
                />
              </ListItemAvatar>
            )}

            <ListItemText
              primary={
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.4rem", marginRight: "1.3rem" }}
                  // key={list + Math.random()}
                />
              }
            />
          </ListItem>
          {arrayRows.length > index + 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

ListSkeleton.propTypes = {
  rows: PropTypes.number,
  showIcons: PropTypes.bool,
  iconSize: PropTypes.number,
  showAvatar: PropTypes.bool,
  avatarSize: PropTypes.number,
};

export default ListSkeleton;
