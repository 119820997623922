import admin from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";

import commonTypes from "../common/action-types";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...admin };

export const getRoles = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/admin/roles");

      dispatch({
        type: types.SET_ROLES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPermissions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/admin/permissions");

      dispatch({
        type: types.SET_PERMISSIONS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//modules
export const getModules = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/admin/modules");

      dispatch({
        type: types.SET_MODULES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//saveOrUpdateRole

export const saveOrUpdateRole = (
  formData,
  setFormData,
  setIsEditing,
  setShowRoleForm,
  isEditing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = !isEditing
        ? await axios.post("/api/admin/roles", formData)
        : await axios.put("/api/admin/roles/" + formData.id, formData);

      dispatch({ type: types.END_LOADING });
      showSuccess(!isEditing ? "Saved successfully" : "Updated successfully");

      setFormData({
        id: "",
        name: "",
      });
      setShowRoleForm(false);
      if (isEditing) {
        dispatch({
          type: types.UPDATE_ROLE,
          data,
        });
        setIsEditing(false);
      } else {
        dispatch({
          type: types.ADD_ROLE,
          data,
        });
      }
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveOrUpdatePermission = (
  formData,
  setFormData,
  setShowPermissionForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/admin/permissions", formData);

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      setFormData({
        name: "",
      });
      setShowPermissionForm(false);

      dispatch({
        type: types.ADD_PERMISSION,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const assignRolePermissions = (
  selectedRole,
  formData,
  setFormData,
  rolePermissons,
  setShowAssignPermissionForm,
  setNotAssignedPermissions
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/admin/role-permissions",
        rolePermissons
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      formData.forEach((element) => {
        selectedRole.permissions.push({ name: element.name });
      });
      dispatch({
        type: types.UPDATE_ROLE,
        data: selectedRole,
      });
      setFormData([]);
      setNotAssignedPermissions([]);
      setShowAssignPermissionForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//getModules
export const assignRoleModules = (
  setFormData,
  roleModules,
  setShowAssignModuleForm,
  setNotAssignedModules
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/admin/role-modules", roleModules);

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.UPDATE_ROLE,
        data,
      });
      setFormData([]);
      setNotAssignedModules([]);
      setShowAssignModuleForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteRole = (
  selectedRole,
  setConfirmRemoveRole,
  setSelectedRole
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/admin/roles/" + selectedRole.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_ROLE,
        data: selectedRole,
      });

      setSelectedRole("");
      setConfirmRemoveRole(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deletePermission = (
  selectedPermission,
  setConfirmRemovePermission,
  setSelectedPermission
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/admin/permissions/" + selectedPermission.name
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_PERMISSION,
        data: selectedPermission,
      });

      setSelectedPermission("");
      setConfirmRemovePermission(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//deleteRolePermission

export const deleteRolePermission = (
  selectedPermission,
  selectedRole,
  setConfirmRemovePermission,
  setSelectedPermission
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/admin/delete-role-permissions",
        selectedPermission
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      const tmpPermissions = [...selectedRole.permissions];

      const index = tmpPermissions.findIndex(
        ({ name }) => name === selectedPermission.permission
      );

      tmpPermissions.splice(index, 1);
      selectedRole.permissions = tmpPermissions;

      dispatch({
        type: types.UPDATE_ROLE,
        data: selectedRole,
      });

      setSelectedPermission("");
      setConfirmRemovePermission(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//deleteRoleModule

export const deleteRoleModule = (
  selectedModule,
  selectedRole,
  setConfirmRemoveModule,
  setSelectedModule
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/admin/delete-role-module",
        selectedModule
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      const tmpModules = [...selectedRole.modules];

      const index = tmpModules.findIndex(({ id }) => id === selectedModule.id);

      tmpModules.splice(index, 1);
      selectedRole.modules = tmpModules;

      dispatch({
        type: types.UPDATE_ROLE,
        data: selectedRole,
      });

      setSelectedModule("");
      setConfirmRemoveModule(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//loadYearlyInstitutions

export const loadYearlyInstitutions = (year, callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/admin/recruitment-oversight/${year}`
      );

      dispatch({
        type: types.LOAD_ALL_ACTIVE_ADVERTISEMENT,
        data,
      });
      dispatch({ type: types.END_LOADING });

      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

export const loadEntityAdvertisements = (entityId, callback) => {
  callback(true, []);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/admin/entity-advertisement/${entityId}`
      );

      callback(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false, []);
    }
  };
};

//loadAdvertisementApplicants

export const loadAdvertisementApplicants = (advertisementId, callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/advertisement/applications/${advertisementId}`
      );

      dispatch({
        type: types.LOAD_ADVERTISEMENT_APPLICANTS,
        data: { data, advertisementId },
      });
      dispatch({ type: types.END_LOADING });

      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * loadAllDisabilities
 * @param {*} callback
 * @returns
 */
export const loadAllDisabilities = (callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/disabilities`);
      dispatch({ type: types.LOAD_DISABILITIES, data });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * addNewDisability
 * @param {*} newDisabilityName
 * @param {*} callback
 * @returns
 */
export const addNewDisability = (newDisabilityName, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/disability`, {
        newDisabilityName,
      });
      dispatch({ type: types.LOAD_DISABILITIES, data: data.disabilities });
      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * editDisability
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const editDisability = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/disability`, formData);
      dispatch({ type: types.UPDATE_DISABILITY, data: formData });
      dispatch({ type: types.END_LOADING });
      showSuccess(data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * loadAllDisabilities
 * @param {*} callback
 * @returns
 */
export const loadDisabilityLevels = (callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/admin/disability-levels`);

      dispatch({ type: types.LOAD_DISABILITY_LEVELS, data });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * addNewDisability
 * @param {*} newDisabilityName
 * @param {*} callback
 * @returns
 */
export const addNewDisabilityLevel = (newLevelName, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/disability-level`, {
        newLevelName,
      });
      dispatch({
        type: types.LOAD_DISABILITY_LEVELS,
        data: data.disabilityLevels,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * editDisabilityLevel
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const editDisabilityLevel = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/disability-level`, formData);
      dispatch({ type: types.UPDATE_DISABILITY_LEVEL, data: formData });
      dispatch({ type: types.END_LOADING });
      showSuccess(data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * loadCertificateField
 * @param {*} callback
 * @returns
 */
export const loadCertificateField = (callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/admin/certificate-fields`);

      dispatch({ type: types.LOAD_CERTIFICATE_FIELD, data });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * addNewCertificateField
 * @param {*} newFieldName
 * @param {*} callback
 * @returns
 */
export const addNewCertificateField = (newFieldName, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/certificate-field`, {
        newFieldName,
      });
      dispatch({
        type: types.LOAD_CERTIFICATE_FIELD,
        data: data.certificateFields,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * editCertificateField
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const editCertificateField = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        `/api/admin/certificate-field`,
        formData
      );
      dispatch({ type: types.UPDATE_CERTIFICATE_FIELD, data: formData });
      dispatch({ type: types.END_LOADING });
      showSuccess(data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * loadCertificate
 * @param {*} callback
 * @returns
 */
export const loadCertificate = (callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/admin/certificates`);

      dispatch({ type: types.LOAD_CERTIFICATE, data });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * addNewCertificate
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const addNewCertificate = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/certificate`, formData);
      dispatch({
        type: types.LOAD_CERTIFICATE,
        data: data.certificates,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * editCertificate
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const editCertificate = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/certificate`, {
        id: formData.id,
        name: formData.name,
        certificateFieldId: formData.id,
      });
      dispatch({ type: types.UPDATE_CERTIFICATE, data: formData });
      dispatch({ type: types.END_LOADING });
      showSuccess(data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * loadCompetencies
 * @param {*} callback
 * @returns
 */
export const loadCompetencies = (callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/admin/competencies`);

      dispatch({ type: types.LOAD_COMPETENCY, data });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * addNewCompetency
 * @param {*} newCompetencyName
 * @param {*} callback
 * @returns
 */
export const addNewCompetency = (newCompetencyName, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/competency`, {
        newCompetencyName,
      });
      dispatch({
        type: types.LOAD_COMPETENCY,
        data: data.competencies,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * editCompetency
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const editCompetency = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/competency`, formData);
      dispatch({ type: types.UPDATE_COMPETENCY, data: formData });
      dispatch({ type: types.END_LOADING });
      showSuccess(data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * loadDegrees
 * @param {*} callback
 * @returns
 */
export const loadDegrees = (callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/admin/degrees`);

      dispatch({ type: types.LOAD_DEGREES, data });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * addNewDegree
 * @param {*} newDegreeName
 * @param {*} callback
 * @returns
 */
export const addNewDegree = (newDegreeName, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/degree`, {
        newDegreeName,
      });
      dispatch({
        type: types.LOAD_DEGREES,
        data: data.degrees,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * editDegree
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const editDegree = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/degree`, formData);
      dispatch({ type: types.UPDATE_DEGREE, data: formData });
      dispatch({ type: types.END_LOADING });
      showSuccess(data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * loadQualification
 * @param {*} callback
 * @returns
 */
export const loadQualification = (callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/admin/qualifications`);

      dispatch({ type: types.LOAD_QUALIFICATION, data });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * addNewQualification
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const addNewQualification = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/qualification`, formData);
      dispatch({
        type: types.LOAD_QUALIFICATION,
        data: data.qualifications,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * editQualification
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const editQualification = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/qualification`, {
        id: formData.id,
        name: formData.name,
        degreeId: formData.degree.id,
        jobFieldId: formData.jobField.id,
      });
      dispatch({ type: types.UPDATE_QUALIFICATION, data: formData });
      dispatch({ type: types.END_LOADING });
      showSuccess(data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * getLocations
 * @param {*} callback
 * @returns
 */
export const getLocations = (callback) => {
  callback(null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/locations`);
      dispatch({ type: types.END_LOADING });
      callback(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(null);
    }
  };
};

/**
 * getSectors
 * @param {*} callback
 * @returns
 */
export const getSectors = (callback) => {
  callback(null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/structure/sectors`);
      dispatch({ type: types.END_LOADING });
      callback(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(null);
    }
  };
};

/**
 * getAdvertisementYear
 * @returns
 */
export const getAdvertisementYear = (callback) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/years");

      dispatch({
        type: types.SET_ADVERTISEMENT_YEARS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false, data.length > 0 ? data[0] : null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 *
 * @param {*} advertisementId
 * @param {*} callback
 * @returns
 */
export const getAppealByAdvertisementId = (advertisementId, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/advertisement-appeal/${advertisementId}`
      );
      dispatch({
        type: types.SET_ADMIN_APPEALS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * getScheduledExams
 * @returns
 */
export const getScheduledExams = (callback) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/scheduled-exams`);
      dispatch({
        type: types.SET_SCHEDULED_EXAMS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false, data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   ALLOWANCE

/**
 * getAdminAllowance
 * @returns
 */
export const getAdminAllowance = (callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/allowance`);
      dispatch({
        type: types.SET_ADMIN_ALLOWANCE,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * addNewAllowance
 * @returns
 */
export const addNewAllowance = (formData, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/allowance`, {
        name: formData.name,
        calculationMethodId: formData.selectedCalculationMethod.id,
        basePayrollComponentId: formData.selectedBasePayroll
          ? formData.selectedBasePayroll.id
          : null,
        allowanceTypeId: formData.selectedAllowanceType.id,
        payTPR: formData.payTPR,
        payCSR: formData.payCSR,
        payMaternity: formData.payMaternity,
        payMedical: formData.payMedical,
        amount: formData.amount,
        isForEmployee: formData.isForEmployee,
      });
      dispatch({
        type: types.SET_ADMIN_ALLOWANCE,
        data: data.allowances,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
      showSuccess(data.message);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * updateNewAllowance
 * @returns
 */
export const updateAllowance = (allowanceId, formData, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/allowance/${allowanceId}`, {
        name: formData.name,
        calculationMethodId: formData.selectedCalculationMethod.id,
        basePayrollComponentId: formData.selectedBasePayroll
          ? formData.selectedBasePayroll.id
          : null,
        allowanceTypeId: formData.selectedAllowanceType.id,
        payTPR: formData.payTPR,
        payCSR: formData.payCSR,
        payMaternity: formData.payMaternity,
        payMedical: formData.payMedical,
        amount: formData.amount,
        isForEmployee: formData.isForEmployee,
      });
      dispatch({
        type: types.UPDATE_ADMIN_ALLOWANCE,
        data: { allowanceId, ...formData },
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * activateAllowance
 * @returns
 */
export const activateAllowance = (allowanceId, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        `/api/admin/allowance-status/${allowanceId}`
      );
      dispatch({
        type: types.ACTIVATE_ADMIN_ALLOWANCE,
        data: allowanceId,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   CREDITOR

/**
 * getAdminCreditor
 * @returns
 */
export const getAdminCreditor = (callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/creditor`);
      dispatch({
        type: types.SET_ADMIN_CREDITOR,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * updateCreditor
 * @returns
 */
export const updateCreditor = (creditorId, formData, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/creditor/${creditorId}`, {
        name: formData.name,
        calculationMethodId: formData.selectedCalculationMethod.id,
        basePayrollComponentId: formData.selectedBasePayroll
          ? formData.selectedBasePayroll.id
          : 3, // Default partial net
        rate: formData.rate.length ? formData.rate : 0,
        bankId: formData.selectedBank.id,
        acountNumber: formData.accountNumber,
      });
      dispatch({
        type: types.UPDATE_ADMIN_CREDITOR,
        data: { creditorId, ...formData },
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * addNewCreditor
 * @returns
 */
export const addNewCreditor = (formData, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/creditor`, {
        name: formData.name,
        calculationMethodId: formData.selectedCalculationMethod.id,
        basePayrollComponentId: formData.selectedBasePayroll
          ? formData.selectedBasePayroll.id
          : null,
        rate: formData.rate.length ? formData.rate : 0,
        bankId: formData.selectedBank.id,
        acountNumber: formData.accountNumber,
      });
      dispatch({
        type: types.SET_ADMIN_CREDITOR,
        data: data.creditors,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
      showSuccess(data.message);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * activateCreditor
 * @returns
 */
export const activateCreditor = (creditorId, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        `/api/admin/creditor-status/${creditorId}`
      );
      dispatch({
        type: types.ACTIVATE_ADMIN_CREDITOR,
        data: creditorId,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   BANKS

/**
 * getAdminBank
 * @returns
 */
export const getAdminBank = (callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/banks`);
      dispatch({
        type: types.SET_ADMIN_BANK,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * updateBank
 * @returns
 */
export const updateBank = (bankId, formData, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/bank/${bankId}`, {
        name: formData.name,
        acronym: formData.acronym || null,
        countryId: formData.country ? formData.country.id : null,
        swiftCode: formData.swiftCode || null,
      });
      dispatch({
        type: types.UPDATE_ADMIN_BANK,
        data: { bankId, ...formData },
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * addNewBank
 * @returns
 */
export const addNewBank = (formData, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/bank`, {
        bankId: formData.bankId,
        name: formData.name,
        acronym: formData.acronym || null,
        countryId: formData.country ? formData.country.id : null,
        swiftCode: formData.swiftCode || null,
      });
      dispatch({
        type: types.ADD_ADMIN_BANK,
        data: formData,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * activateBank
 * @returns
 */
export const activateBank = (bankId, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/bank-status/${bankId}`);
      dispatch({
        type: types.ACTIVATE_ADMIN_BANK,
        data: bankId,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   COUNTRIES

/**
 * getAdminCountry
 * @returns
 */
export const getAdminCountry = (callback) => {
  return async (dispatch) => {
    callback && callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/countries`);
      dispatch({
        type: types.SET_ADMIN_COUNTRY,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback && callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback && callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   COST CENTER

/**
 * getAdminCostCenter
 * @returns
 */
export const getAdminCostCenter = (cb) => {
  return async (dispatch) => {
    cb && cb(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/support/costCenters`);
      dispatch({
        type: types.SET_ADMIN_COST_CENTER,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      cb && cb(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      cb && cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * updateCostCenter
 * @returns
 */
export const updateCostCenter = (costCenterId, formData, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        `/api/admin/cost-center/${costCenterId}`,
        {
          name: formData.name,
          tinNumber: formData.tinNumber || null,
          ifmisCode: formData.ifmisCode,
          countryId: formData.country ? formData.country.id : null,
        }
      );
      dispatch({
        type: types.UPDATE_ADMIN_COST_CENTER,
        data: { costCenterId, ...formData },
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * addNewCostCenter
 * @returns
 */
export const addNewCostCenter = (formData, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/cost-center`, {
        name: formData.name,
        tinNumber: formData.tinNumber || null,
        ifmisCode: formData.ifmisCode,
        countryId: formData.country ? formData.country.id : null,
      });
      dispatch({
        type: types.ADD_ADMIN_COST_CENTER,
        data: formData,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * activateCostCenter
 * @returns
 */
export const activateCostCenter = (costCenterId, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        `/api/admin/cost-center-status/${costCenterId}`
      );
      dispatch({
        type: types.ACTIVATE_ADMIN_COST_CENTER,
        data: costCenterId,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   PAYROLL APPROVER

/**
 * getAdminPayrollApprover
 * @returns
 */
export const getAdminPayrollApprover = (callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/payroll-approver`);
      dispatch({
        type: types.SET_ADMIN_PAYROLL_APPROVER,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * updatePayrollApprover
 * @returns
 */
export const updatePayrollApprover = (
  { payrollApproverId, formData },
  callback
) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        `/api/admin/payroll-approver/${payrollApproverId}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      dispatch({
        type: types.UPDATE_ADMIN_PAYROLL_APPROVER,
        data: { ...data.approver, oldId: payrollApproverId },
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data.message);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * addNewPayrollApprover
 * @returns
 */
export const addNewPayrollApprover = (formData, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        `/api/admin/payroll-approver`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      dispatch({
        type: types.ADD_ADMIN_PAYROLL_APPROVER,
        data: data.approver,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data.message);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * removePayrollApprover
 * @returns
 */
export const removePayrollApprover = (payrollApproverId, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.delete(
        `/api/admin/payroll-approver-status/${payrollApproverId}`
      );
      dispatch({
        type: types.REMOVE_ADMIN_PAYROLL_APPROVER,
        data: payrollApproverId,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const loadPeriodicTempPayrolls = (period, callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/admin/payroll-oversight/temp/${period}`
      );

      dispatch({
        type: types.SET_PAYROLL_OVERSIGHT,
        data,
      });
      dispatch({ type: types.END_LOADING });

      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

export const loadPeriodicArchPayrolls = (period, callback) => {
  callback(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/admin/payroll-oversight/archive/${period}`
      );

      dispatch({
        type: types.SET_PAYROLL_OVERSIGHT,
        data,
      });
      dispatch({ type: types.END_LOADING });

      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * loadPayrollPeriods
 * @returns
 */
export const loadPayrollPeriods = (callback) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/admin/payroll-period");
      dispatch({
        type: types.SET_PAYROLL_PERIODS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false, data.length > 0 ? data[0] : null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   STRUCTURE

/**
 * loadStructureDashboard
 * @returns
 */
export const loadStructureDashboard = (callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/admin/dashboard-structure");

      dispatch({
        type: types.SET_STRUCTURE_DASHBOARD,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadPositionByLevel
 * @returns
 */
export const loadPositionByLevel = (level, callback) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/position-level/${level.levelId}`
      );

      if (data.positions.length)
        dispatch({
          type: types.SET_STRUCTURE_DASHBOARD_POSITIONS,
          data: { level, data },
        });
      dispatch({ type: types.END_LOADING });
      callback(false, { level, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      console.error(error);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadEmployeeByLevel
 * @returns
 */
export const loadEmployeeByLevel = (levelId, callback) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/employee-level/${levelId}`);

      if (data.length)
        dispatch({
          type: types.SET_STRUCTURE_DASHBOARD_EMPLOYEES,
          data: { levelId, data },
        });
      dispatch({ type: types.END_LOADING });
      callback(false, { levelId, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadEntitiesBySector
 * @returns
 */
export const loadEntitiesBySector = (sectorId, callback) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/entities-sector/${sectorId}`
      );

      if (data.length)
        dispatch({
          type: types.SET_STRUCTURE_DASHBOARD_ENTITIES,
          data: { sectorId, data },
        });
      dispatch({ type: types.END_LOADING });
      callback(false, { sectorId, data });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   SOURCE OF FUNDS

/**
 * getAdminSOFunds
 * @returns
 */
export const getAdminSOFunds = (callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/so-fund`);
      dispatch({
        type: types.SET_ADMIN_SOFUNDS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * addNewSOFunds
 * @returns
 */
export const addNewSOFunds = (formData, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/so-fund`, {
        name: formData.name,
        payTPR: formData.payTPR,
        payCSR: formData.payCSR,
        payMaternity: formData.payMaternity,
        payMedical: formData.payMedical,
        payCBHI: formData.payCBHI,
      });
      dispatch({
        type: types.ADD_ADMIN_PAYROLL_SOFUNDS,
        data: { id: data.id, ...formData },
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
      showSuccess(data.message);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * updateSOFunds
 * @returns
 */
export const updateSOFunds = (soFundId, formData, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/so-fund/${soFundId}`, {
        name: formData.name,
        payTPR: formData.payTPR,
        payCSR: formData.payCSR,
        payMaternity: formData.payMaternity,
        payMedical: formData.payMedical,
        payCBHI: formData.payCBHI,
      });
      dispatch({
        type: types.UPDATE_ADMIN_SOFUNDS,
        data: { soFundId, ...formData },
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * activateSOFunds
 * @returns
 */
export const activateSOFunds = (soFundId, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/so-fund-status/${soFundId}`);
      dispatch({
        type: types.ACTIVATE_ADMIN_SOFUNDS,
        data: soFundId,
      });
      dispatch({ type: types.END_LOADING });
      callback(true);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   RECRUITMENT

/**
 * loadRecruitmentDashboard
 * @returns
 */
export const loadRecruitmentDashboard = (year = "0", callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/dashboard-recruitment/${year}`
      );

      dispatch({
        type: types.SET_RECRUITMENT_DASHBOARD,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadApplicationByAdvertAndStatus
 * @returns
 */
export const loadApplicationByAdvertAndStatus = (params, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/application-status/${params.advertId}/${params.status}`
      );

      dispatch({ type: types.END_LOADING });
      callback(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadApplicationByAdvertAndExam
 * @returns
 */
export const loadApplicationByAdvertAndExam = (params, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/application-exam/${params.advertId}/${params.status}`
      );

      dispatch({ type: types.END_LOADING });
      callback(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadApplicationByAdvertAndOffer
 * @returns
 */
export const loadApplicationByAdvertAndOffer = (params, callback) => {
  return async (dispatch) => {
    callback(null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/application-offer/${params.advertId}/${params.status}`
      );

      dispatch({ type: types.END_LOADING });
      callback(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// ******************   EXIT TYPE

/**
 * getAdminExitType
 * @returns
 */
export const getAdminExitType = (callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(`/api/admin/exit-type`);
      dispatch({
        type: types.SET_ADMIN_EXIT_TYPE,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * addNewExitType
 * @returns
 */
export const addNewExitType = (formData, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/exit-type`, {
        name: formData.name,
        initiatorId: formData.initiatorId,
        blacklistPeriod: formData.blacklistPeriod,
        payTerminalBenefit: formData.payTerminalBenefit,
        canBeRehired: formData.canBeRehired,
        effectiveInDays: formData.effectiveInDays,
      });
      dispatch({
        type: types.ADD_ADMIN_EXIT_TYPE,
        data: { ...formData, id: data.id },
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
      showSuccess(data.message);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * updateExitType
 * @returns
 */
export const updateExitType = (exitTypeId, formData, callback) => {
  return async (dispatch) => {
    callback(true);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/exit-type/${exitTypeId}`, {
        name: formData.name,
        initiatorId: formData.initiatorId,
        blacklistPeriod: formData.blacklistPeriod,
        payTerminalBenefit: formData.payTerminalBenefit,
        canBeRehired: formData.canBeRehired,
        effectiveInDays: formData.effectiveInDays,
      });
      dispatch({
        type: types.UPDATE_EXIT_TYPE,
        data: { exitTypeId, exitType: formData },
      });
      dispatch({ type: types.END_LOADING });
      callback(false);
      showSuccess(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadRecruitmentDashboard
 * @returns
 */
export const loadExitDashboard = (
  month = "ALL",
  year = "0",
  entitySector = "ALL",
  callback
) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/exit-dashboard/${year}/${month}/${entitySector}`
      );
      dispatch({ type: types.END_LOADING });
      callback(false, data.exitData.length > 0 ? data : null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadExitDashboardByExitType
 * @returns
 */
export const loadExitDashboardByExitType = (
  month = "ALL",
  year = "0",
  exitTypeId,
  callback
) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/exit-dashboard-exitType/${exitTypeId}/${year}/${month}`
      );

      dispatch({ type: types.END_LOADING });
      callback(false, data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * loadExitDashboardBySectorId
 * @returns
 */
export const loadExitDashboardBySectorId = (
  month = "ALL",
  year = "0",
  sectorId,
  callback
) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/admin/exit-dashboard-exitSector/${sectorId}/${year}/${month}`
      );

      dispatch({ type: types.END_LOADING });
      callback(false, data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/**
 * getExitYearAndMonths
 * @returns
 */
export const getExitYearAndMonths = (callback) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/admin/years-months");

      dispatch({
        type: types.SET_ADMIN_EXIT_YEARS_MONTHS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      callback(false, data.years.length > 0 ? data.years[0] : "ALL");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
