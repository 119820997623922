export const defaultState = {
  units: [],
  unitTypes: [],
  treeUnits: {},
  selectedUnit: {},
  positions: [],
  searchUnit: null,
  searchPosition: null,
  searchCurrentPositions: [],
  salaryStructures: [],
  employeeGroups: [],
  hiringModes: [],
  levels: [],
  echelons: [],
  positionEmployees: [],
  supervisors: [],
  profileDegrees: [],
  qualifications: [],
  professionalCertificates: [],
  knowledges: [],
  certificateTypes: [],
  professionalCertificateTypes: [],
  positionKnowledges: [],
  banks: [],
  medicalInsuranceTypes: [],
  ///////////////////////////////////EMPLOYEEES

  searchEmployee: null,
  searchCurrentEmployees: [],
  employees: [],
  nationalIdDetail: null,
  employee: null,
  salaryIndexGrid: null,
  jobClassifications: [],
  jobClassificationAllowances: [],
  unitAllowances: [],
  unitWithholds: [],
  jobFields: [],
  entitySectors: [],
  vJobClassifications: [],
  position: null,
  positionAllowances: [],
  employeeAllowances: [],
  employeeWithholds: [],
  jobClassificationEmployees: [],
  jobClassificationQualifications: [],
  jobClassificationCompetencies: [],
  competencies: [],
  certificateFields: [],
  certificates: [],
  jobClassificationCertificates: [],
  positionQualifications: [],
  positionCertificates: [],
  positionCompetencies: [],
  entitySectorAllowances: [],
  sharedScopes: [],
  entitySectorPositions: [],
  sharedPositionAllowances: [],
  verifyRssbNumber: null,
  previewEmployeePaySlip: null,
  allowanceTypes: [],
  jobs: [],
  entityClasses: [],
  jobCategories: [],
  employeeBankAccounts: [],
  employeeArrears: [],
  periods: [],
  employeeProfile: {},
  relationships: [],

  countries: [],
  schools: [],
  employeePromotions: [],

  searchedEmployees: [],

  entitiesOversights: [],
  entities: [],

  organigramData: [],
  paymentStatuses: [],
  employeesPullRequests: [],
  employments: [],
  employeeQualifications: [],
  employeeCertificates: [],
  employeeDisabilities: [],
  disabilityLevels: [],
  disabilities: [],
  languages: [],
  employeeReferences: [],
  employeeLanguages: [],
  userProfile: null,
  employeeNexKin: null,
  searchedUserDetails: [],
  headOfInstitution: null,
  missingEmployeeDataSummary: {},
  missingEmployeeData: [],
  nonAddedPositionCertificates: [],
  nonAddedPositionCompetencies: [],
  rraGrades: [],
  rraDepartments: [],
  noOfEmployeeRequests:0,
  employeeRequests:[]
};
