import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { connect } from "react-redux";

const Breadcrumb = (props) => {
  const { env } = props;

  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {/* <>
       <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/recruitment/dashboard"
          className={` px-1 rounded   ${
            location.pathname.includes("/recruitment/dashboard")
              ? " active  "
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">Dashboard</span>
        </Link>
        <HorizontalRuleIcon className="rotated" />

       </> */}

        {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/requisitions"
              className={` px-1 rounded   ${
                location.pathname.includes("/recruitment/requisitions")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Requisitions</span>
            </Link>
            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions([
          "IS_HR",
          "IS_DAF",
          "IS_CBM",
          "IS_RECRUITMENT_MEMBER",
        ]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/advertisements"
              className={` px-1 rounded   ${
                location.pathname.includes("/recruitment/advertisements")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Advertisements</span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions([
          "IS_QUESTION_BANK_ADMIN",
          "IS_QUESTION_BANK_EDITOR",
          "IS_QUESTION_BANK_CREATOR",
        ]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/psychometric"
              className={` px-1 rounded   ${
                location.pathname.includes("/recruitment/psychometric")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Psychometric</span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ env }) => {
  return {
    env,
  };
};
export default connect(mapStateToProps, {})(Breadcrumb);
