import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { login, logout } from "../../../store/common/actions";
import { useEffect } from "react";
import { set } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #222",
  borderRadius: "10px",
  boxShadow: 64,
  p: 4,
};

const LoginModal = (props) => {
  const { selectedModule, user, loading, requireAuth, login, logout } = props;

  const history = useHistory();
  const [credentials, setCredentials] = useState({
    email: user?.email || "",
    password: "",
    showPassword: false,
  });

  useEffect(() => {
    setCredentials({ ...credentials, email: user?.email || "" });
  }, [requireAuth, user]);

  const onLogin = async (e) => {
    e.preventDefault();
    login(
      {
        email: credentials.email,
        password: credentials.password,
        selectedEntitySectorId: user?.selectedEntitySector?.id,
        selectedModule: selectedModule?.id,
        wasLoggedIn: true,
      },
      history,
      true
    );
  };

  return (
    <Modal
      open={requireAuth && user ? true : false}
      aria-labelledby="login-form"
      aria-describedby="help user login to the system"
    >
      <Box sx={style}>
        <form onSubmit={onLogin}>
          <h1 className="h3 mt-3 mb-5 font-weight-normal text-center">
            Verify it's you
          </h1>

          <FormControl fullWidth variant="outlined" className="mb-4 bg-white">
            <InputLabel htmlFor="user">Current user</InputLabel>
            <OutlinedInput
              autoFocus
              id="email"
              placeholder="Enter your email"
              name="email"
              type="email"
              value={credentials.email}
              onChange={(e) => {
                const email = e.target.value;
                setCredentials({ ...credentials, email });
              }}
              label="Enter your email"
              disabled
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" className=" bg-white">
            <InputLabel htmlFor="password">Enter your password</InputLabel>
            <OutlinedInput
              id="password"
              placeholder="Enter your password"
              name="password"
              autoFocus
              disabled={loading}
              type={credentials.showPassword ? "text" : "password"}
              value={credentials.password}
              onChange={(e) => {
                const password = e.target.value;
                setCredentials({ ...credentials, password });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setCredentials({
                        ...credentials,
                        showPassword: !credentials.showPassword,
                      })
                    }
                    edge="end"
                  >
                    {credentials.showPassword ? (
                      <span className="material-icons">visibility_off</span>
                    ) : (
                      <span className="material-icons">visibility</span>
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Enter your password"
            />
          </FormControl>

          {!loading && (
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
              disableElevation
              className="btn btn-block text-light mt-4"
              size="large"
            >
              Confirm
            </Button>
          )}

          {loading && (
            <Button
              type="button"
              variant="contained"
              disableElevation
              className="btn btn-block text-light mt-4"
              size="large"
            >
              Connecting...
            </Button>
          )}
        </form>

        {/* {!loading && (
          <Button
            type="button"
            onClick={() => logout(history)}
            className="btn btn-block  mt-4"
          >
            <span className="material-icons">keyboard_double_arrow_left</span>{" "}
            Close
          </Button>
        )} */}
      </Box>
    </Modal>
  );
};

const mapStateToProps = ({
  selectedModule,
  user,
  isFetchingUser,
  loading,
  requireAuth,
}) => {
  return { selectedModule, user, isFetchingUser, loading, requireAuth };
};
export default connect(mapStateToProps, {
  login,
  logout,
})(LoginModal);
