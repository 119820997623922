import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
  LinearProgress,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  // getDegrees,
  getDegreeQualifications,
  saveJobClassificationQualification,
} from "../../../store/structure/actions";
import { showError } from "../../toastify";
const experiences = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "15",
];

const AddOrUpdateQualificationDialag = (props) => {
  const {
    loading,
    onWait,
    isEditing,
    jobClassificationId,
    setIsEditing,
    profileDegrees,
    qualifications,
    showQualificationForm,
    setShowQualificationForm,
    setSelectedQualification,
    selectedQualification,
    // getDegrees,
    getDegreeQualifications,
    saveJobClassificationQualification,
  } = props;

  const [formData, setFormData] = useState({
    qualificationId: null,
    jobClassificationId: jobClassificationId,
    yearsOfExperience: 0,
  });
  const [degree, setDegree] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [currentQualifications, setCurrentQualifications] = useState([]);
  const [experience, setExperience] = useState(null);

  const [errors, setErrors] = useState({
    degreeHasError: false,
    qualificationHasError: false,
  });

  useEffect(
    () => {
      //getDegrees();

      if (!!degree) getDegreeQualifications(degree.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [degree]
  );

  const formValidator = () => {
    const error = {
      qualificationHasError: false,
      degreeHasError: false,
      hasError: false,
    };

    if (!qualification) {
      error.qualificationHasError = true;
      error.hasError = true;
    }

    if (!degree) {
      error.degreeHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveJobClassificationQualification(
        formData,
        setFormData,
        setShowQualificationForm,
        setSelectedQualification,
        setDegree,
        setQualification,
        setCurrentQualifications,
        setExperience
      );
    }
  };

  const onClose = () => {
    setFormData({
      qualificationId: null,
      jobClassificationId: jobClassificationId,
      yearsOfExperience: 0,
    });
    setShowQualificationForm(false);
    setIsEditing(false);
    setExperience(null);
    setSelectedQualification(null);
    setDegree(null);
    setQualification(null);
    setCurrentQualifications([]);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showQualificationForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {isEditing ? "Edit Qualification" : "Add New Qualification"}{" "}
            {selectedQualification && (
              <strong> ( {selectedQualification.qualification} ) </strong>
            )}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        {onWait && !loading && <LinearProgress />}

        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-8">
                <Autocomplete
                  size="small"
                  className="mt-3"
                  id="degree"
                  defaultValue={null}
                  value={degree || null}
                  options={profileDegrees}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, degree) => {
                    setExperience(null);
                    setQualification(null);
                    setDegree(degree);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, degree) => (
                    <Box component="li" {...props}>
                      {degree.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select degree"
                      label="Select degree"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.degreeHasError && (
                  <div className="text-danger mb-2">Degree is required </div>
                )}
              </div>
              <div className="col-4">
                <Autocomplete
                  size="small"
                  className="mt-3"
                  id="experience"
                  defaultValue={null}
                  value={experience || null}
                  options={!!degree ? experiences : []}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(event, experience) => {
                    setExperience(experience || null);
                    const yearsOfExperience = +experience || 0;
                    setFormData({ ...formData, yearsOfExperience });
                  }}
                  getOptionLabel={(option) =>
                    +option > 1 ? option + " Years" : option + " Year"
                  }
                  renderOption={(props, experience) => (
                    <Box component="li" {...props}>
                      {experience} {+experience > 1 ? "Years" : "Year"}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Years of experience"
                      label="Years of experience"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-12">
                <Autocomplete
                  size="small"
                  className="mt-3"
                  id="qualification"
                  defaultValue={null}
                  value={qualification || null}
                  options={qualifications}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, qualification) => {
                    setQualification(qualification || null);
                    const qualificationId = qualification.id;
                    setFormData({ ...formData, qualificationId });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, qualification) => (
                    <Box component="li" {...props}>
                      {qualification.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select qualification"
                      label="Select qualification"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />

                {errors.qualificationHasError && (
                  <div className="text-danger mb-2">
                    Qualification is required{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-3">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  profileDegrees,
  qualifications,
}) => {
  return {
    loading,
    onWait,
    profileDegrees,
    qualifications,
  };
};
export default connect(mapStateToProps, {
  // getDegrees,
  getDegreeQualifications,
  saveJobClassificationQualification,
})(AddOrUpdateQualificationDialag);
