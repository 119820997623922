import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Skeleton,
  DialogActions,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  saveEntitySectorAllowance,
  getEntitySectorAllowances,
  deleteEntitySectorAllowance,
} from "../../../store/structure/actions";
// import { getAllowances } from "../../../store/payroll/actions";
import { useTheme } from "@mui/material/styles";
import { showError } from "../../../app/toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ReactPaginate from "react-paginate";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid #007bff`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "#007bff",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const EntitySectorAllowances = (props) => {
  const {
    loading,
    selectedEntitySector,
    getAllowances,
    saveEntitySectorAllowance,
    getEntitySectorAllowances,
    deleteEntitySectorAllowance,
    entitySectorAllowances,
    systemAllowances,
    showEntitySectorAllowanceDialog,
    setShowEntitySectorAllowanceDialog,
    entity,
  } = props;

  const [allowance, setAllowance] = useState(null);
  const [selectedEntitySectorAllowance, setSelectedEntitySectorAllowance] =
    useState(null);
  const [
    confirmRemoveEntitySectorAllowance,
    setConfirmRemoveEntitySectorAllowance,
  ] = useState(false);
  const theme = useTheme();

  const [formData, setFormData] = useState({
    entitySectorId: selectedEntitySector.id,
    allowanceId: "",
  });

  const [add, setAdd] = useState(false);

  const [errors, setErrors] = useState({
    allowanceHasError: false,
  });

  useEffect(
    () => {
      // getAllowances();
      getEntitySectorAllowances(selectedEntitySector.id);

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEntitySector]
  );

  useEffect(
    () => {
      const add = entitySectorAllowances.length > 0 ? false : true;

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entitySectorAllowances]
  );

  const formValidator = () => {
    const error = {
      allowanceHasError: false,
      hasError: false,
    };

    if (!formData.allowanceId) {
      error.allowanceHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveEntitySectorAllowance(formData, setFormData, setAdd, setAllowance);
    }
  };

  const onClose = () => {
    setShowEntitySectorAllowanceDialog(false);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(
    () => {
      paginate(entitySectorAllowances);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, entitySectorAllowances]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % entitySectorAllowances.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showEntitySectorAllowanceDialog}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="text-primary">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "70%" }}
        >
          <div className="headerorg mb-0">
            <div className="header_cont">
              <span className="ml-0 text-dark">Allowances | </span>{" "}
              <span className="ml-0">
                {entity && entity.name}{" "}
                <span className="badge badge-secondary">
                  {selectedEntitySector && selectedEntitySector.sector.name}
                </span>
              </span>
            </div>
          </div>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent className="pb-0">
        <div
          className="elevated rounded p-3"
          style={{
            backgroundColor: "#fafafa",
            border: "1px solid #17a2b8",
            minHeight: "75vh",
          }}
        >
          {confirmRemoveEntitySectorAllowance && (
            <ConfirmationDialog
              confirmationDialog={confirmRemoveEntitySectorAllowance}
              message={`Are you sure you want to remove this allowance @${
                selectedEntitySectorAllowance.allowance &&
                selectedEntitySectorAllowance.allowance.name
              }?`}
              setConfirmationDialog={setConfirmRemoveEntitySectorAllowance}
              onYes={() => {
                deleteEntitySectorAllowance(
                  selectedEntitySectorAllowance,
                  setConfirmRemoveEntitySectorAllowance,
                  setSelectedEntitySectorAllowance
                );
              }}
            />
          )}

          {loading && (
            <div className="container mt-2" style={{ width: "100%" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </div>
          )}

          {!loading && (
            <div>
              {!add && (
                <Button
                  className="mb-2"
                  onClick={() => {
                    setAdd(true);
                  }}
                  style={{
                    ...ActiveButton,
                    padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                    float: "right",
                  }}
                  size="sm"
                >
                  <span className="material-icons">add</span>
                </Button>
              )}
              {add && (
                <div
                  className="elevated rounded p-0 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                  }}
                >
                  {!!systemAllowances.length && (
                    <div className="row mt-3 p-3">
                      <div className="col-12">
                        <Autocomplete
                          size="small"
                          id="allowance"
                          className="mb-3"
                          defaultValue={null}
                          value={allowance}
                          options={systemAllowances}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, allowance) => {
                            setAllowance(allowance || null);
                            const allowanceId = allowance ? allowance.id : "";
                            setFormData({ ...formData, allowanceId });
                          }}
                          getOptionLabel={(option) =>
                            option.name + "- RWF " + option.amount
                          }
                          renderOption={(props, allowance) => (
                            <Box component="li" {...props}>
                              {allowance.name} - RWF {allowance.amount}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Allowances"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "allowance", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        {errors.allowanceHasError && (
                          <div className="text-danger mb-2">
                            Allowance is required{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <Divider className="my-1" />
                  <div className="d-flex justify-content-center py-0 p-2">
                    <button
                      onClick={onSave}
                      type="button"
                      className="btn btn-primary text-uppercase"
                      disabled={loading}
                    >
                      {loading ? "Wait..." : "Save"}
                    </button>{" "}
                    {!!paginatedItems.length && (
                      <React.Fragment>
                        &nbsp; &nbsp;
                        <button
                          onClick={() => setAdd(false)}
                          type="button"
                          className="btn btn-default text-uppercase"
                          disabled={loading}
                        >
                          Cancel
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}

              {!!entitySectorAllowances.length && (
                <React.Fragment>
                  <table className="table table-bordered table-sm mt-3">
                    <thead>
                      <tr>
                        <th colSpan={4} style={{ textAlign: "center" }}>
                          Allowances
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedItems.map((entitySectorAllowance, uaIndex) => (
                        <tr
                          key={uaIndex}
                          style={{
                            background: uaIndex % 2 === 0 ? "" : "#f6f7f7",
                            borderBottom: "2px dotted #007bff!important",
                          }}
                        >
                          <td>{uaIndex + 1}</td>
                          <td>
                            {" "}
                            {entitySectorAllowance.allowance &&
                              entitySectorAllowance.allowance.name}{" "}
                          </td>
                          <td>
                            RWF{" "}
                            {entitySectorAllowance.allowance &&
                              entitySectorAllowance.allowance.amount}{" "}
                          </td>
                          <td>
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => {
                                setSelectedEntitySectorAllowance(
                                  entitySectorAllowance
                                );
                                setConfirmRemoveEntitySectorAllowance(true);
                              }}
                              className=" mr-2"
                            >
                              <span className="material-icons">delete</span>
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                    activeLinkClassName="active"
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions className="py-4"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({
  loading,
  allowances,
  entitySectorAllowances,
  systemAllowances,
}) => {
  return {
    loading,
    allowances,
    entitySectorAllowances,
    systemAllowances,
  };
};
export default connect(mapStateToProps, {
  saveEntitySectorAllowance,
  getEntitySectorAllowances,
  deleteEntitySectorAllowance,
  // getAllowances,
})(EntitySectorAllowances);
