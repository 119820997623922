import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  addNewCertificate,
  editCertificate,
  loadCertificate,
  loadCertificateField,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";

const Certificates = (props) => {
  const {
    createCertificate,
    setCreateCertificate,
    // redux -----------------
    loading,
    certificates,
    certificateFields,
    loadCertificate,
    addNewCertificate,
    editCertificate,
    loadCertificateField,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [loadingField, setLoadingField] = useState(false);
  const [certificateId, setCertificateId] = useState(0);
  const [certificateName, setCertificateName] = useState("");
  const [field, setField] = useState(null);
  const [formError, setFormError] = useState(null);
  const [fieldFilter, setFieldFilter] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //PAGINATION
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (certificates === null || certificates.length === 0) {
      loadCertificate(setLoadingData);
    } else {
      setLoadingData(false);
    }

    if (certificateFields === null || certificateFields.length === 0) {
      loadCertificateField(setLoadingField);
    }

    return () => {
      setLoadingData(true);
      setLoadingField(false);
      setCertificateId(0);
      setCertificateName("");
      setField(null);
      setCertificateId("");
      setFormError(null);
      setSearchInput("");
      setFieldFilter(null);
    };
  }, []);

  const onCloseForm = () => {
    setCreateCertificate(false);
    setCertificateId(0);
    setCertificateName("");
    setField(null);
  };

  const onSaveForm = () => {
    if (certificateId === 0 && !createCertificate) {
      setFormError({
        element: "GENERAL",
        msg: "Please select certificate you want to update.",
      });
      return;
    }
    if (certificateName.length === 0) {
      setFormError({ element: "NAME", msg: "Certificate name is required." });
      return;
    }
    if (field === null) {
      setFormError({ element: "FIELD", msg: "Certificate field is required." });
      return;
    }
    if (certificateId > 0 && !createCertificate) {
      editCertificate(
        {
          id: certificateId,
          name: certificateName,
          certificateFieldId: field.id,
          certificateField: field,
        },
        (status) => status && onCloseForm()
      );
    } else {
      addNewCertificate(
        { newName: certificateName, certificateFieldId: field.id },
        (status) => status && onCloseForm()
      );
    }
  };

  const IS_EDITING = Boolean(certificateId > 0);
  let SEARCHED_DATA = !loadingData
    ? searchInput.length === 0 && certificates
      ? certificates
      : certificates.filter((item) =>
          item.name
            .toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase())
        )
    : [];
  SEARCHED_DATA = fieldFilter
    ? SEARCHED_DATA.filter(
        (item) => item.certificateFieldId === fieldFilter?.id
      )
    : SEARCHED_DATA;
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };
  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center">
        <div className="col-12 col-sm">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={
              loadingData || certificates === null || certificates.length === 0
            }
            placeholder="Search certificate..."
          />
        </div>

        <div className="col-12 col-sm mt-2 mt-md-0">
          <Autocomplete
            disabled={loading || loadingData || loadingField}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, field) => (
              <Box component="li" {...props}>
                {field?.name}
              </Box>
            )}
            options={certificateFields || []}
            size="small"
            value={fieldFilter || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Certificate field"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "field",
                }}
              />
            )}
            onChange={(event, field) => setFieldFilter(field || null)}
          />
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData || certificates === null ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Certificate</th>
            <th scope="col">Certificate Field</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        {loadingData || certificates === null ? (
          <TableSkeleton cols={4} />
        ) : certificates.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={4}>
                <div className="text-center font-weight-bold">
                  No certificate found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((certificateItem, index) => (
              <tr key={"certificate-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {index + 1 + itemOffset}
                </th>
                <td>{certificateItem && certificateItem.name}</td>
                <td>
                  {certificateItem &&
                    certificateItem.certificateField &&
                    certificateItem.certificateField.name}
                </td>
                <td align="center">
                  <IconButton
                    aria-label="edit certificate"
                    size="small"
                    onClick={() => {
                      setCertificateId(certificateItem.id);
                      setCertificateName(certificateItem.name);
                      setField(certificateItem.certificateField);
                    }}
                  >
                    <ModeRoundedIcon fontSize="small" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createCertificate || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Certificate" : "Add New Certificate"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            <TextField
              id="outlined-basic"
              label="Disability name"
              variant="outlined"
              size="small"
              className="w-100"
              disabled={loading || loadingData}
              value={certificateName}
              onChange={(e) => setCertificateName(e.target.value)}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <Autocomplete
              disabled={loading || loadingData || loadingField}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, field) => (
                <Box component="li" {...props}>
                  {field?.name}
                </Box>
              )}
              options={certificateFields || []}
              size="small"
              // sx={{ width: 300 }}
              value={field || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Certificate field"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "field",
                  }}
                />
              )}
              onChange={(event, field) => {
                setField(field || null);
                setFormError(null);
              }}
              className="mt-3"
            />
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase  px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

Certificates.propTypes = {
  createCertificate: PropTypes.bool,
  setCreateCertificate: PropTypes.func,
};

const mapStateToProps = ({ loading, certificate }) => {
  return {
    loading,
    certificates: certificate.certificates,
    certificateFields: certificate.certificateFields,
  };
};

const mapDispatchToProps = {
  loadCertificate,
  addNewCertificate,
  editCertificate,
  loadCertificateField,
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
