import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  Typography,
  FormGroup,
  Checkbox,
  InputAdornment,
  Skeleton,
  Tabs,
  Tab,
  Divider,
  FormLabel,
  Chip,
} from "@mui/material";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  // getPosition,
  updatePositionSalarySettings,
  // getSalaryStructure,
  getPositionAllowances,
  // getPositionWithholds,
} from "../../../store/structure/actions";
// import {
//   getSourceOfFunds,
//   getPayrollGroups,
//   getCostCenters,
// } from "../../../store/payroll/actions";
import { showError } from "../../toastify";

import PositionAllowances from "./PositionAllowances";
import { currencyFormat } from "../../common/components/Utils";

const PositionSalarySettingsDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    showPayrollSettingsDialog,
    setShowPayrollSettingsDialog,
    position,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    isEntitySectorLocked,
    getPositionAllowances,
    // getPositionWithholds,
    updatePositionSalarySettings,
    allowanceTypes,
  } = props;

  const [sourceOfFund, setSourceOfFund] = useState(null);
  const [payrollGroup, setPayrollGroup] = useState(null);
  const [costCenter, setCostCenter] = useState(null);
  const [edited, setEdited] = useState(false);

  const [errors, setErrors] = useState({
    sourceOfFundHasError: false,
    payrollGroupHasError: false,
    costCenterHasError: false,
    hasError: false,
  });
  const formValidator = () => {
    const error = {
      sourceOfFundHasError: false,
      payrollGroupHasError: false,
      costCenterHasError: false,
      hasError: false,
    };

    if (!sourceOfFund) {
      error.sourceOfFundHasError = true;
      error.hasError = true;
    }

    if (!payrollGroup) {
      error.payrollGroupHasError = true;
      error.hasError = true;
    }
    if (!costCenter) {
      error.costCenterHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const [formData, setFormData] = useState({
    positionId: position.id,
    sourceOfFundId: position.sourceOfFundId,
    payrollGroupId: position.payrollGroupId,
    costCenterId: position.costCenterId,
    hasHousingAllowance: position.hasHousingAllowance,
    hasTransportAllowance: position.hasTransportAllowance,
    payTPR: true,
    payCSR: true,
    payMedical: true,
    payMaternity: true,
    payCBHI: true,
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const payrollGroupDropdown = () => {
    return payrollGroups.filter(
      (payrollGroup) =>
        payrollGroup.employeeGroupId === position.employeeGroupId
    );
  };

  useEffect(
    () => {
      getPositionAllowances(position.id);
      // getPositionWithholds(position.id);

      if (position) {
        setSourceOfFund(
          sourceOfFunds.find(({ id }) => id === position.sourceOfFundId) || null
        );
        setPayrollGroup(
          payrollGroups.find(({ id }) => id === position.payrollGroupId) || null
        );
        setCostCenter(
          costCenters.find(({ id }) => id === position.costCenterId) || null
        );

        setFormData({
          positionId: position.id,
          sourceOfFundId: position.sourceOfFundId,
          payrollGroupId: position.payrollGroupId,
          costCenterId: position.costCenterId,
          hasHousingAllowance: position.hasHousingAllowance,
          hasTransportAllowance: position.hasTransportAllowance,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [position.id]
  );

  useEffect(
    () => {
      if (sourceOfFund)
        setFormData({
          ...formData,
          payTPR: sourceOfFund.payTPR,
          payCSR: sourceOfFund.payCSR,
          payMedical: sourceOfFund.payMedical,
          payMaternity: sourceOfFund.payMaternity,
          payCBHI: sourceOfFund.payCBHI,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceOfFund]
  );

  const onSave = () => {
    if (!formValidator()) {
      updatePositionSalarySettings(formData, setEdited);
    }
  };

  return (
    <>
      <Dialog
        onClose={() => setShowPayrollSettingsDialog(false)}
        open={showPayrollSettingsDialog}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              {position && (
                <div className="header_cont">
                  <span className="ml-0 text-dark">
                    Position Salary settings |{" "}
                  </span>{" "}
                  <span className="ml-0">{position.name}</span>{" "}
                </div>
              )}
            </div>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setShowPayrollSettingsDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <React.Fragment>
            <div className="row">
              <div className="col-sm-12 mb-3 d-flex justify-content-center">
                <Tabs
                  // orientation="vertical"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  // sx={{
                  //   borderRight: 1,
                  //   borderColor: "divider",
                  //   textAlign: "right",
                  // }}
                >
                  <Tab
                    icon={<span className="material-icons mr-1">info</span>}
                    label="Basic salary settings"
                    {...a11yProps(0)}
                  />

                  <Tab
                    // className={
                    //   position.employeeGroupId === 1 ||
                    //   position.employeeGroupId === 2 ||
                    //   position.employeeGroupId === 4
                    //     ? ""
                    //     : "d-none"
                    // }
                    icon={
                      <span className="material-icons mr-1">bookmark_add</span>
                    }
                    label="Allowances settings"
                    {...a11yProps(1)}
                  />

                  {/* <Tab
                    icon={
                      <span className="material-icons mr-1">
                        bookmark_remove
                      </span>
                    }
                    label="WithHolds"
                    {...a11yProps(2)}
                  /> */}
                </Tabs>
              </div>

              <div className="col-sm-12 ">
                <TabPanel value={value} index={0}>
                  <React.Fragment>
                    <div
                      className="elevated rounded p-3"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #17a2b8",
                        minHeight: "55vh",
                      }}
                    >
                      <div
                        // style={{ backgroundColor: "#cdeef8" }}
                        className="d-flex justify-content-center  align-items-center mb-3"
                      >
                        <Chip
                          style={{ borderRadius: "0px", fontWeight: "bold" }}
                          variant="filled"
                          className="bg-secondary text-light"
                          size="small"
                          label={
                            <>
                              Level:{" "}
                              {position.levelName + "." + position.scaleName}
                            </>
                          }
                        />
                        <span className="material-icons mx-2 text-secondary">
                          play_arrow
                        </span>

                        <Chip
                          style={{ borderRadius: "0px", fontWeight: "bold" }}
                          variant="outlined"
                          className=" "
                          size="small"
                          label={<>Index: {position.index || 0}</>}
                        />
                        <span className="px-2">{"X"}</span>
                        <Chip
                          style={{ borderRadius: "0px", fontWeight: "bold" }}
                          variant="outlined"
                          className=" "
                          size="small"
                          label={<>IndexValue: {position.indexValue || 0}</>}
                        />
                        {/* <span className="px-2">{"="}</span> */}
                        <span className="material-icons mx-2 text-secondary">
                          play_arrow
                        </span>
                        <Chip
                          style={{ borderRadius: "0px", fontWeight: "bold" }}
                          variant="filled"
                          color="primary"
                          size="small"
                          label={
                            <>
                              BasePay:{" "}
                              {currencyFormat(
                                +position.indexValue * +position.index
                              )}
                              RWF
                            </>
                          }
                        />
                      </div>

                      <div className="row">
                        {position && (
                          <div className="col-12 ">
                            <table className="table tablejc table-bordered mb-0">
                              <thead>
                                <tr>
                                  {/* <th>Name</th> */}
                                  <th>Job Classification</th>
                                  <th>Employee Group</th>
                                  <th className="text-right">Posts</th>
                                  {/* <th>Level</th> */}
                                  {/* <th>IndexValue</th> */}
                                  {/* <th>Hiring Mode</th> */}
                                  {/* <th></th> */}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {/* <td style={{ maxWidth: "150px" }}>
                                      {position.name}
                                    </td> */}

                                  <td>
                                    {position.jobClassificationName || "N/A"}
                                  </td>

                                  <td>{position.employeeGroupName || "N/A"}</td>

                                  <td className="text-right">
                                    {position.numberOfPosts -
                                      position.numberOfVacantPosts}
                                    {"/"}
                                    {position.numberOfPosts}
                                  </td>
                                  {/* <td>
                                    {position.levelName}.{position.scaleName}
                                  </td> */}
                                  {/* <td>{position.indexValue || "N/A"}</td> */}

                                  {/* <td>{position.hiringModeName}</td> */}
                                </tr>
                                <tr>
                                  <td colSpan={3}>
                                    <FormGroup
                                      row
                                      className="d-flex justify-content-center  bg-light"
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            disabled={
                                              !!user?.selectedEntitySector
                                                ?.isLocked
                                            }
                                            checked={
                                              formData.hasTransportAllowance
                                            }
                                            onChange={(event) => {
                                              setFormData({
                                                ...formData,
                                                hasTransportAllowance: event
                                                  .target.checked
                                                  ? true
                                                  : false,
                                              });

                                              setEdited(true);
                                            }}
                                          />
                                        }
                                        label="Has Transport All."
                                      />
                                      <FormControlLabel
                                        className="mr-0"
                                        control={
                                          <Checkbox
                                            disabled={
                                              !!user?.selectedEntitySector
                                                ?.isLocked
                                            }
                                            checked={
                                              formData.hasHousingAllowance
                                            }
                                            onChange={(event) => {
                                              setFormData({
                                                ...formData,
                                                hasHousingAllowance: event
                                                  .target.checked
                                                  ? true
                                                  : false,
                                              });

                                              setEdited(true);
                                            }}
                                          />
                                        }
                                        label="Has Housing All."
                                      />
                                    </FormGroup>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                        <div className="col-lg-4 col-xs-12 col-sm-12 col-md-4 mt-3">
                          <Autocomplete
                            disabled={!!user?.selectedEntitySector?.isLocked}
                            size="small"
                            id="source-of-fund"
                            defaultValue={null}
                            value={sourceOfFund}
                            options={sourceOfFunds}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, sourceOfFund) => {
                              setSourceOfFund(sourceOfFund || null);
                              setFormData({
                                ...formData,
                                sourceOfFundId: sourceOfFund?.id || null,
                              });

                              setErrors({
                                ...errors,
                                sourceOfFundHasError: false,
                              });

                              setEdited(true);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, sourceOfFund) => (
                              <Box component="li" {...props}>
                                {sourceOfFund.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Source Of Fund"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "sourceOfFund", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                          {errors.sourceOfFundHasError && (
                            <div className="text-danger mt-2">
                              Source of fund is required{" "}
                            </div>
                          )}
                        </div>

                        <div className="col-lg-4 col-xs-12 col-sm-12 col-md-4 mt-3">
                          <Autocomplete
                            disabled={!!user?.selectedEntitySector?.isLocked}
                            size="small"
                            id="costCenter"
                            defaultValue={null}
                            value={costCenter}
                            options={costCenters}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, costCenter) => {
                              setCostCenter(costCenter || null);
                              setFormData({
                                ...formData,
                                costCenterId: costCenter.id,
                              });

                              setErrors({
                                ...errors,
                                costCenterHasError: false,
                              });

                              setEdited(true);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, costCenter) => (
                              <Box component="li" {...props}>
                                {costCenter.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Cost Center"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "costCenter", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                          {errors.costCenterHasError && (
                            <div className="text-danger mt-2">
                              Cost Center is required{" "}
                            </div>
                          )}
                        </div>

                        <div className="col-lg-4 col-xs-12 col-sm-12 col-md-4 mt-3">
                          <Autocomplete
                            disabled={!!user?.selectedEntitySector?.isLocked}
                            size="small"
                            id="payrollGroup"
                            defaultValue={null}
                            value={payrollGroup}
                            options={payrollGroupDropdown()}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, payrollGroup) => {
                              setPayrollGroup(payrollGroup || null);
                              setFormData({
                                ...formData,
                                payrollGroupId: payrollGroup.id,
                              });

                              setErrors({
                                ...errors,
                                payrollGroupHasError: false,
                              });

                              setEdited(true);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, payrollGroup) => (
                              <Box component="li" {...props}>
                                {payrollGroup.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Payroll Group"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "payrollGroup", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                          {errors.payrollGroupHasError && (
                            <div className="text-danger mt-2">
                              Payroll Group is required{" "}
                            </div>
                          )}
                        </div>
                      </div>

                      <FormGroup
                        row
                        className=" d-flex justify-content-center mt-3"
                        style={{
                          backgroundColor: "#e9ecef",
                          border: "1px solid #cfd3d8",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={
                                !sourceOfFund ||
                                !!user?.selectedEntitySector?.isLocked
                              }
                              checked={formData?.payTPR || false}
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  payTPR: event.target.checked ? true : false,
                                });

                                setEdited(true);
                              }}
                            />
                          }
                          label="Pay TPR"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={
                                !sourceOfFund ||
                                !!user?.selectedEntitySector?.isLocked
                              }
                              checked={formData?.payCSR || false}
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  payCSR: event.target.checked ? true : false,
                                });

                                setEdited(true);
                              }}
                            />
                          }
                          label="Pay CSR"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={
                                !sourceOfFund ||
                                !!user?.selectedEntitySector?.isLocked
                              }
                              checked={formData?.payMedical || false}
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  payMedical: event.target.checked
                                    ? true
                                    : false,
                                });

                                setEdited(true);
                              }}
                            />
                          }
                          label="Pay Medical"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={
                                !sourceOfFund ||
                                !!user?.selectedEntitySector?.isLocked
                              }
                              checked={formData?.payMaternity || false}
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  payMaternity: event.target.checked
                                    ? true
                                    : false,
                                });

                                setEdited(true);
                              }}
                            />
                          }
                          label="Pay Maternity"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={
                                !sourceOfFund ||
                                !!user?.selectedEntitySector?.isLocked
                              }
                              checked={formData?.payCBHI || false}
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  payCBHI: event.target.checked ? true : false,
                                });

                                setEdited(true);
                              }}
                            />
                          }
                          label="Pay CBHI"
                        />
                      </FormGroup>

                      <div className="d-flex justify-content-center mt-4">
                        <button
                          type="button"
                          className="btn btn-primary text-uppercase"
                          disabled={
                            loading ||
                            onWait ||
                            !edited ||
                            !!user?.selectedEntitySector?.isLocked
                          }
                          onClick={onSave}
                        >
                          {onWait ? "Wait..." : "Save"}
                        </button>{" "}
                      </div>
                    </div>
                  </React.Fragment>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <React.Fragment>
                    <div
                      className="elevated rounded p-3"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #17a2b8",
                        minHeight: "55vh",
                      }}
                    >
                      <PositionAllowances
                        isEntitySectorLocked={isEntitySectorLocked}
                        selectedPosition={position}
                      />
                    </div>
                  </React.Fragment>
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                  <React.Fragment>
                    <div
                      className="elevated rounded p-3"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #17a2b8",
                        minHeight: "55vh",
                      }}
                    >
                      <PositionWithHolds
                        isEntitySectorLocked={isEntitySectorLocked}
                        selectedPosition={position}
                      />
                    </div>
                  </React.Fragment>
                </TabPanel> */}
              </div>
            </div>
          </React.Fragment>
        </DialogContent>
        <DialogActions className="py-4"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  sourceOfFunds,
  payrollGroups,
  costCenters,
  allowanceTypes,
}) => {
  return {
    loading,
    onWait,
    user,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    allowanceTypes,
  };
};
export default connect(mapStateToProps, {
  getPositionAllowances,
  // getPositionWithholds,
  updatePositionSalarySettings,
})(PositionSalarySettingsDialog);
