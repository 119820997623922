import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveOrUpdateUnit } from "../../../store/structure/actions";
import { showError } from "../../../app/toastify";

const AddOrUpdateUnitDialog = (props) => {
  const {
    loading,
    onWait,
    isEditing,
    unitTypes,
    units,
    setIsEditing,
    showUnitForm,
    setShowUnitForm,
    parentUnit,
    selectedUnit,
    saveOrUpdateUnit,
    setExpandedNodes,
    getUnitTypes,
    user,
  } = props;

  const [formData, setFormData] = useState({
    unitId: null,
    unitName: "",
    parentUnitId: "",
    unitTypeId: 0,
    isOnStructure: true,
  });
  const [unitType, setUnitType] = useState(null);
  const [unit, setUnit] = useState(null);

  const [errors, setErrors] = useState({
    unitNameHasError: false,
    unitTypeHasError: false,
  });

  useEffect(
    () => {
      if (isEditing)
        setFormData({
          unitId: selectedUnit.unitId,
          unitName: selectedUnit.unitName,
          parentUnitId: selectedUnit.parentUnitId,
          unitTypeId: selectedUnit.unitTypeId,
          isOnStructure: selectedUnit.isOnStructure,
        });
      else
        setFormData({
          unitId: null,
          unitName: "",
          parentUnitId: parentUnit.unitId,
          unitTypeId: 0,
          isOnStructure: true,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedUnit, parentUnit, isEditing]
  );

  useEffect(
    () => {
      if (isEditing) {
        setUnit(
          units.find(({ unitId }) => unitId === formData.parentUnitId) || null
        );
        setUnitType(
          unitTypes.find(({ id }) => id === formData.unitTypeId) || null
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unitTypes, formData, isEditing]
  );

  const formValidator = () => {
    const error = {
      unitNameHasError: false,
      unitTypeHasError: false,
      hasError: false,
    };

    if (!formData.unitName) {
      error.unitNameHasError = true;
      error.hasError = true;
    }

    if (!formData.unitTypeId || formData.unitTypeId === 0) {
      error.unitTypeHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    // if (
    //   isEditing &&
    //   selectedUnit &&
    //   selectedUnit.unitName === formData.unitName &&
    //   selectedUnit.parentUnitId === formData.parentUnitId &&
    //   selectedUnit.unitTypeId === formData.unitTypeId &&
    //   selectedUnit.isOnStructure === formData.isOnStructure
    // ) {
    //   showError("No update yet");
    //   return false;
    // }
    if (!formValidator()) {
      saveOrUpdateUnit(
        formData,
        setFormData,
        setIsEditing,
        setShowUnitForm,
        setExpandedNodes,
        units,
        isEditing
      );
    }
  };

  const onClose = () => {
    setShowUnitForm(false);
    setIsEditing(false);

    setFormData({
      unitId: null,
      unitName: "",
      parentUnitId: "",
      unitTypeId: 0,
      isOnStructure: true,
    });
  };

  const filterUnitTypes = () => {
    return [...unitTypes].filter(
      (unitType) =>
        unitType.sectorId === null ||
        unitType.sectorId === user.selectedEntitySector.sectorId
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showUnitForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-dark font-weight-bold">
              {isEditing ? "Edit" : "Add New"} Unit
            </span>{" "}
            @{isEditing ? selectedUnit.unitName : parentUnit.unitName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            size="small"
            rows={4}
            name="unitName"
            autoFocus
            label="Unit name"
            variant="outlined"
            placeholder="Unit name"
            value={formData.unitName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={(e) => {
              setErrors({ ...errors, unitNameHasError: false });
              const unitName = e.target.value;

              setFormData({ ...formData, unitName });
            }}
          />
          {errors.unitNameHasError && (
            <small className="text-danger ">Unit name is required </small>
          )}

          {isEditing && (
            <>
              <Autocomplete
                className="mt-3"
                size="small"
                id="unit"
                defaultValue={null}
                value={unit || null}
                options={units.filter(
                  ({ unitId }) => unitId !== selectedUnit.unitId
                )}
                isOptionEqualToValue={(option, value) =>
                  option.unitId === value.unitId
                }
                onChange={(event, unit) => {
                  setErrors({ ...errors, unitHasError: false });
                  setUnit(unit || null);

                  setFormData({
                    ...formData,
                    parentUnitId: unit.unitId || null,
                  });
                }}
                getOptionLabel={(option) => option.unitName}
                renderOption={(props, unit) => (
                  <Box component="li" {...props}>
                    {unit.unitName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parent Unit"
                    placeholder="Parent Unit"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {errors.unitHasError && (
                <small className="text-danger ">Unit type is required </small>
              )}
            </>
          )}

          <Autocomplete
            className="mt-3"
            size="small"
            id="unit-type"
            defaultValue={null}
            value={unitType || null}
            options={filterUnitTypes()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, unitType) => {
              setErrors({ ...errors, unitTypeHasError: false });
              setUnitType(unitType || null);
              const unitTypeId = unitType.id;
              setFormData({ ...formData, unitTypeId });
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, unitType) => (
              <Box component="li" {...props}>
                {unitType.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Unit Type"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />

          {errors.unitTypeHasError && (
            <small className="text-danger ">Unit type is required </small>
          )}

          <FormControl className="mt-3 mb-0 d-block">
            <FormLabel id="isOnStructure" className="mb-0">
              Is it on structure?
            </FormLabel>
            <RadioGroup
              className="mt-0"
              row
              name="isOnStructure"
              value={formData.isOnStructure}
              onChange={(event) => {
                formData.isOnStructure =
                  event.target.value === "true" ? true : false;
                setFormData({ ...formData });
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={
              loading ||
              (isEditing &&
                selectedUnit &&
                selectedUnit.parentUnitId === formData.parentUnitId &&
                selectedUnit.unitName === formData.unitName &&
                selectedUnit.unitTypeId === formData.unitTypeId &&
                selectedUnit.isOnStructure === formData.isOnStructure)
            }
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, unitTypes, units, user }) => {
  return {
    loading,
    onWait,
    unitTypes,
    units,
    user,
  };
};
export default connect(mapStateToProps, {
  saveOrUpdateUnit,
})(AddOrUpdateUnitDialog);
