import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
} from "@mui/material";
import React from "react";

import { connect } from "react-redux";

const ConfirmationDialog = (props) => {
  const {
    message,
    setConfirmationDialog,
    loading,
    confirmationDialog,
    onYes,
    noPreFormat,
    error,
    btnMessage,
    isLoading,
    disabled,
    onWait,
  } = props;

  return (
    <>
      <Dialog
        maxWidth="xs"
        onClose={() => {
          setConfirmationDialog(false);
        }}
        open={confirmationDialog}
      >
        <DialogTitle className={`text-${error ? "danger" : "primary"}`}>
          <span className="d-flex align-items-center">
            {!error && <span className="material-icons mr-1">error</span>}
            {error && <span className="material-icons mr-1">warning</span>}

            {error ? "Error" : "Confirm"}
          </span>{" "}
          <IconButton
            aria-label="close"
            onClick={() => {
              setConfirmationDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={`pb-0 text-center ${error ? "text-danger" : ""} `}
        >
          {/* {loading || isLoading ? <LinearProgress />:''} */}

          {noPreFormat ? (
            message
          ) : (
            <pre
              style={{
                fontFamily: `"Roboto","Helvetica","Arial","sans-serif"`,
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
                whiteSpace: "pre-wrap",
              }}
            >
              {message}
            </pre>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            disabled={loading || isLoading || disabled || onWait}
            color="primary"
            variant="contained"
            onClick={onYes}
            className="ml-2 px-4"
          >
            {loading || onWait ? "Wait..." : btnMessage || "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait }) => {
  return { loading, onWait };
};
export default connect(mapStateToProps)(ConfirmationDialog);
