import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Autocomplete,
  Box,
  Divider,
} from "@mui/material";

import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { addOrUpdateRRADepartment } from "../../../store/structure/actions";
import NumberFormat from "react-number-format";

const AddOrUpdateRRADepartmentDialog = (props) => {
  const {
    user,
    loading,

    showDialog,
    setShowDialog,

    formData,
    setFormData,

    addOrUpdateRRADepartment,

    supervisors,
  } = props;

  const [supervisor, setSupervisor] = useState(null);

  useEffect(() => {
    if (!!formData.supervisingPositionId)
      setSupervisor(
        supervisors.find(({ id }) => id === formData.supervisingPositionId) ||
          null
      );
  }, []);

  const onClose = () => {
    setFormData({
      id: null,
      name: "",
      supervisingPositionId: "",
    });
    setShowDialog(false);
  };

  const onSave = () => {
    addOrUpdateRRADepartment(formData, onClose);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Typography className="text-primary text-uppercase">
            {formData?.id ? "Edit " : "Add New"} Department
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            size="small"
            multiline
            autoFocus
            rows={4}
            name="name"
            label="Name"
            variant="outlined"
            placeholder="Output description"
            className="mb-3 mt-3"
            value={formData.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value || "" });
            }}
          />

          <Autocomplete
            fullWidth
            size="small"
            className="mb-3"
            value={supervisor}
            options={supervisors}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, supervisor) => {
              setSupervisor(supervisor || null);

              setFormData({
                ...formData,
                supervisingPositionId: supervisor?.id || null,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supervising position"
                placeholder="Supervising position"
              />
            )}
            renderOption={(props, supervisor) => (
              <>
                <Box component="li" {...props}>
                  {supervisor.name}
                  <small
                    style={{ backgroundColor: "#eee" }}
                    className="px-1 ml-1 rounded"
                  >
                    <em>
                      <span className="badge badge-light text-info">
                        {supervisor.unitName}
                      </span>
                    </em>
                  </small>
                </Box>
                <Divider />
              </>
            )}
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={onSave}
            className="ml-2 px-5"
          >
            {loading ? "Wait..." : !!formData?.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, supervisors }) => {
  return { user, loading, supervisors };
};
export default connect(mapStateToProps, {
  addOrUpdateRRADepartment,
})(AddOrUpdateRRADepartmentDialog);
